import React from "react";

// STYLES
import "./SideProfileComponent.scss";

// LIBRARIES
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import imageCompression from "browser-image-compression";
import { useNavigate } from "react-router-dom";

// CONSTANTS & MOCKS

// COMPONENTS
import iconAdmin from "./iconAdmin.png";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import classNames from "classnames";
import Uploader from "components/Uploader/Uploader";
import { ProfileTabs, ROUND_UPLOADER } from "constants/constants";
import { userRolesTypes } from "constants/userRoles";
import moment from "moment";

const SideProfileComponent = (props) => {
  // PROPS
  const {
    position = "",
    localAccountData = {
      personalInfo: {
        fullName: "",
        profilePhoto: {
          content: "",
        },
      },
    },
    accountData = {},
    pageStatus = ProfileTabs.PERSONAL,
    setAccountData = () => {},
    isReadOnly = false,
    handleEdit = () => {},
    logOut = () => {},
  } = props;

  // CONSTANTS USING LIBRARYS
  const navigate = useNavigate();

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS
  const pageWidth = window.innerWidth;

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  const onUploadToBase64 = async (files) => {
    // TODO: refactor this method to be globally
    let base64Img = "";

    files?.forEach((item) => {
      var options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      imageCompression(item, options)
        .then(function (compressedFile) {
          const reader = new FileReader();
          reader.readAsDataURL(compressedFile);

          reader.onload = () => {
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
            if (encoded.length % 4 > 0) {
              encoded += "=".repeat(4 - (encoded.length % 4));
            }

            base64Img = encoded;
            if (base64Img !== "") {
              setAccountData({
                ...localAccountData,
                personalInfo: {
                  ...localAccountData.personalInfo,
                  profilePhoto: base64Img,
                },
              });
            }
          };
          reader.onerror = (error) =>
            console.log("[Uploader] onUploadToBase64", error);
        })
        .catch(function (error) {
          console.log(error.message);
        });
    });
  };
  const redirectAdmin = () => {
    navigate("/admin");
  };

  return (
    <div className="component-side-profile-container">
      <div className="component-side-profile-content-container">
        <div className="component-side-profile-image-container">
          {!isReadOnly && pageStatus === ProfileTabs.PERSONAL ? (
            <div className="image-uploader">
              <Uploader
                onDrop={onUploadToBase64}
                maxFiles={1}
                uploadedImage={localAccountData?.personalInfo?.profilePhoto}
                type={ROUND_UPLOADER}
              />
            </div>
          ) : position === userRolesTypes.ADMIN ? (
            <img
              src={iconAdmin}
              className={classNames(
                "component-side-profile-content-profile-img",
                position
              )}
              alt=""
            />
          ) : (
            <img
              src={`data:image/png;base64,${localAccountData?.personalInfo?.profilePhoto}`}
              alt=""
              className={classNames(
                "component-side-profile-content-profile-img",
                position
              )}
            />
          )}
        </div>

        <div className="component-side-profile-text-container">
          <div className="component-side-profile-name-wrapper">
            <p>{accountData?.fullName}</p>
          </div>
          <div className="component-side-profile-position-wrapper">
            <div className="component-side-profile-line"></div>
            {/* TODO: change to actual position, for the moment is hardcoded */}
            <p>{position}</p>
            <div className="component-side-profile-line"></div>
          </div>
          <div className="component-side-profile-country-wrapper">
            <p>
              <FormattedMessage id={"component.profile.side.profile.country"} />
            </p>
          </div>
        </div>

        <div className="component-side-profile-buttons-container">
          {/*{(projectStatus !== "BLOCKED" ||*/}
          {/*  position === userRolesTypes.PARTNER) && (*/}
          <div className="profile-action-btns-wrapper">
            {position !== userRolesTypes.ADMIN && (
              <div className="component-side-profile-edit-wrapper">
                <ButtonComponent
                  buttonText={"component.profile.side.profile.edit"}
                  color={position === userRolesTypes.BRAINER ? "blue" : "gold"}
                  onClick={handleEdit}
                  selected={
                    isReadOnly && pageWidth <= 900
                      ? true
                      : isReadOnly
                      ? false
                      : true
                  }
                />
              </div>
            )}

            {position === userRolesTypes.ADMIN && (
              <div className="component-side-profile-edit-wrapper-admin">
                <ButtonComponent
                  buttonText={"component.profile.side.profile.admin.redirect"}
                  color={position === userRolesTypes.BRAINER ? "blue" : "gold"}
                  onClick={redirectAdmin}
                  selected={
                    isReadOnly && pageWidth <= 900
                      ? true
                      : isReadOnly
                      ? false
                      : true
                  }
                />
              </div>
            )}
          </div>
          <div className="profile-action-btns-wrapper">
            <div className="component-side-profile-logout-wrapper">
              <ButtonComponent
                buttonText={"component.profile.side.profile.logout"}
                color="gold"
                onClick={logOut}
                selected={
                  isReadOnly && pageWidth <= 900
                    ? true
                    : isReadOnly
                    ? false
                    : true
                }
              />
            </div>
          </div>
          <div className="component-side-profile-member-wrapper">
            <p>
              <FormattedMessage id={"component.profile.side.profile.member"} />
              {moment(accountData?.memberSince).format("DD/MM/YYYY")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

SideProfileComponent.propTypes = {
  name: PropTypes.string,
  position: PropTypes.string,
  logOut: PropTypes.func,
  handleEdit: PropTypes.func,
  setAccountData: PropTypes.func,
  isReadOnly: PropTypes.bool,
  pageStatus: PropTypes.string,
  // TODO: declare object shape
  localAccountData: PropTypes.shape(),
  accountData: PropTypes.shape(),
};

export default SideProfileComponent;
