import React from "react";

// STYLES
// import "./ResertValidationCodeModal.scss";

// LIBRARIES
import PropTypes from "prop-types";

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS
import ButtonComponent from "components/ButtonComponent/ButtonComponent";

const ResetValidationCodeModal = (props) => {
  // PROPS
  const {
    setShowModal = () => {
    }
  } = props

  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className="component-reset-validation-code-container">
      <div className="component-reset-validation-code-title-wrapper ">
        <p className="title">The code was reseted</p>
      </div>
      <div className="component-reset-validation-code-content-wrapper">
        <p className="content">
          Please check your email for the new validation code
        </p>
      </div>

      <div className="component-reset-validation-code-button-wrapper">
        <ButtonComponent
          buttonText={"page.register.nextButton"}
          onClick={() => setShowModal(false)}
        />
      </div>
    </div>
  );
};

ResetValidationCodeModal.propTypes = {
  setShowModal: PropTypes.func,
};

export default ResetValidationCodeModal;