import React, { useEffect, useState } from "react";

// STYLES
import "./InvestmentRequestModal.scss";

// LIBRARIES
import { FormattedMessage, injectIntl } from "react-intl";
import { Grid } from "@mui/material";

// CONSTANTS & MOCKS
import { investmentRequest } from "./Mocks/investmentRequest";
import TextInput from "components/Atoms/TextInput/TextInput";
import CustomButton from "components/CustomButton/CustomButton";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import PropTypes from "prop-types";

// REDUX
import { investmentRequestId, investmentRequestData } from "slices/adminSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptDeal,
  getInvestmentById,
  getInvestmentRequest,
  rejectDeal,
} from "api/adminApi";
import { AdminTabs } from "constants/constants";

// COMPONENTS

const InvestmentRequestModal = (props) => {
  // PROPS
  const { intl = {}, setShowModal = () => {} } = props;

  // CONSTANTS USING LIBRARYS
  const investId = useSelector(investmentRequestId);
  const investData = useSelector(investmentRequestData);
  const dispatch = useDispatch();

  // CONSTANTS USING HOOKS
  const [showInput, setShowInput] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const [data, setData] = useState(0);

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION
  useEffect(() => {
    dispatch(getInvestmentById(investId));

    // eslint-disable-next-line
  }, []);

  // REQUEST API FUNCTIONS
  const handleReject = () => {
    dispatch(rejectDeal(investId)).then(() => {
      setShowModal(false);
    });
  };

  const handleSave = () => {
    if (investId) {
      dispatch(acceptDeal({ finalAmount: data, id: investId.id })).then(() => {
        dispatch(
          getInvestmentRequest({
            type: AdminTabs.INVESTMENT_REQUEST,
            pageNumber: 0,
            pageSize: 10,
          })
        );
        setShowModal(false);
      });
    }
  };

  // HANDLERS FUNCTIONS

  const handleChange = (event) => {
    setData(event.target.value);
  };

  const handleBack = () => {
    setShowModal(false);
  };

  const handleAccept = () => {
    setShowInput(true);
    setShowButtons(false);
  };

  return (
    <Grid container className="component-investment-request-modal-container">
      <Grid
        item
        xs={12}
        className="component-investment-request-modal-title-wrapper"
      >
        <p className={"investment-request-title"}>
          <FormattedMessage id="modal.investment.request.title" />
        </p>
      </Grid>

      <Grid
        item
        container
        xs={12}
        className="component-investment-request-modal-inputs-wrapper"
      >
        {investmentRequest?.map((item, index) => {
          return (
            <Grid item xs={12} md={6} className={"input-element"} key={item.id}>
              <TextInput
                label={item.label}
                readOnly={true}
                name={item.name}
                value={investData?.[item.name] || ""}
                multiline={false}
                minRows={1}
                maxRows={1}
                disabled={true}
                select={false}
              />
            </Grid>
          );
        })}
      </Grid>

      <Grid
        item
        container
        xs={12}
        className={"component-investment-request-buttons-wrapper"}
      >
        <Grid item xs={12} className={"buttons-title-wrapper"}>
          <p className={"buttons-title"}>
            <FormattedMessage id="modal.meeting.buttons.title" />
          </p>
        </Grid>
        <Grid item xs={12} className={"investment-buttons-wrapper"}>
          {showButtons && (
            <>
              <CustomButton
                text={"ACCEPT"}
                color={"blue"}
                isEnabled={true}
                handleChange={handleAccept}
              />
              <CustomButton
                text={"REJECT"}
                color={"blue"}
                isEnabled={true}
                handleChange={handleReject}
              />
            </>
          )}
        </Grid>
        {showInput && (
          <Grid
            item
            xs={6}
            className={"investment-request-final-amount-wrapper"}
          >
            <TextInput
              label={"component.investment.request.modal.final.amount.label"}
              labelColor={"blue"}
              placeholder={intl.formatMessage({
                id: "component.investment.request.modal.final.amount.placeholder",
              })}
              name={"finalAmount"}
              type={"text"}
              handleChange={handleChange}
              select={false}
              component={"textInput"}
              value={data || ""}
              labelStyle="thin"
            />
          </Grid>
        )}
      </Grid>

      <Grid
        item
        xs={12}
        className={"component-investment-request-modal-action-buttons"}
      >
        <ButtonComponent
          buttonText={"admin.project.overview.card.back.button"}
          onClick={handleBack}
        />
        <ButtonComponent
          buttonText={"admin.project.overview.card.save.button"}
          onClick={handleSave}
        />
      </Grid>
    </Grid>
  );
};

InvestmentRequestModal.prototype = {
  //TODO fix this patzzz
  intl: PropTypes.shape(),
  setShowModal: PropTypes.func,
};

export default injectIntl(InvestmentRequestModal);
