import React, { useState } from "react";

// STYLES
import "./AdminTableComponent.scss";

// LIBRARIES
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// CONSTANTS & MOCKS
import {
  AdminTabs,
  BYTES,
  DocumentTypes,
  modalContentType,
  NDA,
} from "constants/constants";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  changeMeeting,
  handleChangePage,
  paginationDetails,
  handleInvestmentData,
  selectedTabs,
  handleModalData,
  handleSaveRequestDetails,
} from "slices/adminSlice";
import {
  downloadRequestDocument,
  getInvestmentReport,
  getInvestmentRequest,
  getPartnersRequest,
  getProjectApproval,
  getSignUpDocument,
  uploadRequestDocument,
  uploadSignUpDocument,
  getProjectRequests,
  getSignUpRequests,
} from "api/adminApi";

// COMPONENTS
import CustomModal from "components/CustomModal/CustomModal";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import eye from "assets/admin/eye.svg";
import DownloadIcon from "assets/pictures/DownloadIcon.svg";
import UploadIcon from "assets/pictures/UploadIcon.svg";
import ViewIcon from "assets/pictures/ViewIcon.svg";
import ApproveButtonIcon from "assets/pictures/ApproveButtonIcon.svg";
import RejectButtonIcon from "assets/pictures/RejectButtonIcon.svg";
import { convertBase64ToFile } from "helpers/converters";
import Dropzone from "react-dropzone";

const AdminTableComponent = (props) => {
  // PROPS
  const {
    header = [],
    data = [],
    handleAction = () => {},
    status = false,
  } = props;

  // CONSTANTS USING LIBRARYS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const paginationData = useSelector(paginationDetails);
  const selectedTabsData = useSelector(selectedTabs);

  // CONSTANTS USING HOOKS
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  // GENERAL CONSTANTS

  // USE EFFECT

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  const handleProjectDetails = (id) => {
    navigate(`/overview/${id}`);
  };

  const handleApproveClick = (item) => {
    let payload = {
      id: item.id,
      requestFromEmail: item.row[0].name,
      requestFromName: item.row[1].name,
      requestToEmail: item.row[2].name,
      requestToName: item.row[3].name,
    };
    dispatch(changeMeeting(payload));
    setShowModal(true);
  };

  const handlePagination = (event, value) => {
    dispatch(
      handleChangePage({
        name: "pageNumber",
        value: value - 1,
      })
    );

    if (selectedTabsData.mainTab === AdminTabs.INVESTMENT) {
      switch (selectedTabsData.secondaryTab) {
        case AdminTabs.INVESTMENT_REQUEST:
          dispatch(
            getInvestmentRequest({
              type: AdminTabs.INVESTMENT_REQUEST,
              pageNumber: value - 1,
              pageSize: paginationData.pageSize,
            })
          );
          break;
        case AdminTabs.DEALS_DONE:
          dispatch(
            getInvestmentRequest({
              type: AdminTabs.DEALS_DONE,
              pageNumber: value - 1,
              pageSize: paginationData.pageSize,
            })
          );
          break;
        case AdminTabs.INVESTMENT_REPORT:
          dispatch(
            getInvestmentReport({
              pageNumber: value - 1,
              pageSize: paginationData.pageSize,
            })
          );
          break;

        default:
          dispatch(
            getInvestmentRequest({
              type: AdminTabs.INVESTMENT_REQUEST,
              pageNumber: value - 1,
              pageSize: paginationData.pageSize,
            })
          );
          break;
      }
    } else {
      switch (selectedTabsData.mainTab) {
        case AdminTabs.PROJECT_APPROVAL:
          dispatch(
            getProjectApproval({
              pageNumber: value - 1,
              pageSize: paginationData.pageSize,
            })
          );
          break;
        case AdminTabs.PROJECT_REQUESTS:
          dispatch(
            getProjectRequests({
              requestType: "IDEA",
              status: selectedTabsData.secondaryTab,
              pageNumber: value - 1,
              pageSize: paginationData.pageSize,
            })
          );
          break;
        case AdminTabs.VIEW_PROJECT_REQUESTS:
          dispatch(
            getProjectRequests({
              requestType: "VIEW",
              status: selectedTabsData.secondaryTab,
              pageNumber: value - 1,
              pageSize: paginationData.pageSize,
            })
          );
          break;
        case AdminTabs.BRAINER_SIGN_UP_REQUESTS:
          dispatch(
            getSignUpRequests({
              signUpRequestStatus: selectedTabsData.secondaryTab,
              role: "BRAINER",
              pageNumber: value - 1,
              pageSize: paginationData.pageSize,
            })
          );
          break;
        case AdminTabs.PARTNER_SIGN_UP_REQUESTS:
          dispatch(
            getSignUpRequests({
              signUpRequestStatus: selectedTabsData.secondaryTab,
              role: "PARTNER",
              pageNumber: value - 1,
              pageSize: paginationData.pageSize,
            })
          );
          break;
        case AdminTabs.PARTNERS_MEETING:
          dispatch(
            getPartnersRequest({
              pageNumber: value - 1,
              pageSize: paginationData.pageSize,
            })
          );
          break;
        default:
          break;
      }
    }
  };

  const handleActionModal = (data, type) => {
    setModalContent(type);
    setShowModal(true);
    if (type === modalContentType.INVESTMENT_REPORT) {
      dispatch(handleModalData(data));
    } else {
      dispatch(handleInvestmentData(data));
    }
  };

  const handleDownloadFile = (requestId, type, requestType) => {
    const action =
      requestType === "project" ? downloadRequestDocument : getSignUpDocument;
    dispatch(
      action({
        requestId,
        documentType: type,
      })
    )
      .unwrap()
      .then((document) => {
        let file = convertBase64ToFile(
          document.content,
          document.fileName,
          document.fileExtension
        );

        const fileURL = URL.createObjectURL(file);
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
      });
  };

  const handleUploadFile = (requestId, file, type, requestType) => {
    let extension = file[0].type.split("/");

    let documentModel = {
      type: NDA,
      file: file[0],
      fileName: file[0].name,
      fileExtension: extension[1],
    };

    // TODO: refactor this code
    let formData = new FormData();
    file = new File([documentModel.file], documentModel.fileName, { type });
    formData.append("file", file);

    const action =
      requestType === "project" ? uploadRequestDocument : uploadSignUpDocument;
    dispatch(
      action({
        requestId,
        documentType: type,
        file: formData,
      })
    ).then(() => {
      handleAction();
    });
  };

  const handleApproveOrRejectProjectRequest = (id, action, type) => {
    dispatch(
      handleSaveRequestDetails({
        requestId: id,
        requestType: type,
      })
    );

    if (action === "approve") {
      switch (selectedTabsData.mainTab) {
        case AdminTabs.PROJECT_REQUESTS:
          setModalContent(modalContentType.APPROVE_PROJECT_REQUEST);
          break;
        case AdminTabs.VIEW_PROJECT_REQUESTS:
          setModalContent(modalContentType.APPROVE_PROJECT_VIEW_REQUEST);
          break;
        case AdminTabs.PARTNER_SIGN_UP_REQUESTS:
        case AdminTabs.BRAINER_SIGN_UP_REQUESTS:
          setModalContent(modalContentType.APPROVE_SIGN_UP_REQUEST);
          break;
        default:
          break;
      }
    } else {
      switch (selectedTabsData.mainTab) {
        case AdminTabs.PROJECT_REQUESTS:
          setModalContent(modalContentType.REJECT_PROJECT_REQUEST);
          break;
        case AdminTabs.VIEW_PROJECT_REQUESTS:
          setModalContent(modalContentType.REJECT_PROJECT_VIEW_REQUEST);
          break;
        case AdminTabs.PARTNER_SIGN_UP_REQUESTS:
        case AdminTabs.BRAINER_SIGN_UP_REQUESTS:
          setModalContent(modalContentType.REJECT_SIGN_UP_REQUEST);
          break;
        default:
          break;
      }
    }
    setShowModal(true);
  };

  const handleRenderUploadDownloadProjectOrViewRequestsSection = (
    item,
    type
  ) => {
    return (
      <TableCell className="table-cell-action" component="th" scope="row">
        {((type === DocumentTypes.NDA && item.row[3]?.name === false) ||
          (type === DocumentTypes.PARTNERSHIP_AGREEMENT &&
            item.row[4]?.name === false)) &&
        status === "PENDING" ? (
          <div className="table-view-details">
            <img
              src={DownloadIcon}
              className="icon"
              alt={""}
              onClick={() => handleDownloadFile(item.id, type, "project")}
            />
            <div>
              <Dropzone
                onDrop={(file) =>
                  handleUploadFile(item.id, file, type, "project")
                }
                maxFiles={1}
                maxSize={10 * BYTES} // MB
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <img src={UploadIcon} alt={""} className="icon" />
                    <input {...getInputProps()} accept={"application/pdf"} />
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
        ) : (
          <div className="table-view-details">
            <img
              src={ViewIcon}
              className="icon"
              alt={""}
              onClick={() => handleDownloadFile(item.id, type, "project")}
            />
            {status === "PENDING" && (
              <div>
                <Dropzone
                  onDrop={(file) =>
                    handleUploadFile(item.id, file, type, "project")
                  }
                  maxFiles={1}
                  maxSize={10 * BYTES} // MB
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <img src={UploadIcon} alt={""} className="icon" />
                      <input {...getInputProps()} accept={"application/pdf"} />
                    </div>
                  )}
                </Dropzone>
              </div>
            )}
          </div>
        )}
      </TableCell>
    );
  };

  const handleRenderUploadDownloadSignUpRequestsSection = (item, type) => {
    return (
      <TableCell className="table-cell-action" component="th" scope="row">
        {type === "SIGN_UP_NDA" &&
        item.row[2]?.name === false &&
        status === "PENDING" ? (
          <div className="table-view-details">
            <img
              src={DownloadIcon}
              className="icon"
              alt={""}
              // TODO: change to proper endpoint
              onClick={() => handleDownloadFile(item.id, type, "sign_up")}
            />
            <div>
              <Dropzone
                // TODO: change to proper endpoint
                onDrop={(file) =>
                  handleUploadFile(item.id, file, type, "sign_up")
                }
                maxFiles={1}
                maxSize={10 * BYTES} // MB
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <img src={UploadIcon} alt={""} className="icon" />
                    <input {...getInputProps()} accept={"application/pdf"} />
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
        ) : (
          <div className="table-view-details">
            <img
              src={ViewIcon}
              className="icon"
              alt={""}
              // TODO: change to proper endpoint
              onClick={() => handleDownloadFile(item.id, type, "sign_up")}
            />
            {status === "PENDING" && (
              <div>
                <Dropzone
                  // TODO: change to proper endpoint
                  onDrop={(file) =>
                    handleUploadFile(item.id, file, type, "sign_up")
                  }
                  maxFiles={1}
                  maxSize={10 * BYTES} // MB
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <img src={UploadIcon} alt={""} className="icon" />
                      <input {...getInputProps()} accept={"application/pdf"} />
                    </div>
                  )}
                </Dropzone>
              </div>
            )}
          </div>
        )}
      </TableCell>
    );
  };

  const handleRenderApproveAndRejectButton = (item) => {
    if (status === "PENDING") {
      switch (selectedTabsData.mainTab) {
        case AdminTabs.PROJECT_REQUESTS:
        case AdminTabs.VIEW_PROJECT_REQUESTS:
          return (
            <TableCell
              className="table-cell-view-action"
              component="th"
              scope="row"
              align="right"
            >
              {item?.row[3]?.name === true && item?.row[4]?.name === true ? (
                <div className="table-view-details">
                  <div className="admin-button-wrapper">
                    <img
                      src={ApproveButtonIcon}
                      alt={""}
                      onClick={() =>
                        handleApproveOrRejectProjectRequest(
                          item.id,
                          "approve",
                          "project"
                        )
                      }
                    />
                  </div>
                  <div className="admin-button-wrapper">
                    <img
                      src={RejectButtonIcon}
                      alt={""}
                      onClick={() =>
                        handleApproveOrRejectProjectRequest(
                          item.id,
                          "reject",
                          "project"
                        )
                      }
                    />
                  </div>
                </div>
              ) : (
                <div className="admin-button-wrapper">
                  <img
                    src={RejectButtonIcon}
                    alt={""}
                    onClick={() =>
                      handleApproveOrRejectProjectRequest(
                        item.id,
                        "reject",
                        "project"
                      )
                    }
                  />
                </div>
              )}
            </TableCell>
          );
        case AdminTabs.BRAINER_SIGN_UP_REQUESTS:
        case AdminTabs.PARTNER_SIGN_UP_REQUESTS:
          return (
            <TableCell
              className="table-cell-view-action"
              component="th"
              scope="row"
              align="right"
            >
              {item?.row[2]?.name === true ? (
                <div className="table-view-details">
                  <div className="admin-button-wrapper">
                    <img
                      src={ApproveButtonIcon}
                      alt={""}
                      onClick={() =>
                        handleApproveOrRejectProjectRequest(
                          item.id,
                          "approve",
                          "sign_up"
                        )
                      }
                    />
                  </div>
                  <div className="admin-button-wrapper">
                    <img
                      src={RejectButtonIcon}
                      alt={""}
                      onClick={() =>
                        handleApproveOrRejectProjectRequest(
                          item.id,
                          "reject",
                          "sign_up"
                        )
                      }
                    />
                  </div>
                </div>
              ) : (
                <div className="admin-button-wrapper">
                  <img
                    src={RejectButtonIcon}
                    alt={""}
                    onClick={() =>
                      handleApproveOrRejectProjectRequest(
                        item.id,
                        "reject",
                        "sign_up"
                      )
                    }
                  />
                </div>
              )}
            </TableCell>
          );
        default:
          break;
      }
    }
  };

  return (
    <div className="component-admin-table-container">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {header.map((item, index) => {
                if (status === false) {
                  return (
                    <TableCell
                      className={"table-header-item"}
                      align={index === header.length - 1 ? "right" : "center"}
                      key={`item--${index}--${item.id}`}
                    >
                      <p className={"component-admin-table-header-text"}>
                        {item.title}
                      </p>
                    </TableCell>
                  );
                } else if (status === "PENDING") {
                  return (
                    <TableCell
                      className={"table-header-item"}
                      align={index === header.length - 1 ? "right" : "center"}
                      key={`item--${index}--${item.id}`}
                    >
                      <p className={"component-admin-table-header-text"}>
                        {item.title}
                      </p>
                    </TableCell>
                  );
                } else if (index < header.length - 1) {
                  return (
                    <TableCell
                      className={"table-header-item"}
                      align={index === header.length - 1 ? "right" : "center"}
                      key={`item--${index}--${item.id}`}
                    >
                      <p className={"component-admin-table-header-text"}>
                        {item.title}
                      </p>
                    </TableCell>
                  );
                } else {
                  return null;
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item) => (
              <TableRow
                key={item.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className={"table-row-item"}
              >
                {item?.row.map((cell, cellIndex) => {
                  if (cell.documentType === "nda") {
                    return handleRenderUploadDownloadProjectOrViewRequestsSection(
                      item,
                      DocumentTypes.NDA
                    );
                  } else if (cell.documentType === "agreement") {
                    return handleRenderUploadDownloadProjectOrViewRequestsSection(
                      item,
                      DocumentTypes.PARTNERSHIP_AGREEMENT
                    );
                  } else if (cell.documentType === "signUpNda") {
                    return handleRenderUploadDownloadSignUpRequestsSection(
                      item,
                      "SIGN_UP_NDA"
                    );
                  } else {
                    return (
                      <TableCell
                        className="table-body-item"
                        component="th"
                        scope="row"
                        align={
                          cellIndex === header.length - 1 ? "right" : "center"
                        }
                        key={`key--${item?.id}--${cellIndex}`}
                      >
                        <p className={"component-admin-table-body-text"}>
                          {cell?.name}
                        </p>
                      </TableCell>
                    );
                  }
                })}
                {handleRenderApproveAndRejectButton(item)}
                {selectedTabsData.mainTab === AdminTabs.PROJECT_APPROVAL && (
                  <TableCell
                    className="table-cell-view-action"
                    component="th"
                    scope="row"
                    align="right"
                  >
                    <div className="table-view-details">
                      <img
                        src={eye}
                        alt={""}
                        onClick={() => handleProjectDetails(item.id)}
                      />
                    </div>
                  </TableCell>
                )}
                {selectedTabsData.mainTab === AdminTabs.INVESTMENT &&
                  selectedTabsData.secondaryTab === "REQUEST" && (
                    <TableCell
                      className="table-cell-view-action"
                      component="th"
                      scope="row"
                      align="right"
                    >
                      <div className="table-view-details">
                        <img
                          src={eye}
                          alt={""}
                          onClick={() =>
                            handleActionModal(
                              item,
                              modalContentType.INVESTMENT_REQUEST
                            )
                          }
                        />
                      </div>
                    </TableCell>
                  )}
                {selectedTabsData.mainTab === AdminTabs.INVESTMENT &&
                  selectedTabsData.secondaryTab ===
                    AdminTabs.INVESTMENT_REPORT && (
                    <TableCell
                      className="table-cell-view-action"
                      component="th"
                      scope="row"
                      align="right"
                    >
                      <div className="table-view-details">
                        <img
                          src={eye}
                          alt={""}
                          onClick={() =>
                            handleActionModal(
                              item,
                              modalContentType.INVESTMENT_REPORT
                            )
                          }
                        />
                      </div>
                    </TableCell>
                  )}
                {selectedTabsData.mainTab === AdminTabs.PARTNERS_MEETING && (
                  <TableCell
                    className="table-body-item"
                    component="th"
                    scope="row"
                    align="right"
                  >
                    <div className="table-meeting-button">
                      <ButtonComponent
                        buttonText={"component.approve.button"}
                        color={"gold"}
                        selected={true}
                        onClick={() => {
                          handleApproveClick(item);
                        }}
                      />
                    </div>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {data?.length < 1 && (
        <div className="table-row-no-content">
          <p className="no-content-text">There is no content to display</p>
        </div>
      )}
      <CustomModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        directionTo="top"
        modalContent={modalContent}
        setShowModal={setShowModal}
      />
      <div style={{ marginTop: "2rem" }}>
        <Pagination
          count={Math.ceil(
            paginationData?.totalCount / paginationData?.pageSize
          )}
          page={paginationData?.pageNumber + 1}
          // siblingCount={0}
          shape="rounded"
          onChange={handlePagination}
        />
      </div>
    </div>
  );
};

AdminTableComponent.propTypes = {
  header: PropTypes.array,
  status: PropTypes.object,
  data: PropTypes.shape({
    data: PropTypes.array,
  }),
  handleAction: PropTypes.func,
};

export default AdminTableComponent;
