import React from "react";

// STYLES
import "./TitleAndSubtitle.scss";

// LIBRARIES
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const TitleAndSubtitle = (props) => {
  // PROPS
  const {title = "", subtitle = ""} = props;

  return (
    <div className="component-title-container">
      <div className="component-title-wrapper">
        <span className="component-title-title-text">
          <FormattedMessage id={title}/>
        </span>
      </div>
      {subtitle && (
        <div className="component-title-subtitle-wrapper">
          <span className="component-title-subtitle-text">
            <FormattedMessage id={subtitle}/>
          </span>
        </div>
      )}
    </div>
  );
};

TitleAndSubtitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default TitleAndSubtitle;
