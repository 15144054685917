import React from "react";

// STYLES
import "components/Atoms/Loading/Loading.scss";

// LIBRARIES
import CircularProgress from '@mui/material/CircularProgress';

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS

const Loading = (props) => {
  // PROPS


  // CONSTANTS USING HOOKS

  return (
    <div className="component-loading-container">
      <CircularProgress/>
    </div>
  );
};

Loading.propTypes = {};

export default Loading;
