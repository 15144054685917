import { AdminTabs } from "constants/constants";

export const buttonName = [
  {
    id: 1,
    buttonText: "admin.component.select.table.brainerSignUpRequests",
    color: "blue",
    clickDetail: AdminTabs.BRAINER_SIGN_UP_REQUESTS,
    action: "getSignUpRequests",
  },
  {
    id: 2,
    buttonText: "admin.component.select.table.partnerSignUpRequests",
    color: "blue",
    clickDetail: AdminTabs.PARTNER_SIGN_UP_REQUESTS,
    action: "getSignUpRequests",
  },
  {
    id: 3,
    buttonText: "admin.component.select.table.projectRequests",
    color: "blue",
    clickDetail: AdminTabs.PROJECT_REQUESTS,
    action: "getProjectRequests",
  },
  {
    id: 4,
    buttonText: "admin.component.select.table.projectApproval",
    color: "blue",
    clickDetail: AdminTabs.PROJECT_APPROVAL,
    action: "getProjectApproval",
  },
  {
    id: 5,
    buttonText: "admin.component.select.table.viewProjectRequests",
    color: "blue",
    clickDetail: AdminTabs.VIEW_PROJECT_REQUESTS,
    action: "getProjectRequests",
  },
  {
    id: 6,
    buttonText: "admin.component.select.table.invest",
    color: "blue",
    clickDetail: AdminTabs.INVESTMENT,
    action: "getInvestment",
  },
  {
    id: 7,
    buttonText: "admin.component.select.partnersMeeting",
    color: "blue",
    clickDetail: AdminTabs.PARTNERS_MEETING,
  },
  {
    id: 8,
    buttonText: "admin.component.select.categories",
    color: "blue",
    clickDetail: "categoriesList",
    action: "",
  },
];

export const investmentTableHeader = [
  {
    id: 0,
    title: "Project name",
  },
  {
    id: 1,
    title: "Partner email",
  },
  {
    id: 2,
    title: "Pledged founds",
  },

  {
    id: 3,
    title: "Actions",
  },
];

export const projectApprovalTableHeader = [
  {
    id: 1,
    title: "Project name",
  },
  {
    id: 2,
    title: "Submission by",
  },
  {
    id: 3,
    title: "E-mail",
  },
  {
    id: 4,
    title: "Category",
  },
  {
    id: 5,
    title: "View submission",
  },
];

export const projectRequestTableHeader = [
  {
    id: 1,
    title: "Partner name",
  },
  {
    id: 2,
    title: "E-mail",
  },
  {
    id: 3,
    title: "Project name",
  },
  {
    id: 4,
    title: "Non-disclosure agreement",
  },
  {
    id: 5,
    title: "Contract close deal",
  },
  {
    id: 6,
    title: "Action",
  },
];

export const signUpRequestsTableHeader = [
  {
    id: 1,
    title: "Full name",
  },
  {
    id: 2,
    title: "E-mail",
  },
  {
    id: 3,
    title: "Non-disclosure agreement",
  },
  {
    id: 4,
    title: "Action",
  },
];

export const partnersMeetingTableHeader = [
  {
    id: 1,
    title: "Request from",
  },
  {
    id: 2,
    title: "E-mail",
  },
  {
    id: 3,
    title: "Request to",
  },
  {
    id: 4,
    title: "E-mail",
  },
  {
    id: 5,
    title: "Actions",
  },
];

export const investmentClosedDealsHeader = [
  {
    id: 1,
    title: "Partner name",
  },
  {
    id: 2,
    title: "Brainer name",
  },
  {
    id: 3,
    title: "Final amount",
  },
];

export const investmentReportHeader = [
  {
    id: 1,
    title: "Partner name",
  },
  {
    id: 2,
    title: "Country",
  },
  {
    id: 3,
    title: "Money pledged",
  },
  {
    id: 4,
    title: "Projects total views",
  },
  {
    id: 5,
    title: "Partnership closed",
  },
  {
    id: 6,
    title: "Partner email",
  },
  {
    id: 7,
    title: "NDA",
  },
];

export const titleDataProject = [
  "Project name",
  "Submission by",
  "E-mail",
  "Category",
  "View submission",
];

export const titleDataPartnersApproval = [
  "Request from",
  "E-mail",
  "Request to",
  "E-mail",
  "Accepted",
  "Not accepted",
];
export const titleDataPartnersList = [
  "Partner name",
  "Country",
  "Money pledged",
  "Project total views",
  "Partnership closed",
];

export const rowContentProjectApproval = [
  "nume",
  "nume lung",
  "mailmail",
  "categorie",
];

export const rowContentPartnersApproval = [
  {
    name: "name",
    email: "email",
    requestTo: "other's guy name",
    email2: "email 2",
    accept: "accept",
    reject: "reject",
  },
  {
    name: "name",
    email: "email",
    requestTo: "other's guy name",
    email2: "email 2",
    accept: "accept",
    reject: "reject",
  },
];

export const investmentSubcategoryButtonName = [
  {
    id: 1,
    title: "admin.component.select.subcategory.investmentRequest",
    name: "REQUEST",
  },
  {
    id: 2,
    title: "admin.component.select.subcategory.dealsDone",
    name: "CLOSED_DEAL",
  },
  {
    id: 3,
    title: "admin.component.select.subcategory.investmentReport",
    name: "REPORT",
  },
];

export const projectRequestButtonName = [
  {
    id: 1,
    title: "admin.component.select.project.request.pending",
    name: "PENDING",
  },
  {
    id: 2,
    title: "admin.component.select.project.request.approved",
    name: "APPROVED",
  },
  {
    id: 3,
    title: "admin.component.select.project.request.rejected",
    name: "REJECTED",
  },
];
