import React from "react";

// STYLES
import "./ViewIdeaDetails.scss";

// LIBRARIES

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS
import ViewIdeaDetailsCard from "components/ViewIdeaDetailsCard/ViewIdeaDetailsCard";

const ViewIdeaDetails = () => {
  // PROPS

  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className="page-view-idea-details-container">
      <ViewIdeaDetailsCard />
    </div>
  );
};

export default ViewIdeaDetails;
