import React, { useState } from "react";

// STYLES
import "./ModalChangePasswordContent.scss";

// LIBRARIES
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";

// CONSTANTS & MOCKS
import { resetPasswordInputs } from "./Mocks/inputMock";

// REDUX
import { useDispatch } from "react-redux";
import { changePassword } from "api/userApi";

// COMPONENTS
import TextInput from "components/Atoms/TextInput/TextInput";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";

const ModalChangePasswordContent = (props) => {
  // PROPS
  const { setShowModal = () => {}, intl = {} } = props;
  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch();

  // CONSTANTS USING HOOKS
  const [formState, setFormState] = useState({
    values: {},
  });

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  const handleChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleSubmit = () => {
    dispatch(changePassword(formState.values)).then(() => {
      setShowModal(false);
    });
  };

  return (
    <div className="component-modal-change-password-content-container">
      <div className="modal-change-password-content-title-subtitle">
        <p className="title">
          <FormattedMessage id={"modal.change.password.content.title"} />
        </p>
        <p className="subtitle">
          <FormattedMessage id={"modal.change.password.content.subtitle"} />
        </p>
      </div>
      <div className="modal-change-password-content-inputs">
        {resetPasswordInputs?.map((item, index) => {
          return (
            <TextInput
              key={`item--${index}--${item.label}`}
              label={item?.label}
              labelColor={"blue"}
              placeholder={intl.formatMessage({ id: item.placeholder })}
              name={item?.name}
              type={item?.type}
              handleChange={handleChange}
              select={item.component === "select" ? true : false}
              component={item.component}
              value={formState.values[item.name] || ""}
            />
          );
        })}
      </div>
      <div className="modal-change-password-content-buttons">
        <ButtonComponent
          buttonText={"modal.change.password.content.back.button"}
          onClick={() => setShowModal(false)}
        />
        <div style={{ marginLeft: "4rem" }}>
          <ButtonComponent
            buttonText={"modal.change.password.content.save.button"}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

ModalChangePasswordContent.prototype = {
  intl: PropTypes.shape(),
  setShowModal: PropTypes.func,
};

export default injectIntl(ModalChangePasswordContent);
