import React, { useState, useEffect } from "react";

import "./ExplorePage.scss";

// LIBRARIES
import classNames from "classnames";

// CONSTANTS & MOCKS

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "api/sessionApi";
import { getFilteredProjects, getFilteredPartners } from "api/exploreApi";
import {
  explorePage,
  filterType,
  handleExplorePageNr,
} from "slices/sessionSlice";

// COMPONENTS
import SelectCategoryComponent from "components/SelectCategoryComponent/SelectCategoryComponent";
import SearchComponent from "components/SearchComponent/SearchComponent";
import ExploreProjectsAndPartners from "components/ExploreProjectsAndPartners/ExploreProjectsAndPartners";
import SearchMobile from "components/SearchMobile/SearchMobile";

const ExplorePage = () => {
  // PROPS

  // CONSTANTS USING LIBRARIES
  const dispatch = useDispatch();
  const explorePageData = useSelector(explorePage);
  const exploreFilter = useSelector(filterType);

  // CONSTANTS USING HOOKS
  const [showFilter, setShowFilter] = useState(false);

  // GENERAL CONSTANTS
  const currentPageNr = `${exploreFilter}PageNr`;
  const currentPageSize = `${exploreFilter}PageSize`;
  const currentFilter = `${exploreFilter}Filter`;

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  const handleSearch = () => {
    dispatch(handleExplorePageNr({ type: exploreFilter, value: 0 }));

    if (exploreFilter === "projects") {
      dispatch(
        getFilteredProjects({
          ...explorePageData?.[currentFilter],
          pageNumber: explorePageData?.[currentPageNr],
        })
      );
    } else if (exploreFilter === "partners") {
      dispatch(
        getFilteredPartners({
          nameStartsWith: explorePageData[currentFilter]?.nameStartsWith,
          pageNumber: explorePageData?.[currentPageNr],
          pageSize: explorePageData?.[currentPageSize],
          country: explorePageData?.[currentFilter].country,
        })
      );
    }
  };

  return (
    <div className="page-explore-container">
      <div
        className={classNames(
          exploreFilter === "partners" && "search-component-margin-down",
          "page-explore-search-component-container"
        )}
      >
         <SearchComponent handleSearch={handleSearch} />
      </div>
      <div className="page-explore-search-mobile-container">
        <SearchMobile
          projectnumbers={500}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          handleSearch={handleSearch}
        />
      </div>
      <div className="page-explore-filter-container">
        {exploreFilter === "projects" && <SelectCategoryComponent />}
      </div>
      <div className="page-explore-projects-partners-component-container">
        {!showFilter && <ExploreProjectsAndPartners />}
      </div>
    </div>
  );
};

export default ExplorePage;
