import React from "react";
// STYLES
import "./AboutUsCard.scss";
// LIBRARIES
import PropTypes from "prop-types";
//COMPONENTS

const AboutUsCard = (props) => {
  // PROPS
  const { number = "", title = "", text = "" } = props;

  return (
    <div className="component-card-container">
      <div className="component-card-title-number-container">
        <span className="component-card-number-text">{number}</span>

        <span className="component-card-title-text">{title}</span>
      </div>
      <div className="component-card-text-wrapper">
        <span className="component-card-text-text">{text}</span>
      </div>
    </div>
  );
};

AboutUsCard.propTypes = {
  number: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
};

export default AboutUsCard;
