import React from "react";

// STYLES
// import "./MandatoryFields.scss";

// LIBRARIES
import { Grid } from "@mui/material";
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";

// CONSTANTS & MOCKS
import { projectDescription } from "./Mocks/projectBuilderMock";
import TextInput from "../Atoms/TextInput/TextInput";
import { currency } from "constants/constants";

// REDUX
import { getCategories } from "api/sessionApi";
import { useDispatch } from "react-redux";

// COMPONENTS

const MandatoryFields = (props) => {
  // PROPS
  const {
    intl = {},
    handleChange = () => {},
    formState = {},
    categoryList = [],
    subcategoryList = [],
  } = props;

  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch();

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  const localHandleChange = (event, field) => {
    if (
      (event.target.value.length === 0 && field.allowEmpty === true) ||
      (event.target.value.length > 0 && field.allowEmpty === false)
    ) {
      handleChange(event, true, field);
    } else {
      handleChange(event, false, field);
    }
  };

  const handleGet = () => {
    dispatch(getCategories());
  };

  return (
    <Grid container className="component-MandatoryFields-container">
      <div className="page-build-project-card-title-wrapper">
        <p className={"title-number"}>1.</p>
        <div className="title-text-wrapper">
          <p className="title">
            <FormattedMessage id={"component.project.builder.firstTitle"} />
          </p>
          <p className="subtitle">
            <FormattedMessage
              id={"component.project.builder.label.mandatoryFields"}
            />
          </p>
        </div>
      </div>
      {projectDescription?.map((field, index) => {
        return (
          <Grid
            item
            xs={field.xs}
            md={field.md}
            className={"component-MandatoryFields-item"}
            key={`inputs--${index}`}
          >
            <TextInput
              label={field?.label}
              labelColor={"blue"}
              placeholder={intl.formatMessage({ id: field.placeholder })}
              name={field?.name}
              type={field?.type}
              handleChange={(event) => localHandleChange(event, field)}
              selectValues={
                field.content === "categories"
                  ? categoryList
                  : field.content === "subcategories"
                  ? subcategoryList
                  : currency
              }
              select={field.component === "select" ? true : false}
              component={field.component}
              multiline={field.multiline}
              value={
                field?.name === "shares"
                  ? parseInt(formState.values[field.name])
                  : formState.values[field.name] || ""
              }
              handleGet={handleGet}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

MandatoryFields.prototype = {
  intl: PropTypes.shape(),
  formState: PropTypes.shape(),
  handleChange: PropTypes.func,
  categoryList: PropTypes.array,
  subcategoryList: PropTypes.array,
};

export default injectIntl(MandatoryFields);
