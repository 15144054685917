import React from "react";

// STYLES
import "./OverviewProjectDetailsCard.scss";

// LIBRARIES
import PropTypes from "prop-types";

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS
import overviewLocationImage from "assets/components/overviewProjectDetailsCard/overviewLocationImage.svg";
import overviewCategoryType from "assets/components/overviewProjectDetailsCard/overviewCategoryType.svg";

const OverviewProjectDetailsCard = (props) => {
  // PROPS
  const {projectDetails = {}} = props;
  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className="component-overview-project-details-card-container">
      <div className="component-overview-project-details-card-wrapper">
        <p className="component-overview-project-details-card-title">
          {projectDetails?.projectName}
        </p>
        <p className="component-overview-project-details-card-description">
          {projectDetails?.projectShortDescription}
        </p>
        {projectDetails?.companyProjectModel?.documentModel && (
          <div className="component-overview-project-details-card-details">
            <div className="details-company-logo-name">
              <div className="logo">
                <img
                  src={`data:image/png;base64,${projectDetails?.companyProjectModel?.documentModel?.content}`}
                  alt=""
                  className="company-logo"
                />
              </div>
              <p className="name">
                {projectDetails.companyProjectModel?.companyName}
              </p>
            </div>
            <div className="details-location-image-city">
              <div className="image">
                <img src={overviewLocationImage} alt=""/>
              </div>
              <p className="city">
                {projectDetails?.companyProjectModel?.registeredOffice}
              </p>
            </div>
            <div className="details-category-picture-type">
              <div className="picture">
                <img src={overviewCategoryType} alt=""/>
              </div>
              <p className="type">{projectDetails?.categoryModel?.name}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

OverviewProjectDetailsCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  companyLogo: PropTypes.string,
  companyName: PropTypes.string,
  locationLogo: PropTypes.string,
  locationCity: PropTypes.string,
  categoryLogo: PropTypes.string,
  categoryName: PropTypes.string,
  projectDetails: PropTypes.shape({}),
};

export default OverviewProjectDetailsCard;
