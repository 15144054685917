import React from "react";

// STYLES
import "./PartnerPledgeCard.scss";
import CreditCardIcon from "@mui/icons-material/CreditCard";
// LIBRARIES
import PropTypes from "prop-types";

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS

const PartnerPledgeCard = (props) => {
  // PROPS
  const {
    title = "",
    subtitle = "",
    logo = "",
    investedAmount = 0
  } = props;
  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className="component-partner-pledge-card-container">
      <div className="component-partner-card-information-container">

        <div className="component-partner-card-logo-wrapper">
          <img src={`data:image/png;base64,${logo}`} alt="logo" className={"invested-project-logo"}/>
        </div>

        <div className="component-partner-card-title-subtitle-container">
          <p className="component-partner-card-title-wrapper">{title}</p>
          <div className="subtitle-wrapper">
            <p className="component-partner-card-subtitle-wrapper">
              {subtitle}
            </p>
          </div>
        </div>

      </div>
      <div className="component-partner-card-amount-container">
        <CreditCardIcon/>
        {/*//TODO: ask for invested amount*/}
        <p>{investedAmount}</p>
      </div>
    </div>
  );
};

PartnerPledgeCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  amount: PropTypes.string,
  logo: PropTypes.string,
  investedAmount: PropTypes.number,
};
export default PartnerPledgeCard;
