import React, { useEffect } from "react";

// STYLES
import "./PaymentSuccess.scss";

// LIBRARIES
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

// CONSTANTS & MOCKS

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { payment } from "api/sessionApi";
import { paymentPayload, removePaymentPayload } from "slices/userSlice";

// COMPONENTS
import ButtonComponent from "components/ButtonComponent/ButtonComponent";

const PaymentSuccess = () => {
  // PROPS

  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paymentPayloadData = useSelector(paymentPayload);

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION
  useEffect(() => {
    let payloadToSend = localStorage.getItem("paymentPayload");
    if (Object.keys(JSON.parse(payloadToSend)).length > 0) {
      dispatch(payment(JSON.parse(payloadToSend)))
        .unwrap()
        .then(() => {
          dispatch(removePaymentPayload());
        });
    }
    // eslint-disable-next-line
  }, [paymentPayloadData]);

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className="page-payment-success-container">
      <div className="page-payment-card-container">
        <div className="page-payment-success-title-wrapper">
          <p className="title">
            <FormattedMessage id={"page.payment.success.title"} />
          </p>
          <p className="subtitle">
            <FormattedMessage id={"page.payment.success.subtitle"} />
          </p>
          <div className="button-wrapper">
            <ButtonComponent
              buttonText={"page.payment.success.button.profile"}
              color={"blue"}
              onClick={() => navigate(`/profile-page`)}
            />
            <ButtonComponent
              buttonText={"page.payment.success.button.homepage"}
              color={"blue"}
              onClick={() => navigate(`/homepage`)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
