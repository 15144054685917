import React, { useState, useEffect } from "react";

// STYLES
import "./SelectCategoryComponent.scss";

// LIBRARIES
import classNames from "classnames";
import { injectIntl } from "react-intl";

// CONSTANTS & MOCKS
import { listOfCategories, rangeInputs } from "./selectCategoryMock";
import countriesList from "constants/countriesList";

// REDUX
import { getExploreSubcategories } from "api/exploreApi";
import { useDispatch, useSelector } from "react-redux";
import {
  categories,
  handleExploreFilterChange,
  explorePage,
  subcategoryList,
  handleResetFilters,
  language,
  filterType,
} from "slices/sessionSlice";

// COMPONENTS
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import PropTypes from "prop-types";
import CustomInput from "components/CustomInput/CustomInput";
import { getCategories, getSubcategories } from "api/sessionApi";

const SelectCategoryComponent = (props) => {
  // PROPS
  const { intl = {} } = props;

  // CONSTANTS USING LIBRARIES
  const dispatch = useDispatch();
  const categoriesListData = useSelector(categories);
  const subcategoryListData = useSelector(subcategoryList);
  const explorePageData = useSelector(explorePage);
  const languageData = useSelector(language);
  const exploreFilter = useSelector(filterType);

  // CONSTANTS USING HOOKS
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [toggleButton, setToggleButton] = useState(false);
  const [filter, setFilter] = useState("list");
  const [localSubs, setLocalSubs] = useState({});
  const [selectedCategories, setSelectedCategories] = useState([]);

  // GENERAL CONSTANTS
  const currentPageNr = `${exploreFilter}PageNr`;
  const currentFilter = `${exploreFilter}Filter`;
  const currentProjectFilter = `${exploreFilter}Filter`;
  const localCountries = countriesList;
  const localAmountRange = [
    { name: "10000 - 50000" },
    { name: "50000 - 100000" },
    { name: "100000 - 150000" },
  ];
  const localCurrency = [{ name: "USD" }, { name: "EUR" }];
  const localSorted = [
    { name: "TRENDING" },
    { name: "NEWEST" },
    { name: "OLDEST" },
  ];

  // USE EFFECT FUNCTION
  useEffect(() => {
    dispatch(getCategories());

    const handleCloseList = (e) => {
      const isNot = !e.target.className.includes("subcategoriesList");
      isNot && setToggleButton(false);
    };

    document.addEventListener("click", handleCloseList, false);

    return () => window.removeEventListener("click", handleCloseList, false);
  }, []);

  // HANDLERS FUNCTIONS
  const handleListToMap = (val) => {
    switch (val) {
      case "CATEGORY":
        return categoriesListData;

      case "SUBCATEGORY":
        return subcategoryListData || [];

      case "PRICE RANGE":
        return localAmountRange;

      case "COUNTRY":
        return localCountries;

      case "CURRENCY":
        return localCurrency;

      case "SORTED BY":
        return localSorted;
      default:
        break;
    }
  };

  const handleToggleButton = (val, type) => {
    setSelectedCategoryId(val.id);

    if (type !== "clearFilters") {
      setToggleButton(true);

      setFilter(type);
    } else {
      setToggleButton(false);
      setSelectedCategories([]);
      dispatch(handleResetFilters());
    }
  };
  const handleChange = (event, name, id) => {
    let localCategories;
    let catArr = selectedCategories;

    if (!catArr.includes(id)) {
      catArr.push(id);
      setSelectedCategories(catArr);
    } else if (catArr.includes(id)) {
      let catArrFiltered = [];
      catArr.map((item) => {
        if (item !== id) {
          catArrFiltered.push(item);
        }
      });
      catArr = catArrFiltered;

      setSelectedCategories(catArr);
    }
    dispatch(getExploreSubcategories(catArr));

    if (name === "categoryIds" || name === "subcategoryIds") {
      localCategories = [...explorePageData[currentProjectFilter][name]];

      if (
        explorePageData[currentProjectFilter][name].includes(event.target.value)
      ) {
        localCategories = localCategories.filter(
          (e) => e !== event.target.value
        );
      } else {
        localCategories = [
          ...explorePageData[currentProjectFilter][name],
          event.target.value,
        ];
      }

      setLocalSubs({
        ...localSubs,
        name: localCategories,
      });
    } else {
      localCategories = event.target.value;
      name = event.target.name;
    }
    dispatch(
      handleExploreFilterChange({
        value: localCategories,
        name: name,
        type: exploreFilter,
      })
    );
  };

  return (
    <div className="component-select-container">
      {listOfCategories?.map((category, index) => {
        return (
          <div key={index}>
            <ButtonComponent
              className={classNames(
                toggleButton && "subcategoriesList",
                "component-select-category-wrapper"
              )}
              onClick={() => {
                handleToggleButton(category, category.name);
              }}
              intlRequired={false}
              buttonText={category.btnName || ""}
              color={category?.color}
            />

            <div className="component-select-subcategory-wrapper subcategoriesList">
              {toggleButton && selectedCategoryId === category.id && (
                <ul
                  className="subcategory-list-items"
                  onClick={() => {
                    setSelectedCategoryId(category.id);
                  }}
                >
                  {filter === "range" ? (
                    <>
                      {rangeInputs?.map((item) => {
                        return (
                          <CustomInput
                            key={item.id}
                            label={item.label}
                            placeholder={intl.formatMessage({
                              id: "component.explore.search.text",
                            })}
                            name={item.name}
                            handleChange={handleChange}
                            value={
                              explorePageData[currentProjectFilter][
                                item.name
                              ] || ""
                            }
                            className={"subcategoriesList"}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {handleListToMap(category.btnName)?.map(
                        (item, index2) => {
                          const typeOfDropDown =
                            category.name === "categoryIds" ||
                            category.name === "subcategoryIds"
                              ? item?.id?.toString()
                              : item.name;

                          return (
                            <li
                              className={classNames(
                                toggleButton && "subcategoriesList",
                                "list"
                              )}
                              key={`item--${index2}`}
                            >
                              <label
                                className={classNames(
                                  toggleButton && "subcategoriesList",
                                  "list-items"
                                )}
                                key={index2}
                                style={{ textTransform: "capitalize" }}
                              >
                                <input
                                  type={"checkbox"}
                                  name={category?.name}
                                  className="subcategoriesList"
                                  checked={explorePageData?.[
                                    currentProjectFilter
                                  ]?.[category.name]?.includes(typeOfDropDown)}
                                  onChange={(event) => {
                                    handleChange(
                                      event,
                                      category?.name,
                                      item.id
                                    );
                                  }}
                                  value={typeOfDropDown}
                                />
                                {category.name === "categoryIds" ||
                                category.name === "subcategoryIds"
                                  ? item[languageData]
                                  : item.name}
                                <span
                                  className={classNames(
                                    toggleButton && "subcategoriesList",
                                    "checkmark"
                                  )}
                                />
                              </label>
                            </li>
                          );
                        }
                      )}
                    </>
                  )}
                </ul>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

SelectCategoryComponent.propTypes = {
  intl: PropTypes.shape(),
};

export default injectIntl(SelectCategoryComponent);
