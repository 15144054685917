import React from "react";

// STYLES
import "./InvestorsCard.scss";

// LIBRARIES
import PropTypes from "prop-types";

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS

const InvestorsCard = (props) => {
  // PROPS
  const {
    investorImgs = "",
    investorName = "",
    investorPosition = "",
    reserveFunds = "",
    fundsNumber = 0,
    pledgeFunds = "",
    pledgeNumber = 0,
    companyName = "",
    showDetails = false,
  } = props;

  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className="component-investors-card-container">
      <div className="component-investors-card-image-wrapper">
        <img src={`data:image/png;base64,${investorImgs}`} alt="" />
      </div>
      <div className="component-investors-card-details-wrapper">
        <div className="card-details-name-position">
          <p className="name">{investorName}</p>
          <p className="position">{investorPosition}</p>
          <p className="position">{companyName}</p>
        </div>
        {showDetails === true && (
          <div className="card-details-funds">
            <div className="reserved-funds">
              <p className="title">{reserveFunds}</p>
              <p className="text">{fundsNumber}</p>
            </div>
            <div className="pledge-funds">
              <p className="title">{pledgeFunds}</p>
              <p className="text">{pledgeNumber}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

InvestorsCard.propTypes = {
  investorImgs: PropTypes.string,
  investorName: PropTypes.string,
  investorPosition: PropTypes.string,
  reserveFunds: PropTypes.string,
  fundsNumber: PropTypes.number,
  pledgeFunds: PropTypes.string,
  companyName: PropTypes.string,
  pledgeNumber: PropTypes.number,
  showDetails: PropTypes.bool,
};

export default InvestorsCard;
