import { FileExtensions } from "constants/constants";

export const convertRequestFilesToFormData = (files) => {
  let formData = new FormData();
  let fileList = null;

  files.forEach((file, index) => {
    if (index === 0) {
      fileList = new File([file.file], file.file.name, {
        type: "NDA",
      });
    } else {
      fileList = new File([file.file], file.file.name, {
        type: "PARTNERSHIP_AGREEMENT",
      });
    }
    formData.append("files", fileList);
  });

  return formData;
};

export const convertFileToFormData = (files, type) => {
  let formData = new FormData();
  let fileList = null;

  if (Array.isArray(files)) {
    files.forEach((file) => {
      fileList = new File([file.file], file.file.name, {
        type,
      });
      formData.append("files", fileList);
    });
  } else {
    fileList = new File([files], files.name, {
      type,
    });
    formData.append("file", fileList);
  }

  return formData;
};

export const convertBase64ToFile = (base64String, fileName, extension) => {
  const trimmedString = base64String.replace("dataimage/jpegbase64", "");
  const imageContent = atob(trimmedString);
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }

  let converType;

  if (FileExtensions.IMAGE.includes(extension)) {
    converType = `image/${extension}`;
  } else if (FileExtensions.VIDEO.includes(extension)) {
    converType = `video/${extension}`;
  } else if (FileExtensions.DOCUMENTS.includes(extension)) {
    converType = `application/${extension}`;
  }

  const blob = new Blob([buffer], { type: converType });
  let file = new File([blob], `${fileName}.${extension}`, {
    lastModified: new Date().getTime(),
    type: converType,
  });
  return file;
};

export const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
