import React, { useEffect, useState } from "react";

// STYLES
import "./ProfilePage.scss";

// LIBRARIES
import { useNavigate } from "react-router-dom";
import moment from "moment";

// CONSTANTS & MOCKS
import {
  LOGO,
  ProfileTabs,
  ProjectRequestType,
  projectType,
  RequestStatus,
} from "constants/constants";
import { decodeJwtToken } from "helpers/decoder";
import { userRolesTypes } from "constants/userRoles";
import { convertBase64ToFile } from "helpers/converters";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { getCompanyInfo, getPersonalInfo } from "api/sessionApi";
import {
  handleLogOutUser,
  accountInfo,
  companyInfo,
  isLoading,
  userRole,
} from "slices/sessionSlice";
import { editAccountInfo, editCompanyInfo } from "api/userApi";
import { addReservedFounds } from "api/projectApi";

// COMPONENTS
import SideProfileComponent from "components/SideProfileComponent/SideProfileComponent";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import ProfileInformation from "components/ProfileInformation/ProfileInformation";
import ProfileMenuComponent from "components/ProfileMenu/ProfileMenu";
import companyLogoMock from "assets/uploader/copanyLogo.png";
import Loading from "components/Atoms/Loading/Loading";

const ProfilePage = () => {
  const previewImgFallback = companyLogoMock;
  // PROPS

  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch();
  const accountData = useSelector(accountInfo);
  const companyData = useSelector(companyInfo);
  const userRoleData = useSelector(userRole);
  const isLoadingData = useSelector(isLoading);
  const navigate = useNavigate();

  // CONSTANTS USING HOOKS
  const [textFieldValue, setTextFieldValue] = useState({
    personalInfo: {},
    companyInfo: {},
    documentModel: null,
    previewImg: previewImgFallback,
  });
  const [readOnly, setReadOnly] = useState(true);
  const [pageStatus, setPageStatus] = useState(ProfileTabs.PERSONAL);
  const [projectStatus, setProjectStatus] = useState(projectType.DRAFTS);
  const [requestStatus, setRequestStatus] = useState(
    ProjectRequestType.PENDING
  );
  const [founds, setFounds] = useState({
    values: {},
  });
  const [mobileMenuStatus, setMobileMenuStatus] = useState([
    ProfileTabs.PERSONAL,
    ProfileTabs.COMPANY,
    ProfileTabs.REQUESTS,
    ProfileTabs.PROJECTS,
    ProfileTabs.INTERESTS,
  ]);
  const [mobileSubmenuStatus, setMobileSubmenuStatus] = useState({
    projects: [
      projectType.DRAFTS,
      projectType.APPROVED,
      projectType.PUBLISHED,
      projectType.INVESTED,
    ],
    requests: [
      RequestStatus.PENDING,
      RequestStatus.APPROVED,
      RequestStatus.REJECTED,
    ],
  });
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [mobileSubmenuVisible, setMobileSubmenuVisible] = useState(false);

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION
  useEffect(() => {
    handleSetDefaultValues();
    setProjectStatus(
      userRoleData === userRolesTypes.BRAINER
        ? projectType.DRAFTS
        : projectType.BLOCKED
    );
    // eslint-disable-next-line
  }, [accountData]);

  useEffect(() => {
    handleSetDefaultValues();
    if (accountData?.emailAddress) {
      dispatch(getCompanyInfo(accountData.emailAddress)).then((answer) => {
        setTextFieldValue((textFieldValue) => ({
          ...textFieldValue,
          companyInfo: {
            companyName: answer?.companyName,
            domain: answer?.domain,
            country: answer?.country,
            jobTitle: answer?.jobTitle,
            registeredOffice: answer?.registeredOffice,
            tradeRegistrationNumber: answer?.tradeRegistrationNumber,
            uniqueRegistrationCode: answer?.uniqueRegistrationCode,
            companyPhoto: answer?.documentModel,
          },
        }));
      });
    }
    // eslint-disable-next-line
  }, [readOnly]);

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  const handleMobileMenuSelection = (selection) => {
    let firstElement = mobileMenuStatus[0];
    let filteredArray = mobileMenuStatus.filter((item) => item !== selection);
    filteredArray.shift();
    filteredArray.push(firstElement);
    filteredArray.unshift(selection);
    setMobileMenuStatus(filteredArray);
    setMobileMenuVisible(false);
  };

  const handleMobileSubmenuSelection = (selection, type) => {
    let firstElement =
      type === "projects"
        ? mobileSubmenuStatus.projects[0]
        : mobileSubmenuStatus.requests[0];
    let filteredArray =
      type === "projects"
        ? mobileSubmenuStatus.projects.filter((item) => item !== selection)
        : mobileSubmenuStatus.requests.filter((item) => item !== selection);
    filteredArray.shift();
    filteredArray.push(firstElement);
    filteredArray.unshift(selection);

    switch (type) {
      case "projects":
        setMobileSubmenuStatus({
          ...mobileSubmenuStatus,
          projects: filteredArray,
        });
        break;
      case "requests":
        setRequestStatus(selection);
        setMobileSubmenuStatus({
          ...mobileSubmenuStatus,
          requests: filteredArray,
        });
        break;
      default:
        break;
    }

    setMobileSubmenuVisible(false);
  };

  const handleSetDefaultValues = () => {
    let receivedProfilePhoto = accountData?.documentModels?.find(
      (photo) => photo.documentType === LOGO
    );

    setTextFieldValue((textFieldValue) => ({
      ...textFieldValue,
      personalInfo: {
        ...textFieldValue.personalInfo,
        fullName: accountData?.fullName,
        emailAddress: accountData?.emailAddress,
        country: accountData?.country,
        dateOfBirth: accountData?.dateOfBirth,
        aboutMe: accountData?.aboutMe,
        profilePhoto: receivedProfilePhoto?.content,
        memberSince: accountData.memberSince,
        signUpStatus: accountData.signUpStatus,
      },
    }));
  };

  const handleEditText = (event, section) => {
    switch (section) {
      case ProfileTabs.PERSONAL:
        setTextFieldValue((textFieldValue) => ({
          ...textFieldValue,
          personalInfo: {
            ...textFieldValue.personalInfo,
            [event.target.name]: event.target.value,
          },
        }));
        break;
      case ProfileTabs.COMPANY:
        setTextFieldValue((textFieldValue) => ({
          ...textFieldValue,
          companyInfo: {
            ...textFieldValue.companyInfo,
            [event.target.name]: event.target.value,
          },
        }));
        break;
      default:
        break;
    }
  };

  const handleDate = (event) => {
    setTextFieldValue((textFieldValue) => ({
      ...textFieldValue,
      personalInfo: {
        ...textFieldValue.personalInfo,
        dateOfBirth: moment(event).format("yyyy-MM-DD"),
      },
    }));
  };

  const handleEdit = () => {
    setReadOnly(false);
  };

  const handleLogOut = () => {
    dispatch(handleLogOutUser());
    navigate("/homepage");
  };

  const handleEditAccountInfo = () => {
    let decode = decodeJwtToken();
    const formData = new FormData();
    switch (pageStatus) {
      case ProfileTabs.PERSONAL:
        if (decode.id) {
          let payload = {
            id: decode.id,
            fullName: textFieldValue.personalInfo.fullName,
            emailAddress: textFieldValue.personalInfo.emailAddress,
            country: textFieldValue.personalInfo.country,
            dateOfBirth: textFieldValue.personalInfo.dateOfBirth,
            aboutMe: textFieldValue.personalInfo.aboutMe,
            documentModels: [
              {
                content: textFieldValue.personalInfo.profilePhoto,
                documentType: LOGO,
              },
            ],
          };

          dispatch(editAccountInfo(payload)).then(() => {
            dispatch(getPersonalInfo(accountData.emailAddress));
          });
        }
        break;
      case ProfileTabs.COMPANY:
        if (decode.sub) {
          let payload = {
            companyName: textFieldValue.companyInfo.companyName,
            uniqueRegistrationCode:
              textFieldValue.companyInfo.uniqueRegistrationCode,
            country: textFieldValue.companyInfo.country,
            jobTitle: textFieldValue.companyInfo.jobTitle,
            tradeRegistrationNumber:
              textFieldValue.companyInfo.tradeRegistrationNumber,
            registeredOffice: textFieldValue.companyInfo.registeredOffice,
            emailAddress: decode.sub,
          };

          let file;
          if (textFieldValue.documentModel) {
            file = new File(
              [textFieldValue.documentModel],
              [textFieldValue.documentModel].name,
              { type: LOGO }
            );
          } else {
            const img = convertBase64ToFile(
              textFieldValue.companyInfo.companyPhoto.content,
              textFieldValue.companyInfo.companyPhoto.fileName,
              textFieldValue.companyInfo.companyPhoto.fileExtension
            );
            file = new File([img], img.name, { type: LOGO });
          }

          formData.append("file", file);
          formData.append("companyRequestModel", JSON.stringify(payload));

          dispatch(editCompanyInfo(formData)).then(() => {
            dispatch(getCompanyInfo(accountData.emailAddress)).then(
              (answer) => {
                setTextFieldValue((textFieldValue) => ({
                  ...textFieldValue,
                  companyInfo: {
                    companyName: answer?.companyName,
                    country: answer?.country,
                    jobTitle: answer?.jobTitle,
                    registeredOffice: answer?.registeredOffice,
                    tradeRegistrationNumber: answer?.tradeRegistrationNumber,
                    uniqueRegistrationCode: answer?.uniqueRegistrationCode,
                    companyPhoto: answer?.documentModel,
                  },
                }));
              }
            );
          });
        }
        break;
      case ProfileTabs.PROJECTS:
        let body = {
          currency: founds?.values?.currency,
          partnerEmail: accountData?.emailAddress,
          reservedFunds: founds?.values?.reservedFunds,
        };
        dispatch(addReservedFounds(body));
        break;
      default:
        break;
    }

    setReadOnly(true);
  };

  const handleMobilePage = (element) => {
    setPageStatus(element);
    handleMobileMenuSelection(element);
  };

  const handleFounds = (event) => {
    setFounds((founds) => ({
      ...founds,
      values: {
        ...founds.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  return (
    <div className="page-profile-container">
      <div className="page-profile-side-card-container">
        <SideProfileComponent
          name="Constantin Doe"
          position={decodeJwtToken().role}
          handleEdit={handleEdit}
          isReadOnly={readOnly}
          logOut={handleLogOut}
          pageStatus={pageStatus}
          accountData={accountData}
          localAccountData={textFieldValue}
          setAccountData={setTextFieldValue}
          projectStatus={projectStatus}
        />
      </div>
      <div className="page-profile-content-container">
        <ProfileMenuComponent
          pageStatus={pageStatus}
          userRoleData={userRoleData}
          projectStatus={projectStatus}
          setPageStatus={setPageStatus}
          requestStatus={requestStatus}
          setRequestStatus={setRequestStatus}
          setReadOnly={setReadOnly}
          setProjectStatus={setProjectStatus}
          mobileMenuStatus={mobileMenuStatus}
          setMobileMenuVisible={setMobileMenuVisible}
          mobileMenuVisible={mobileMenuVisible}
          mobileSubmenuStatus={mobileSubmenuStatus}
          mobileSubmenuVisible={mobileSubmenuVisible}
          setMobileSubmenuVisible={setMobileSubmenuVisible}
          handleMobilePage={handleMobilePage}
          handleMobileSubmenuSelection={handleMobileSubmenuSelection}
        />
        {isLoadingData && (
          <div className="page-profile-loading-container">
            <Loading />
          </div>
        )}
        <ProfileInformation
          pageStatus={pageStatus}
          isReadOnly={readOnly}
          isBrainer={userRoleData === userRolesTypes.BRAINER}
          handleEditText={handleEditText}
          textFieldValue={textFieldValue}
          setTextFieldValue={setTextFieldValue}
          projectStatus={projectStatus}
          requestStatus={requestStatus}
          accountData={accountData}
          companyData={companyData}
          handleDate={handleDate}
          handleFounds={handleFounds}
        />
        {!readOnly && (
          <div className="page-profile-buttons-container">
            <ButtonComponent
              color={userRoleData === userRolesTypes.BRAINER ? "blue" : "gold"}
              buttonText="component.profile.submenu.button.cancel"
              onClick={() => {
                handleSetDefaultValues();
                setReadOnly(true);
              }}
              selected={true}
            />
            <ButtonComponent
              color={userRoleData === userRolesTypes.BRAINER ? "blue" : "gold"}
              buttonText="component.profile.submenu.button.save"
              onClick={() => {
                handleEditAccountInfo();
              }}
              selected={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfilePage;
