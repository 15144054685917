export const getHeaderLinks = (role, loggedIn) => {
  let headerLinks = [
    {
      label: "component.header.home",
      path: "/",
      isVisible: true,
      color: "blue",
    },
    {
      label: "component.header.explore",
      path: "/explore",
      isVisible: true,
      color: "blue",
    },
    {
      label: "component.header.consulting",
      path: "/consulting",
      isVisible: true,
      color: "blue",
    },
    {
      label: "component.header.partnersMeeting",
      path: "/meeting",
      isVisible:  false,
      color: "gold",
    },
    {
      label: "component.header.submitIdea",
      path: "/submit",
      isVisible: role === "BRAINER" && loggedIn ? true : false,
      color: "gold",
    },
    {
      label: "component.header.about",
      path: "https://nafram.org",
      isVisible: true,
      color: "blue",
    },
  ];
  return headerLinks;
};
