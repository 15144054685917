import React, { useEffect } from "react";

// LIBRARIES
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

// COMPONENTS
import HomePage from "view/HomePage/HomePage";
import ExplorePage from "view/ExplorePage/ExplorePage";
import ConsultingPage from "view/ConsultingPage/ConsultingPage";
import AboutUsPage from "view/AboutUsPage/AboutUsPage";
import SubmitIdea from "view/SubmitIdea/SubmitIdea";
import BuildProject from "view/BuildProject/BuildProject";
import ProjectOverview from "view/ProjectOverview/ProjectOverview";
import CompletedProject from "view/CompletedProject/CompletedProject";
import PartnersMeeting from "view/PartnersMeeting/PartnersMeeting";
import Profile from "view/ProfilePage/ProfilePage";
import LogIn from "view/LogIn/LogIn";
import SelectRolePage from "view/SelectRolePage/SelectRolePage";
import RegisterPage from "view/RegisterPage/RegisterPage";
import ViewIdeaDetails from "view/ViewIdeaDetails/ViewIdeaDetails";
import AdminPage from "view/AdminPage/AdminPage";
import ResetPassword from "view/ResetPassword/ResetPassword";
import PaymentSuccess from "view/PaymentSuccess/PaymentSuccess";
import PaymentCanceled from "view/PaymentCanceled/PaymentCanceled";
import NotFound from "view/NotFound/NotFound";
import Layout from "components/Layout/Layout";
import TermsOfService from "components/TermsOfService/TermsOfService";
import ForgotPassword from "view/ForgotPassword/ForgotPassword";
import UploadDocumentsForIdea from "view/UploadDocumentsForIdea/UploadDocumentsForIdea";
import UploadDocumentsForViewIdea from "view/UploadDocumentsForViewIdea/UploadDocumentsForViewIdea";
import ProceedToPayForViewIdea from "view/ProceedToPayForViewIdea/ProceedToPayForViewIdea";
import ConsultingHelpPage from "view/ConsultingHelpPage/ConsultingHelpPage";
import store from "store/store";
import InterestsPage from "view/InterestsPage/InterestsPage";
import useMediaQuery from "@mui/material/useMediaQuery";
import Splash from "view/Splash/Splash";

const AppRoutes = () => {
  // CONSTANTS USING HOOKS
  const { pathname } = useLocation();
  const token = store.getState().session.loggedState.token || null;
  const isMobile = useMediaQuery("(max-width:1279px)");

  // USE EFFECT FUNCTIONS
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      {/* ------------- ADMIN ROUTE ------------- */}

      <Route
        path="/admin"
        element={
          <Layout>
            <AdminPage />
          </Layout>
        }
      />

      {/* ----------------------------------------------- */}
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Splash />} />
      <Route
        path="/homepage"
        element={
          <Layout>
            <HomePage />
          </Layout>
        }
      />

      <Route
        path="/explore"
        element={
          <Layout>
            <ExplorePage />
          </Layout>
        }
      />
      <Route
        path="/interests"
        element={
          <Layout>
            <InterestsPage />
          </Layout>
        }
      />
      <Route
        path="/view-idea/:id"
        element={
          <Layout>
            <ViewIdeaDetails />
          </Layout>
        }
      />
      <Route
        path="/consulting"
        element={
          <Layout>
            <ConsultingPage />
          </Layout>
        }
      />
      <Route
        path="/consulting-help/:role"
        element={
          <Layout>
            <ConsultingHelpPage />
          </Layout>
        }
      />
      <Route
        path="/about"
        element={
          <Layout>
            <AboutUsPage />
          </Layout>
        }
      />
      <Route
        path="/submit"
        element={
          <Layout>
            <SubmitIdea />
          </Layout>
        }
      />
      <Route
        path="/upload-documents-for-idea"
        element={
          <Layout>
            <UploadDocumentsForIdea />
          </Layout>
        }
      />
      <Route
        path="/upload-documents-for-view-idea"
        element={
          <Layout>
            <UploadDocumentsForViewIdea />
          </Layout>
        }
      />
      <Route
        path="/proceed-to-pay-for-view-idea/:projectId/:emailAddress"
        element={
          <Layout>
            <ProceedToPayForViewIdea />
          </Layout>
        }
      />
      <Route path="/build">
        <Route
          path=""
          element={
            <Layout>
              <BuildProject />
            </Layout>
          }
        />
        <Route
          path=":projectId"
          element={
            <Layout>
              <BuildProject />
            </Layout>
          }
        />
      </Route>
      <Route
        path="/completed"
        element={
          <Layout>
            <CompletedProject />
          </Layout>
        }
      />
      <Route
        path="/overview/:projectId"
        element={
          <Layout>
            <ProjectOverview />
          </Layout>
        }
      />
      <Route
        path="/meeting"
        element={
          <Layout>
            <PartnersMeeting />
          </Layout>
        }
      />
      <Route
        path="/profile-page"
        element={
          token ? (
            <Layout>
              <Profile />
            </Layout>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/signin"
        element={
          <Layout>
            <LogIn />
          </Layout>
        }
      />
      <Route
        path="/select-role"
        element={
          <Layout>
            <SelectRolePage />
          </Layout>
        }
      />
      <Route
        path="/terms"
        element={
          <Layout>
            <TermsOfService />
          </Layout>
        }
      />
      <Route
        path="/register"
        element={
          <Layout header={true} footer={false}>
            <RegisterPage />
          </Layout>
        }
      />
      <Route
        path="/login"
        element={
          !token ? (
            <Layout header={true} footer={false}>
              <LogIn />
            </Layout>
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="/reset-password"
        element={
          <Layout header={true} footer={false}>
            <ResetPassword />
          </Layout>
        }
      />
      <Route
        path="/payment-success"
        element={
          <Layout header={true} footer={false}>
            <PaymentSuccess />
          </Layout>
        }
      />
      <Route
        path="/payment-canceled"
        element={
          <Layout header={true} footer={false}>
            <PaymentCanceled />
          </Layout>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <Layout header={true} footer={false}>
            <ForgotPassword />
          </Layout>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
