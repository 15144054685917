import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import React from "react";

// STYLES
import "./ProfileMenu.scss";

// LIBRARIES

// CONSTANTS & MOCKS
import { ProfileTabs, projectType, RequestStatus } from "constants/constants";
// REDUX

// COMPONENTS
import { userRolesTypes } from "constants/userRoles";
import arrowMobile from "assets/components/mobileProfilePageMenu/arrowMobile.svg";
import { FormattedMessage } from "react-intl";
import { element } from "prop-types";
import { Link } from "react-router-dom";

const ProfileMenuComponent = (props) => {
  // PROPS
  const {
    pageStatus,
    userRoleData,
    setPageStatus,
    setReadOnly,
    setProjectStatus,
    mobileMenuStatus,
    setMobileMenuVisible,
    mobileMenuVisible,
    handleMobilePage,
    projectStatus,
    mobileSubmenuStatus,
    setMobileSubmenuVisible,
    mobileSubmenuVisible,
    handleMobileSubmenuSelection,
    requestStatus,
    setRequestStatus,
  } = props;

  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS
  const ProfileMenuTabs = [
    ProfileTabs.PERSONAL,
    ProfileTabs.COMPANY,
    ProfileTabs.REQUESTS,
    ProfileTabs.PROJECTS,
    ProfileTabs.INTERESTS,
  ];
  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  return (
    <div className="page-menu-container">
      {/* WEB MENU */}
      <div className="page-profile-menu-container">
        {ProfileMenuTabs?.map((element, index) => {
          return (
            <ButtonComponent
              key={index}
              buttonText={
                element === ProfileTabs.PERSONAL
                  ? "component.profile.menu.button.personal"
                  : element === ProfileTabs.COMPANY
                  ? "component.profile.menu.button.company"
                  : element === ProfileTabs.REQUESTS
                  ? "component.profile.menu.button.requests"
                  : element === ProfileTabs.PROJECTS
                  ? "component.profile.menu.button.projects"
                  : element === ProfileTabs.INTERESTS
                  ? "component.profile.menu.button.interests"
                  : null
              }
              selected={pageStatus === element ? true : false}
              color={userRoleData === userRolesTypes.BRAINER ? "blue" : "gold"}
              onClick={() => {
                setPageStatus(element);
                setReadOnly(true);
              }}
            />
          );
        })}
      </div>

      {/* MOBILE MENU */}
      <div className="page-profile-menu-mobile-container">
        {mobileMenuStatus?.map((element, index) => {
          return (
            <div key={`page-profile-menu-mobile-selection--${index}`}>
              {index === 0 ? (
                <div
                  key={`page-profile-menu-mobile-selection-wrapper--${index}`}
                  className="page-profile-menu-mobile-selection-wrapper"
                  onClick={() => {
                    setMobileMenuVisible(!mobileMenuVisible);
                  }}
                >
                  <p>{element}</p>
                  <img
                    className={
                      mobileMenuVisible
                        ? "page-profile-menu-mobile-arrow"
                        : "page-profile-menu-mobile-arrow-rotated"
                    }
                    src={arrowMobile}
                    alt=""
                  />
                </div>
              ) : (
                <div
                  key={`page-profile-menu-mobile-selection-wrapper--${index}`}
                  className={
                    mobileMenuVisible
                      ? "page-profile-menu-mobile-menu-wrapper"
                      : "page-profile-menu-mobile-menu-hidden"
                  }
                >
                  <div className="page-profile-menu-mobile-selection-wrapper">
                    <p
                      onClick={() => {
                        handleMobilePage(element);
                      }}
                    >
                      {element}
                    </p>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>

      {/* REQUESTS SUBMENU */}
      {pageStatus === ProfileTabs.REQUESTS && (
        <div className="page-profile-submenu-container">
          <>
            {/* WEB SUBMENU */}
            <div className="page-profile-submenu-web-container">
              <p
                className={
                  requestStatus === RequestStatus.PENDING
                    ? "page-profile-submenu-wrapper-clicked"
                    : "page-profile-submenu-wrapper"
                }
                onClick={() => {
                  setRequestStatus(RequestStatus.PENDING);
                }}
              >
                <FormattedMessage id="component.profile.submenu.button.pending" />
              </p>
              <p
                className={
                  requestStatus === RequestStatus.REJECTED
                    ? "page-profile-submenu-wrapper-clicked"
                    : "page-profile-submenu-wrapper"
                }
                onClick={() => {
                  setRequestStatus(RequestStatus.REJECTED);
                }}
              >
                <FormattedMessage id="component.profile.submenu.button.rejected" />
              </p>
              <p
                className={
                  requestStatus === RequestStatus.APPROVED
                    ? "page-profile-submenu-wrapper-clicked"
                    : "page-profile-submenu-wrapper"
                }
                onClick={() => {
                  setRequestStatus(RequestStatus.APPROVED);
                }}
              >
                <FormattedMessage id="component.profile.submenu.button.approved" />
              </p>
            </div>

            {/* MOBILE SUBMENU */}
            <div className="page-profile-submenu-mobile-container">
              {mobileSubmenuStatus?.requests?.map((element, index) => {
                return index === 0 ? (
                  <div
                    key={`page-profile-submenu-mobile-selection-wrapper--${index}`}
                    className="page-profile-submenu-mobile-selection-wrapper"
                    onClick={() => {
                      setMobileSubmenuVisible(!mobileSubmenuVisible);
                    }}
                  >
                    <p>{element?.toLowerCase()}</p>
                    <img
                      className={
                        mobileSubmenuVisible
                          ? "page-profile-submenu-mobile-arrow"
                          : "page-profile-submenu-mobile-arrow-rotated"
                      }
                      src={arrowMobile}
                      alt=""
                    />
                  </div>
                ) : (
                  <div
                    key={`page-profile-submenu-mobile-selection-wrapper--${index}`}
                    className={
                      mobileSubmenuVisible
                        ? "page-profile-submenu-mobile-menu-wrapper"
                        : "page-profile-submenu-mobile-menu-hidden"
                    }
                  >
                    <div className="page-profile-submenu-mobile-selection-wrapper">
                      <p
                        onClick={() => {
                          setProjectStatus(element);
                          handleMobileSubmenuSelection(element, "requests");
                        }}
                      >
                        {element?.toLowerCase()}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        </div>
      )}

      {/* PROJECTS SUBMENU */}
      {pageStatus === ProfileTabs.PROJECTS && (
        <div className="page-profile-submenu-container">
          {/* WEB SUBMENU */}
          {userRoleData === userRolesTypes.PARTNER && (
            <div className="page-profile-partner-submenu-container">
              <p
                className={
                  projectStatus === projectType.BLOCKED
                    ? "page-profile-submenu-wrapper-clicked"
                    : "page-profile-submenu-wrapper"
                }
                onClick={() => {
                  setProjectStatus(projectType.BLOCKED);
                }}
              >
                <FormattedMessage id="component.profile.submenu.button.partner.view" />
              </p>
              <p
                className={
                  projectStatus === projectType.INVESTED
                    ? "page-profile-submenu-wrapper-clicked"
                    : "page-profile-submenu-wrapper"
                }
                onClick={() => {
                  setProjectStatus(projectType.INVESTED);
                }}
              >
                <FormattedMessage id="component.profile.submenu.button.partner.invested" />
              </p>
            </div>
          )}

          {/* WEB SUBMENU */}
          {userRoleData === userRolesTypes.BRAINER && (
            <>
              <div className="page-profile-submenu-web-container">
                <p
                  className={
                    projectStatus === projectType.DRAFTS
                      ? "page-profile-submenu-wrapper-clicked"
                      : "page-profile-submenu-wrapper"
                  }
                  onClick={() => {
                    setProjectStatus(projectType.DRAFTS);
                  }}
                >
                  <FormattedMessage id="component.profile.submenu.button.drafts" />
                </p>
                <p
                  className={
                    projectStatus === projectType.APPROVED
                      ? "page-profile-submenu-wrapper-clicked"
                      : "page-profile-submenu-wrapper"
                  }
                  onClick={() => {
                    setProjectStatus(projectType.APPROVED);
                  }}
                >
                  <FormattedMessage id="component.profile.submenu.button.approved" />
                </p>
                <p
                  className={
                    projectStatus === projectType.PUBLISHED
                      ? "page-profile-submenu-wrapper-clicked"
                      : "page-profile-submenu-wrapper"
                  }
                  onClick={() => {
                    setProjectStatus(projectType.PUBLISHED);
                  }}
                >
                  <FormattedMessage id="component.profile.submenu.button.published" />
                </p>
                <p
                  className={
                    projectStatus === projectType.INVESTED
                      ? "page-profile-submenu-wrapper-clicked"
                      : "page-profile-submenu-wrapper"
                  }
                  onClick={() => {
                    setProjectStatus(projectType.INVESTED);
                  }}
                >
                  <FormattedMessage id="component.profile.submenu.button.invested" />
                </p>
              </div>
              <div className="page-profile-submenu-mobile-container">
                {/* MOBILE SUBMENU */}
                {mobileSubmenuStatus?.projects?.map((element, index) => {
                  return index === 0 ? (
                    <div
                      key={`page-profile-submenu-mobile-selection-wrapper--${index}`}
                      className="page-profile-submenu-mobile-selection-wrapper"
                      onClick={() => {
                        setMobileSubmenuVisible(!mobileSubmenuVisible);
                      }}
                    >
                      <p>{element.toLowerCase()}</p>
                      <img
                        className={
                          mobileSubmenuVisible
                            ? "page-profile-submenu-mobile-arrow"
                            : "page-profile-submenu-mobile-arrow-rotated"
                        }
                        src={arrowMobile}
                        alt=""
                      />
                    </div>
                  ) : (
                    <div
                      key={`page-profile-submenu-mobile-selection-wrapper--${index}`}
                      className={
                        mobileSubmenuVisible
                          ? "page-profile-submenu-mobile-menu-wrapper"
                          : "page-profile-submenu-mobile-menu-hidden"
                      }
                    >
                      <div className="page-profile-submenu-mobile-selection-wrapper">
                        <p
                          onClick={() => {
                            setProjectStatus(element);
                            handleMobileSubmenuSelection(element, "projects");
                          }}
                        >
                          {element.toLowerCase()}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ProfileMenuComponent;
