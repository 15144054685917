export const profileInfo = {
  values: {
    personalInfo: [
      {
        label: "Full Name",
        key: "fullName",
        id: "component.profile.information.name",
        multiline: false,
        md: 6,
        xs: 12,
      },
      {
        label: "E-mail Adress",
        key: "emailAddress",
        id: "component.profile.information.email",
        multiline: false,
        md: 6,
        xs: 12,
      },
      {
        label: "Country",
        key: "country",
        id: "component.profile.information.country",
        multiline: false,
        component: "select",
        type: "dropdown",
        md: 6,
        xs: 12,
      },
      {
        label: "Date of birth",
        key: "dateOfBirth",
        id: "component.profile.information.date",
        multiline: false,
        component: "datePicker",
        md: 6,
        xs: 12,
      },
      {
        label: "About",
        key: "aboutMe",
        id: "component.profile.information.about",
        multiline: true,
        maxRows: 3,
        minRows: 3,
        md: 12,
        xs: 12,
      },
    ],

    companyInfo: [
      {
        value: "Company Name",
        label: "Company Name",
        key: "companyName",
        id: "component.profile.information.company",
      },
      {
        value: "Domain",
        label: "Domain",
        key: "domain",
        id: "component.profile.information.domain",
      },
      {
        value: "Job title",
        label: "Job title",
        key: "jobTitle",
        id: "component.profile.information.position",
      },
      {
        value: "Unique Registration Code",
        label: "Unique Registration Code",
        key: "uniqueRegistrationCode",
        id: "component.profile.information.unique",
      },
      {
        value: "Trade registration number",
        label: "Trade registration number",
        key: "tradeRegistrationNumber",
        id: "component.profile.information.registration",
      },
      {
        value: "Country",
        label: "Country",
        key: "country",
        id: "component.profile.information.company.country",
        component: "select",
        type: "dropdown",
      },
      {
        value: "Registered Office ",
        label: "Registered Office ",
        key: "registeredOffice",
        id: "component.profile.information.office",
      },
    ],

    projects: [
      {
        status: "drafts",
        name: "component.profile.card.project.drafts.name",
        time: "21",
        timePeriod: "component.profile.card.project.time.period",
        editText: "component.profile.card.project.button.edit",
        deactivateText: "component.profile.card.project.button.deactivate",
      },
      {
        status: "drafts",
        name: "component.profile.card.project.drafts.name",
        time: "21",
        timePeriod: "component.profile.card.project.time.period",
        editText: "component.profile.card.project.button.edit",
        deactivateText: "component.profile.card.project.button.deactivate",
      },
      {
        status: "approved",
        name: "component.profile.card.project.approved.name",
        time: "21",
        timePeriod: "component.profile.card.project.time.period",
        editText: "component.profile.card.project.button.edit",
        deactivateText: "component.profile.card.project.button.deactivate",
      },
      {
        status: "invested",
        name: "component.profile.card.project.invested.name",
        time: "21",
        timePeriod: "component.profile.card.project.time.period",
        editText: "component.profile.card.project.button.edit",
        deactivateText: "component.profile.card.project.button.deactivate",
      },
    ],

    projectsPartner: [
      {
        status: "view",
        projectsCardBrainerImage: "INVESTED",
        projectsCardImage: "21",
        profilePictureActive: true,
        description:
          "We are Preparing to Release a Wrist Wearable Tap that will Greatly Expand our Market. Tap is fast, accurate, mobile and eyes free - it is the perfect solution for wearable AR & VR...",
        title: "The Input Technology for the Metaverse",
      },
      {
        status: "view",
        projectsCardBrainerImage: "INVESTED",
        projectsCardImage: "21",
        profilePictureActive: true,
        description:
          "We are Preparing to Release a Wrist Wearable Tap that will Greatly Expand our Market. Tap is fast, accurate, mobile and eyes free - it is the perfect solution for wearable AR & VR...",
        title: "The Input Technology for the Metaverse",
      },
      {
        status: "view",
        projectsCardBrainerImage: "INVESTED",
        projectsCardImage: "21",
        profilePictureActive: true,
        description:
          "We are Preparing to Release a Wrist Wearable Tap that will Greatly Expand our Market. Tap is fast, accurate, mobile and eyes free - it is the perfect solution for wearable AR & VR...",
        title: "The Input Technology for the Metaverse",
      },
    ],

    pledgePartner: [
      {
        name: "INFINITY TECH",
        description: "Professional Digital Tape Measure",
        amount: "2.340.000$",
      },
      {
        name: "INFINITY TECH",
        description: "Professional Digital Tape Measure",
        amount: "2.340.000$",
      },
      {
        name: "INFINITY TECH",
        description: "Professional Digital Tape Measure",
        amount: "2.340.000$",
      },
    ],
  },
};
