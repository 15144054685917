import React, { useEffect } from "react";

// STYLES
import "./ExploreProjectsAndPartners.scss";

// LIBRARIES
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// CONSTANTS & MOCKS

// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  categories,
  explorePage,
  filterType,
  handleExplorePageNr,
  isLoading,
  subcategoryList,
} from "slices/sessionSlice";
import { language } from "slices/sessionSlice";
// COMPONENTS
import PartnerCard from "components/PartnerCard/PartnerCard";
import Loading from "components/Atoms/Loading/Loading";
import PartnerProjectCard from "components/PartnerProjectCard/PartnerProjectCard";
import { getFilteredPartners, getFilteredProjects } from "api/exploreApi";
import { useMediaQuery } from "@material-ui/core";

const ExploreProjectsAndPartners = (props) => {
  // PROPS

  // CONSTANTS USING LIBRARIES
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const explorePageData = useSelector(explorePage);

  const categoryListData = useSelector(categories);
  const subcategoryListData = useSelector(subcategoryList);
  const languageData = useSelector(language);
  const exploreFilter = useSelector(filterType);
  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS
  const currentCategory = `${exploreFilter}Categories`;
  const currentPageNr = `${exploreFilter}PageNr`;
  const currentFilter = `${exploreFilter}Filter`;
  const currentPageSize = `${exploreFilter}PageSize`;
  const isMobile = useMediaQuery("(max-width:700px)");

  // USE EFFECT FUNCTION
  // useEffect(() => {
  //   if (exploreFilter === "projects") {
  //     dispatch(
  //       getFilteredProjects({
  //         ...explorePageData?.[currentFilter],
  //         pageNumber: explorePageData?.[currentPageNr],
  //       })
  //     );
  //   } else if (exploreFilter === "partners") {
  //     dispatch(
  //       getFilteredPartners({
  //         nameStartsWith: explorePageData[currentFilter]?.nameStartsWith,
  //         pageNumber: explorePageData?.[currentPageNr],
  //         pageSize: explorePageData?.[currentPageSize],
  //         country: explorePageData?.[currentFilter].country,
  //       })
  //     );
  //   }

  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (exploreFilter === "projects") {
      dispatch(
        getFilteredProjects({
          ...explorePageData?.[currentFilter],
          pageNumber: explorePageData?.[currentPageNr],
        })
      );
    } else if (exploreFilter === "partners") {
      dispatch(
        getFilteredPartners({
          nameStartsWith: explorePageData[currentFilter]?.nameStartsWith,
          pageNumber: explorePageData?.[currentPageNr],
          pageSize: explorePageData?.[currentPageSize],
          country: explorePageData?.[currentFilter].country,
        })
      );
    }

    // eslint-disable-next-line
  }, [explorePageData[currentFilter]]);

  // HANDLERS FUNCTIONS
  const handlePagination = (event, value) => {
    dispatch(handleExplorePageNr({ type: exploreFilter, value }));
  };

  const selectedCategories = categoryListData?.filter((word) => {
    return explorePageData?.projectsFilter?.categoryIds?.includes(
      isMobile ? word?.id : word?.id?.toString()
    );
  });

  const selectedCategoriesName = () => {
    return selectedCategories?.map((item, index) => {
      return (
        <p
          className="component-explore-projects-partners-title-category"
          key={`component-explore-projects--${index}`}
        >
          {item[languageData]}
          {selectedCategories?.length - 1 !== index && ","}{" "}
        </p>
      );
    });
  };

  const selectedSubcategories = subcategoryListData?.filter((word) =>
    explorePageData?.projectsFilter?.subcategoryIds?.includes(
      isMobile ? word?.id : word?.id?.toString()
    )
  );

  const selectedSubcategoriesName = () => {
    return selectedSubcategories?.map((item, index) => {
      return (
        <p
          className="component-explore-projects-partners-title-subcategory"
          key={`component-explore-projects--${index}`}
        >
          {item[languageData]}
          {selectedCategories?.length === index && " "}{" "}
        </p>
      );
    });
  };

  return (
    <>
      {/* {isLoadingData && <Loading />} */}

      <div className="component-explore-projects-partners-container">
        <div className="component-explore-projects-partners-title-subtitle">
          <div className="component-explore-projects-partners-title-text">
            <p className="title">
              {exploreFilter === "partners" && (
                <FormattedMessage
                  id={"component.explore.projects.partners.cards.title"}
                />
              )}
              {exploreFilter === "projects" &&
                (selectedCategories.length > 0 ? (
                  <FormattedMessage
                    id={"component.explore.projects.projects.cards.title"}
                  />
                ) : (
                  <FormattedMessage
                    id={"component.explore.projects.partners.cards.no.category"}
                  />
                ))}
            </p>

            {exploreFilter === "projects" && selectedCategoriesName()}
          </div>

          {/* <div className="component-explore-projects-partners-selected">
            {exploreFilter === "projects" && selectedSubcategoriesName()}
            {exploreFilter === "partners" && (
              <p className="subtitle">
                <FormattedMessage
                  id={"component.explore.projects.partners.cards.subtitle"}
                />
              </p>
            )}
          </div> */}
        </div>

        <div className="component-explore-projects-partners-cards-wrapper">
          {/* OLD ATTEMPT */}
          <div className="projects-partners-cards-container">
            {/*  */}
            {explorePageData?.[currentCategory]?.items?.length === 0 && (
              <div className="projects-partners-not-found-container">
                {
                  <>
                    <span>
                      <FormattedMessage
                        id={
                          "explore.partners.search.not.found.first.part.message"
                        }
                      />
                    </span>
                    <span>
                      {exploreFilter === "partners" ? (
                        <FormattedMessage
                          id={
                            "explore.partners.search.not.found.second.part.message"
                          }
                        />
                      ) : (
                        <FormattedMessage
                          id={
                            "explore.projects.search.not.found.second.part.message"
                          }
                        />
                      )}
                    </span>
                  </>
                }
              </div>
            )}

            {explorePageData?.[currentCategory]?.["items"]?.[0]?.[
              "partnersSearchModels"
            ]?.length === 0 && (
              <div className="projects-partners-not-found-container">
                {/* {
                  <span>
                    <FormattedMessage
                      id={
                        "explore.partners.search.not.found.first.part.message"
                      }
                    />
                  </span>
                } */}
              </div>
            )}
          </div>

          {/* NEW ATTEMPT */}
          <div className="projects-partners-cards-container">
            {exploreFilter === "projects"
              ? explorePageData[currentCategory]?.items?.map((card, index) => {
                  return (
                    <div
                      className={"component-card-item"}
                      key={index}
                      onClick={() => {
                        navigate(`/view-idea/${card.projectId}`);
                      }}
                    >
                      <PartnerProjectCard project={card} />
                    </div>
                  );
                })
              : explorePageData[currentCategory]?.items?.map((card, index) => {
                  return card?.partnersSearchModels?.map((item, index) => {
                    return (
                      <div
                        className={"component-card-partner-item"}
                        key={index}
                      >
                        <PartnerCard
                          key={index}
                          fullName={item?.fullName}
                          companyName={item?.companyName}
                          aboutMe={item?.aboutMe}
                          image={item?.documentModel?.content}
                        />
                      </div>
                    );
                  });
                })}
          </div>

          {/* <div className="bottom-pagination">
            <div className="projects-partners-cards-pagination-container">
              <Stack spacing={2}>
                <Pagination
                  count={Math.ceil(
                    explorePageData?.[currentCategory]?.totalCount / 16
                  )}
                  page={explorePageData?.[currentPageNr]}
                  siblingCount={0}
                  shape="rounded"
                  onChange={handlePagination}
                />
              </Stack>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

ExploreProjectsAndPartners.propTypes = {
  data: PropTypes.shape(),
  setData: PropTypes.func,
};

export default ExploreProjectsAndPartners;
