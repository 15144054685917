import React from "react";

// STYLES
import "./ModalValidationProject.scss";

// LIBRARIES
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS
import ButtonComponent from "components/ButtonComponent/ButtonComponent";

const ModalValidationProject = (props) => {
  // PROPS
  const {
    setShowModal = false,
    showNumber = true,
    title = "",
    text = "",
    showSecondButton = true,
    secondButtonText = "",
  } = props;

  // CONSTANTS USING LIBRARYS
  const navigate = useNavigate();

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className="component-modal-validation-project-container">
      <p className="title">
        <FormattedMessage id={title} />
      </p>
      <p className="text">
        <FormattedMessage id={text} />
      </p>
      {showNumber && (
        <p className="number">
          <FormattedMessage id={"modal.validation.project.number"} /> $
        </p>
      )}

      <div className="buttons">
        <ButtonComponent
          color="blue"
          buttonText={secondButtonText}
          onClick={() => setShowModal(false)}
        />
        {showSecondButton && (
          <ButtonComponent
            color="blue"
            buttonText={"modal.validation.project.accept.button"}
            onClick={() => navigate("/homepage")}
          />
        )}
      </div>
    </div>
  );
};

ModalValidationProject.propTypes = {
  setShowModal: PropTypes.func,
  showNumber: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  showSecondButton: PropTypes.bool,
  secondButtonText: PropTypes.string,
};

export default ModalValidationProject;
