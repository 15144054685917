import React, { useEffect, useState } from "react";

// STYLES
import "./FilterMobile.scss";

// LIBRARIES
import PropTypes from "prop-types";
import classNames from "classnames";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel } from "@material-ui/core";

// CONSTANTS & MOCKS
import { hardcodedFilterData } from "constants/constants.js";
import filterArrow from "./filterArrow.svg";

// REDUX
import {
  categories,
  explorePage,
  filterType,
  handleExploreFilterChange,
  handleResetFilters,
  handleResetPartnerFilters,
  language,
  subcategoryList,
} from "slices/sessionSlice";

// COMPONENTS
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  getExploreSubcategories,
  getFilteredPartners,
  getFilteredProjects,
} from "api/exploreApi";
import countriesList from "constants/countriesList";
import CustomInput from "components/CustomInput/CustomInput";

const FilterMobile = (props) => {
  // PROPS
  const {
    filterFunction = () => {},
    handleSearch = () => {},
    btnType = "",
  } = props;

  // CONSTANTS USING LIBRARIES
  const dispatch = useDispatch();
  const categoriesListData = useSelector(categories);
  const subcategories = useSelector(subcategoryList);
  const explorePageData = useSelector(explorePage);
  const currentLanguage = useSelector(language);
  const exploreFilter = useSelector(filterType);
  // CONSTANTS USING HOOKS
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedCategoriesIds, setSelectedCategoriesIds] = useState(
    explorePageData.projectsFilter.categoryIds
  );
  const [localCategoriesArr, setLocalCategoriesArr] = useState({
    category: "",
    values: [],
  });
  // GENERAL CONSTANTS
  const currentPageNr = `${btnType}PageNr`;
  const currentFilter = `${btnType}Filter`;
  const currentPageSize = `${btnType}PageSize`;
  // USE EFFECT FUNCTION
  useEffect(() => {
    dispatch(getExploreSubcategories(selectedCategoriesIds));
    // setLocalCategoriesArr([]);
    // eslint-disable-next-line
  }, [selectedCategoriesIds]);

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  const handleExpand = (filterName) => {
    selectedFilters.includes(filterName)
      ? setSelectedFilters(
          selectedFilters.filter((item) => item !== filterName)
        )
      : setSelectedFilters([...selectedFilters, filterName]);
  };

  const handleCategory = (category) => {
    let localSelectedCategoriesIds = [];
    if (!selectedCategoriesIds.includes(category.id)) {
      localSelectedCategoriesIds = [...selectedCategoriesIds, category.id];
      setSelectedCategoriesIds(localSelectedCategoriesIds);
    } else {
      localSelectedCategoriesIds = selectedCategoriesIds.filter(
        (id) => id !== category.id
      );
      setSelectedCategoriesIds(localSelectedCategoriesIds);
    }

    dispatch(
      handleExploreFilterChange({
        value: localSelectedCategoriesIds,
        name: "categoryIds",
        type: "projects",
      })
    );
  };

  const handleIds = (categoryName, value) => {
    let localCategoriesObject = {};
    if (localCategoriesArr.category === categoryName) {
      localCategoriesObject = {
        ...localCategoriesArr,
        values: [...localCategoriesArr.values, value],
      };
      setLocalCategoriesArr({
        ...localCategoriesArr,
        values: [...localCategoriesArr.values, value],
      });
    } else {
      localCategoriesObject = { category: categoryName, values: [value] };
      setLocalCategoriesArr({ category: categoryName, values: [value] });
    }
    dispatch(
      handleExploreFilterChange({
        value: localCategoriesObject.values,
        name: categoryName,
        type: btnType,
      })
    );
  };

  const handleChangeText = (event, name) => {
    dispatch(
      handleExploreFilterChange({
        value: event.target.value,
        name: name,
        type: "projects",
      })
    );
  };

  const handleCountries = (country) => {
    dispatch(
      handleExploreFilterChange({
        value: country,
        name: "country",
        type: btnType,
      })
    );
  };
  const handleDisplay = (element) => {
    switch (element.name) {
      case "category":
        return categoriesListData?.map((element, index) => {
          return (
            <FormControlLabel
              // onClick={localHandleChange}
              onClick={() => handleCategory(element)}
              control={
                <Checkbox
                  checked={explorePageData.projectsFilter.categoryIds.includes(
                    element.id
                  )}
                />
              }
              key={`component-filter-mobile-category--${index}`}
              label={<p>{element[currentLanguage]}</p>}
            />
          );
        });
      case "subcategory":
        return subcategories.map((element, index) => {
          return (
            <FormControlLabel
              onClick={() => handleIds("subcategoryIds", element.id)}
              // onChange={localHandleChange}
              control={
                <Checkbox
                  checked={explorePageData.projectsFilter.subcategoryIds.includes(
                    element.id
                  )}
                />
              }
              label={<p>{element.name}</p>}
              key={`component-filter-mobile-subcategory--${index}`}
            />
          );
        });
      case "country":
        return countriesList.map((country, index) => {
          return (
            <FormControlLabel
              onClick={() =>
                btnType === "projects"
                  ? handleIds("country", country.name)
                  : handleCountries(country.name)
              }
              // onChange={localHandleChange}
              name={"country"}
              value={country.name}
              control={
                <Checkbox
                  checked={explorePageData[currentFilter].country.includes(
                    country.name
                  )}
                />
              }
              label={<p>{country.name}</p>}
              key={`component-filter-mobile-subcategory--${index}`}
            />
          );
        });
      case "amount":
        return (
          <div className="component-filter-mobile-amount-container">
            <CustomInput
              label={"Starting From"}
              value={
                explorePageData["projectsFilter"]["rangeGraterOrEqualThan"] ||
                ""
              }
              handleChange={(event) => {
                handleChangeText(event, "rangeGraterOrEqualThan");
              }}
            />
            <CustomInput
              label={"Up To"}
              value={
                explorePageData["projectsFilter"]["rangeLessOrEqualThan"] || ""
              }
              handleChange={(event) => {
                handleChangeText(event, "rangeLessOrEqualThan");
              }}
            />
          </div>
        );

      default:
        return element?.values?.map((value, index) => {
          return (
            <FormControlLabel
              onClick={() => handleIds(element.name, value)}
              control={<Checkbox />}
              label={<p>{value}</p>}
              key={`component-filter-mobile-subcategory--${index}`}
            />
          );
        });
    }
  };

  const localHandleChange = (event) => {
    dispatch(
      handleExploreFilterChange({
        value: event.target.value,
        name: "country",
        type: exploreFilter,
      })
    );
  };

  const handleShowContent = () => {
    filterFunction(false);
    // handleSearch();
    if (btnType === "partners") {
      dispatch(
        getFilteredPartners({
          nameStartsWith: explorePageData[currentFilter]?.nameStartsWith,
          pageNumber: explorePageData?.[currentPageNr],
          pageSize: explorePageData?.[currentPageSize],
          country: explorePageData?.[currentFilter].country,
        })
      );
    } else {
      dispatch(
        getFilteredProjects({
          ...explorePageData?.[currentFilter],
          pageNumber: explorePageData?.[currentPageNr],
        })
      );
    }

    // dispatch(handleResetFilters());
  };

  const handleClear = () => {
    filterFunction(false);
    if (btnType === "projects") {
      dispatch(handleResetFilters());
    } else {
      dispatch(handleResetPartnerFilters());
    }

    // dispatch(
    //   getFilteredProjects({
    //     ...explorePageData?.[currentFilter],
    //     pageNumber: explorePageData?.[currentPageNr] - 1,
    //   })
    // );
    // dispatch(
    //   getFilteredPartners({
    //     nameStartsWith: "",
    //     pageNumber: 0,
    //     pageSize: 12,
    //     country: "",
    //   })
    // );
  };
  return (
    <div className="component-filter-mobile-container">
      <div className="component-filter-mobile-top-container">
        <ButtonComponent
          buttonText={
            btnType === "projects"
              ? "component.filter.mobile.button.show"
              : "component.filter.mobile.button.showPartners"
          }
          color="blue"
          onClick={handleShowContent}
          selected={true}
        />
        <ButtonComponent
          buttonText="component.filter.mobile.button.clear"
          color="gold"
          onClick={handleClear}
          selected={true}
        />
      </div>
      <div className="component-filter-mobile-menu-container">
        {hardcodedFilterData?.map((element, index) => {
          if (btnType === "projects") {
            return (
              <div
                className="component-filter-mobile-category-container"
                key={`component-filter-mobile-category-container--${index}`}
              >
                <div
                  id={element.name}
                  className="component-filter-mobile-name-wrapper"
                  onClick={() => handleExpand(element.name)}
                >
                  <p
                    className={classNames(
                      "component-filter-mobile-category-text",
                      selectedFilters.includes(element.name) && "text-selected"
                    )}
                  >
                    {element.text}
                  </p>
                  <img
                    src={filterArrow}
                    alt=""
                    className={
                      selectedFilters.includes(element.name)
                        ? "menu-arrow-rotated"
                        : "menu-arrow"
                    }
                  />
                </div>

                <div
                  className={classNames(
                    "component-filter-mobile-category-wrapper",
                    !selectedFilters.includes(element.name) && "hidden"
                  )}
                >
                  {handleDisplay(element)}
                </div>
              </div>
            );
          } else {
            if (element.name === "country") {
              return (
                <div
                  className="component-filter-mobile-category-container"
                  key={`component-filter-mobile-category-container--${index}`}
                >
                  <div
                    id={element.name}
                    className="component-filter-mobile-name-wrapper"
                    onClick={() => handleExpand(element.name)}
                  >
                    <p
                      className={classNames(
                        "component-filter-mobile-category-text",
                        selectedFilters.includes(element.name) &&
                          "text-selected"
                      )}
                    >
                      {element.text}
                    </p>
                    <img
                      src={filterArrow}
                      alt=""
                      className={
                        selectedFilters.includes(element.name)
                          ? "menu-arrow-rotated"
                          : "menu-arrow"
                      }
                    />
                  </div>

                  <div
                    className={classNames(
                      "component-filter-mobile-category-wrapper",
                      !selectedFilters.includes(element.name) && "hidden"
                    )}
                  >
                    {handleDisplay(element)}
                  </div>
                </div>
              );
            }
          }
        })}
      </div>
    </div>
  );
};

FilterMobile.propTypes = {
  filterFunction: PropTypes.func,
  handleSearch: PropTypes.func,
  btnType: PropTypes.string,
};

export default FilterMobile;
