import PartnerPledgeCard from "components/PartnerPledgeCard/PartnerPledgeCard";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

// STYLES
import "./PartnerInvestment.scss";

// LIBRARIES
import classNames from "classnames";
import PropTypes from "prop-types";

// CONSTANTS & MOCKS
import { currency } from "constants/constants";

// REDUX
import { getReservedFounds, getInvestedProjects } from "api/projectApi";
import { useDispatch, useSelector } from "react-redux";
import { accountInfo } from "slices/sessionSlice";

// COMPONENTS
import TextField from "@mui/material/TextField";
import TextInput from "components/Atoms/TextInput/TextInput";

const PartnerInvestment = (props) => {
  // PROPS
  const {
    reservedCurrency = "",
    isReadOnly = true,
    handleFounds = () => {},
  } = props;
  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch();
  const accountData = useSelector(accountInfo);

  // CONSTANTS USING HOOKS
  const [total, setTotal] = useState(0);
  const [investedProjects, setInvestedProjects] = useState([]);

  // GENERAL CONSTANTS
  let totalInvestedConst = 0;

  // USE EFFECT FUNCTION

  useEffect(() => {
    if (accountData.emailAddress) {
      dispatch(getReservedFounds({ email: accountData.emailAddress }))
        .unwrap()
        .then((answer) => {
          setTotal(answer.reservedFunds);
        });
      dispatch(
        getInvestedProjects({
          email: accountData.emailAddress,
          pageNumber: 0,
          pageSize: 10,
        })
      )
        .unwrap()
        .then((answer) => {
          setInvestedProjects(answer.items);
        });
      handleTotalInvested.then((answer) => {
        // eslint-disable-next-line
        totalInvestedConst = answer;
      });
    }
    // eslint-disable-next-line
  }, [accountData]);

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  const handleTotalInvested = new Promise((resolve, reject) => {
    let count = 0;
    investedProjects.forEach((item, index) => {
      count = count + item?.finalAmount;
      if (index === investedProjects.length - 1) {
        resolve(count);
        totalInvestedConst = count;
      }
    });
  });

  return (
    <div className="component-partner-investment-container">
      <div
        className={classNames(
          "component-partner-investment-reserved-container",
          isReadOnly && "underline"
        )}
      >
        <p className="component-partner-investment-reserved-wrapper">
          <FormattedMessage id="component.profile.invested.reserved" />
        </p>
        {isReadOnly && (
          <span className="component-partner-investment-amount-wrapper">
            {total}
          </span>
        )}
        <span className="component-partner-investment-currency-wrapper">
          {reservedCurrency}
        </span>
        {!isReadOnly && (
          <>
            <p className="component-partner-investment-filler-wrapper">
              <FormattedMessage id="component.profile.invested.filler" />
            </p>

            <div className="component-partner-investment-editable-container">
              <div className="component-partner-investment-amount-editable-wrapper">
                <TextField
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  fullWidth
                  placeholder="Reserved funds"
                  name={"reservedFunds"}
                  onChange={handleFounds}
                />
              </div>

              <div className="component-partner-investment-dropdown-wrapper">
                <TextInput
                  label={"label.empty"}
                  readOnly={false}
                  name={"currency"}
                  disabled={false}
                  handleChange={handleFounds}
                  select={true}
                  selectValues={currency}
                  component={"select"}
                  value={"EUR"}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div className="component-partner-investment-pledged-container">
        <p className="component-partner-investment-pledged-wrapper">
          <FormattedMessage id="component.profile.invested.pledge" />
        </p>
        <div className="component-partner-investment-pledged-cards-container">
          {investedProjects?.map((element, index) => {
            return (
              <div
                className={`component-partner-investment-pledged-cards-container--${index}`}
                key={`index--${index}`}
              >
                <PartnerPledgeCard
                  title={element?.projectName}
                  subtitle={element?.projectDescription}
                  amount={element?.amount}
                  logo={element?.projectMainImage?.content}
                  investedAmount={element?.finalAmount}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="component-partner-investment-total-container">
        <p className="component-partner-investment-total-wrapper">
          <FormattedMessage id="component.profile.invested.total" />
        </p>
        <span className="component-partner-investment-total-amount-wrapper">
          {totalInvestedConst}
        </span>
      </div>
    </div>
  );
};
PartnerInvestment.propTypes = {
  info: PropTypes.object,
  reservedAmount: PropTypes.string,
  reservedCurrency: PropTypes.string,
  totalAmount: PropTypes.string,
  totalCurrency: PropTypes.string,
  isReadOnly: PropTypes.bool,
  handleFounds: PropTypes.func,
};
export default PartnerInvestment;
