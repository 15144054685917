import React from "react";

// STYLES

// LIBRARIES
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import { FormattedMessage } from "react-intl";

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS

const UploadingProjectModalContent = (props) => {
  // PROPS

  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className="component-modal-validation-project-container">
      <p className="title">
        <FormattedMessage id={"uploading.project.modal.content.title"} />
      </p>
      <div className="component-modal-loader-wrapper">
        <CircularProgress />
      </div>
      <p className="text">
        <FormattedMessage id={"uploading.project.modal.content.text"} />
      </p>
    </div>
  );
};

UploadingProjectModalContent.propTypes = {
  setShowModal: PropTypes.func,
  showNumber: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  showSecondButton: PropTypes.bool,
  secondButtonText: PropTypes.string,
};

export default UploadingProjectModalContent;
