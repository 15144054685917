import React from "react";

// STYLES
import "./FeedbackModalContent.scss";

// LIBRARIES
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS

const FeedBackModalContent = (props) => {
  // PROPS
  const {
    onClose = () => {
    }
  } = props

  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className="connect-partner-request-container">
      <div className="connect-partner-request-title-wrapper">
        <p className={"title"}>
          <FormattedMessage id={"modal.partner.connect.request.title"}/>
        </p>
      </div>
      <div className="connect-partner-request-description-wrapper">
        <p className={"description"}>
          <FormattedMessage id={"modal.partner.connect.request.description"}/>
        </p>
      </div>

      <div className="connect-partner-request-button">
        <ButtonComponent
          buttonText={"page.register.role.selector.show.partner.back.button"}
          onClick={onClose}
          color={"blue"}
        />
      </div>
    </div>
  );
};
FeedBackModalContent.propTypes = {
  onClose: PropTypes.func,
};

export default FeedBackModalContent;