import React from "react";

// STYLES
import "./NotFound.scss";

// LIBRARIES
import { useNavigate } from "react-router-dom";

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import logo from "assets/header/bp-logo.png";
import heroImageWeb from "assets/components/HeroComponent/heroImage.svg";
import heroImageTablet from "assets/hero-images/heroTablet.svg";
import { FormattedMessage } from "react-intl";

const NotFound = () => {
  // PROPS

  // CONSTANTS USING LIBRARYS
  const navigate = useNavigate();

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS
  const windowWidth = window.innerWidth;
  const src = windowWidth >= 1280 ? heroImageWeb : heroImageTablet;

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  const handleNavHome = () => {
    navigate("/homepage");
  };

  return (
    <div className="page-not-found-container">
      <div className="page-not-found-header">
        <img
          src={logo}
          alt=""
          onClick={handleNavHome}
          className="not-found-logo"
        />
      </div>
      <div className="page-not-found-content-wrapper">
        <div className="not-found-hero-image">
          <img className="hero-image-web" src={src} alt="#" />
        </div>
        <div className="content-details">
          <p className="details-big-title">404</p>
          <p className="details-title">
            <FormattedMessage id={"page.not.found.content.details.title"} />
          </p>
          <p className="details-subtitle">
            <FormattedMessage id={"page.not.found.content.details.subtitle"} />
          </p>
          <div className="details-button">
            <ButtonComponent
              buttonText="page.not.found.content.details.back.button"
              onClick={handleNavHome}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
