import React from "react";

// STYLES
import "./TeamComponentWeb.scss";

// LIBRARIES

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS

const TeamComponentWeb = (props) => {
  // PROPS
  const {
    name = "",
    position = "",
    index = 0,
    text = "",
    picture = "",
  } = props;
  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS
  let isReversed = index % 2 !== 0 ? true : false;
  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div
      className={`component-team-web-container ${
        isReversed && "container-reversed"
      }`}
    >
      <div className="component-team-web-picture-container">
        <div className="component-team-web-image-wrapper">
          <img
            className={`component-team-web-image ${
              isReversed && "image-reversed"
            }`}
            src={picture}
            alt=""
          />
        </div>
        <div
          className={`component-team-web-info-wrapper ${
            isReversed && "container-reversed"
          } `}
        >
          <div className="component-team-web-name-wrapper">
            <p className="component-team-web-name">{name}</p>
          </div>
          <div className="component-team-web-position-wrapper">
            <div className="component-team-web-line"></div>
            <p className="component-team-web-position">{position}</p>
            <div className="component-team-web-line"></div>
          </div>
        </div>
      </div>
      <div
        className={`component-team-web-text-container ${
          isReversed && "text-reversed"
        }`}
      >
        <p className="component-team-web-text">{text}</p>
      </div>
    </div>
  );
};

export default TeamComponentWeb;
