import React from "react";
import { FormattedMessage } from "react-intl";

// STYLES
import "./ProfileButton.scss";
// LIBRARIES
import PropTypes from "prop-types";

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS

const ProfileButton = (props) => {
  // PROPS
  const {icon = "", text = "", onClick = () => {}} = props;
  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className="component-profile-button-container" onClick={onClick}>
      <div className="component-profile-content-container">
        <div className="component-profile-icon-container">{icon}</div>
        <div className="component-profile-text-container">
          <p>
            <FormattedMessage id={text}/>
          </p>
        </div>
      </div>
      <div className="component-profile-underline-container">
        <div className="component-profile-underline"/>
      </div>
    </div>
  );
};
ProfileButton.propTypes = {
  icon: PropTypes.element,
  text: PropTypes.string,
  onClick: PropTypes.func,
};
export default ProfileButton;
