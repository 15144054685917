import React from "react";

// STYLES
import "./InvestmentSubcategories.scss";

// LIBRARIES

// CONSTANTS & MOCKS
import { investmentSubcategoryButtonName } from "view/AdminPage/Mocks/AdminPageMocks";

// REDUX
import { handleChangePage, handleSelectAdminTab, selectedTabs } from "slices/adminSlice";
import { useSelector, useDispatch } from "react-redux";

// COMPONENTS
import ButtonComponent from "components/ButtonComponent/ButtonComponent";

const InvestmentSubcategories = () => {
  // PROPS


  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch()
  const selectedTabsData = useSelector(selectedTabs)

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  const handleClick = (val) => {
    dispatch(handleSelectAdminTab({value: val, type: "secondary"}))
    dispatch(handleChangePage({
      name: "pageNumber",
      value: 0
    }));
  };


  return (
    <div className="component-investment-subcategories-container">
      {investmentSubcategoryButtonName?.map((card, index) => {
        return (
          <div className="buttons" key={`key--admin-button ${index}`}>
            <ButtonComponent
              buttonText={card.title}
              color={"gold"}
              onClick={() => handleClick(card.name)}
              selected={card.name === selectedTabsData.secondaryTab}
            />
          </div>
        );
      })}
    </div>
  );
};

InvestmentSubcategories.propTypes = {};

export default InvestmentSubcategories;
