import React from "react";

// STYLES
import "./DatePicker.scss";

// LIBRARIES
import PropTypes from "prop-types";
import classNames from "classnames";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { FormattedMessage } from "react-intl";

const DatePicker = (props) => {
  // PROPS
  const {
    labelColor = "",
    label = "",
    type = "text",
    handleChange = () => {},
    name = "",
    value = "",
    profileStyle = false,
    isReadOnly = false,
    mandatory = true,
    maxDate = "",
  } = props;

  // CONSTANTS USING LIBRARYS
  const today = moment(new Date()).format("MM/dd/yyyy");

  return (
    <div className="component-datePicker-input-label-container">
      <div className="component-text-label-wrapper">
        {!profileStyle && (
          <span className={classNames("component-text-label", labelColor)}>
            <FormattedMessage id={label} />
          </span>
        )}
        {mandatory && <span className="text-label-mandatory"> *</span>}
      </div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div
          className={`${
            profileStyle && isReadOnly
              ? "component-text-input-profilePage-wrapper"
              : "component-text-input-wrapper"
          }`}
        >
          <DesktopDatePicker
            fullWidth
            disableFuture
            inputFormat="MM/dd/yyyy"
            onChange={handleChange}
            name={name}
            type={type}
            maxDate={maxDate}
            value={value || today}
            renderInput={(params) => <TextField {...params} />}
            PaperProps={{
              className: "component-date-picker-wrapper",
            }}
            InputProps={{
              disableUnderline: true,
            }}
            className={`date-picker-input-item`}
          />
        </div>
      </LocalizationProvider>
    </div>
  );
};

DatePicker.propTypes = {
  labelColor: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  minRows: PropTypes.number,
  multiline: PropTypes.bool,
  handleChange: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  select: PropTypes.bool,
  selectValues: PropTypes.array,
  profileStyle: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

export default DatePicker;
