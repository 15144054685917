import React, { useState } from "react";

// STYLES

// LIBRARIES
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { Grid } from "@mui/material";

// CONSTANTS & MOCKS
import imageCompression from "browser-image-compression";
import photo from "assets/uploader/projectMainImg.png";

// REDUX

// COMPONENTS
import TextInput from "components/Atoms/TextInput/TextInput";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import TitleAndSubtitle from "components/TitleAndSubtitle/TitleAndSubtitle";
import Uploader from "components/Uploader/Uploader";
import { yourTeam } from "components/SubmitIdeaForm/Mocks/projectBuilderMock";
import deleteBtn from "assets/projectBuilder/deleteBtn.svg";

const YourTeamComponent = (props) => {
  const photoMock = photo;
  // PROPS
  const { intl = {}, formState = {}, handleChange = () => {} } = props;

  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS
  const [teamMember, setTeamMember] = useState({
    fullName: "",
    jobTitle: "",
    document: photoMock,
  });
  const [uploaded, setUploaded] = useState(false);

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  const handleTeamMemberDetailsChange = (event) => {
    setTeamMember((team) => ({
      ...team,
      [event.target.name]: event.target.value,
    }));
  };

  const handleAdd = () => {
    let newTeamMembers = [...formState?.teamMembers, teamMember];
    let teamMembersDetails = {
      name: "team",
      members: newTeamMembers,
    };

    setTeamMember({
      fullName: "",
      jobTitle: "",
      document: "",
    });

    handleChange(teamMembersDetails);
    setUploaded(false);
  };

  const handleRemoveMember = (memberToRemove) => {
    let teamMembersDetails = {
      name: "team",
      members: formState.teamMembers.filter(
        (member) => member !== memberToRemove
      ),
    };

    handleChange(teamMembersDetails);
  };

  const handleUploadPhoto = async (files) => {
    let mainImg = "";

    files?.forEach((item) => {
      var options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      imageCompression(item, options)
        .then(function (compressedFile) {
          const reader = new FileReader();
          reader.readAsDataURL(compressedFile);

          reader.onload = () => {
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
            if (encoded.length % 4 > 0) {
              encoded += "=".repeat(4 - (encoded.length % 4));
            }

            mainImg = encoded;
            if (mainImg !== "") {
              setTeamMember((team) => ({
                ...team,
                document: mainImg,
              }));
              setUploaded(true);
            }
          };
          reader.onerror = (error) =>
            console.log("[Uploader] handleUploadPhoto", error);
        })
        .catch(function (error) {
          console.log(error.message);
        });
    });
  };

  return (
    <div className="component-CompanyInformation-container">
      <div className="page-build-project-card-title-wrapper">
        <TitleAndSubtitle
          title={"component.project.builder.title.yourTeam"}
          subtitle={"component.project.builder.disclaimer.yourTeam"}
        />
      </div>

      <div className="component-CompanyInformation-inputs-wrapper">
        <div className="component-CompanyInformation-upload-inputs-wrapper">
          <div className="uploder-item">
            <p className={"component-text-label"}>
              <FormattedMessage
                id={"component.project.builder.label.personPhoto"}
              />
            </p>
            <Uploader
              accept={"image/jpeg,image/jpg,image/png"}
              onDrop={handleUploadPhoto}
              previewImg={teamMember?.document}
              edit={uploaded}
              showText={false}
              teamComp={true}
            />
          </div>
        </div>
        <div className="component-CompanyInformation-input-wrapper">
          {yourTeam?.map((item, index) => {
            return (
              <Grid
                item
                xs={item.xs}
                md={item.md}
                className={"component-MandatoryFields-item"}
                key={`inputs--${index}`}
              >
                <TextInput
                  label={item?.label}
                  labelColor={"blue"}
                  placeholder={intl.formatMessage({ id: item.placeholder })}
                  name={item?.name}
                  type={item?.type}
                  handleChange={handleTeamMemberDetailsChange}
                  component={item.component}
                  multiline={item.multiline}
                  value={teamMember[item.name] || ""}
                />
              </Grid>
            );
          })}
        </div>
      </div>
      <div className="add-button-wrapper">
        <ButtonComponent
          buttonText={"component.project.builder.button.projectLink"}
          onClick={handleAdd}
        />
      </div>

      <div className="component-CompanyInformation-team-cards-list-wrapper">
        {formState.teamMembers?.map((member, index) => {
          return (
            <div
              className="component-CompanyInformation-team-member-card-wrapper"
              key={`team-cards--${index}`}
            >
              <div className="image-wrapper">
                <img
                  src={`data:image/jpeg;base64,${member?.document}`}
                  alt=""
                  className={"team-member-avatar"}
                />
              </div>

              <div className="team-card-member-details-wrapper">
                <div className="team-card-delet-btn-wrapper">
                  <div className="close-button">
                    <img
                      src={deleteBtn}
                      alt=""
                      className={"delete-btn"}
                      onClick={() => handleRemoveMember(member)}
                    />
                  </div>
                </div>
                <div className="team-member-details">
                  <p className="team-member-name">{member.fullName || ""}</p>
                  <p className="team-member-job-description">
                    {member.jobTitle || ""}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

YourTeamComponent.propTypes = {
  intl: PropTypes.shape(),
  formState: PropTypes.shape(),
  projectId: PropTypes.number,
  membersList: PropTypes.array,
};

export default injectIntl(YourTeamComponent);
