import React, { useEffect, useState } from "react";

// STYLES
import "./AdminCategoriesListComponent.scss";

// LIBRARIES

// CONSTANTS & MOCKS

// REDUX
import { useDispatch } from "react-redux";
import { createCategory, getMappedCategories } from "api/adminApi";

// COMPONENTS
import TextInput from "components/Atoms/TextInput/TextInput";
import CustomButton from "components/CustomButton/CustomButton";
import AdminCategoriesListEdit from "./AdminCategoriesListEdit/AdminCategoriesListEdit";

const AdminCategoriesListComponent = () => {
  // PROPS

  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch();

  // CONSTANTS USING HOOKS
  const [formState, setFormState] = useState({
    values: {
      category: "",
    },
    categoryList: [],
    isVisible: false,
  });

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION
  useEffect(() => {
    handleGetMappedCategories();
    // eslint-disable-next-line
  }, []);

  // REQUEST API FUNCTIONS

  const handleGetMappedCategories = () => {
    dispatch(getMappedCategories())
      .unwrap()
      .then((answer) => {
        setFormState((formState) => ({
          ...formState,
          categoryList: [answer],
        }));
      });
  };

  const handlePostCategory = () => {
    const body = {
      name: formState?.values?.category,
    };
    dispatch(createCategory(body))
      .unwrap()
      .then(() => {
        dispatch(getMappedCategories())
          .unwrap()
          .then((answer) => {
            setFormState((formState) => ({
              ...formState,
              categoryList: [answer],
            }));
          });
      });
  };

  // HANDLERS FUNCTIONS

  const handleChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  return (
    <>
      <div className="component-admin-categories-list-container">
        <div className="component-admin-categories-list-input-button">
          <TextInput
            labelColor="blue"
            label={"admin.component.addCategory.text.label"}
            placeholder={"Text Here"}
            handleChange={handleChange}
            value={formState?.values?.category || ""}
            name={"category"}
          />
          <CustomButton
            name="submit"
            handleChange={handlePostCategory}
            type="empty"
            text="Add"
            isEnabled={formState.values.category !== "" ? true : false}
          />
        </div>
        {formState?.isVisible && (
          <div className="component-admin-categories-list-input-button">
            <TextInput
              labelColor="blue"
              label={"admin.component.addSubcategories.text.label"}
              placeholder={"Text Here"}
              handleChange={handleChange}
              value={formState?.values?.subcategory || ""}
              name={"subcategory"}
            />
            <CustomButton
              name="submit"
              type="empty"
              text="Add"
              isEnabled={formState?.values?.subcategory !== "" ? true : false}
            />
          </div>
        )}
      </div>
      {formState?.categoryList?.map((categories) => {
        return categories.map((item, index) => {
          return (
            <div className="component-admin-categories-list-edit" key={index}>
              <AdminCategoriesListEdit
                item={item}
                handleGetMappedCategories={handleGetMappedCategories}
              />
            </div>
          );
        });
      })}
    </>
  );
};

export default AdminCategoriesListComponent;
