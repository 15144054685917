import React, { useState } from "react";

// STYLES
import "./PartnerCard.scss";

// LIBRARIES
import PropTypes from "prop-types";

// CONSTANTS & MOCKS

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { sendPartnerContactRequest } from "api/exploreApi";
import { loginState, userRole } from "slices/sessionSlice";

// COMPONENTS
import CustomModal from "components/CustomModal/CustomModal";
import { FormattedMessage } from "react-intl";

const PartnerCard = (props) => {
  // PROPS
  const {
    fullName = "",
    companyName = "",
    aboutMe = "",
    image = "",
    email = "",
  } = props;

  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch();
  const myEmail = useSelector(loginState);
  const myRole = useSelector(userRole);

  // CONSTANTS USING HOOKS
  const [showModal, setShowModal] = useState(false);
  // eslint-disable-next-line
  const [showBtn, setShowBtn] = useState(false);

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  const handleContact = (email) => {
    const body = {
      requestFrom: myEmail.email,
      requestTo: email,
    };
    dispatch(sendPartnerContactRequest(body)).then(() => {
      setShowModal(true);
    });
  };

  // HANDLERS FUNCTIONS

  return (
    <div
      className={`component-projects-card-container ${showBtn && "full-one"}`}
    >
      {myRole === "PARTNER" &&
        <>
          <div className={"full-one"}></div>
          <p className="over-buttons" onClick={() => handleContact(email)}>
            <FormattedMessage id={"projects.card.overlayStyle.button.text"}/>
          </p>
        </>
      }

      <div className="component-projects-card-image">
        <div className="project-image">
          <img src={`data:image/png;base64,${image}`} alt="#"/>
        </div>
      </div>
      <div className="component-projects-card-details">
        <p className="title">{fullName}</p>
        <p className="text">{companyName}</p>
        <p className="text">{aboutMe}</p>
      </div>

      <CustomModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        directionTo="top"
        modalContent={""}
        setShowModal={setShowModal}
        parent={"PartnerConnectRequest"}
      />
    </div>
  );
};
PartnerCard.propTypes = {
  projectsCardBrainerImage: PropTypes.string,
  projectsCardImage: PropTypes.array,
  profilePictureActive: PropTypes.bool,
  companyName: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  overlayStyleOne: PropTypes.string,
  overlayStyleThree: PropTypes.string,
  email: PropTypes.string,
};

export default PartnerCard;
