import React, { useState } from "react";

// STYLES
import "./SearchComponent.scss";

// LIBRARIES
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import TextField from "@mui/material/TextField";
import classNames from "classnames";

// CONSTANTS & MOCKS
import { listOfCategories } from "../SelectCategoryComponent/selectCategoryMock";
//REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  explorePage,
  filterType,
  handleChangeFitlerType,
  handleExploreFilterChange,
  handleResetFilters,
  handleResetPartnerFilters,
  subcategoryList,
} from "slices/sessionSlice";

// COMPONENTS
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import countriesList from "constants/countriesList";
import { getFilteredPartners } from "api/exploreApi";

const SearchComponent = (props) => {
  // PROPS
  const { intl, hide = "", big = "", handleSearch = () => {} } = props;

  // CONSTANTS USING LIBRARIES
  const dispatch = useDispatch();
  const explorePageData = useSelector(explorePage);
  const exploreFilter = useSelector(filterType);
  const subcategoryData = useSelector(subcategoryList);
  const [toggleButton, setToggleButton] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [filter, setFilter] = useState("list");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS
  const categoryType = `${exploreFilter}Filter`;
  const localCountries = countriesList;
  const currentPageNr = `${exploreFilter}PageNr`;
  const currentFilter = `${exploreFilter}Filter`;
  const currentProjectFilter = `${exploreFilter}Filter`;
  // HANDLERS FUNCTIONS

  const handleProjects = () => {
    dispatch(handleChangeFitlerType("projects"));
  };

  const handlePartners = () => {
    dispatch(handleChangeFitlerType("partners"));
  };

  const localHandleChange = (event) => {
    dispatch(
      handleExploreFilterChange({
        value: event.target.value,
        name: event.target.name,
        type: exploreFilter,
      })
    );
  };

  const handleClear = () => {
    setSelectedCountry("");
    dispatch(handleResetPartnerFilters());
    dispatch(
      getFilteredPartners({
        nameStartsWith: "",
        pageNumber: 0,
        pageSize: 16,
        country: "",
      })
    );
  };

  const Placeholder = ({ children }) => {
    return <div className={"dropdown-placeholder"}>{children}</div>;
  };

  const handleChange = (country) => {
    setSelectedCountry(country);
    setIsOpen(false);
    if (country === selectedCountry) {
      handleClear();
    } else {
      dispatch(
        handleExploreFilterChange({
          value: country,
          name: "country",
          type: exploreFilter,
        })
      );
    }
  };

  return (
    <div className="component-search-container">
      <div className={classNames("component-search-dropdown-container", hide)}>
        <ButtonComponent
          buttonText={"component.search.viewProjects.button"}
          onClick={handleProjects}
          selected={exploreFilter === "partners" ? false : true}
          color="gold"
        />
        {/* <ButtonComponent
          buttonText={"component.search.viewPartners.button"}
          onClick={handlePartners}
          selected={exploreFilter === "partners" ? true : false}
          color="gold"
        /> */}
      </div>

      {exploreFilter === "partners" && (
        <div
          className="dropdown-wrapper"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <FormControl>
            <Select
              value={"tests"}
              onChange={localHandleChange}
              displayEmpty={true}
              name={"country"}
              renderValue={
                explorePageData?.partnersFilter?.country !== ""
                  ? () => explorePageData?.partnersFilter?.country
                  : () => <Placeholder>Select country</Placeholder>
              }
              MenuProps={{
                MenuListProps: {
                  style: {
                    height: "25rem",
                    width: "20rem",
                    padding: "1rem",
                  },
                },
                style: { display: isOpen ? "block" : "none" },
              }}
            >
              {countriesList?.map((item, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      fontFamily: "Oswald",
                      width: "20rem",
                    }}
                    key={index}
                  >
                    {/* <input
                      type={"checkbox"}
                      name={"country"}
                      className="subcategoriesList"
                      defaultChecked={
                        selectedCountry === item.name ? true : false
                      }
                      value={item.name}
                    /> */}
                    <MenuItem
                      style={{
                        color: "#002365",
                        fontWeight: "700",
                        fontSize: "1.5rem",
                        display: "flex",
                      }}
                      value={item.name}
                      key={item.code}
                      onClick={() => {
                        handleChange(item.name);
                      }}
                    >
                      {item.name}
                    </MenuItem>
                  </div>
                );
              })}
            </Select>
          </FormControl>
        </div>
      )}

      <div className={classNames("component-search-search-container", big)}>
        <TextField
          variant="standard"
          margin="normal"
          placeholder={intl.formatMessage({
            id: "component.explore.search.text",
          })}
          InputProps={{
            disableUnderline: true,
          }}
          name={"nameStartsWith"}
          onChange={localHandleChange}
          value={explorePageData?.[categoryType]?.nameStartsWith}
        />
      </div>

      {exploreFilter === "partners" && (
        <div className="component-search-sorted-container">
          <ButtonComponent buttonText="Clear" onClick={handleClear} />
        </div>
      )}

      <div className="component-search-sorted-container">
        <ButtonComponent
          buttonText={"component.explore.search.button"}
          onClick={handleSearch}
        />
      </div>
    </div>
  );
};

SearchComponent.propTypes = {
  handleChange: PropTypes.func,
  handleSearch: PropTypes.func,
  setFilteredData: PropTypes.func,
  hide: PropTypes.string,
  big: PropTypes.string,
  filteredData: PropTypes.shape(),
};

export default injectIntl(SearchComponent);
