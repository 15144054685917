import React, { useState } from "react";

// STYLES
// import "./Step1.scss";

// LIBRARIES
import { Grid } from "@mui/material";
import { FormattedMessage, injectIntl } from "react-intl";

// CONSTANTS & MOCKS
import { step2 } from "../Mocks/registerInputs";
import { TEXT_INPUT, SELECT, LOGO } from "constants/constants";
import countriesList from "constants/countriesList";

// REDUX
import { useDispatch } from "react-redux";
import { createCompany } from "api/userApi";

// COMPONENTS
import TextInput from "components/Atoms/TextInput/TextInput";
import Uploader from "components/Uploader/Uploader";

import PropTypes from "prop-types";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";

const Step2 = (props) => {
  // PROPS
  const {
    intl = {},
    handleStep = () => {},
    registerState = {},
    setRegisterState = () => {},
  } = props;

  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch();

  // CONSTANTS USING HOOKS
  const [uploaded, setUploaded] = useState(false);

  // GENERAL CONSTANTS
  const mobileWidth = window.innerWidth <= 600;

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  const handleUpload = (files) => {
    const myFile = new File([files[0]], files[0].name, { type: LOGO });

    let reader = new FileReader();
    reader.readAsDataURL(myFile);

    reader.onload = function () {
      setRegisterState((state) => ({
        ...state,
        companyFiles: {
          ...state.companyFiles,
          img: myFile,
          previewImg: reader.result,
        },
      }));
      setUploaded(true);
    };
  };

  function handleCreateCompany() {
    const companyInfo = getCompanyInfoFromState();
    const userInfo = getUserInfoFromState();
    const imgFile = getCompanyImageFileFromState();

    const formData = createCompanyFormData(companyInfo, userInfo, imgFile);

    dispatch(createCompany(formData)).then(() => handleStep(3));
  }

  function getCompanyInfoFromState() {
    if (!registerState?.companyInfo) {
      throw new Error("Missing company information in state");
    }
    return registerState.companyInfo;
  }

  function getUserInfoFromState() {
    if (!registerState?.userInfo) {
      throw new Error("Missing user information in state");
    }
    return registerState.userInfo;
  }

  function getCompanyImageFileFromState() {
    if (!registerState?.companyFiles?.img) {
      throw new Error("Missing company image file in state");
    }
    return registerState.companyFiles.img;
  }

  function createCompanyFormData(companyInfo, userInfo, imgFile) {
    const formData = new FormData();

    formData.append("file", imgFile);
    formData.append(
      "companyRequestModel",
      JSON.stringify({
        ...companyInfo,
        emailAddress: userInfo.emailAddress,
      })
    );

    return formData;
  }

  const handleChange = (event) => {
    setRegisterState((state) => ({
      ...state,
      companyInfo: {
        ...state.companyInfo,
        [event.target.name]: event.target.value,
      },
    }));
  };

  return (
    <>
      <p className="page-RegisterPage-card-inputs-text">
        <FormattedMessage id="page.registerPage.card.inputs.text" />
      </p>
      <Grid item container className={"page-RegisterPage-card-inputs-wrapper"}>
        {step2?.map((item, index) => {
          return (
            <Grid
              item
              xs={12}
              md={6}
              sm={6}
              className={"page-RegisterPage-card-item"}
              key={`item--${index}--${item.label}`}
            >
              {(item.component === TEXT_INPUT || item.component === SELECT) && (
                <TextInput
                  label={item?.label}
                  labelColor={"blue"}
                  placeholder={intl.formatMessage({ id: item.placeholder })}
                  name={item?.name}
                  type={item?.type}
                  handleChange={handleChange}
                  selectValues={countriesList}
                  select={item.component === SELECT ? true : false}
                  component={item.component}
                  value={registerState.companyInfo[item.name] || ""}
                />
              )}
            </Grid>
          );
        })}
        <Grid
          item
          container
          xs={12}
          className={"page-RegisterPage-document-upload-wrapper"}
        >
          <Grid
            item
            xs={12}
            md={6}
            sm={6}
            className={"page-RegisterPage-document-upload-item"}
          >
            <Uploader
              onDrop={handleUpload}
              previewImg={registerState?.companyFiles?.previewImg}
              showText={false}
              edit={uploaded}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} className={"page-RegisterPage-buttons-wrapper"}>
          <ButtonComponent
            buttonText={"page.register.backButton"}
            onClick={() => handleStep(1)}
            selected={mobileWidth && true}
            color="gold"
          />

          <ButtonComponent
            buttonText={"page.register.nextButton"}
            onClick={handleCreateCompany}
            selected={mobileWidth && true}
            color="gold"
          />
        </Grid>
      </Grid>
    </>
  );
};

Step2.prototype = {
  intl: PropTypes.shape(),
  countriesList: PropTypes.array,
  userInfoState: PropTypes.shape(),
  handleStep: PropTypes.func,
  registerState: PropTypes.shape(),
  setRegisterState: PropTypes.func,
};

export default injectIntl(Step2);
