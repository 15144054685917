export const resetPasswordInputs = [
  {
    label: 'modal.change.password.component.current',
    placeholder: "modal.change.password.component.current.placeholder",
    type: "password",
    name: "oldPassword",
    required: true,
    component: "textInput"
  },
  {
    label: 'modal.change.password.component.new',
    placeholder: "modal.change.password.component.new.placeholder",
    type: "password",
    name: "newPassword",
    required: true,
    component: "textInput"
  },
  {
    label: 'modal.change.password.component.confirm',
    placeholder: "modal.change.password.component.confirm.placeholder",
    type: "password",
    name: "confirmNewPassword",
    required: true,
    component: "textInput"
  },
]