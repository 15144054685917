import React, { useState } from "react";

// STYLES
import "./ProjectApprovalCard.scss";

// LIBRARIES
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// CONSTANTS & MOCKS

// REDUX
import { useDispatch } from "react-redux";
import { approveProject, rejectProject } from "api/adminApi";

// COMPONENTS
import AdminDownloadButton from "components/AdminDownloadButton/AdminDownloadButton";
import TextInput from "components/Atoms/TextInput/TextInput";

const ProjectApprovalCard = (props) => {
  // PROPS
  const { projectId } = props;

  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // CONSTANTS USING HOOKS
  const [rejectReason, setRejectReason] = useState("");

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS
  const approveProjectPost = () => {
    dispatch(approveProject(projectId))
      .unwrap()
      .then(() => navigate("/admin"));
  };

  const rejectProjectPost = () => {
    let payload = {
      projectId,
      rejectReason,
    };
    dispatch(rejectProject(payload))
      .unwrap()
      .then(() => navigate("/admin"));
  };

  // HANDLERS FUNCTIONS
  const handleChange = (event) => {
    setRejectReason(event.target.value);
  };

  const handleGoBack = () => {
    navigate("/admin");
  };

  return (
    <div className="component-project-approval-card-container">
      <p className="title">
        <FormattedMessage
          id={"admin.project.overview.card.projectApproval.label"}
        />
      </p>

      <div className="component-project-approval-input">
        <TextInput
          labelColor="blue"
          label="admin.project.overview.card.rejectReason.label"
          placeholder="Please argue your reason..."
          minRows={15}
          multiline={true}
          handleChange={handleChange}
          value={rejectReason}
        />
      </div>
      <div className="buttons">
        <AdminDownloadButton
          title={"admin.project.overview.card.back.button"}
          small="small"
          color="black"
          onClick={handleGoBack}
        />
        <AdminDownloadButton
          title={"admin.project.overview.card.reject.button"}
          color="black"
          onClick={rejectProjectPost}
        />
        <AdminDownloadButton
          title={"admin.project.overview.card.approve.button"}
          onClick={approveProjectPost}
        />
      </div>
    </div>
  );
};

ProjectApprovalCard.propTypes = {
  projectId: PropTypes.string,
};

export default ProjectApprovalCard;
