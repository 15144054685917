import React from "react";

// STYLES
import "./NdaCard.scss";
import ButtonComponent from "../ButtonComponent/ButtonComponent";

// LIBRARIES

// CONSTANTS & MOCKS
import titleIcon from "assets/components/ndaCard/titleIcon.svg";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";

// REDUX

// COMPONENTS

const NdaCard = (props) => {
  // PROPS
  const {
    upload = () => {},
    handleDownloadFile = () => {},
    ndaUploaded = false,
  } = props;

  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  const handleUpload = () => {
    document.getElementById("fileUpload").click();
  };

  return (
    <div className="component-NdaCard-container">
      <div className="component-NdaCard-title-wrapper">
        <img src={titleIcon} alt={""} className={"nda-icon"} />
        <p className="component-NdaCard-title-text">Non-disclosure agreement</p>
      </div>

      <div className="component-NdaCard-buttons-wrapper">
        <ButtonComponent
          buttonText={"page.register.downloadButton"}
          onClick={handleDownloadFile}
        />

        <Dropzone
          onDrop={upload}
          accept={".png,.jpeg,.jpg,.JPEG,.JPG,.pdf"}
          maxFiles={1}
          maxSize={1 * 1048576} // MB
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <ButtonComponent
                buttonText={"page.register.uploadButton"}
                onClick={handleUpload}
              />
              <input {...getInputProps()} />
              {ndaUploaded && <p className={"uploaded-text"}>Uploaded</p>}
            </div>
          )}
        </Dropzone>
      </div>
    </div>
  );
};

NdaCard.prototype = {
  upload: PropTypes.func,
  handleDownloadFile: PropTypes.func,
  ndaUploaded: PropTypes.bool,
};

export default NdaCard;
