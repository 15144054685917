import React from "react";

// STYLES
import "./PartnersMeetingModalContent.scss";
// LIBRARIES
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";

// CONSTANTS & MOCKS

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { partnersMeeting, removeMeetingPayload } from "slices/adminSlice";
import {
  acceptPartnersMeetingRequest,
  getPartnersRequest,
  rejectPartnersMeetingRequest,
} from "api/adminApi";
// COMPONENTS

const PartnersMeetingModalContent = (props) => {
  // PROPS
  const {
    setShowModal = () => {
    }
  } = props

  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch();
  const partnersData = useSelector(partnersMeeting);
  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  const handleAcceptMeeting = () => {
    dispatch(acceptPartnersMeetingRequest(partnersData))
      .unwrap()
      .then(() => {
        // TODO: call get partners meeting
        dispatch(removeMeetingPayload());
        dispatch(getPartnersRequest());
        setShowModal(false)
      });
  };

  const handleRejectMeeting = () => {
    dispatch(rejectPartnersMeetingRequest(partnersData))
      .unwrap()
      .then(() => {
        // TODO: call get partners meeting
        dispatch(removeMeetingPayload());
        // TODO: implement pagination
        dispatch(getPartnersRequest());
        setShowModal(false)
      });
  };

  return (
    <div className="component-modal-validation-project-container">
      <p className="component-modal-validation-project-title">
        <FormattedMessage id="modal.meeting.partners.title"/>
      </p>
      <p className="component-modal-validation-project-text">
        <FormattedMessage id="modal.meeting.partners.text"/>
      </p>
      <div className="component-modal-validation-project-buttons">
        <ButtonComponent
          buttonText="Accept"
          color="gold"
          selected={true}
          onClick={handleAcceptMeeting}
        />
        <ButtonComponent
          buttonText="Decline"
          color="gold"
          selected={true}
          onClick={handleRejectMeeting}
        />
      </div>
    </div>
  );
};

PartnersMeetingModalContent.propTypes = {
  setShowModal: PropTypes.func,
  showNumber: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  showSecondButton: PropTypes.bool,
  secondButtonText: PropTypes.string,
};

export default PartnersMeetingModalContent;
