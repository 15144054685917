import { createAsyncThunk } from "@reduxjs/toolkit";
import { REST } from "config/axiosConfig";

// =============== CREATE PROJECT API =============== //
export const createProject = createAsyncThunk(
  "brainer/createProject",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.post(`/brainer/submit-your-idea`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const uploadDocument = createAsyncThunk(
  "project/uploadDocument",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.post(
        `/brainer/project-document?projectId=${data.id}`,
        data.data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const postDocuments = createAsyncThunk(
  "brainer/postDocuments",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.post(
        `/brainer/project-documents?projectId=${data.id}`,
        data.files
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const postProjectLinks = createAsyncThunk(
  "project/postProjectLinks",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.post(`/brainer/project-links`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const postTeamMembers = createAsyncThunk(
  "project/postTeamMembers",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.post(`/brainer/project-members`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createBlankProject = createAsyncThunk(
  "project/createBlankProject",
  async (email, { rejectWithValue }) => {
    try {
      const response = await REST.post(
        `/brainer/submit-request?brainerEmailAddress=${email}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// =============== GET PROJECT API =============== //
export const getBrainerProjects = createAsyncThunk(
  "project/getBrainerProjects",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.get(
        `/project/brainer/ideas?emailAddress=${data.email}&projectStatus=${data.projectStatus}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPartnerBlockedProjects = createAsyncThunk(
  "project/getPartnerBlockedProjects",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.get(
        `/project/partner/blocked?emailAddress=${data.email}&projectStatus=${data.projectStatus}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getProject = createAsyncThunk(
  "project/getProject",
  async (id, { rejectWithValue }) => {
    try {
      const response = await REST.get(`/brainer/your-idea?projectId=${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getProjectDocuments = createAsyncThunk(
  "project/getProjectDocuments",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await REST.get(
        `/brainer/project-documents?projectId=${id}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getProjectDocumentByType = createAsyncThunk(
  "project/getProjectDocumentByType",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.get(
        `/brainer/project-document-type?documentType=${data.type}&projectId=${data.id}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getProjectsDocumentByType = createAsyncThunk(
  "project/getProjectsDocumentByType",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.get(
        `/brainer/projects-document-type?documentType=${data.type}&projectId=${data.id}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getProjectLinks = createAsyncThunk(
  "project/getProjectLinks",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await REST.get(`/brainer/project-links?projectId=${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getTeamMembers = createAsyncThunk(
  "brainer/getTeamMembers",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await REST.get(
        `/brainer/project-members?projectId=${id}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// =============== DELETE PROJECT API =============== //
export const deleteProjectLink = createAsyncThunk(
  "project/deleteProjectLink",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.delete(
        `/brainer/project-link?projectId=${data.projectId}&linkId=${data.linkId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// =============== UPDATE PROJECT API =============== //
export const updateProject = createAsyncThunk(
  "brainer/updateProject",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.put(
        `/brainer/update-your-idea?projectId=${data.projectId}`,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// =============== ADD RESERVED FOUNDS API =============== //
export const addReservedFounds = createAsyncThunk(
  "project/partner/reserved-funds",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.post(`/project/partner/reserved-funds`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// =============== GET RESERVED FOUNDS API =============== //
export const getReservedFounds = createAsyncThunk(
  "project/partner/getReservedFounds",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.get(
        `/project/partner/reserved-funds?emailAddress=${data.email}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// =============== GET INVESTED PROJECTS  API =============== //

export const getInvestedProjects = createAsyncThunk(
  "project/partner/getInvestedProjects",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.get(
        `/project/partner/closed-deals?emailAddress=${data.email}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&projectStatus=INVESTED`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const investInProject = createAsyncThunk(
  "project/investInProject",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.post(`/partner/investment-request`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// =============== CREATE PROJECT REQUESTS API =============== //
export const createProjectRequest = createAsyncThunk(
  "project/createProjectRequest",
  async (data, { rejectWithValue }) => {
    try {
      var response = null;
      if (data.projectId) {
        response = await REST.post(
          `/request/upload-documents?requestType=${data.requestType}&projectId=${data.projectId}`,
          data.files
        );
      } else {
        response = await REST.post(
          `/request/upload-documents?requestType=${data.requestType}&projectName=${data.projectName}`,
          data.files
        );
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// =============== VERIFY BUILD PROJECT CODE API =============== //
export const verifyCode = createAsyncThunk(
  "project/verifyCode",
  async (code, { rejectWithValue }) => {
    try {
      const response = await REST.get(
        `/request/validationCode?verificationCode=${code}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// =============== GET PROJECT REQUETS API =============== //
export const getProjectRequests = createAsyncThunk(
  "project/getProjectRequests",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.get(
        `/brainer/requests-by-status?requestStatus=${data.requestStatus}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// =============== PAYMENT VERIFY API =============== //
export const paymentVerify = createAsyncThunk(
  "project/paymentVerify",
  async (projectId, { rejectWithValue }) => {
    try {
      const response = await REST.get(
        `/payment/verify?projectId=${projectId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
