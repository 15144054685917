import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";

// swiper css
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import building1 from "../../assets/pictures/stockPictures/building1.jpg";
import building2 from "../../assets/pictures/stockPictures/building2.jpg";
import building3 from "../../assets/pictures/stockPictures/building3.jpg";
const HeroSliderComponent = () => {
  return (
    <div className="w-full m-auto mt-20">
      <div>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          className="h-[60vh]"
          spaceBetween={50}
          slidesPerView={1}
          onSwiper={(swiper) => console.log(swiper)}
          navigation
          loop={true}
          autoplay={{ delay: 3000 }}
          pagination={{ clickable: true }}
          style={{
            "--swiper-navigation-color": "#002365",
            "--swiper-pagination-color": "#002365",
          }}
        >
          <SwiperSlide>
            <div className="container m-auto w-full flex justify-center items-center h-full">
              <img src={building1} />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="container m-auto w-full flex justify-center items-center h-full">
              <img src={building2} />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="container m-auto w-full flex justify-center items-center h-full">
              <img src={building3} />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default HeroSliderComponent;
