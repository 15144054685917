import React, { useState, useEffect } from "react";

// STYLES

// LIBRARIES
import { useParams } from "react-router-dom";

// CONSTANTS & MOCKS
import { userRolesTypes } from "constants/userRoles";
import { decodeJwtToken } from "helpers/decoder";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  getProject,
  getProjectDocuments,
  getProjectLinks,
  getTeamMembers,
} from "api/projectApi";

// COMPONENTS
import OverviewProjectDetailsCard from "components/OverviewProjectDetailsCard/OverviewProjectDetailsCard";
import OverviewProjectTitleCard from "components/OverviewProjectTitleCard/OverviewProjectTitleCard";
import ProjectOverviewDetails from "components/ProjectOverviewDetails/ProjectOverviewDetails";
import ProjectApprovalCard from "components/ProjectApprovalCard/ProjectApprovalCard";
import {
  BUSINESS_PLAN,
  FileExtensions,
  projectDocuments,
  PROJECT_MAIN_IMAGE,
  VISUAL_PRESENTATION,
} from "constants/constants";
import { projectData, setProjectDocuments } from "slices/projectSlice";
import OverviewProjectVisualPresentation from "components/OverviewProjectVisualPresentation/OverviewProjectVisualPresentation";

const ProjectOverview = () => {
  // PROPS

  // CONSTANTS USING LIBRARYS
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const projectDetails = useSelector(projectData);

  // CONSTANTS USING HOOKS
  const [role, setRole] = useState(userRolesTypes.ADMIN);
  const [visualPresentationData, setVisualPresentationData] = useState([]);

  // const [video]

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION
  useEffect(() => {
    let decodedRole = decodeJwtToken().role;
    setRole(decodedRole);
  }, []);

  useEffect(() => {
    if (projectId) {
      getProjectData(projectId);
    }
    // eslint-disable-next-line
  }, [projectId]);

  useEffect(() => {
    if (projectDetails.documents && projectDetails.links) {
      let contentToDisplay = projectDetails.documents.filter(
        (documentModel) =>
          documentModel.documentType !== projectDocuments.PROJECT_MAIN_IMAGE &&
          documentModel.documentType !== projectDocuments.BUSINESS_PLAN
      );
      let linksToDisplay = projectDetails?.links?.map((link) => {
        return {
          content: link.link,
          documentType: projectDocuments.VISUAL_PRESENTATION,
          fileExtension: FileExtensions.LINK,
          fileName: "videoLink",
        };
      });
      setVisualPresentationData([...contentToDisplay, ...linksToDisplay]);
    }
    // eslint-disable-next-line
  }, [projectDetails.documents, projectDetails.links]);

  // REQUEST API FUNCTIONS
  const getProjectData = (id) => {
    // 1. getProject -- PROJECT_MODEL
    dispatch(getProject(id))
      .unwrap()
      .then(() => {
        // 2. get project documents -- PROJECT_MAIN_IMAGE -- VISUAL_PRESENTATION - video&image -- BUSINESS_PLAN
        dispatch(
          getProjectDocuments({
            id: id,
          })
        )
          .unwrap()
          .then((documentsAnswer) => {
            let projectMainImageBase64 = documentsAnswer.find(
              (documentModel) =>
                documentModel.documentType === PROJECT_MAIN_IMAGE
            );
            let visualPresentationImagesBase64 = documentsAnswer.filter(
              (documentModel) =>
                documentModel.documentType === VISUAL_PRESENTATION &&
                FileExtensions.IMAGE.includes(documentModel.fileExtension)
            );
            let visualPresentationVideosBase64 = documentsAnswer.filter(
              (documentModel) =>
                documentModel.documentType === VISUAL_PRESENTATION &&
                FileExtensions.VIDEO.includes(documentModel.fileExtension)
            );
            let businessPlanBase64 = documentsAnswer.filter(
              (documentModel) => documentModel.documentType === BUSINESS_PLAN
              // && FileExtensions.DOCUMENTS.includes(documentModel.fileExtension)
            );

            dispatch(
              setProjectDocuments([
                projectMainImageBase64,
                ...visualPresentationImagesBase64,
                ...visualPresentationVideosBase64,
                ...businessPlanBase64,
              ])
            );

            // 3. get project links
            dispatch(
              getProjectLinks({
                id: id,
              })
            )
              .unwrap()
              .then(() => {
                dispatch(
                  getTeamMembers({
                    id: id,
                  })
                );
              });
          });
      })
      .catch((error) => {
        console.log("ERR:", error);
      });
  };

  // HANDLERS FUNCTIONS
  return (
    <div className="page-project-overview-container">
      <OverviewProjectDetailsCard projectDetails={projectDetails} />
      {visualPresentationData.length > 0 && (
        <OverviewProjectVisualPresentation
          contentToDisplay={visualPresentationData}
        />
      )}
      <OverviewProjectTitleCard title={"component.overview.title.card.title"} />
      <ProjectOverviewDetails projectDetails={projectDetails} />
      {role === userRolesTypes.ADMIN && (
        <ProjectApprovalCard projectId={projectId} />
      )}
    </div>
  );
};

export default ProjectOverview;
