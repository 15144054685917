import React from "react";

// STYLES
import "./ModalViewPartnersMeeting.scss";

// LIBRARIES
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS
import ButtonComponent from "components/ButtonComponent/ButtonComponent";

const ModalViewPartnersMeeting = (props) => {
  // PROPS
  const { setShowModal = false } = props;

  // CONSTANTS USING LIBRARYS
  const navigate = useNavigate();

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className="component-modal-registration-content-container">
      <p className="title">
        <FormattedMessage id={"modal.view.partners.meeting.title"} />
      </p>
      <p className="text">
        <FormattedMessage id={"modal.view.partners.meeting.description"} />
      </p>
      <div className="component-modal-registration-content-buttons">
        <ButtonComponent
          color="blue"
          buttonText="Back"
          onClick={() => setShowModal(false)}
        />
        <ButtonComponent
          color="blue"
          buttonText="Registration"
          onClick={() => navigate("/select-role")}
        />
        <ButtonComponent
          color="blue"
          buttonText="Log In"
          onClick={() => navigate("/login")}
        />
      </div>
    </div>
  );
};

export default ModalViewPartnersMeeting;
