import React from "react";

// STYLES
import "./ConsultingHelpPage.scss";

// LIBRARIES
import { useParams } from "react-router-dom";

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS
import RefactoredConsultingCard from "components/ConsultingCard/RefactoredConsultingCard";
import {
  consultingCards,
  consultingCards2,
} from "view/ConsultingPage/Mocks/consultingMock";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

const ConsultingHelpPage = () => {
  // PROPS

  // CONSTANTS USING LIBRARYS
  const { role } = useParams();

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className="page-consulting-help-container">
      <div className="component-content-hero-component-container">
        <div className="component-content-hero-title-text-wrapper">
          <div className="component-content-hero-title-text">
            <div className="component-content-hero-title">
              {/* <div className={classNames("line left")} /> */}
              <div className="component-content-hero-title-wrapper">
                <FormattedMessage
                  id={
                    role === "brainer"
                      ? "component.about.hero.title.brainers"
                      : "component.about.hero.title.partners"
                  }
                />
              </div>
              {/* <div className={classNames("line right")} /> */}
            </div>
            <div className="component-content-hero-subtitle">
              <FormattedMessage id={"component.about.hero.subtitle"} />
            </div>
          </div>
        </div>
      </div>

      <div className="page-consulting-brainer-card-container">
        {role === "brainer" &&
          consultingCards?.map((card, index) => {
            return (
              <div style={{ marginBottom: "5rem" }}>
                <RefactoredConsultingCard
                  number={`${index + 1}`}
                  topSubtitle={card.topSubtitle}
                  bottomSubtitle={card.bottomSubtitle}
                  text={card.text}
                  image={card.image}
                  key={`consulting-card--${index}`}
                />
              </div>
            );
          })}
      </div>

      <div className="page-consulting-partner-card-container">
        {role === "partner" &&
          consultingCards2?.map((card, index) => {
            return (
              <RefactoredConsultingCard
                number={`${index + 1}`}
                topSubtitle={card.topSubtitle}
                bottomSubtitle={card.bottomSubtitle}
                text={card.text}
                image={card.image}
                key={`consulting-card--${index}`}
              />
            );
          })}
      </div>
    </div>
  );
};

export default ConsultingHelpPage;
