import React, { Fragment } from "react";

// STYLES
import "./Uploader.scss";

// LIBRARIES
import Dropzone from "react-dropzone";
import PropTypes from "prop-types";

//REDUX

// CONSTANTS & MOCKS
import { BYTES, SQUARE_UPLOADER } from "constants/constants";
import classNames from "classnames";

// REDUX

// COMPONENTS

const Uploader = (props) => {
  // PROPS
  const {
    onDrop = () => {},
    maxFiles = 25,
    uploadedImage = "",
    type = SQUARE_UPLOADER,
    accept = ".png,.jpeg,.jpg,.JPEG,.JPG,.pdf,.mov,.mp4",
    infoText = "Please make sure your photo clearly shows your face",
    edit = false,
    previewImg = "",
    showText = true,
    teamComp = false,
  } = props;

  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS
  const imgSrc = previewImg;

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  // eslint-disable-next-line

  return (
    <div className="page-uploader-container">
      <Fragment>
        <Dropzone
          onDrop={onDrop}
          accept={accept}
          maxFiles={maxFiles}
          maxSize={10 * BYTES} // MB
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              className={
                typeof uploadData !== "string"
                  ? "uploader-dropzone-multi-input"
                  : "uploader-dropzone-input"
              }
            >
              <input {...getInputProps()} accept={accept} />
              {uploadedImage.length === 0 && (
                <div
                  className={classNames(
                    "uploader-dropzone-text-container",
                    type
                  )}
                >
                  <img
                    src={teamComp ? `data:image/png;base64, ${imgSrc}` : imgSrc}
                    alt=""
                    className={`${edit ? "preview-image" : "mock-img"}`}
                  />
                  {showText && (
                    <p className="uploader-dropzone-text">{infoText}</p>
                  )}
                </div>
              )}
              {uploadedImage.length > 0 && (
                <div
                  className={classNames(
                    "uploader-dropzone-uploaded-image",
                    type
                  )}
                >
                  <img
                    src={
                      edit
                        ? previewImg
                        : `data:image/png;base64,${uploadedImage}`
                    }
                    alt=""
                    className={classNames("uploaded-image", type)}
                  />
                </div>
              )}
            </div>
          )}
        </Dropzone>
      </Fragment>
    </div>
  );
};

Uploader.propTypes = {
  maxFiles: PropTypes.number,
  uploadImage: PropTypes.string,
  accept: PropTypes.string,
  onDrop: PropTypes.func,
  uploadedImage: PropTypes.string,
  type: PropTypes.string,
  infoText: PropTypes.string,
  previewImg: PropTypes.string,
  edit: PropTypes.bool,
  teamComp: PropTypes.bool,
  showText: PropTypes.bool,
};

export default Uploader;
