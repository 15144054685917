import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// STYLES
import "./Splash.scss";
import logo from "./logo.svg";
import text from "./text.svg";
import background from "./background.png";

import classNames from "classnames";

// LIBRARIES

// CONSTANTS & MOCKS
import { tabletResolutionHigh } from "constants/constants.js";

// REDUX

// COMPONENTS

const Splash = () => {
  // PROPS

  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS
  const navigate = useNavigate();
  const [isSmall, setIsSmall] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [isDesktop, setDesktop] = useState(
    window.innerWidth > tabletResolutionHigh
  );
  // GENE   RAL CONSTANTS

  // USE EFFECT FUNCTION
  useEffect(() => {
    if (isDesktop) {
      navigate("/homepage");
    }
    setTimeout(() => {
      setIsSmall(true);
    }, 500);
    setTimeout(() => {
      setFadeOut(true);
    }, 1500);
    setTimeout(() => {
      navigate("/homepage");
    }, 2500);
  }, []);
  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div
      className={classNames(
        `component-splash-container ${fadeOut && "add-fade-out"}`
      )}
      style={{ backgroundImage: `url(${background})` }}
    >
      <img
        src={logo}
        className={classNames(
          `component-logo-container-big ${
            isSmall && "component-logo-container-small"
          }`
        )}
      />
      {isSmall && <img src={text} className="component-text" />}
    </div>
  );
};

export default Splash;
