import React from "react";

// STYLES
import "./PartnersMeetingModalContent.scss";

// LIBRARIES
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

// CONSTANTS & MOCKS

// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  handleSaveRequestDetails,
  paginationDetails,
  requestDetails,
  selectedTabs,
} from "slices/adminSlice";
import { approveSignUpRequest } from "api/adminApi";

// COMPONENTS
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import { handleTableDataRequest } from "helpers/adminTablesFunctions";
import { AdminTabs, RequestStatus } from "constants/constants";

const ApproveSignUpRequestContent = (props) => {
  // PROPS
  const { setShowModal = () => {} } = props;

  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch();
  const requestDetailsData = useSelector(requestDetails);
  const paginationData = useSelector(paginationDetails);
  const selectedTabsData = useSelector(selectedTabs);

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  const handleApproveRequest = () => {
    dispatch(approveSignUpRequest(requestDetailsData.requestId))
      .unwrap()
      .then(() => {
        handleTableDataRequest(
          {
            mainTab:
              selectedTabsData.mainTab === AdminTabs.BRAINER_SIGN_UP_REQUESTS
                ? AdminTabs.BRAINER_SIGN_UP_REQUESTS
                : AdminTabs.PARTNER_SIGN_UP_REQUESTS,
            secondaryTab: RequestStatus.PENDING,
          },
          paginationData
        );
        setShowModal(false);
        dispatch(handleSaveRequestDetails(null));
      });
  };

  return (
    <div className="component-modal-validation-project-container">
      <p className="component-modal-validation-project-title">
        <FormattedMessage id="modal.meeting.sign.up.approve.title" />
      </p>
      <p className="component-modal-validation-project-text">
        <FormattedMessage id="modal.meeting.sign.up.approve.text" />
      </p>
      <div className="component-modal-validation-project-buttons">
        <ButtonComponent
          buttonText="Cancel"
          color="blue"
          onClick={() => setShowModal(false)}
        />
        <ButtonComponent
          buttonText="Yes"
          color="blue"
          onClick={handleApproveRequest}
        />
      </div>
    </div>
  );
};

ApproveSignUpRequestContent.propTypes = {
  setShowModal: PropTypes.func,
  type: PropTypes.string,
};

export default ApproveSignUpRequestContent;
