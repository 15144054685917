import React, { useEffect, useState } from "react";

// STYLES

// LIBRARIES
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import imageCompression from "browser-image-compression";

// CONSTANTS & MOCKS
import { PROJECT_MAIN_IMAGE } from "constants/constants";
import photo from "assets/uploader/projectMainImg.png"

// REDUX

// COMPONENTS
import TextInput from "components/Atoms/TextInput/TextInput";
import Uploader from "components/Uploader/Uploader";
import TitleAndSubtitle from "components/TitleAndSubtitle/TitleAndSubtitle";

const PresentationContent = (props) => {
  // PROPS
  const {
    intl = {},
    formState = {},
    setFormState = () => {
    },
    handleChange = () => {
    },
  } = props;

  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS
  const [imagePreview, setImagePreview] = useState("");

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION
  useEffect(() => {
    if (formState.projectMainImage?.file) {
      const objectUrl = URL.createObjectURL(formState.projectMainImage.file);
      setImagePreview(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    } else {
      setImagePreview("");
    }
  }, [formState.projectMainImage?.file]);

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  const handleUploadImage = async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    const compressedFile = await imageCompression(file[0], options);

    let extension = file[0].type.split("/");
    let documentModel = {
      type: PROJECT_MAIN_IMAGE,
      file: compressedFile,
      fileName: file[0].name,
      fileExtension: extension[1],
    };

    handleChange(documentModel);
  };

  const handleRemoveImage = () => {
    setFormState({
      ...formState,
      projectMainImage: {},
    });
  };

  const localHandleChange = (event, field) => {
    if (event.target.value.length > 0) {
      handleChange(event, true);
    } else {
      handleChange(event, false);
    }
  };

  return (
    <div className="component-PresentationContent-container">
      <div className="page-build-project-card-title-wrapper">
        <TitleAndSubtitle
          title={"component.project.builder.title.presentationContent"}
          subtitle={"component.project.builder.disclaimer.presentationContent"}
        />
      </div>
      <div className="component-PresentationContent-inputs-wrapper">
        <div className="component-PresentationContent-upload-wrapper">
          <p className={"component-text-label"}>
            <FormattedMessage
              id={"component.project.builder.label.mainImage"}
            />
          </p>
          <Uploader
            accept={"image/jpeg,image/jpg,image/png"}
            onDrop={handleUploadImage}
            previewImg={photo}
            showText={false}
            edit={false}
          />

          {imagePreview && (
            <div className={"preview-image-wrapper"}>
              <img
                id={"preview"}
                src={imagePreview}
                alt={""}
                className={"preview-img"}
              />
              <DeleteForeverIcon
                className="remove-icon"
                onClick={handleRemoveImage}
              />
            </div>
          )}
        </div>
        <div className="component-PresentationContent-input-wrapper">
          <TextInput
            label={"component.project.builder.label.projectDescription"}
            labelColor={"blue"}
            placeholder={intl.formatMessage({
              id: "component.project.builder.placeholder.textInput",
            })}
            name={"projectDescription"}
            type={"text"}
            handleChange={localHandleChange}
            multiline={true}
            value={formState.values["projectDescription"]}
          />
        </div>
      </div>
    </div>
  );
};

PresentationContent.propTypes = {
  intl: PropTypes.shape(),
  formState: PropTypes.shape(),
  handleChange: PropTypes.func,
  projectId: PropTypes.number,
};

export default injectIntl(PresentationContent);
