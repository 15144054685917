import { createAsyncThunk } from "@reduxjs/toolkit";
import { REST } from "config/axiosConfig";

export const createCategory = createAsyncThunk(
  "admin/createCategory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.post("/admin/category/create", data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const editCategory = createAsyncThunk(
  "admin/editCategory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.put(
        `/admin/category/update?categoryId=${data.id}`,
        data.data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createSubcategory = createAsyncThunk(
  "admin/createSubcategory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.post(
        "/admin/category/subcategory/create",
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getMappedCategories = createAsyncThunk(
  "admin/getMappedCategories",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.get("/admin/category/all-mapped");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPartnersRequest = createAsyncThunk(
  "admin/getPartnersRequest",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.get(
        `/admin/partners-meeting/requests?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getInvestmentRequest = createAsyncThunk(
  "admin/investment/requests",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.get(
        `/admin/investment/requests?investmentType=${data.type}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getProjectApproval = createAsyncThunk(
  "admin/getProjectApproval",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.get(
        `/admin/project/drafts?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getProjectRequests = createAsyncThunk(
  "admin/getProjectRequests",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.get(
        `/admin/approve/requests?requestType=${data.requestType}&requestStatus=${data.status}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getProjectById = createAsyncThunk(
  "admin/getInvestment",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.get(`/project/view?projectId=${data}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const acceptPartnersMeetingRequest = createAsyncThunk(
  "admin/acceptPartnersMeetingRequest",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.put(`/admin/partners-meeting/accepted`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const rejectPartnersMeetingRequest = createAsyncThunk(
  "admin/rejectPartnersMeetingRequest",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.put(
        `/admin/partners-meeting/unaccepted?partnersMeetingId=${data.id}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const approveProject = createAsyncThunk(
  "admin/approveProject",
  async (id, { rejectWithValue }) => {
    try {
      const response = await REST.put(`/admin/project/approve?projectId=${id}`);
      return response.data.items;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const rejectProject = createAsyncThunk(
  "admin/rejectProject",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.put(`/admin/approve/reject`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getInvestmentById = createAsyncThunk(
  `admin/getInvestmentById`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.get(
        `/admin/investment/view-details?investmentId=${data.id}`,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const rejectDeal = createAsyncThunk(
  `admin/rejectDeal`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.put(
        `/admin/investment/reject-deal?investmentId=${data.id}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const acceptDeal = createAsyncThunk(
  `admin/acceptDeal`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.put(
        `/admin/investment/close-deal?finalAmount=${data.finalAmount}&investmentId=${data.id}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getInvestmentReport = createAsyncThunk(
  `admin/getInvestmentReport`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.get(
        `/admin/investment/report?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSubcategory = createAsyncThunk(
  `admin/deleteSubcategory`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.delete(
        `/admin/category/subcategory/delete?subcategoryId=${data.id}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const downloadReport = createAsyncThunk(
  `admin/downloadReport`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.get(
        `/admin/investment/export?exportRequestType=${data.path}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const downloadRequestDocument = createAsyncThunk(
  `admin/downloadRequestDocument`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.get(
        `/admin/approve/request/document?requestId=${data.requestId}&documentType=${data.documentType}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const uploadRequestDocument = createAsyncThunk(
  `admin/uploadRequestDocument`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.post(
        `/admin/approve/upload-request-document?requestId=${data.requestId}&documentType=${data.documentType}`,
        data.file
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const approveRequest = createAsyncThunk(
  `admin/approveRequest`,
  async (requestId, { rejectWithValue }) => {
    try {
      const response = await REST.post(
        `/admin/approve/approve?requestId=${requestId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const rejectRequest = createAsyncThunk(
  `admin/rejectRequest`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.post(`/admin/approve/reject`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// ============= SIGN UP APIS ================ //

export const getSignUpRequests = createAsyncThunk(
  `admin/getSignUpRequests`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.get(
        `/admin/account/requests?signUpRequestStatus=${data.signUpRequestStatus}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&role=${data.role}`,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSignUpDocument = createAsyncThunk(
  `admin/getSignUpDocument`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.get(
        `/admin/account/request/document?requestId=${data.requestId}&documentType=${data.documentType}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const uploadSignUpDocument = createAsyncThunk(
  `admin/uploadSignUpDocument`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.post(
        `/admin/account/upload-request-document?requestId=${data.requestId}&documentType=${data.documentType}`,
        data.file
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const approveSignUpRequest = createAsyncThunk(
  `admin/approveSignUpRequest`,
  async (requestId, { rejectWithValue }) => {
    try {
      const response = await REST.post(
        `/admin/account/approve?requestId=${requestId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const rejectSignUpRequest = createAsyncThunk(
  `admin/rejectSignUpRequest`,
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.post(`/admin/account/reject`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);