import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import React from "react";

// STYLES
import "./TermsOfServiceCard.scss";
import image from "assets/components/termsOfServiceCard/termsOfServiceCard.svg";
import tabletImage from "assets/components/termsOfServiceCard/termsOfServiceTabletImage.svg";
// LIBRARIES
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS

const TermsOfServiceCard = () => {
  // PROPS

  // CONSTANTS USING LIBRARYS
  const navigate = useNavigate();
  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  const handleNavigateToTerms = () => {
    navigate(`/terms`);
  };
  return (
    <div className="component-term-of-service-card-container">
      <div className="component-term-of-service-card-background-image-wrapper">
        <img
          src={
            window.innerWidth <= 599
              ? tabletImage
              : window.innerWidth <= 1279
              ? tabletImage
              : image
          }
          alt=""
          className="background-image"
        />
      </div>
      <div className="component-term-of-service-card-details-wrapper">
        <p className="title">
          <FormattedMessage id="component.term.of.service.card.details.title" />
        </p>
        <p className="subtitle">
          <FormattedMessage id="component.term.of.service.card.details.subtitle" />
        </p>
        <div className="button">
          <ButtonComponent
            buttonText={"component.term.of.service.card.details.button"}
            color="gold"
            onClick={handleNavigateToTerms}
          />
        </div>
      </div>
    </div>
  );
};

export default TermsOfServiceCard;
