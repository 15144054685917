import React, { useEffect, useState } from "react";

// STYLES
import "./Professionals.scss";

// LIBRARIES

// CONSTANTS & MOCKS

// REDUX
import { useDispatch } from "react-redux";
import { getTrendingPartners } from "api/exploreApi";

// COMPONENTS
import InvestorsCard from "components/InvestorCard/InvestorsCard";
import { tabletResolutionHigh } from "constants/constants";
import { FormattedMessage } from "react-intl";

const Professionals = () => {
  // PROPS

  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch();

  // CONSTANTS USING HOOKS
  const [data, setData] = useState([]);
  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION
  useEffect(() => {
    dispatch(getTrendingPartners())
      .unwrap()
      .then((answer) => {
        setData(answer);
      });
    // eslint-disable-next-line
  }, []);

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className="component-trending-investors-container">
      <div className="component-trending-investors-title-subtitle-wrapper">
        <p className="title">
          <FormattedMessage id="component.trending.investors.title" />
        </p>
      </div>
      <div className="component-trending-investors-project-cards-wrapper">
        {data?.map((card, index) => {
          if (index && index < 3 && window.innerWidth < tabletResolutionHigh) {
            return (
              <div
                key={index}
                className={"component-trending-investors-card-item"}
              >
                <InvestorsCard
                  showDetails={false}
                  investorImgs={card?.documentModel?.content}
                  investorName={card?.fullName}
                  investorPosition={card?.investorPosition}
                  reserveFunds={"Reserved funds"}
                  fundsNumber={card?.reserveFunds}
                  pledgeFunds={"Pledged Funds"}
                  pledgeNumber={card?.pledgeFunds}
                  companyName={card?.companyName}
                />
              </div>
            );
          } else if (window.innerWidth > tabletResolutionHigh) {
            return (
              <div
                key={index}
                className={"component-trending-investors-card-item"}
              >
                <InvestorsCard
                  investorImgs={card?.documentModel?.content}
                  investorName={card?.fullName}
                  investorPosition={card?.investorPosition}
                  reserveFunds={"Reserved funds"}
                  fundsNumber={card?.reserveFunds}
                  pledgeFunds={"Pledged Funds"}
                  pledgeNumber={card?.pledgeFunds}
                  companyName={card?.companyName}
                />
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};
export default Professionals;
