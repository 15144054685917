import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import React from "react";

// STYLES
import "./IdeaComponent.scss";

// LIBRARIES
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// CONSTANTS & MOCKS

// REDUX
// COMPONENTS
import submitIdea from "assets/pictures/sumitIdea.png";

const IdeaComponent = (props) => {
  // PROPS
  const { title = "", text = "", subtext = "" } = props;

  // CONSTANTS USING LIBRARIES
  const navigate = useNavigate();

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <div className="component-idea-submit-container">
      <div className="component-idea-image-wrapper">
        <img className="component-idea-image" src={submitIdea} alt="idea" />
      </div>
      <div className="component-idea-content-container">
        <div className="component-idea-title-container">
          <div className="component-idea-line"></div>
          <p className="component-idea-title">{title}</p>
          <div className="component-idea-line"></div>
        </div>
        <div className="component-idea-text-wrapper">
          <p className="component-idea-text-first">{text}</p>
          <p className="component-idea-text-second">{subtext}</p>
        </div>
        <div className="component-idea-button-wrapper">
          <ButtonComponent
            color={"gold"}
            buttonText={"component.submitIdea.button"}
            onClick={() => handleNavigate("/build")}
            fontSize="small-button-font-size"
          />
        </div>
      </div>
    </div>
  );
};

IdeaComponent.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  subtext: PropTypes.string,
};

export default IdeaComponent;
