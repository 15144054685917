import React, { useState } from "react";

// STYLES
import "./UploadDocumentsForIdea.scss";

// LIBRARIES
import { FormattedMessage } from "react-intl";
import Dropzone from "react-dropzone";
import roNdaBrainerFile from "assets/Files/ro-nda-brainer.docx";
import roBrainerAgreement from "assets/Files/ro-brainer-agreement.docx";
import enNdaBrainerFile from "assets/Files/en-nda-brainer.docx";
import enBrainerAgreement from "assets/Files/en-brainer-agreement.docx";
import { injectIntl, useIntl } from "react-intl";

// CONSTANTS & MOCKS
import { BRAINER_AGREEMENT, BYTES, NDA } from "constants/constants";
import { convertRequestFilesToFormData } from "helpers/converters";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { createProjectRequest } from "api/projectApi";
import { language } from "slices/sessionSlice";

// COMPONENTS
import TextInput from "components/Atoms/TextInput/TextInput";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import FileIcon from "assets/pictures/file.svg";

const UploadDocumentsForIdea = () => {
  // PROPS

  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch();
  const languageData = useSelector(language);
  const intl = useIntl();

  // CONSTANTS USING HOOKS
  const [projectRequestData, setProjectRequestData] = useState({
    projectName: "",
    ndaFile: null,
    agreementFile: null,
  });
  const [projectRequestStatus, setProjectRequestStatus] = useState("request");

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS
  const submitRequest = () => {
    // TODO: We use in this step the following ENUMS: NDA , PARTNERSHIP_AGREEMENT
    let requestFormData = convertRequestFilesToFormData([
      projectRequestData.ndaFile,
      projectRequestData.agreementFile,
    ]);

    dispatch(
      createProjectRequest({
        requestType: "IDEA",
        projectName: projectRequestData.projectName,
        files: requestFormData,
      })
    ).then(() => {
      setProjectRequestStatus("requested");
    });
  };

  // HANDLERS FUNCTIONS
  const handleUpoadDocument = (file, documentType) => {
    let extension = file[0].type.split("/");

    let documentModel = {
      type: NDA,
      file: file[0],
      fileName: file[0].name,
      fileExtension: extension[1],
    };

    if (documentType === "NDA") {
      setProjectRequestData({ ...projectRequestData, ndaFile: documentModel });
    } else {
      setProjectRequestData({
        ...projectRequestData,
        agreementFile: documentModel,
      });
    }
  };

  const handleRemoveDocument = (documentType) => {
    if (documentType === NDA) {
      setProjectRequestData({ ...projectRequestData, ndaFile: null });
    } else {
      setProjectRequestData({
        ...projectRequestData,
        agreementFile: null,
      });
    }
  };

  // const handleDownloadDocument = (documentType) => {
  //   switch (documentType) {
  //     case NDA:
  //       const ndaFile = URL.createObjectURL(SamplePdf);
  //       const ndaFileWindow = window.open();
  //       ndaFileWindow.location.href = ndaFile;
  //       break;
  //     case BRAINER_AGREEMENT:
  //       const brainerAgreementPdf = URL.createObjectURL(SamplePdf);
  //       const brainerAgreementWindow = window.open();
  //       brainerAgreementWindow.location.href = brainerAgreementPdf;
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const handleChangeProjectName = (event) => {
    setProjectRequestData({
      ...projectRequestData,
      projectName: event.target.value,
    });
  };

  const handleDownloadNDAFile = () => {
    const link = document.createElement("a");

    const brainerFileByLang =
      languageData === "ro" ? roNdaBrainerFile : enNdaBrainerFile;

    link.href = brainerFileByLang;
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleDownloadAgreementFile = () => {
    const link = document.createElement("a");

    const brainerFileByLang =
      languageData === "ro" ? roBrainerAgreement : enBrainerAgreement;

    link.href = brainerFileByLang;
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="page-upload-documents-for-idea-container">
      {projectRequestStatus === "request" && (
        <div className="component-unsubmitted-content-container">
          <div className="page-upload-documents-for-idea-title-subtitle">
            <p className="title">
              <FormattedMessage
                id={"page.upload.documents.for.idea.content.title"}
              />
            </p>
            <p className="subtitle">
              <FormattedMessage
                id={"page.upload.documents.for.idea.content.subtitle"}
              />
            </p>
          </div>
          <div className="page-upload-documents-for-idea-details">
            <p className="title-text">
              <FormattedMessage
                id={"page.upload.documents.for.idea.project.name.title"}
              />
            </p>

            <TextInput
              placeholder={intl.formatMessage({
                id: "page.upload.documents.for.idea.input.project.name.placeholder",
              })}
              handleChange={handleChangeProjectName}
              value={projectRequestData.projectName || ""}
              name={"projectName"}
            />
          </div>
          <div className="page-upload-documents-for-idea-details">
            <p className="title-text">
              <FormattedMessage
                id={"page.upload.documents.for.idea.nda.title"}
              />
            </p>
            <p className="subtitle-text">
              <FormattedMessage
                id={"page.upload.documents.for.idea.nda.text"}
              />
            </p>
          </div>
          <div className="page-upload-documents-for-idea-nda">
            <div className="image-name">
              <div className="image">
                <img src={FileIcon} alt="" />
              </div>
              <p className="nda-name">
                <FormattedMessage
                  id={"modal.payment.required.content.nda.name"}
                />
              </p>
            </div>
            <div className="download-upload-buttons">
              <div className="download">
                <ButtonComponent
                  buttonText={intl.formatMessage({
                    id: "page.upload.documents.for.idea.download.button.nda.title",
                  })}
                  onClick={handleDownloadNDAFile}
                />
              </div>
              {/*<div className="separation-line"></div>*/}
              <div className="upload">
                {!projectRequestData.ndaFile ? (
                  <Dropzone
                    onDrop={(file) => handleUpoadDocument(file, NDA)}
                    maxFiles={1}
                    maxSize={10 * BYTES} // MB
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <ButtonComponent
                          buttonText={"page.register.uploadButton"}
                        />
                        <input
                          {...getInputProps()}
                          accept={"application/pdf"}
                        />
                      </div>
                    )}
                  </Dropzone>
                ) : (
                  <div>
                    <ButtonComponent
                      buttonText={intl.formatMessage({
                        id: "page.upload.documents.for.idea.button.nda.remove",
                      })}
                      onClick={() => handleRemoveDocument(NDA)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="page-upload-documents-for-idea-details">
            <p className="title-text">
              <FormattedMessage
                id={"page.upload.documents.for.idea.brainerAgreement.title"}
              />
            </p>
            <p className="subtitle-text">
              <FormattedMessage
                id={"page.upload.documents.for.idea.brainerAgreement.text"}
              />
            </p>
          </div>
          <div className="page-upload-documents-for-idea-nda">
            <div className="image-name">
              <div className="image">
                <img src={FileIcon} alt="" />
              </div>
              <p className="nda-name">
                <FormattedMessage
                  id={"modal.payment.required.content.brainerAgreement.name"}
                />
              </p>
            </div>
            <div className="download-upload-buttons">
              <div className="download">
                <ButtonComponent
                  buttonText={intl.formatMessage({
                    id: "page.upload.documents.for.idea.download.button.brainer.agreement.title",
                  })}
                  onClick={handleDownloadAgreementFile}
                />
              </div>
              {/*<div className="separation-line"></div>*/}
              <div className="upload">
                {!projectRequestData.agreementFile ? (
                  <Dropzone
                    onDrop={(file) =>
                      handleUpoadDocument(file, BRAINER_AGREEMENT)
                    }
                    maxFiles={1}
                    maxSize={10 * BYTES} // MB
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <ButtonComponent
                          buttonText={"page.register.uploadButton"}
                        />
                        <input
                          {...getInputProps()}
                          accept={"application/pdf"}
                        />
                      </div>
                    )}
                  </Dropzone>
                ) : (
                  <div>
                    <ButtonComponent
                      buttonText={intl.formatMessage({
                        id: "page.upload.documents.for.idea.button.brainer.agreement.remove",
                      })}
                      onClick={() => handleRemoveDocument(BRAINER_AGREEMENT)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="button-container">
            <ButtonComponent
              buttonText={intl.formatMessage({
                id: "page.upload.documents.for.idea.button.send.request",
              })}
              onClick={submitRequest}
            />
          </div>
        </div>
      )}
      {projectRequestStatus === "requested" && (
        <div className="page-upload-documents-for-idea-requested-container">
          <div className="page-upload-documents-for-idea-title-subtitle">
            <p className="title">
              <FormattedMessage
                id={"page.upload.documents.for.idea.sent.title"}
              />
            </p>
            <p className="subtitle">
              <FormattedMessage
                id={"page.upload.documents.for.idea.sent.subtitle"}
              />
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default injectIntl(UploadDocumentsForIdea);
