import React from "react";
import { FormattedMessage } from "react-intl";

// STYLES
import "./TermsOfService.scss";
import roTerms from "assets/Files/ro-terms.docx";
import enTerms from "assets/Files/en-terms.docx";

// LIBRARIES

// CONSTANTS & MOCKS

// REDUX
import { useSelector } from "react-redux";
import { language } from "slices/sessionSlice";

// COMPONENTS

const TermsOfService = () => {
  // PROPS

  // CONSTANTS USING LIBRARIES
  const languageData = useSelector(language);

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className="page-terms-of-service-container">
      <p className="title">
        <FormattedMessage id={"page.terms.of.service.title"} />
      </p>
      <p className="description">
        <FormattedMessage
          id={"page.terms.of.service.description"}
          values={{ br: <br /> }}
        />
      </p>

      <a href={languageData === "ro" ? roTerms : enTerms} download>
        <p className="termsDownload">
          <FormattedMessage id={"component.footer.terms.download"} />
        </p>
      </a>
    </div>
  );
};

export default TermsOfService;
