import React, { useState, useEffect } from "react";

// STYLES
import "./Header.scss";

// LIBRARIES
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// CONSTANTS & MOCKS
import { getHeaderLinks } from "./Mocks/linksMock";
import { languages } from "constants/constants";

// REDUX
import {
  accountInfo,
  handleChangeLanguage,
  language,
  loginState,
  userRole,
} from "slices/sessionSlice";
import { useDispatch } from "react-redux";

// COMPONENTS
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import logo from "assets/header/bp-logo.png";
import AvatarFallback from "assets/pictures/AvatarFallback.jpeg";
import { decodeJwtToken } from "helpers/decoder";
import classNames from "classnames";
import { handleRegistrationStep } from "slices/userSlice";

const Header = () => {
  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch();
  const currentLanguageData = useSelector(language);
  const navigate = useNavigate();
  const loggedState = useSelector(loginState);
  const currentUserRole = useSelector(userRole);
  const accountData = useSelector(accountInfo);

  // CONSTANTS USING HOOKS
  const [showLanguages, setShowLanguages] = useState(false);

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  useEffect(() => {
    const closeLanguageSelector = (e) => {
      const isNot = !e.target.className.includes("languageSelector");
      isNot && setShowLanguages(false);
    };

    document.addEventListener("click", closeLanguageSelector, false);

    return () =>
      window.removeEventListener("click", closeLanguageSelector, false);
  }, []);

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  const handleShowLanguages = () => {
    setShowLanguages(!showLanguages);
  };

  const handleLanguage = (item) => {
    dispatch(handleChangeLanguage(item));
  };

  const handleNavHome = () => {
    navigate("/homepage");
  };

  const handleNavLinks = (path) => {
    navigate(path);
  };
  return (
    <div className="component-header-container shadow">
      <div className="component-header-logo-wrapper">
        <img
          src={logo}
          alt=""
          className="component-header-logo"
          onClick={handleNavHome}
        />
      </div>

      <div className="component-header-links-wrapper">
        {currentUserRole &&
          getHeaderLinks(currentUserRole, loggedState?.loggedIn).map(
            (item, index) => {
              const path = window.location.pathname;
              return (
                <>
                  {item?.isVisible && (
                    <div
                      key={`key--${index}--${item.label}`}
                      style={{ marginLeft: "2rem" }}
                    >
                      {item.path === "https://nafram.org" ? (
                        <ButtonComponent
                          buttonText={item.label}
                          color={item.color}
                          onClick={() => {
                            window.location.href = "https://nafram.org";
                          }}
                          selected={path === item.path && true}
                        />
                      ) : (
                        <ButtonComponent
                          buttonText={item.label}
                          color={item.color}
                          onClick={() => {
                            window.location.pathname === "/register" &&
                              dispatch(handleRegistrationStep(1));
                            handleNavLinks(item.path);
                          }}
                          selected={path === item.path && true}
                        />
                      )}
                    </div>
                  )}
                </>
              );
            }
          )}
      </div>

      <div className="component-header-account-options-wrapper">
        <img
          src={require(`assets/header/languages/${currentLanguageData}.svg`)}
          alt=""
          onClick={handleShowLanguages}
          className="header-languages-icon languageSelector"
        />
        {loggedState?.loggedIn ? (
          <div className="component-header-logged-in-options">
            {accountData?.documentModels?.[0]?.content ? (
              <img
                src={`data:image/png;base64,${accountData?.documentModels?.[0]?.content}`}
                alt=""
                onClick={() => handleNavLinks("/profile-page")}
                className={classNames(
                  "header-options-profile-icon",
                  decodeJwtToken().role
                )}
              />
            ) : (
              <img
                src={AvatarFallback}
                alt=""
                onClick={() => handleNavLinks("/profile-page")}
                className="header-options-profile-icon"
              />
            )}
          </div>
        ) : (
          <>
            <ButtonComponent
              buttonText={"component.header.login"}
              color="blue"
              onClick={() => handleNavLinks("/login")}
              selected={window.location.pathname === "/login" && true}
            />
            <ButtonComponent
              buttonText={"component.header.signin"}
              color="blue"
              onClick={() => handleNavLinks("/select-role")}
              selected={
                (window.location.pathname === "/select-role" ||
                  window.location.pathname === "/register") &&
                true
              }
            />
          </>
        )}
      </div>
      {showLanguages && (
        <div
          className={`languages-wrapper shadow slideInDown languageSelector `}
          style={{ right: currentUserRole === "GUEST" ? "29rem" : "10rem" }}
        >
          {languages?.map((item, index) => {
            return (
              <p
                key={`item--${index}`}
                className="languages-wrapper-item"
                onClick={() => handleLanguage(item.abbreviation)}
              >
                {item.language}
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Header;
