import React from "react";

// STYLES
import "./PartnersMeetingSearchComponent.scss";

// LIBRARIES
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// CONSTANTS & MOCKS
import countriesList from "constants/countriesList";

// REDUX

// COMPONENTS
import TextField from "@mui/material/TextField";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";

const PartnersMeetingSearchComponent = (props) => {
  // PROPS
  const {
    intl = {},
    setSearchState = () => {
    },
    searchState = {},
    getContent = () => {
    }
  } = props;
  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS


  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  const handleChange = (event) => {
    setSearchState(searchState => ({
      ...searchState,
      values: {
        ...searchState.values,
        [event.target.name]: event.target.value
      }
    }))
  }

  const Placeholder = ({children}) => {
    return <div className={"dropdown-placeholder"}>{children}</div>;
  };

  return (
    <div className="page-PartnersMeetingSearchComponent-container">
      <div className="input-wrapper">
        <TextField
          variant="standard"
          margin="normal"
          placeholder={intl.formatMessage({
            id: "component.explore.search.text",
          })}
          InputProps={{
            disableUnderline: true,
          }}
          name={"nameStartsWith"}
          onChange={handleChange}
          value={searchState?.values?.nameStartsWith || ""}
        />
      </div>
      <div className="dropdown-wrapper">

        <FormControl
        >
          <Select
            value={searchState?.values?.country || "Search Country"}
            onChange={handleChange}
            displayEmpty={true}
            name={"country"}
            renderValue={
              searchState?.values?.country !== "" ? undefined : () => <Placeholder>Select country</Placeholder>
            }
          >
            {countriesList?.map((item, index) => {
              return (
                <MenuItem value={item.name} key={item.code}>
                  {item.name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
      <div className="component-search-sorted-container">
        <ButtonComponent buttonText={"component.explore.search.button"} onClick={getContent}/>
      </div>
    </div>
  );
};

PartnersMeetingSearchComponent.prototype = {
  intl: PropTypes.shape(),
  setSearchState: PropTypes.func,
  getContent: PropTypes.func,
  searchState: PropTypes.shape()
};

export default injectIntl(PartnersMeetingSearchComponent);