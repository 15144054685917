import React from "react";

// STYLES
import "./AdminDownloadButton.scss";

// LIBRARIES
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import classNames from "classnames";

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS

const AdminDownloadButton = (props) => {
  // PROPS
  const { title = "", color = "", small = "", onClick = () => {} } = props;

  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div
      className={classNames(
        "component-admin-download-button-container",
        color,
        small
      )}
      onClick={onClick}
    >
      <p className="button-title">
        <FormattedMessage id={title} />
      </p>
    </div>
  );
};

AdminDownloadButton.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  small: PropTypes.string,
  onClick: PropTypes.func,
};

export default AdminDownloadButton;
