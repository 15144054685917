import React, { useState, useEffect } from "react";

// STYLES
import "./Layout.scss";

// COMPONENTS
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import HeaderMobile from "components/HeaderMobile/HeaderMobile";

//LIBRARIES
import PropTypes from "prop-types";
import classNames from "classnames";

// CONSTANTS & MOCKS
import { tabletResolutionHigh } from "constants/constants.js";

const Layout = (props) => {
  // PROPS
  const { children, header = true, footer = true } = props;

  // CONSTANTS USING HOOKS
  const [isDesktop, setDesktop] = useState(
    window.innerWidth > tabletResolutionHigh
  );

  // USE EFFECT FUNCTION
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const updateMedia = () => {
    setDesktop(window.innerWidth > tabletResolutionHigh);
  };

  return (
    <div className="component-layout-container">
      <div
        className={classNames(
          "component-layout-header-container",
          (window.location.pathname === "/login" ||
            window.location.pathname === "/select-role" ||
            window.location.pathname === "/register" ||
            window.location.pathname === "/admin" ||
            window.location.pathname === "/reset-password" ||
            window.location.pathname === "/signin") &&
            "component-layout-header-container-hidden",
          window.location.pathname === "/homepage" &&
            "component-layout-header-container-for-mobile-homepage"
        )}
      >
        {header && isDesktop ? <Header /> : <HeaderMobile />}
      </div>

      <div className="component-layout-content-container">{children}</div>

      {footer && (
        <div
          className={classNames(
            "component-layout-footer-container",
            window.location.pathname === "/select-role" &&
              "component-layout-footer-container-hidden"
          )}
        >
          <Footer />
        </div>
      )}
    </div>
  );
};

Layout.prototype = {
  header: PropTypes.bool,
  footer: PropTypes.bool,
};

export default Layout;
