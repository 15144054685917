export const listOfCategories = [
  {
    id: 1,
    name: "categoryIds",
    btnName: "CATEGORY",
    type: "checkbox",
    color: "blue",
  },
  {
    id: 2,
    name: "subcategoryIds",
    btnName: "SUBCATEGORY",
    type: "checkbox",
    color: "blue",
  },
  {
    id: 3,
    name: "range",
    btnName: "PRICE RANGE",
    type: "checkbox",
    color: "blue",
  },
  {
    id: 4,
    name: "country",
    btnName: "COUNTRY",
    type: "checkbox",
    color: "blue",
  },
  {
    id: 5,
    name: "currency",
    btnName: "CURRENCY",
    type: "checkbox",
    color: "blue",
  },
  {
    id: 6,
    name: "sort",
    btnName: "SORTED BY",
    type: "checkbox",
    color: "blue",
  },
  {
    id: 7,
    name: "clearFilters",
    btnName: "CLEAR FILTERS",
    type: "button",
    color: "gold",
  },
];

export const rangeInputs = [
  {
    id: 1,
    name: "rangeGraterOrEqualThan",
    type: "text",
    label: "Starting at",
  },
  {
    id: 2,
    name: "rangeLessOrEqualThan",
    type: "text",
    label: "Up to",
  },
];
