import classNames from "classnames";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import React, { useEffect, useState } from "react";

// STYLES
import "./InterestsPage.scss";
import { decodeJwtToken } from "helpers/decoder";
import { useDispatch } from "react-redux";
import { getPersonalInfo } from "api/sessionApi";
import { editAccountInfo } from "api/userApi";
import { useNavigate } from "react-router-dom";
const InterestsSelectComponent = ({ buttons = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selections, setSelections] = useState([
    { label: "Information Technologies", selected: false },
    { label: "Sports", selected: false },
    { label: "Energy", selected: false },
    { label: "Communication", selected: false },
    { label: "Finconnect", selected: false },
    { label: "Real estate", selected: false },
    { label: "Agriculture", selected: false },
    { label: "Trade", selected: false },
    { label: "Pharmaceutical", selected: false },
    { label: "Industry", selected: false },
    { label: "Construction", selected: false },
    { label: "Transport", selected: false },
    { label: "Education", selected: false },
    { label: "Art", selected: false },
    { label: "Entertainment", selected: false },
    { label: "Tourism", selected: false },
  ]);
  useEffect(() => {
    fetchPersonalInfoAndSetInterests();
  }, []);

  const handleSaveInterests = () => {
    let decode = decodeJwtToken();
    const interests = getSelectedInterestsAsString(selections);
    let payload = {
      id: decode.id,
      interests,
      emailAddress: decode.sub,
    };
    dispatch(editAccountInfo(payload));
  };

  useEffect(() => {
    handleSaveInterests();
  }, [selections]);

  const toggleItem = (item) => {
    setSelections(
      selections.map((selection) => {
        if (selection.label === item.label) {
          return { ...selection, selected: !selection.selected };
        }
        return selection;
      })
    );
  };

  const fetchPersonalInfoAndSetInterests = async () => {
    try {
      const decode = decodeJwtToken();
      const payload = {
        id: decode.id,
        emailAddress: decode.sub,
      };

      const response = await dispatch(getPersonalInfo(decode?.sub));
      const interests = response?.payload?.interests;

      if (interests) {
        updateSelections(interests);
      }
    } catch (error) {
      console.error("Error fetching personal info", error);
    }
  };

  const updateSelections = (interests) => {
    setSelections(
      selections.map((selection) => {
        return interests.includes(selection.label)
          ? { ...selection, selected: true }
          : selection;
      })
    );
  };

  return (
    <>
      <div className="component-interests-selections-container">
        {selections.map((item, index) => {
          return (
            <div
              key={index}
              className={classNames(
                `component-interests-buttons ${
                  selections[index].selected === true
                    ? "colors-selected"
                    : "colors-deselected"
                }`
              )}
              onClick={() => {
                toggleItem(item);
              }}
            >
              <p>{item.label}</p>
            </div>
          );
        })}
      </div>

      {/* skip and save buttons */}
      {buttons && (
        <div className="component-interests-buttons-container">
          <div className="component-interests-buttons-wrapper">
            <ButtonComponent
              buttonText="Save"
              onClick={() => navigate("/explore")}
            />
          </div>
          {/* <div className="component-interests-buttons-wrapper button-margin">
            <ButtonComponent
              buttonText="Skip"
              oonClick={() => navigate("/explore")}
            />
          </div> */}
        </div>
      )}
    </>
  );
};

function getSelectedInterestsAsString(allInterests) {
  const selectedInterests = allInterests.filter(
    (interest) => interest.selected
  );
  const selectedInterestsLabels = selectedInterests.map(
    (interest) => interest.label
  );
  const interestsString = selectedInterestsLabels.join(",");
  return interestsString;
}

export default InterestsSelectComponent;
