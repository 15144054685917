import { createAsyncThunk } from "@reduxjs/toolkit";
import { REST } from "config/axiosConfig";

export const getExploreProjects = createAsyncThunk(
  "project/explore",
  async ({ pageNumber, pageSize }, { rejectWithValue }) => {
    try {
      const response = await REST.get(
        `/project/explore?pageNumber=${pageNumber}&pageSize=${pageSize}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getExplorePartners = createAsyncThunk(
  "project/getExplorePartners",
  async ({ pageNumber, pageSize }, { rejectWithValue }) => {
    try {
      const response = await REST.get(
        `/partner/explore?pageNumber=${pageNumber}&pageSize=${pageSize}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getTrendingProjects = createAsyncThunk(
  "project/getExploreProjects",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.get(`/project/homepage-preview`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getTrendingPartners = createAsyncThunk(
  "project/getTrendingPartners",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.get(`/partner/preview`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSearchPartners = createAsyncThunk(
  "project/getSearchPartners",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.get(
        `/partner/search?country=${data.country}&nameStartsWith=${data.name}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFilteredProjects = createAsyncThunk(
  "project/getFilteredProjects",
  async (
    {
      categoryIds,
      country,
      currency,
      nameStartsWith,
      pageNumber,
      rangeGraterOrEqualThan,
      rangeLessOrEqualThan,
      sort,
      subcategoryIds,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await REST.get(
        `/project/explore/filter?categoryIds=${categoryIds}&country=${country}&currency=${currency}&nameStartsWith=${nameStartsWith}&pageNumber=${pageNumber}&pageSize=16&rangeGraterOrEqualThan=${rangeGraterOrEqualThan}&rangeLessOrEqualThan=${rangeLessOrEqualThan}&sort=${sort}&subcategoryIds=${subcategoryIds}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFilteredPartners = createAsyncThunk(
  "partner/search-explore-partners",
  async (
    { nameStartsWith, pageNumber, pageSize, country },
    { rejectWithValue }
  ) => {
    try {
      const response = await REST.get(
        `/partner/search?country=${country}&nameStartsWith=${nameStartsWith}&pageNumber=${pageNumber}&pageSize=${pageSize}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getExploreSubcategories = createAsyncThunk(
  "explore/getExploreSubcategories",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.get(
        `/admin/category/explore-subcategories?categoryIds=${data}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPartnersMeetingContent = createAsyncThunk(
  "explore/getPartnersMeetingContent",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.get(
        `/partner/search?nameStartsWith=${data.nameStartsWith}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sendPartnerContactRequest = createAsyncThunk(
  "explore/sendPartnerContactRequest",
  async (data, { rejectWithValue }) => {
    try {
      const response = await REST.post(`/partner/request-contact`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
