import React from "react";

// STYLES
import "./TextInput.scss";

// LIBRARIES
import PropTypes from "prop-types";
import classNames from "classnames";

// CONSTANTS & MOCKS

// REDUX
import { useSelector } from "react-redux";
import { language } from "slices/sessionSlice";

// COMPONENTS
import TextField from "@mui/material/TextField";
import { FormattedMessage } from "react-intl";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const TextInput = (props) => {
  // PROPS
  const {
    labelColor = "",
    label = "",
    placeholder = "",
    minRows = 8,
    multiline = false,
    type = "text",
    handleChange = () => {},
    name = "",
    select = false,
    selectValues = [],
    component = "textInput",
    value = "",
    readOnly = false,
    disabled = false,
    partnersMeeting = false,
    helperText = "",
    error = false,
    labelStyle = "default",
    handleKeyPress = () => {},
    handleGet = () => {},
    mandatory = false,
  } = props;

  // CONSTANTS USING HOOKS
  const languageData = useSelector(language);
  return (
    <div className="component-text-input-label-container">
      <div className="component-text-label-wrapper">
        <span
          className={classNames(
            "component-text-label",
            labelColor,
            labelStyle === "thin" && "component-thin-text-label"
          )}
        >
          {label && <FormattedMessage id={label} />}
        </span>
        {mandatory && <span className="text-label-mandatory"> *</span>}
      </div>

      <div
        className={
          readOnly
            ? "component-text-input-read-only-wrapper"
            : partnersMeeting
            ? "component-text-input-wrapper-meeting"
            : "component-text-input-wrapper"
        }
      >
        {(component === "textInput" || component === "select") && (
          <TextField
            id={readOnly ? "standard-helperText" : ""}
            placeholder={!readOnly ? placeholder : ""}
            variant="standard"
            InputProps={{
              readOnly: readOnly ? readOnly : false,
              disableUnderline: true,
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleKeyPress();
              }
            }}
            fullWidth
            multiline={multiline}
            minRows={minRows}
            maxRows={8}
            onChange={handleChange}
            type={type}
            name={name}
            select={select}
            value={value}
            disabled={disabled}
            helperText={helperText}
            error={error}
            SelectProps={{
              onOpen: handleGet,
              IconComponent: () => <KeyboardArrowDownIcon />,
            }}
          >
            <MenuItem value="select" disabled>
              <p className="select-country-menu-item">Select</p>
            </MenuItem>
            {select &&
              selectValues?.map((item, index) => {
                return (
                  <MenuItem
                    key={`item--key--${index}`}
                    value={item?.name || "Romania"}
                    className={"menu-item-wrapper"}
                  >
                    <p className="select-country-menu-item">
                      {item[languageData] ? item[languageData] : item.name}
                    </p>
                  </MenuItem>
                );
              })}
          </TextField>
        )}
      </div>
    </div>
  );
};

TextInput.propTypes = {
  labelColor: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  minRows: PropTypes.number,
  multiline: PropTypes.bool,
  handleChange: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  select: PropTypes.bool,
  selectValues: PropTypes.array,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  labelStyle: PropTypes.string,
  handleGet: PropTypes.func,
};

export default TextInput;
