import React, { useEffect, useState } from "react";

// STYLES
import "./ProfileInformation.scss";

// LIBRARIES
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import classNames from "classnames";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Grid } from "@material-ui/core";

// CONSTANTS & MOCKS
import {
  projectType,
  LOGO,
  DATE_PICKER,
  SELECT,
  ProfileTabs,
  RequestStatus,
} from "constants/constants";
import { profileInfo } from "view/ProfilePage/Mocks/profileInputs";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  projectsData,
  paginationDetails,
  handleChangePage,
  projectRequestsData,
  pageNumber,
} from "slices/projectSlice";
import {
  getBrainerProjects,
  getPartnerBlockedProjects,
  getProjectRequests,
} from "api/projectApi";

// COMPONENTS
import CustomModal from "components/CustomModal/CustomModal";
import TextInput from "components/Atoms/TextInput/TextInput";
import EditableProjectCard from "components/EditableProjectCard/EditableProjectCard";
import Uploader from "components/Uploader/Uploader";
import DatePicker from "components/Atoms/DatePicker/DatePicker";
import countriesList from "constants/countriesList";
import PartnerInvestment from "components/PartnerInvestment/PartnerInvestment";
import RequestProjectCard from "components/RequestProjectCard/RequestProjectCard";
import { decodeJwtToken } from "helpers/decoder";
import PartnerProjectCard from "components/PartnerProjectCard/PartnerProjectCard";
import InterestsPage from "view/InterestsPage/InterestsPage";
import InterestsSelectComponent from "view/InterestsPage/InterestsSelectComponent";

const ProfileInformation = (props) => {
  // PROPS
  const {
    intl = {},
    isReadOnly = true,
    isBrainer = false,
    pageStatus = ProfileTabs.PERSONAL,
    requestStatus = RequestStatus.PENDING,
    projectStatus = projectType.DRAFTS,
    handleEditText = () => {},
    handleDate = () => {},
    textFieldValue = {},
    setTextFieldValue = () => {},
    accountData = {},
    companyData = {},
    handleFounds = () => {},
  } = props;

  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch();
  const projects = useSelector(projectsData);
  const projectRequests = useSelector(projectRequestsData);
  const paginationData = useSelector(paginationDetails);

  // CONSTANTS USING HOOKS
  const [showModal, setShowModal] = useState(false);
  // eslint-disable-next-line
  // GENERAL CONSTANTS
  const page = useSelector(pageNumber);

  // USE EFFECT FUNCTION
  useEffect(() => {
    getProjects(projectStatus);
    // eslint-disable-next-line
  }, [projectStatus]);

  useEffect(() => {
    getRequests(requestStatus);
    // eslint-disable-next-line
  }, [requestStatus]);

  useEffect(() => {
    if (!isBrainer) {
      dispatch(
        getPartnerBlockedProjects({
          email: accountData.emailAddress,
          pageNumber: page,
          pageSize: 10,
          projectStatus: "BLOCKED",
        })
      );
    }
    // eslint-disable-next-line
  }, [page]);

  // REQUEST API FUNCTIONS
  const getProjects = (status) => {
    const action = isBrainer ? getBrainerProjects : getPartnerBlockedProjects;
    dispatch(
      action({
        email: decodeJwtToken().sub,
        pageNumber: page,
        pageSize: 10,
        projectStatus: status,
      })
    );
  };

  const getRequests = (status) => {
    // const action = isBrainer ? getProjectRequests : getPartnerBlockedProjects;
    dispatch(
      getProjectRequests({
        pageNumber: page,
        pageSize: 10,
        requestStatus: status,
      })
    );
  };

  // HANDLERS FUNCTIONS
  const handleViewModal = () => {
    setShowModal(true);
  };

  const handleUpload = (files) => {
    const myFile = new File([files[0]], files[0].name, { type: LOGO });

    let reader = new FileReader();
    reader.readAsDataURL(myFile);

    reader.onload = function () {
      setTextFieldValue({
        ...textFieldValue,
        previewImg: reader.result,
        documentModel: myFile,
      });
    };
  };

  const handlePagination = (event, value) => {
    dispatch(
      handleChangePage({
        name: "pageNumber",
        value: value - 1,
      })
    );
    if (decodeJwtToken().role === "BRAINER") {
      getProjects(projectStatus);
      dispatch(
        getBrainerProjects({
          email: decodeJwtToken().sub,
          pageNumber: value - 1,
          pageSize: 16,
          projectStatus: projectStatus,
        })
      );
    } else if (decodeJwtToken().role === "PARTNER") {
      dispatch(
        getPartnerBlockedProjects({
          email: decodeJwtToken().sub,
          pageNumber: value - 1,
          pageSize: 16,
          projectStatus: "BLOCKED",
        })
      );
    }
  };
  return (
    <div className="component-profile-container">
      {pageStatus === ProfileTabs.PERSONAL && (
        <div className="component-profile-info-wrapper">
          <Grid
            container
            spacing={2}
            className="component-profile-personal-container"
          >
            {
              // TODO: change country field to dropdown, using countriesList
              // TODO: change gender to dropdown, using MALE, FEMALE, with translation
              profileInfo?.values?.personalInfo?.map((element, index) => {
                return (
                  <Grid
                    item
                    xs={element.xs}
                    md={element.md}
                    className={classNames(
                      `component-profile-personal-wrapper ${
                        element.key === ProfileTabs.ABOUT &&
                        "component-profile-about-wrapper"
                      }`
                    )}
                    key={`component-profile-personal-container--${index}`}
                  >
                    <TextInput
                      label={element.id.toString()}
                      readOnly={isReadOnly}
                      name={element.key}
                      value={
                        isReadOnly
                          ? accountData[element.key]
                          : textFieldValue.personalInfo[element.key] || ""
                      }
                      multiline={element.multiline}
                      minRows={element.minRows}
                      maxRows={element.maxRows}
                      disabled={
                        element.key === "emailAddress" && !isReadOnly
                          ? true
                          : false
                      }
                      handleChange={(event) =>
                        handleEditText(event, ProfileTabs.PERSONAL)
                      }
                      select={element?.type === "dropdown" ? true : false}
                      selectValues={countriesList}
                      component={element?.component}
                    />

                    {element.component === DATE_PICKER && !isReadOnly && (
                      <div className="date-picker-item">
                        <DatePicker
                          label={element?.label}
                          labelColor={"blue"}
                          placeholder={intl.formatMessage({ id: element.id })}
                          name={element?.name}
                          type={element?.type}
                          handleChange={(event) =>
                            handleDate(event, ProfileTabs.PERSONAL)
                          }
                          selectValues={countriesList}
                          select={element.component === SELECT ? true : false}
                          component={element.component}
                          value={
                            isReadOnly
                              ? accountData[element.key]
                              : textFieldValue.personalInfo.dateOfBirth
                          }
                          profileStyle={true}
                          isReadOnly={isReadOnly}
                          disabled={isReadOnly}
                          mandatory={false}
                        />
                      </div>
                    )}
                    {element.component === DATE_PICKER && isReadOnly && (
                      <TextInput
                        readOnly={isReadOnly}
                        name={element.key}
                        value={accountData.dateOfBirth}
                        multiline={element.multiline}
                        minRows={element.minRows}
                        maxRows={element.maxRows}
                        disabled={false}
                      />
                    )}
                  </Grid>
                );
              })
            }
          </Grid>
          {!isReadOnly && (
            <div className="component-profile-password-container">
              <p>
                <FormattedMessage id="component.profile.password.text" />
              </p>
              <button onClick={handleViewModal}>
                <FormattedMessage id="component.profile.password.change" />
              </button>
            </div>
          )}
        </div>
      )}
      {pageStatus === ProfileTabs.COMPANY && (
        <div className="component-company-info-wrapper">
          <div className="component-company-logo-container">
            {isReadOnly ? (
              companyData?.documentModel?.content ? (
                <img
                  src={`data:image/png;base64,${companyData?.documentModel?.content}`}
                  alt=""
                  className="company-logo"
                />
              ) : (
                <p className="company-logo">Add a picture</p>
              )
            ) : (
              <Uploader
                onDrop={handleUpload}
                maxFiles={1}
                uploadedImage={
                  textFieldValue?.companyInfo?.companyPhoto?.content
                }
                edit={!isReadOnly}
                previewImg={textFieldValue.previewImg}
                showText={false}
              />
            )}
          </div>
          <Grid
            container
            spacing={2}
            className="component-profile-company-container"
          >
            {profileInfo?.values?.companyInfo?.map((element, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  md={6}
                  className="component-profile-company-wrapper"
                  key={`component-profile-company-wrapper--${index}`}
                >
                  <TextInput
                    label={element.id.toString()}
                    readOnly={isReadOnly}
                    name={element.key}
                    value={
                      isReadOnly
                        ? companyData[element.key]
                        : textFieldValue.companyInfo[element.key]
                    }
                    multiline={element.multiline}
                    minRows={element.minRows}
                    maxRows={element.maxRows}
                    handleChange={(event) =>
                      handleEditText(event, ProfileTabs.COMPANY)
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>
      )}
      {pageStatus === ProfileTabs.REQUESTS && (
        <div className="component-profile-project-requests-container">
          {requestStatus && (
            <div className={"component-profile-project-requests-wrapper"}>
              <div className="projects-partners-cards-pagination-container">
                {projectRequests.totalCount > 0 && (
                  <Stack spacing={2}>
                    <Pagination
                      count={Math.ceil(projectRequests?.totalCount / 16)}
                      page={projectRequests?.pageNumber + 1}
                      shape="rounded"
                      onChange={handlePagination}
                    />
                  </Stack>
                )}
              </div>
              {projectRequests?.items?.length > 0 &&
                projectRequests?.items?.map((request, index) => (
                  <RequestProjectCard requestData={request} />
                ))}
            </div>
          )}
        </div>
      )}
      {pageStatus === ProfileTabs.PROJECTS && (
        <div className="component-profile-project-container">
          {isBrainer && (
            <div className="bottom-pagination">
              <div className="projects-partners-cards-pagination-container">
                {paginationData?.totalCount > 0 && (
                  <Stack spacing={2}>
                    <Pagination
                      count={Math.ceil(
                        paginationData?.totalCount / paginationData?.pageSize
                      )}
                      page={paginationData?.pageNumber + 1}
                      shape="rounded"
                      onChange={handlePagination}
                    />
                  </Stack>
                )}
              </div>
              {projectStatus && (
                <div className={"component-profileInfo-projects-wrapper"}>
                  {projects?.map((project, index) => {
                    return (
                      <div
                        className="component-profile-project-wrapper"
                        key={`project-card-${index}`}
                      >
                        <EditableProjectCard
                          name={project?.projectName}
                          projectStatus={projectStatus}
                          key={index}
                          projectDetails={project}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
          {!isBrainer && projectStatus === projectType.VIEW && (
            <div className={"profile-information-projects-wrapper"}>
              <div className="projects-partners-cards-pagination-container">
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(
                      paginationData?.totalCount / paginationData?.pageSize
                    )}
                    page={paginationData?.pageNumber + 1}
                    shape="rounded"
                    onChange={handlePagination}
                  />
                </Stack>
              </div>
              <div className="projects-partners-cards-view-container">
                {projects?.map((element, index) => {
                  return (
                    <div
                      key={`component-profile-project--${element?.projectId}`}
                      className="projects-partners-cards-view"
                    >
                      {projectStatus === projectType.VIEW && (
                        <PartnerProjectCard project={element} profile={true} />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {!isBrainer && projectStatus === projectType.INVESTED && (
            <div className="component-profile-project-invested-container">
              <PartnerInvestment
                info={profileInfo}
                isReadOnly={isReadOnly}
                handleFounds={handleFounds}
              />
            </div>
          )}
        </div>
      )}
      {pageStatus === ProfileTabs.INTERESTS && (
        <Grid
          container
          spacing={2}
          className="component-profile-personal-container"
        >
          <InterestsSelectComponent />
        </Grid>
      )}

      <CustomModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        directionTo="top"
        changePasswordModal={true}
        setShowModal={setShowModal}
      />
    </div>
  );
};

ProfileInformation.propTypes = {
  isReadOnly: PropTypes.bool,
  isBrainer: PropTypes.bool,
  pageStatus: PropTypes.string,
  projectStatus: PropTypes.string,
  handleEditText: PropTypes.func,
  setTextFieldValue: PropTypes.func,
  handleDate: PropTypes.func,
  handleFounds: PropTypes.func,
  // TODO: define shape of objects
  textFieldValue: PropTypes.shape(),
  accountData: PropTypes.shape(),
  companyData: PropTypes.shape(),
};

export default injectIntl(ProfileInformation);
