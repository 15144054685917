import React, { useEffect, useState } from "react";

// STYLES
import "./RegisterPage.scss";

// LIBRARIES
import { Grid } from "@mui/material";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

// CONSTANTS & MOCKS
import companyLogoMock from "assets/uploader/copanyLogo.png";
import profilePhotoMock from "assets/uploader/photoLogo.png";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { userRole } from "slices/sessionSlice";

import { registerFlow } from "slices/userSlice";
import { handleRegistrationStep } from "slices/userSlice";

// COMPONENTS
import webBackgroundImg from "assets/components/registerPage/X.svg";
import heroImageMobile from "assets/components/HeroComponent/heroImageMobile.jpg";
import ProgressBar from "./Components/ProgressBar";
import Step1 from "./Components/Step1";
import Step2 from "./Components/Step2";
import Step3 from "./Components/Step3";
import logo from "assets/header/bp-logo.png";
import { useBackListener } from "helpers/hooks/useBackListener";

const RegisterPage = (props) => {
  const comapnyPreview = companyLogoMock;
  const profilePhotoPreview = profilePhotoMock;
  // PROPS

  // CONSTANTS USING LIBRARYS
  const userInfoState = useSelector(registerFlow);
  const dispatch = useDispatch();
  // TODO: use selectedRole from path param
  const selectedRole = useSelector(userRole);

  const navigate = useNavigate();
  const ageRestriction = new Date().setFullYear(new Date().getFullYear() - 14);
  // CONSTANTS USING HOOKS
  const [registerState, setRegisterState] = useState({
    userInfo: {
      registrationType: selectedRole,
      country: "select",
      dateOfBirth: null,
      emailAddress: null,
      fullName: null,
      password: null,
      confirmPassword: null,
    },
    userFiles: {
      image: null,
      nda: null,
      previewImg: profilePhotoPreview,
    },
    companyInfo: {},
    companyFiles: {
      img: "",
      previewImg: comapnyPreview,
    },
  });
  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION
  useBackListener(() => {
    dispatch(handleRegistrationStep(1));
  });
  useEffect(() => {
    setRegisterState((registerState) => ({
      ...registerState,
      userInfo: {
        ...registerState.userInfo,
        registrationType: selectedRole,
      },
    }));
    // eslint-disable-next-line
  }, [userInfoState]);

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  //TODO: Implement steps in register
  const handleStep = (step) => {
    // setStep(step)
    dispatch(handleRegistrationStep(step));
  };

  return (
    <div className="page-register-page-container">
      <img src={webBackgroundImg} alt="" className="page-register-page-image" />
      <img
        src={heroImageMobile}
        alt=""
        className="page-register-page-image-mobile"
      />
      <Grid container className="page-RegisterPage-container">
        <Grid item container xs={12} className="page-RegisterPage-card-wrapper">
          <Grid item xs={12} className={"page-RegisterPage-title-wrapper"}>
            <img
              className="page-RegisterPage-logo-mobile"
              src={logo}
              alt="#"
              onClick={() => navigate("/homepage")}
            />
            <p className="page-RegisterPage-title-text">
              <FormattedMessage id={"page.register.partner.title"} />{" "}
              {selectedRole}
            </p>
            <ProgressBar step={userInfoState?.step || 1} />
          </Grid>

          {userInfoState.step === 1 && (
            <Step1
              handleStep={handleStep}
              setRegisterState={setRegisterState}
              registerState={registerState}
              selectedRole={selectedRole}
            />
          )}
          {userInfoState.step === 2 && (
            <Step2
              handleStep={handleStep}
              registerState={registerState}
              setRegisterState={setRegisterState}
            />
          )}

          {userInfoState.step === 3 && (
            <Step3 handleStep={handleStep} registerState={registerState} />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

RegisterPage.prototype = {
  intl: PropTypes.shape(),
};

export default injectIntl(RegisterPage);
