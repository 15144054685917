import React, { useState } from "react";

// STYLES
import "./AdminCategoriesListEdit.scss";

// LIBRARIES
import PropTypes from "prop-types";

// CONSTANTS & MOCKS

// REDUX
import { useDispatch } from "react-redux";
import {
  createSubcategory,
  editCategory,
  deleteSubcategory,
} from "api/adminApi";

// COMPONENTS
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import TextInput from "components/Atoms/TextInput/TextInput";
import CustomButton from "components/CustomButton/CustomButton";

const AdminCategoriesListEdit = (props) => {
  // PROPS
  const { item = {}, handleGetMappedCategories = () => {} } = props;

  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch();

  // CONSTANTS USING HOOKS

  const [isEditMode, setIsEditMode] = useState(false);
  const [formState, setFormState] = useState({
    values: {
      subcategory: "",
      category: "",
    },
    editCategory: false,
  });

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  const handleDeleteSubcategory = (id) => {
    dispatch(deleteSubcategory({ id: id }))
      .unwrap()
      .then(() => {
        handleGetMappedCategories();
      });
  };

  const handlePostSubcategory = () => {
    const body = {
      categoryId: item?.category?.id,
      name: formState.values.subcategory,
    };
    dispatch(createSubcategory(body)).then(() => {
      handleGetMappedCategories();
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          subcategory: "",
        },
        editCategory: false,
      }));
      setIsEditMode(false);
    });
  };

  const handlePostEditCategory = () => {
    dispatch(
      editCategory({
        data: formState?.values?.category,
        id: item?.category?.id,
      })
    ).then(() => {
      handleGetMappedCategories();
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          category: "",
        },
        editCategory: false,
      }));
    });
  };

  // HANDLERS FUNCTIONS
  const handleEditModeOn = () => {
    setIsEditMode(true);
  };

  const handleChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleEditCategory = () => {
    setFormState((formState) => ({
      ...formState,
      editCategory: !formState.editCategory,
    }));
  };

  return (
    <div className="component-admin-categories-list-edit-container">
      <div className="close-edit-mode">
        {isEditMode && (
          <div className="close">
            <ClearIcon fontSize="large" onClick={() => setIsEditMode(false)} />
          </div>
        )}
      </div>

      <div className="component-admin-categories-list-edit-category-button">
        <div className="category">
          {!formState?.editCategory && (
            <>
              <p className="category-text">Category:</p>
              <p className="category-item">{item?.category?.name}</p>
              <div className={"button"} onClick={handleEditCategory}>
                <EditIcon />
              </div>
            </>
          )}
          {formState?.editCategory && (
            <div className={"edit-category-component"}>
              <TextInput
                labelColor="blue"
                label={"admin.component.editCategory.text.label"}
                placeholder={"Text Here"}
                name={"category"}
                handleChange={handleChange}
                value={formState?.values?.category || ""}
              />
              <CustomButton
                name="submit"
                handleChange={handlePostEditCategory}
                type="empty"
                text="Add"
                isEnabled={formState?.values?.category !== "" ? true : false}
              />
              <CustomButton
                name="submit"
                handleChange={handleEditCategory}
                type="empty"
                text="Cancel"
                isEnabled={true}
              />
            </div>
          )}
        </div>
        {isEditMode && (
          <div className="input-button">
            <TextInput
              labelColor="blue"
              label={"admin.component.addSubcategories.text.label"}
              placeholder={"Text Here"}
              name={"subcategory"}
              handleChange={handleChange}
              value={formState?.values?.subcategory || ""}
            />
            <div className="input-margin">
              <CustomButton
                name=""
                type="empty"
                text="Add"
                isEnabled={formState?.values?.subcategory !== "" ? true : false}
                handleChange={handlePostSubcategory}
              />
            </div>
          </div>
        )}

        <div className="button">
          {!isEditMode ? (
            <EditIcon onClick={handleEditModeOn} />
          ) : (
            <DeleteIcon />
          )}
        </div>
      </div>
      <div className="component-admin-categories-list-edit-subcategory">
        <p className="subcategory">Subcategory:</p>
        {item?.subcategories?.map((item, index) => {
          return (
            <p className="subcategory-item" key={index}>
              {item?.name}{" "}
              <span>
                {isEditMode && (
                  <ClearIcon onClick={() => handleDeleteSubcategory(item.id)} />
                )}
              </span>
            </p>
          );
        })}
      </div>
    </div>
  );
};
AdminCategoriesListEdit.propTypes = {
  item: PropTypes.shape({
    category: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    subcategories: PropTypes.array,
  }),
};

export default AdminCategoriesListEdit;
