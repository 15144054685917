import React from "react";

// STYLES
import "./CustomModal.scss";

// LIBRARIES
import PropTypes from "prop-types";
import { Modal } from "@material-ui/core";
import classNames from "classnames";

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS
import ContentForModal from "./Content/ContentForModal";
import ModalChangePasswordContent from "components/ModalChangePasswordContent/ModalChangePasswordContent";
import FeedBackModalContent from "components/FeedbackModalContent/FeedbackModalContent";

const CustomModal = (props) => {
  const {
    directionTo = "top",
    showModal = false,
    modalContent = "",
    onClose = () => {},
    setShowModal = () => {},
    changePasswordModal = false,
    showIdeaModalContent = () => {},
    ideaModal = false,
    parent = "",
    hideBackdrop = false,
  } = props;

  return (
    <Modal
      className={`custom-modal-container`}
      open={showModal}
      onClose={onClose}
      disableEnforceFocus
      hideBackdrop={hideBackdrop}
    >
      <div className={classNames("custom-modal-wrapper", `to-${directionTo}`)}>
        {!changePasswordModal && !ideaModal && (
          <ContentForModal
            modalContent={modalContent}
            setShowModal={setShowModal}
          />
        )}

        {showModal && ideaModal && showIdeaModalContent()}

        {changePasswordModal && (
          <ModalChangePasswordContent setShowModal={setShowModal} />
        )}

        {parent === "PartnerConnectRequest" && (
          <FeedBackModalContent onClose={onClose} />
        )}
      </div>
    </Modal>
  );
};

CustomModal.propTypes = {
  children: PropTypes.any,
  showModal: PropTypes.bool,
  directionTo: PropTypes.string,
  parent: PropTypes.string,
  modalContent: PropTypes.string,
  onClose: PropTypes.func,
  setShowModal: PropTypes.func,
  changePasswordModal: PropTypes.bool,
  ideaModal: PropTypes.bool,
  showIdeaModalContent: PropTypes.func,
  hideBackdrop: PropTypes.bool,
};

export default CustomModal;
