import React, { useState, useEffect } from "react";

// STYLES
import "./PreviewProject.scss";

// LIBRARIES

// CONSTANTS & MOCKS
import { FileExtensions, projectDocuments } from "constants/constants";
import { convertFileToBase64 } from "helpers/converters";

// REDUX
import { companyInfo } from "slices/sessionSlice";
import { useSelector } from "react-redux";

// COMPONENTS
import OverviewProjectDetailsCard from "components/OverviewProjectDetailsCard/OverviewProjectDetailsCard";
import OverviewProjectTitleCard from "components/OverviewProjectTitleCard/OverviewProjectTitleCard";
import ProjectOverviewDetails from "components/ProjectOverviewDetails/ProjectOverviewDetails";
import OverviewProjectVisualPresentation from "components/OverviewProjectVisualPresentation/OverviewProjectVisualPresentation";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";

const PreviewProject = (props) => {
  // PROPS
  const {
    projectData = {},
    setCurrentState = () => {},
    postProject = () => {},
  } = props;

  // CONSTANTS USING LIBRARYS
  const companyData = useSelector(companyInfo);
  // CONSTANTS USING HOOKS
  const [visualPresentationData, setVisualPresentationData] = useState([]);
  const [projectDetails, setProjectDetails] = useState({});

  // const [video]

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION
  useEffect(() => {
    if (projectData) {
      createDocumentsArray();
    }
    // eslint-disable-next-line
  }, []);

  const createDocumentsArray = async () => {
    let projectMainImageBase64 = await convertFileToBase64(
      projectData?.projectMainImage.file
    );
    projectMainImageBase64 = projectMainImageBase64.split(",");

    let projectMainImageDocumentBase64 = {
      fileName: projectData?.projectMainImage.fileName,
      fileExtension: projectData?.projectMainImage.fileExtension,
      documentType: projectDocuments.PROJECT_MAIN_IMAGE,
      content: projectMainImageBase64[1],
    };

    let visualPresentationImagesDocumentsBase64 = await Promise.all(
      projectData?.visualPresentation?.imagesFile.map(async (document) => {
        let documentContentBase64 = await convertFileToBase64(
          // projectData?.projectMainImage.file
          document.file
        );
        documentContentBase64 = documentContentBase64.split(",");
        let convertedDocument = {
          fileName: document?.fileName,
          fileExtension: document?.fileExtension,
          documentType: projectDocuments.VISUAL_PRESENTATION,
          content: documentContentBase64[1],
        };

        return convertedDocument;
      })
    );

    let visualPresentationVideosDocumentsBase64 = await Promise.all(
      projectData?.visualPresentation?.videosFile.map(async (document) => {
        let documentContentBase64 = await convertFileToBase64(
          // projectData?.projectMainImage.file
          document?.file
        );
        documentContentBase64 = documentContentBase64.split(",");
        let convertedDocument = {
          fileName: document?.fileName,
          fileExtension: document?.fileExtension,
          documentType: projectDocuments.VISUAL_PRESENTATION,
          content: documentContentBase64[1],
        };

        return convertedDocument;
      })
    );

    let businessPlanDocumentsDocumentsBase64 = await Promise.all(
      projectData?.businessPlan?.businessPlanFile.map(async (document) => {
        let documentContentBase64 = await convertFileToBase64(
          // projectData?.projectMainImage.file
          document?.file
        );
        documentContentBase64 = documentContentBase64.split(",");
        let convertedDocument = {
          fileName: document?.fileName,
          fileExtension: document?.fileExtension,
          documentType: projectDocuments.BUSINESS_PLAN,
          content: documentContentBase64[1],
        };

        return convertedDocument;
      })
    );

    let linksToDisplay = projectData?.links?.map((link) => {
      return {
        content: link.link,
        documentType: projectDocuments.VISUAL_PRESENTATION,
        fileExtension: FileExtensions.LINK,
        fileName: "videoLink",
      };
    });

    let documents = [
      projectMainImageDocumentBase64,
      ...visualPresentationImagesDocumentsBase64,
      ...visualPresentationVideosDocumentsBase64,
      ...businessPlanDocumentsDocumentsBase64,
      ...linksToDisplay,
    ];

    let projectDetailsToSet = {
      categoryModel: {
        id: projectData?.cat,
        name: projectData?.values?.categoryId,
      },
      companyProjectModel: {
        documentModel: {
          content: companyData?.documentModel?.content,
        },
        companyName: companyData?.companyName,
        registeredOffice: companyData?.registeredOffice,
      },
      country: projectData?.values?.country,
      currency: projectData?.values?.currency,
      desiredInvestment: projectData?.values?.desiredInvestment,
      links: projectData?.links,
      members: projectData?.teamMembers,
      projectDescription: projectData?.values?.projectDescription,
      projectName: projectData?.values?.projectName,
      projectShortDescription: projectData?.values?.projectShortDescription,
      shares: projectData?.values?.shares,
      subcategoryResponseModel: {
        id: projectData?.subCat,
        name: projectData?.values?.subcategoriesId,
      },
      documents: documents,
    };

    setVisualPresentationData(documents);
    setProjectDetails(projectDetailsToSet);
  };

  return (
    <div className="page-project-overview-container">
      <OverviewProjectDetailsCard projectDetails={projectDetails} />
      {visualPresentationData.length > 0 && (
        <OverviewProjectVisualPresentation
          contentToDisplay={visualPresentationData}
        />
      )}
      <OverviewProjectTitleCard title={"component.overview.title.card.title"} />
      <ProjectOverviewDetails projectDetails={projectDetails} />

      <div className="page-build-project-buttons-wrrapper">
        <ButtonComponent
          buttonText={"Edit mode"}
          color={"blue"}
          onClick={() => setCurrentState("build")}
        />
        <ButtonComponent
          buttonText={"component.project.builder.button.submit"}
          color={"blue"}
          onClick={postProject}
        />
      </div>
    </div>
  );
};

export default PreviewProject;
