import React, { useState, useEffect } from "react";

// STYLES
import "./ProjectOverviewDetails.scss";

// LIBRARIES
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

// CONSTANTS & MOCKS
import { userRolesTypes } from "constants/userRoles";
import { modalContentType } from "constants/constants";

// REDUX

// COMPONENTS
import OverviewProjectPlan from "components/OverviewProjectPlan/OverviewProjectPlan";
import { decodeJwtToken } from "helpers/decoder";
import ProjectTeamComponent from "components/ProjectTeamComponent/ProjectTeamComponent";
import CustomModal from "components/CustomModal/CustomModal";
import projectOverviewDetailsImage from "assets/components/projectOverviewDetails/projectOverviewDetailsImage.svg";
import { useDispatch } from "react-redux";
import { handleSaveInvestmentProjectId } from "slices/projectSlice";

const ProjectOverviewDetails = (props) => {
  // PROPS
  const { projectDetails = {} } = props;

  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch();

  // CONSTANTS USING HOOKS
  const [role, setRole] = useState(userRolesTypes.PARTNER);
  const [showModal, setShowModal] = useState(false);
  const [currentModalContent, setCurrentModalContent] = useState("");

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION
  useEffect(() => {
    setRole(decodeJwtToken().role);
  }, []);

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  const handleInvest = () => {
    // dispatch(handleSaveInvestmentProjectId(projectDetails.projectId));
    // setShowModal(true);
    // setCurrentModalContent(modalContentType.INVEST);
    window.location.href = "https://slingshot.nafram.org/";
  };

  const handleReject = () => {
    dispatch(handleSaveInvestmentProjectId(projectDetails.projectId));
    setShowModal(true);
    setCurrentModalContent(modalContentType.REJECT);
  };

  return (
    <div className="component-project-overview-details-container">
      <div className="component-project-overview-details-title-subtitle-wrapper">
        <p className="title">
          <FormattedMessage
            id={"component.project.overview.details.title.pledge"}
          />
          <span>
            {projectDetails?.desiredInvestment} {projectDetails?.currency}{" "}
            {/* TODO:  */}
            {/* {projectDetails?.desiredInvestment?.toFixed(0)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
            {projectDetails?.currency}{" "} */}
          </span>
          <FormattedMessage
            id={"component.project.overview.details.title.goal"}
          />
        </p>
        <p className="subtitle">
          <span>{projectDetails.shares} %</span>{" "}
          <FormattedMessage
            id={"component.project.overview.details.title.shares"}
          />
        </p>
      </div>

      {role === userRolesTypes.PARTNER && (
        <>
          <div
            className="component-project-overview-invest-button"
            onClick={handleInvest}
          >
            <FormattedMessage
              id={"project.overview.details.partner.invest.button"}
            />
          </div>
          <div
            className="component-project-overview-reject-button"
            onClick={handleReject}
          >
            <FormattedMessage
              id={"project.overview.details.partner.reject.button"}
            />
          </div>
        </>
      )}

      <div className="project-overview-team-component-wrapper">
        <ProjectTeamComponent members={projectDetails.members} />
      </div>
      <div className="project-overview-selection-wrapper">
        <OverviewProjectPlan
          projectDescriptionTitle={
            "component.overview.project.plan.description.title"
          }
          image={projectOverviewDetailsImage}
          text={"component.overview.project.plan.description.text"}
          businessPlanTitle={"component.overview.project.plan.business.title"}
          projectDetails={projectDetails}
        />
      </div>
      <CustomModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        directionTo="top"
        modalContent={currentModalContent}
        setShowModal={setShowModal}
      />
    </div>
  );
};

ProjectOverviewDetails.propTypes = {
  projectDetails: PropTypes.shape({}),
};

export default ProjectOverviewDetails;
