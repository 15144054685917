import { createSlice } from "@reduxjs/toolkit";
import {
  createCategory,
  createSubcategory,
  editCategory,
  getMappedCategories,
  getPartnersRequest,
  getInvestmentRequest,
  getProjectApproval,
  approveProject,
  rejectProject,
  acceptPartnersMeetingRequest,
  rejectPartnersMeetingRequest,
  getInvestmentById,
  rejectDeal,
  getInvestmentReport,
  deleteSubcategory,
  downloadReport,
  getProjectRequests,
  downloadRequestDocument,
  uploadRequestDocument,
  approveRequest,
  rejectRequest,
  getSignUpRequests,
  getSignUpDocument,
  uploadSignUpDocument,
  approveSignUpRequest,
  rejectSignUpRequest,
} from "api/adminApi";
import { AdminTabs } from "constants/constants";
import { handleAdminTableData } from "helpers/adminTablesFunctions";

export const adminSlice = createSlice({
  name: "adminSlice",
  initialState: {
    partnersMeeting: {},
    signUpRequestsContent: [],
    projectRequestContent: [],
    projectApprovalContent: [],
    investmentContent: [],
    investmentList: [],
    partnersMeetingContent: [],
    paginationData: {
      pageNumber: 0,
      pageSize: 10,
      totalCount: 0,
    },
    investmentRequestId: 0,
    investmentRequestData: {},
    selectedTabs: {
      mainTab: "PROJECT_APPROVAL",
      secondaryTab: AdminTabs.INVESTMENT_REQUEST,
    },
    modalContentReport: {},
    projectRequestIdToAccept: null,
    requestDetails: {
      requestId: null,
      requestType: null
    }
  },
  reducers: {
    changeMeeting: (state, action) => {
      state.partnersMeeting = action.payload;
    },
    removeMeetingPayload: (state) => {
      state.partnersMeeting = {};
    },
    handleChangePage: (state, action) => {
      state.paginationData = {
        ...state.paginationData,
        [action.payload.name]: action.payload.value,
      };
    },
    handleInvestmentData: (state, action) => {
      state.investmentRequestId = action.payload;
    },
    handleSelectAdminTab: (state, action) => {
      if (action.payload.type === "main") {
        state.selectedTabs.mainTab = action.payload.value;
      } else if (action.payload.type === "secondary") {
        state.selectedTabs.secondaryTab = action.payload.value;
      }
    },
    handleModalData: (state, action) => {
      state.modalContentReport = action.payload;
    },
    handleSaveRequestId: (state, action) => {
      state.projectRequestIdToAccept = action.payload;
    },
    handleSaveRequestDetails: (state, action) => {
      state.requestDetails = action.payload
    }
  },
  extraReducers: {
    // ======= POST CREATE CATEGORIES REQUEST HANDLERS ======= //
    [createCategory.pending]: (state) => {
      state.isLoading = true;
    },
    [createCategory.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [createCategory.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },
    // ======= POST CREATE SUBCATEGORIES REQUEST HANDLERS ======= //
    [createSubcategory.pending]: (state) => {
      state.isLoading = true;
    },
    [createSubcategory.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [createSubcategory.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },
    // ======= POST EDIT CATEGORY REQUEST HANDLERS ======= //
    [editCategory.pending]: (state) => {
      state.isLoading = true;
    },
    [editCategory.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [editCategory.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },
    // ======= POST EDIT CATEGORY REQUEST HANDLERS ======= //
    [getMappedCategories.pending]: (state) => {
      state.isLoading = true;
    },
    [getMappedCategories.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [getMappedCategories.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },
    // ======= GET PARTNERS APPROVAL REQUEST HANDLERS ======= //
    [getPartnersRequest.pending]: (state) => {
      state.isLoading = true;
    },
    [getPartnersRequest.fulfilled]: (state, action) => {
      state.isLoading = false;
      let content = handleAdminTableData(
        action.payload.items,
        AdminTabs.PARTNERS_MEETING
      );
      let newPaginationData = {
        ...state.paginationData,
        totalCount: action.payload.totalCount,
      };

      state.paginationData = newPaginationData;
      state.partnersMeetingContent = content;
    },
    [getPartnersRequest.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },
    // ======= GET ADMIN IVNESTMENT LIST REQUEST HANDLERS ======= //
    [getInvestmentRequest.pending]: (state) => {
      state.isLoading = true;
    },
    [getInvestmentRequest.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.investmentList = action.payload.items;
      let content = handleAdminTableData(
        action.payload.items,
        AdminTabs.INVESTMENT
      );
      let newPaginationData = {
        ...state.paginationData,
        totalCount: action.payload.totalCount,
      };
      state.paginationData = newPaginationData;
      state.investmentContent = content;
    },
    [getInvestmentRequest.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },
    // ======= GET PROJECT APPROVAL REQUEST HANDLERS ======= //
    [getProjectApproval.pending]: (state) => {
      state.isLoading = true;
    },
    [getProjectApproval.fulfilled]: (state, action) => {
      state.isLoading = false;
      let content = handleAdminTableData(
        action.payload.items,
        AdminTabs.PROJECT_APPROVAL
      );
      let newPaginationData = {
        ...state.paginationData,
        totalCount: action.payload.totalCount,
      };

      state.paginationData = newPaginationData;
      state.projectApprovalContent = content;
    },
    [getProjectApproval.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },
    // ======= GET PROJECT REQUEST HANDLERS ======= //
    [getProjectRequests.pending]: (state) => {
      state.isLoading = true;
    },
    [getProjectRequests.fulfilled]: (state, action) => {
      state.isLoading = false;
      let content = handleAdminTableData(
        action.payload.items,
        AdminTabs.PROJECT_REQUESTS
      );
      let newPaginationData = {
        ...state.paginationData,
        totalCount: action.payload.totalCount,
      };
      state.paginationData = newPaginationData;
      state.projectRequestContent = content;
    },
    [getProjectRequests.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },
    // ======= APPROVE PROJECT ======= //
    [approveProject.pending]: (state) => {
      state.isLoading = true;
    },
    [approveProject.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [approveProject.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },
    // ======= REJECT PROJECT ======= //
    [rejectProject.pending]: (state) => {
      state.isLoading = true;
    },
    [rejectProject.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [rejectProject.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },
    // ======= ACCEPT CONNECTION ======= //
    [acceptPartnersMeetingRequest.pending]: (state) => {
      state.isLoading = true;
    },
    [acceptPartnersMeetingRequest.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [acceptPartnersMeetingRequest.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },
    // ======= REJECT CONNECTION ======= //
    [rejectPartnersMeetingRequest.pending]: (state) => {
      state.isLoading = true;
    },
    [rejectPartnersMeetingRequest.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [rejectPartnersMeetingRequest.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },
    // ======= GET INVESTMENT BY ID ======= //
    [getInvestmentById.pending]: (state) => {
      state.isLoading = true;
    },
    [getInvestmentById.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.investmentRequestData = action.payload;
    },
    [getInvestmentById.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },
    // ======= REJECT DEAL  ======= //
    [rejectDeal.pending]: (state) => {
      state.isLoading = true;
    },
    [rejectDeal.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [rejectDeal.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },
    // ======= GET INVESTMENT REPORTS  ======= //
    [getInvestmentReport.pending]: (state) => {
      state.isLoading = true;
    },
    [getInvestmentReport.fulfilled]: (state, action) => {
      state.isLoading = false;
      let content = handleAdminTableData(
        action.payload.items,
        AdminTabs.INVESTMENT_REPORT
      );
      state.investmentContent = content;
    },
    [getInvestmentReport.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },
    // ======= DELETE SUBCATEGORY  ======= //
    [deleteSubcategory.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteSubcategory.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [deleteSubcategory.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },

    // ======= DOWNLOAD REPORT  ======= //
    [downloadReport.pending]: (state) => {
      state.isLoading = true;
    },
    [downloadReport.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [downloadReport.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },

    // ======= DOWNLOAD REQUEST PROJECT DOCUMENT  ======= //
    [downloadRequestDocument.pending]: (state) => {
      state.isLoading = true;
    },
    [downloadRequestDocument.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [downloadRequestDocument.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },

    // ======= UPLOAD REQUEST PROJECT DOCUMENT  ======= //
    [uploadRequestDocument.pending]: (state) => {
      state.isLoading = true;
    },
    [uploadRequestDocument.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [uploadRequestDocument.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },

    // ======= APPROVE REQUEST PROJECT DOCUMENT  ======= //
    [approveRequest.pending]: (state) => {
      state.isLoading = true;
    },
    [approveRequest.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [approveRequest.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },

    // ======= REJECT REQUEST PROJECT DOCUMENT  ======= //
    [rejectRequest.pending]: (state) => {
      state.isLoading = true;
    },
    [rejectRequest.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [rejectRequest.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },

    // ======= SIGN UP REQUESTS APIS ========== //

    // ======= GET SIGN UP REQUESTS  ======= //
    [getSignUpRequests.pending]: (state) => {
      state.isLoading = true;
    },
    [getSignUpRequests.fulfilled]: (state, action) => {
      state.isLoading = false;

      state.isLoading = false;
      let content = handleAdminTableData(
        action.payload.items,
        AdminTabs.BRAINER_SIGN_UP_REQUESTS
      );
      let newPaginationData = {
        ...state.paginationData,
        totalCount: action.payload.totalCount,
      };
      state.paginationData = newPaginationData;
      state.signUpRequestsContent = content;
    },
    [getSignUpRequests.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },

    // ======= GET SIGN UP DOCUMENTS  ======= //
    [getSignUpDocument.pending]: (state) => {
      state.isLoading = true;
    },
    [getSignUpDocument.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [getSignUpDocument.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },

    // ======= UPLOAD SIGN UP DOCUMENTS  ======= //
    [uploadSignUpDocument.pending]: (state) => {
      state.isLoading = true;
    },
    [uploadSignUpDocument.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [uploadSignUpDocument.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },

    // ======= APPROVE SIGN UP REQUEST  ======= //
    [approveSignUpRequest.pending]: (state) => {
      state.isLoading = true;
    },
    [approveSignUpRequest.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [approveSignUpRequest.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },

    // ======= REJECT SIGN UP REQUEST  ======= //
    [rejectSignUpRequest.pending]: (state) => {
      state.isLoading = true;
    },
    [rejectSignUpRequest.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [rejectSignUpRequest.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },
  },
});

export const partnersMeeting = (state) => state.admin.partnersMeeting;
export const projectApprovalContent = (state) =>
  state.admin.projectApprovalContent;
export const projectRequestContent = (state) =>
  state.admin.projectRequestContent;
export const signUpRequestsContent = (state) =>
  state.admin.signUpRequestsContent;
export const investmentContent = (state) => state.admin.investmentContent;
export const partnersMeetingContent = (state) =>
  state.admin.partnersMeetingContent;
export const paginationDetails = (state) => state.admin.paginationData;
export const investmentList = (state) => state.admin.investmentList;
export const investmentRequestId = (state) => state.admin.investmentRequestId;
export const investmentRequestData = (state) =>
  state.admin.investmentRequestData;
export const selectedTabs = (state) => state.admin.selectedTabs;
export const modalContentReport = (state) => state.admin.modalContentReport;
export const projectRequestIdToAccept = (state) =>
  state.admin.projectRequestIdToAccept;
export const requestDetails = (state) =>
  state.admin.requestDetails;

export const {
  changeMeeting,
  removeMeetingPayload,
  handleChangePage,
  handleInvestmentData,
  handleSelectAdminTab,
  handleModalData,
  handleSaveRequestId,
  handleSaveRequestDetails
} = adminSlice.actions;

export default adminSlice.reducer;
