import { ContactlessOutlined } from "@mui/icons-material";
import classNames from "classnames";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import CustomButton from "components/CustomButton/CustomButton";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { modalContentType } from "constants/constants";
import CustomModal from "components/CustomModal/CustomModal";

// STYLES
import "./InterestsPage.scss";
import { decodeJwtToken } from "helpers/decoder";
import { useDispatch } from "react-redux";
import { getPersonalInfo } from "api/sessionApi";
import { editAccountInfo } from "api/userApi";
import InterestsSelectComponent from "./InterestsSelectComponent";

// LIBRARIES

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS

const InterestsPage = () => {
  // PROPS

  // CONSTANTS USING HOOKS
  const [selections, setSelections] = useState([
    { label: "Information Technologies", selected: false },
    { label: "Sports", selected: false },
    { label: "Energy", selected: false },
    { label: "Communication", selected: false },
    { label: "Finconnect", selected: false },
    { label: "Real estate", selected: false },
    { label: "Agriculture", selected: false },
    { label: "Trade", selected: false },
    { label: "Pharmaceutical", selected: false },
    { label: "Industry", selected: false },
    { label: "Construction", selected: false },
    { label: "Transport", selected: false },
    { label: "Education", selected: false },
    { label: "Art", selected: false },
    { label: "Entertainment", selected: false },
    { label: "Tourism", selected: false },
  ]);

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const dispatch = useDispatch();
  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // HANDLERS FUNCTIONS

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  const handleNavigation = () => {
    navigate("/explore");
  };

  return (
    <div className="component-interests-page-container">
      <div className="component-interests-title-container">
        <p className="component-interests-title">What interests you?</p>
        <p className="component-interests-subtitle">
          CATEGORIES OF PROJECTS AND IDEAS
        </p>
      </div>
      <InterestsSelectComponent buttons={true} />
    </div>
  );
};

export default InterestsPage;
