import React from "react";

// STYLES
import "./OverviewProjectPlan.scss";
import businessPlanCardImage from "assets/components/advertisementImages/advertisementImageBrainer.svg";

// LIBRARIES
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS
import BusinessPlanCard from "components/BusinessPlanCard/BusinessPlanCard";
import { projectDocuments } from "constants/constants";

const OverviewProjectPlan = (props) => {
  // PROPS
  const {
    projectDescriptionTitle = "",
    businessPlanTitle = "",
    projectDetails = {},
  } = props;
  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS
  const documents = projectDetails?.documents?.filter(
    (document) => document.documentType === projectDocuments.BUSINESS_PLAN
  );

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className="component-overview-project-plan-container">
      <div className="component-overview-project-plan-description-wrapper">
        <p className="plan-description-title">
          <span>&#8226;</span>
          <FormattedMessage id={projectDescriptionTitle} />
        </p>
        <div className="plan-description-image">
          <img
            src={`data:image/png;base64,${
              projectDetails?.documents?.find(
                (document) =>
                  document.documentType === projectDocuments.PROJECT_MAIN_IMAGE
              )?.content
            }`}
            alt=""
          />
        </div>
        <p className="plan-description-text">
          {/* <FormattedMessage id={text} /> */}
          {projectDetails?.projectDescription}
        </p>
      </div>
      <div className="component-overview-project-plan-business-plan-wrapper">
        <p className="business-plan-title">
          <span>&#8226;</span>
          <FormattedMessage id={businessPlanTitle} />
        </p>
        <div className="business-plan-cards">
          {documents?.length > 0 &&
            documents?.map((document, index) => (
              <BusinessPlanCard
                document={document}
                image={businessPlanCardImage}
                title={`Document ${index + 1}`}
                text="component.project.overview.project.plan.business.text"
              />
            ))}
        </div>
      </div>
    </div>
  );
};

OverviewProjectPlan.propTypes = {
  projectDescriptionTitle: PropTypes.string,
  image: PropTypes.string,
  text: PropTypes.string,
  businessPlanTitle: PropTypes.string,
  projectDetails: PropTypes.shape({}),
};

export default OverviewProjectPlan;
