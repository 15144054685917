export const getMobileHeaderLinks = (role) => {
  let headerLinks = [
    {
      label: "component.header.explore",
      path: "/explore",
      isVisible: true,
    },
    {
      label: "component.header.consulting",
      path: "/consulting",
      isVisible: true,
    },
    {
      label: "component.header.partnersMeeting",
      path: "/meeting",
      isVisible: role === "PARTNER" ? true : false,
    },
    {
      label: "component.header.submitIdea",
      path: "/submit",
      isVisible: role === "BRAINER" ? true : false,
    },
    {
      label: "component.header.about",
      path: "https://nafram.org",
      isVisible: true,
    },
  ];
  return headerLinks;
};
