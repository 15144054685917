import React from "react";

// STYLES
import "./CustomButton.scss";

// LIBRARIES
import classNames from "classnames";
import PropTypes from "prop-types";

const CustomButton = (props) => {
  // PROPS

  const {
    handleChange = () => {},
    type = "fill",
    name = "",
    text = "",
    isEnabled = false,
  } = props;

  return (
    <div className="custom-btn-container">
      <button
        name={name}
        className={classNames("custom-btn", {
          [`${type}-btn-disabled`]: !isEnabled,
          [`${type}-btn`]: isEnabled,
        })}
        onClick={isEnabled ? handleChange : () => {}}
      >
        {text}
      </button>
    </div>
  );
};

CustomButton.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  isEnabled: PropTypes.bool,
  handleChange: PropTypes.func,
};

export default CustomButton;
