import React from "react";

// STYLES
import "./CustomInput.scss";

// LIBRARIES
import PropTypes from "prop-types";

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS

const CustomInput = (props) => {
  // PROPS
  const {
    value = "",
    handleChange = () => {
    },
    type = "text",
    name = "",
    placeholder = "",
    className = "",
    label = ""
  } = props;

  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className="page-CustomInput-container">
      <p className={"page-CustomInput-label"}>{label}</p>
      <input
        id={name}
        name={name}
        type={type}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        className={`${className} custom-input-atom`}
      />
    </div>
  );
};

CustomInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  labelText: PropTypes.string,
  containerClass: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  inputStyle: PropTypes.string,
  handleChange: PropTypes.func,
};

export default CustomInput;