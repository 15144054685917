import React, { useState } from "react";

// STYLES
import "./PartnersMeetingModalContent.scss";

// LIBRARIES
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import TextInput from "components/Atoms/TextInput/TextInput";
import { rejectSignUpRequest } from "api/adminApi";
import { useDispatch, useSelector } from "react-redux";
import {
  handleSaveRequestDetails,
  paginationDetails,
  requestDetails,
  selectedTabs,
} from "slices/adminSlice";
import { handleTableDataRequest } from "helpers/adminTablesFunctions";
import { AdminTabs, RequestStatus } from "constants/constants";

const RejectSignUpRequestContent = (props) => {
  // PROPS
  const { setShowModal = () => {} } = props;

  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch();
  const paginationData = useSelector(paginationDetails);
  const selectedTabsData = useSelector(selectedTabs);
  const requestDetailsData = useSelector(requestDetails);

  // CONSTANTS USING HOOKS
  const [rejectRequestReason, setRejectRequestReason] = useState("");

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  const handleRejectRequest = () => {
    dispatch(
      rejectSignUpRequest({
        requestId: requestDetailsData.requestId,
        rejectRequestReason,
      })
    )
      .unwrap()
      .then(() => {
        handleTableDataRequest(
          {
            mainTab:
              selectedTabsData.mainTab === AdminTabs.BRAINER_SIGN_UP_REQUESTS
                ? AdminTabs.BRAINER_SIGN_UP_REQUESTS
                : AdminTabs.PARTNER_SIGN_UP_REQUESTS,
            secondaryTab: RequestStatus.PENDING,
          },
          paginationData
        );
        dispatch(handleSaveRequestDetails(null));
        setShowModal(false);
      });
  };

  return (
    <div className="component-modal-validation-project-container">
      <p className="component-modal-validation-project-title">
        <FormattedMessage id="modal.meeting.sign.up.reject.title" />
      </p>
      <p className="component-modal-validation-project-text">
        <FormattedMessage id="modal.meeting.sign.up.reject.text" />
      </p>

      <div className="reject-reason-wrapper">
        <TextInput
          labelColor="blue"
          placeholder="Please argue your reject reason..."
          minRows={15}
          multiline={true}
          handleChange={(event) => setRejectRequestReason(event.target.value)}
          value={rejectRequestReason}
        />
      </div>

      <div className="component-modal-validation-project-buttons">
        <ButtonComponent
          buttonText="Cancel"
          color="blue"
          onClick={() => setShowModal(false)}
        />
        <ButtonComponent
          buttonText="Yes"
          color="blue"
          onClick={handleRejectRequest}
        />
      </div>
    </div>
  );
};

RejectSignUpRequestContent.propTypes = {
  setShowModal: PropTypes.func,
  type: PropTypes.string,
};

export default RejectSignUpRequestContent;
