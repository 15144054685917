import React from "react";
// STYLES
import "./ButtonComponent.scss";

// LIBRARIES
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

// CONSTANTS & MOCKS
import { tabletResolutionHigh } from "constants/constants.js";
// REDUX

// COMPONENTS

const ButtonComponent = (props) => {
  // PROPS
  const {
    buttonText = "",
    color = "",
    onClick = () => {},
    intlRequired = true,
    selected = false,
    textStyle = "default",
    fontSize = "small-button-font-size",
    isInvesting = false,
  } = props;

  let intFrameWidth = window.innerWidth;

  return (
    <div
      className={classNames("component-button-container", "subcategoriesList")}
      onClick={onClick}
      style={{ height: isInvesting ? "100%" : "" }}
    >
      <p
        className={classNames(
          "component-button",
          color,
          "subcategoriesList",
          selected && "selected",
          textStyle === "thin" && "thin-component-button",
          fontSize
        )}
      >
        {intlRequired && <FormattedMessage id={buttonText} />}
        {!intlRequired &&
          (buttonText === "SORTED BY" && intFrameWidth < tabletResolutionHigh
            ? "SORTED"
            : buttonText === "PRICE RANGE" &&
              intFrameWidth < tabletResolutionHigh
            ? "AMOUNT"
            : buttonText)}
      </p>
    </div>
  );
};

ButtonComponent.propTypes = {
  buttonText: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  intlRequired: PropTypes.bool,
  selected: PropTypes.bool,
  textStyle: PropTypes.string,
  fontSize: PropTypes.string,
};

export default ButtonComponent;
