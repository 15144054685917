import React, { useState } from "react";

// STYLES
import "./ContactComponent.scss";
import TextInput from "components/Atoms/TextInput/TextInput";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";

//LYBRARIES
import { injectIntl, useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";

//REDUX
import { postContactForm } from "api/sessionApi";
import { useDispatch } from "react-redux";

const ContactComponent = () => {
  // PROPS
  // CONSTANTS USING LIBRARIES
  const intl = useIntl();
  const dispatch = useDispatch();

  // CONSTANTS USING HOOKS
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [closeMessage, setCloseMessage] = useState(false);
  const [data, setData] = useState({
    values: {},
  });

  // GENERAL CONSTANTS
  // USE EFFECT FUNCTION
  // REQUEST API FUNCTIONS
  const handlePostContactForm = () => {
    if (handleCheckEmail(data.values.emailAddress)) {
      dispatch(postContactForm(data.values))
        .unwrap()
        .then(() => {
          setData({ values: {} });
          setCloseMessage(true);
        })
        .catch(function (error) {
          console.log(error.message);
          setCloseMessage(false);
        });
    }
  };

  // HANDLERS FUNCTIONS

  const handleCheckEmail = (email) => {
    // eslint-disable-next-line
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setEmailInvalid(false);
      return true;
    } else {
      setEmailInvalid(true);
      return false;
    }
  };

  const handleChange = (event) => {
    if (event.target.name === "emailAddress") {
      handleCheckEmail(event.target.value);
    }
    setData((data) => ({
      ...data,
      values: {
        ...data.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  return (
    <div className="component-contact-container">
      {closeMessage && (
        <div className="component-contact-feedback-message">
          <p className="title">
            <FormattedMessage id={"component.contact.feedback.message.title"} />
          </p>
          <p className="description">
            <FormattedMessage
              id={"component.contact.feedback.message.description"}
            />
          </p>
          <ButtonComponent
            buttonText="component.contact.feedback.message.button.text"
            onClick={() => setCloseMessage(false)}
            color="blue"
          />
        </div>
      )}
      <div className="component-contact-content-container">
        <div className="component-contact-name-email-contact-container">
          <div className="component-contact-name-email-container">
            <div className="component-contact-name-container">
              <TextInput
                labelColor="gold"
                label={"component.team.textInput.labelName"}
                placeholder={intl.formatMessage({
                  id: "component.team.placeholder.labelName",
                })}
                name={"fullName"}
                handleChange={handleChange}
                value={data.values["fullName"] || ""}
              />
            </div>
            <div className="component-contact-email-container">
              <TextInput
                labelColor="gold"
                label={"component.team.textInput.labelEmail"}
                placeholder={intl.formatMessage({
                  id: "component.team.placeholder.labelEmail",
                })}
                name={"emailAddress"}
                handleChange={handleChange}
                value={data.values["emailAddress"] || ""}
              />
              <div className="component-contact-invalid-email">
                {Object.keys(data.values).length >= 1 && emailInvalid === true && (
                  <div className="password-strength poor">
                    <FormattedMessage
                      id={"component.contact.invalid.email.message"}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="component-contact-contact-container">
            <TextInput
              labelColor="gold"
              label={"component.team.textInput.labelContact"}
              placeholder={intl.formatMessage({
                id: "component.team.placeholder.labelContact",
              })}
              multiline={true}
              name={"content"}
              handleChange={handleChange}
              value={data.values["content"] || ""}
            />
          </div>
        </div>
      </div>
      <div className="component-contact-button-container">
        <ButtonComponent
          buttonText="component.team.button.buttonText"
          onClick={handlePostContactForm}
        />
      </div>
    </div>
  );
};

export default injectIntl(ContactComponent);
