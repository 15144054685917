import React from "react";

// STYLES

// LIBRARIES
import PropTypes from "prop-types";

// CONSTANTS & MOCKS
import { modalContentType } from "constants/constants";
// REDUX

// COMPONENTS
import ModalValidationProject from "components/ModalValidationProject/ModalValidationProject";
import UploadingProjectModalContent from "./UploadingProjectModalContent";
import LoadingProjectModalContent from "./LoadingProjectModalContent";
import PartnersMeetingModalContent from "./PartnersMeetingModalContent";
import ProjectUploadedContent from "./ProjectUploadedContent";
import InvestInProjectModalContent from "./InvestInProjectModalContent";
import InvestmentRequestModal from "components/InvestmentRequestModal/InvestmentRequestModal";
import InvestmentReport from "components/InvestmentReportModal/InvestmentReport";
import ResetValidationCodeModal from "view/RegisterPage/Components/ResetValidationModal";
import RejectProjectRequestContent from "./RejectProjectRequestContent";
import ApproveProjectRequestContent from "./ApproveProjectRequestContent";
import ApproveSignUpRequestContent from "./ApproveSignUpRequestContent";
import RejectSignUpRequestContent from "./RejectSignUpRequestContent";
import ContactComponent from "components/ContactComponent/ContactComponent";

const ContentForModal = (props) => {
  // PROPS
  const { modalContent = "", setShowModal = () => {} } = props;

  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  const renderModalContent = () => {
    switch (modalContent) {
      case modalContentType.PARTNER_MEETING_CARD:
        return (
          <ModalValidationProject
            showNumber={false}
            showSecondButton={false}
            secondButtonText={"partners.meeting.card.button.modal.back"}
            title={"partners.meeting.card.button.modal.title"}
            text={"partners.meeting.card.button.modal.text"}
            setShowModal={setShowModal}
          />
        );
      case modalContentType.PARTNER_PROFILE_CARD_INVEST:
        return <InvestInProjectModalContent setShowModal={setShowModal} />;
      case modalContentType.PARTNER_PROFILE_CARD_REJECT:
        return (
          <ModalValidationProject
            setShowModal={setShowModal}
            showNumber={false}
            secondButtonText={
              "project.overview.details.partner.reject.modal.second.button.text"
            }
            title={"partner.profile.card.reject.project.modal.title"}
            text={"partner.profile.card.reject.project.modal.text"}
          />
        );
      case modalContentType.INVEST:
        return <InvestInProjectModalContent />;
      case modalContentType.REJECT:
        return (
          <ModalValidationProject
            setShowModal={setShowModal}
            showNumber={false}
            secondButtonText={
              "project.overview.details.partner.reject.modal.second.button.text"
            }
            title={"project.overview.details.partner.reject.modal.title"}
            text={"project.overview.details.partner.reject.modal.text"}
          />
        );

      case modalContentType.PROJECT_SUBMIT:
        return (
          <ProjectUploadedContent
            setShowModal={setShowModal}
            showNumber={false}
            title={"modal.validation.project.title"}
            text={"modal.validation.project.text"}
          />
        );
      case modalContentType.PARTNERS_MEETING:
        return <PartnersMeetingModalContent setShowModal={setShowModal} />;
      case modalContentType.UPLOADING_PROJECT:
        return <UploadingProjectModalContent />;
      case modalContentType.LOADING_PROJECT:
        return <LoadingProjectModalContent />;
      case modalContentType.INVESTMENT_REQUEST:
        return <InvestmentRequestModal setShowModal={setShowModal} />;
      case modalContentType.INVESTMENT_REPORT:
        return <InvestmentReport setShowModal={setShowModal} />;
      case modalContentType.RESET_VALIDATION_CODE:
        return <ResetValidationCodeModal setShowModal={setShowModal} />;
      case modalContentType.REJECT_PROJECT_REQUEST:
        return (
          <RejectProjectRequestContent
            setShowModal={setShowModal}
            type={"IDEA"}
          />
        );
      case modalContentType.APPROVE_PROJECT_REQUEST:
        return (
          <ApproveProjectRequestContent
            setShowModal={setShowModal}
            type={"IDEA"}
          />
        );
      case modalContentType.REJECT_PROJECT_VIEW_REQUEST:
        return (
          <RejectProjectRequestContent
            setShowModal={setShowModal}
            type={"VIEW"}
          />
        );
      case modalContentType.APPROVE_PROJECT_VIEW_REQUEST:
        return (
          <ApproveProjectRequestContent
            setShowModal={setShowModal}
            type={"VIEW"}
          />
        );
      case modalContentType.APPROVE_SIGN_UP_REQUEST:
        return (
          <ApproveSignUpRequestContent
            setShowModal={setShowModal}
            type={"VIEW"}
          />
        );
      case modalContentType.REJECT_SIGN_UP_REQUEST:
        return (
          <RejectSignUpRequestContent
            setShowModal={setShowModal}
            type={"VIEW"}
          />
        );
      case modalContentType.HELPING_BRAINERS_ADVICE:
        return <ContactComponent />;
      default:
        return "";
    }
  };

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className="component-content-for-modal-container">
      {renderModalContent()}
    </div>
  );
};

ContentForModal.propTypes = {
  modalContent: PropTypes.string,
  setShowModal: PropTypes.func,
};

export default ContentForModal;
