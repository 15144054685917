export const step1 = [
  {
    label: "card.register.label.fullName",
    placeholder: "card.register.placeholder.fullName",
    type: "text",
    name: "fullName",
    required: true,
    component: "textInput",
    mandatory: true,
    md: 6,
    xs: 12,
  },
  {
    label: "card.register.label.email",
    placeholder: "card.register.placeholder.email",
    type: "text",
    name: "emailAddress",
    required: true,
    component: "textInput",
    mandatory: true,
    md: 6,
    xs: 12,
  },
  {
    label: "card.register.label.birthDate",
    placeholder: "card.register.placeholder.birthDate",
    type: "text",
    name: "dateOfBirth",
    required: true,
    component: "datePicker",
    mandatory: true,
    md: 6,
    xs: 12,
  },
  {
    label: "card.register.label.password",
    placeholder: "card.register.placeholder.password",
    type: "password",
    name: "password",
    required: true,
    component: "textInput",
    mandatory: true,
    md: 6,
    xs: 12,
  },

  {
    label: "card.register.label.repeatpassword",
    placeholder: "card.register.placeholder.repeatassword",
    type: "password",
    name: "confirmPassword",
    required: true,
    component: "textInput",
    mandatory: true,
    md: 6,
    xs: 12,
  },

  {
    label: "card.register.label.country",
    placeholder: "card.register.placeholder.country",
    type: "select",
    name: "country",
    required: true,
    component: "select",
    md: 6,
    xs: 12,
  },
];

export const step2 = [
  {
    label: "card.register.label.companyName",
    placeholder: "card.register.placeholder.companyName",
    type: "text",
    name: "companyName",
    required: true,
    component: "textInput",
  },
  {
    label: "card.register.label.domain",
    placeholder: "card.register.placeholder.domain",
    type: "text",
    name: "domain",
    required: true,
    component: "textInput",
  },
  {
    label: "card.register.label.registrationNumber",
    placeholder: "card.register.placeholder.registrationNumber",
    type: "text",
    name: "tradeRegistrationNumber",
    required: true,
    component: "textInput",
  },
  {
    label: "card.register.label.jobTitle",
    placeholder: "card.register.placeholder.jobTitle",
    type: "text",
    name: "jobTitle",
    required: true,
    component: "textInput",
  },
  {
    label: "card.register.label.registeredOffice",
    placeholder: "card.register.placeholder.registeredOffice",
    type: "text",
    name: "registeredOffice",
    required: true,
    component: "textInput",
  },
  {
    label: "card.register.label.uniqueRegistrationCode",
    placeholder: "card.register.placeholder.uniqueRegistrationCode",
    type: "text",
    name: "uniqueRegistrationCode",
    required: true,
    component: "textInput",
  },
  {
    label: "card.register.label.compoanyCountry",
    placeholder: "card.register.placeholder.country",
    type: "text",
    name: "country",
    required: true,
    component: "select",
  },
];
