import React, { useState, useEffect } from "react";

// STYLES
import "./ForgotPassword.scss";
import heroImageDesktop from "assets/components/registerPage/X.svg";
import heroImageMobile from "assets/components/HeroComponent/heroImageMobile.jpg";
import heroImageTablet from "assets/components/HeroComponent/heroImageTablet.jpg";

// LIBRARIES
import { FormattedMessage, injectIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

// CONSTANTS & MOCKS
import { forgotPasswordInputs } from "../SelectRolePage/Mocks/inputsMock";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { resetPasswordRequest } from "api/sessionApi";
import { loginState } from "slices/sessionSlice";

// COMPONENTS
import TextInput from "components/Atoms/TextInput/TextInput";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import { decodeJwtToken } from "helpers/decoder";

const ForgotPassword = (props) => {
  // PROPS
  const { intl } = props;

  // CONSTANTS USING LIBRARIES
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedState = useSelector(loginState);

  // CONSTANTS USING HOOKS
  const [formState, setFormState] = useState({
    values: {
      email: "",
      password: "",
    },
  });
  const [showError, setShowError] = useState([]);
  // eslint-disable-next-line
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [sentEmail, setSentEmail] = useState(false);
  // GENERAL CONSTANTS
  const contentToMap = forgotPasswordInputs;

  // USE EFFECT FUNCTION
  useEffect(() => {
    if (loggedState?.loggedIn) {
      navigate("/homepage");
    }
    if (decodeJwtToken().role === "ADMIN") {
      navigate("/admin");
    }
    // eslint-disable-next-line
  }, [loggedState]);

  // HANDLERS FUNCTIONS

  const handleChange = (event, name) => {
    if (showError.length > 0) {
      setShowError([]);
    }

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleForgotPassword = () => {
    dispatch(resetPasswordRequest(formState.values["forgotEmail"]))
      .unwrap()
      .catch((error) => {
        setEmailErrorMessage(error.errors);
      });
    setSentEmail(true);
  };

  const handleKeyPress = () => {
    handleForgotPassword();
  };

  const handleBackToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="page-forgot-password-container">
      <img
        className="page-forgot-password-image"
        src={
          window.innerWidth <= 599
            ? heroImageMobile
            : window.innerWidth <= 1279
            ? heroImageTablet
            : heroImageDesktop
        }
        alt=""
      />
      <div className="page-forgot-password-card-wrapper">
        <div className={"page-forgot-password-title-wrapper"}>
          <p className="page-forgot-password-title-text">
            <FormattedMessage id={"card.login.forgotPasswordText"} />
          </p>
        </div>

        <div className={"page-forgot-password-card-inputs-wrapper"}>
          <p className="page-forgot-password-subtitle-forgot-pass-text">
            <FormattedMessage id={"card.login.subtitle.forgotPassword"} />
          </p>

          {contentToMap?.map((item, index) => {
            return (
              <div
                className="page-forgot-password-card-inputs-item"
                key={`forgot-password-card-inputs-${index}`}
              >
                <TextInput
                  key={`item--${index}--${item.label}`}
                  label={item?.label}
                  labelColor={"blue"}
                  placeholder={intl.formatMessage({ id: item.placeholder })}
                  name={item?.name}
                  type={item?.type}
                  handleChange={handleChange}
                  select={item.component === "select" ? true : false}
                  component={item.component}
                  handleKeyPress={handleKeyPress}
                  value={formState.values[item.name] || ""}
                  labelStyle="thin"
                />
              </div>
            );
          })}
        </div>

        <div className="page-forgot-password-button-and-text-container">
          {sentEmail && (
            <p className="page-forgot-password-text">
              <FormattedMessage id="forgot.password.sent.email.message" />
            </p>
          )}
          <div className="page-forgot-password-card-button-container">
            <div className="page-forgot-password-card-button-wrapper">
              <ButtonComponent
                buttonText={"card.login.label.forgotBtnPassword.button"}
                color={"blue"}
                onClick={handleForgotPassword}
              />
            </div>

            <p
              className="page-forgot-password-card-back-to-login-text"
              onClick={handleBackToLogin}
            >
              <FormattedMessage id="card.forgotPassword.label.backToLogin.button" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

ForgotPassword.prototype = {
  //TODO fix this patzzz
  intl: PropTypes.shape(),
};

export default injectIntl(ForgotPassword);
