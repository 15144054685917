// CONSTANTS & MOCKS
import classNames from "classnames";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import ContactComponent from "components/ContactComponent/ContactComponent";
import CustomModal from "components/CustomModal/CustomModal";
// LIBRARIES
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// REDUX
import { loginState } from "slices/sessionSlice";
// STYLES
import "./RefactoredConsultingCard.scss";




// COMPONENTS

const RefactoredConsultingCard = (props) => {
  // PROPS
  const {
    topSubtitle = "",
    bottomSubtitle = "",
    text = "",
    image = "",
    number = "",
  } = props;

  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS
  const [showModal, setShowModal] = useState(false);
  const loggedState = useSelector(loginState);
  const navigate = useNavigate();

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleModalContent = () => {
    if (loggedState.loggedIn) {
      return (
        <div style={{ width: "80vw" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={{
                color: "#b7803b",
                fontSize: "2rem",
                marginBottom: ".5rem",
              }}
            >
              HOW CAN WE HELP YOU?
            </p>
            <button
              style={{
                height: "3rem",
                width: "6rem",
                fontSize: "2rem",
                color: "#b7803b",
                border: "none",
                backgroundColor: "transparent",
              }}
              onClick={() => setShowModal(false)}
            >
              Close
            </button>
          </div>
          <ContactComponent />{" "}
        </div>
      );
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="component-refactored-consulting-card-container">
      <div
        className={classNames(
          `component-refactored-consulting-card-wrapper ${
            number % 2 === 0 && "reverse"
          }`
        )}
      >
        {image && (
          <div className="component-refactored-consulting-card-image-container">
            <img
              src={image}
              alt=""
              className="component-refactored-consulting-card-image"
            />
          </div>
        )}

        <div className="component-refactored-consulting-card-details">
          <div className="component-refactored-consulting-card-number-subtitle-wrapper">
            <p className="component-refactored-consulting-card-number">
              {number}.
            </p>
            <p className="component-refactored-consulting-card-top-subtitle">
              <FormattedMessage id={topSubtitle} />
            </p>
          </div>
          <div className="component-refactored-consulting-card-text-wrapper">
            <p className="component-refactored-consulting-card-text">
              <FormattedMessage id={text} />
            </p>
          </div>
          <div className="component-refactored-consulting-card-button-wrapper">
            <ButtonComponent
              buttonText="Seek Advice"
              color="blue"
              selected={true}
              onClick={handleOpenModal}
            />
          </div>
        </div>
      </div>

      <div className="component-refactored-consulting-card-modal-wrapper">
        <CustomModal
          showModal={showModal}
          onClose={() => setShowModal(false)}
          directionTo="top"
          modalContent={"helping-brainers-advice"}
          showIdeaModalContent={handleModalContent}
          ideaModal={true}
        />
      </div>
    </div>
  );
};

RefactoredConsultingCard.propTypes = {
  data: PropTypes.object,
};

export default RefactoredConsultingCard;
