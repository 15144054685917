import React, { useState, useEffect } from "react";

// STYLES
// import "./Step1.scss";

// LIBRARIES
import { Grid } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";

// CONSTANTS & MOCKS
import { step1 } from "../Mocks/registerInputs";
import { DATE_PICKER, SELECT, NDA, LOGO } from "constants/constants";
import countriesList from "constants/countriesList";
import roNdaPartnerFile from "assets/Files/ro-nda-partner.docx";
import roNdaBrainerFile from "assets/Files/ro-nda-brainer.docx";
import enNdaPartnerFile from "assets/Files/en-nda-partner.docx";
import enNdaBrainerFile from "assets/Files/en-nda-brainer.docx";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { createUser } from "api/userApi";
import { registerFlow } from "slices/userSlice";
import { language } from "slices/sessionSlice";
import { userRole } from "slices/sessionSlice";

// COMPONENTS
import TextInput from "components/Atoms/TextInput/TextInput";
import Uploader from "components/Uploader/Uploader";
import NdaCard from "components/NdaCard/NdaCard";
import DatePicker from "components/Atoms/DatePicker/DatePicker";

import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import moment from "moment";

const Step1 = (props) => {
  // PROPS
  const { intl = {}, registerState = {}, setRegisterState = () => {} } = props;

  // CONSTANTS USING LIBRARIES
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const registrationType = useSelector(registerFlow);
  const selectedRole = useSelector(userRole);
  const languageData = useSelector(language);

  // CONSTANTS USING HOOKS
  const [formState, setFormState] = useState({
    values: {},
    files: {
      image: "",
      nda: "",
    },
    terms: false,
    dataCollection: false,
    ageConsent: false,
    errorMsg: "",
  });
  const [uploaded, setUploaded] = useState(false);
  const [ndaUploaded, setNdaUploaded] = useState(false);
  const [showUploaderText, setShowUploaderText] = useState(true);

  // GENERAL CONSTANTS
  const mobileWidth = window.innerWidth <= 600;

  // USE EFFECT FUNCTION
  useEffect(() => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        registrationType: registrationType.userInfo.registrationType,
      },
    }));
    // eslint-disable-next-line
  }, []);

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  const handleErrorMessage = (message) => {
    setFormState((state) => ({
      ...state,
      errorMsg: message,
    }));
    setTimeout(() => {
      setFormState((state) => ({
        ...state,
        errorMsg: "",
      }));
    }, 2000);
  };

  const handleFilterObject = (object) => {
    let include = false;
    Object.keys(object).forEach((key) => {
      if (object[key] === null || object[key] === "") {
        include = true;
      }
    });
    return include;
  };

  const handleTerms = (event) => {
    setFormState((state) => ({
      ...state,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleUpload = (files, type) => {
    let extension = null;
    let documentModel = null;
    if (type === LOGO) {
      extension = files[0].type.split("/");

      documentModel = {
        type: LOGO,
        file: files[0],
        fileName: files[0].name,
        fileExtension: extension[1],
      };

      let reader = new FileReader();

      reader.readAsDataURL(documentModel.file);
      reader.onload = function () {
        setRegisterState((state) => ({
          ...state,
          userFiles: {
            ...formState.userFiles,
            image: documentModel,
            previewImg: reader.result,
          },
        }));
        setUploaded(true);
        setShowUploaderText(false);
      };
    } else {
      extension = files[0].type.split("/");

      documentModel = {
        type: LOGO,
        file: files[0],
        fileName: files[0].name,
        fileExtension: extension[1],
      };

      setRegisterState((state) => ({
        ...state,
        userFiles: {
          ...state.userFiles,
          nda: documentModel,
        },
      }));

      setNdaUploaded(true);
    }
  };

  const handleRegister = () => {
    if (formState.dataCollection && formState.terms && formState.ageConsent) {
      if (
        handleFilterObject(registerState.userInfo) ||
        handleFilterObject(registerState.userFiles)
      ) {
        handleErrorMessage("Please complete all fields !");
      } else {
        let formData = new FormData();
        let logoFile = new File(
          [registerState.userFiles.image.file],
          registerState.userFiles.image.file.name,
          {
            type: "LOGO",
          }
        );
        formData.append("files", logoFile);
        let ndaFile = new File(
          [registerState.userFiles.nda.file],
          registerState.userFiles.nda.file.name,
          {
            type: "SIGN_UP_NDA",
          }
        );
        formData.append("files", ndaFile);
        formData.append(
          "registrationModel",
          JSON.stringify(registerState.userInfo)
        );
        dispatch(createUser(formData))
          .unwrap()
          .catch((err) => {
            setFormState((state) => ({
              ...state,
              errorMsg: err.errors[0],
            }));
          });
      }
    } else {
      handleErrorMessage("You did not accepted Terms and Conditions !");
    }
  };

  const handleChange = (event) => {
    setRegisterState((state) => ({
      ...state,
      userInfo: {
        ...state.userInfo,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleDate = (event) => {
    setRegisterState((state) => ({
      ...state,
      userInfo: {
        ...state.userInfo,
        dateOfBirth: moment(event).format("yyyy-MM-DD"),
      },
    }));
  };

  const handleDownloadFile = () => {
    const link = document.createElement("a");

    const partnerFileByLang =
      languageData === "ro" ? roNdaPartnerFile : enNdaPartnerFile;
    const brainerFileByLang =
      languageData === "ro" ? roNdaBrainerFile : enNdaBrainerFile;

    link.href =
      selectedRole === "PARTNER" ? partnerFileByLang : brainerFileByLang;
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const ageRestriction = new Date().setFullYear(new Date().getFullYear() - 14);

  return (
    <Grid container className={"page-RegisterPage-card-inputs-wrapper"}>
      {step1?.map((item, index) => {
        return (
          <Grid
            item
            xs={12}
            md={6}
            sm={6}
            className={"page-RegisterPage-card-item"}
            key={`item--${index}--${item.label}`}
          >
            {(item.component === "textInput" ||
              item.component === "select") && (
              <TextInput
                label={item?.label}
                labelColor={"blue"}
                placeholder={intl.formatMessage({ id: item.placeholder })}
                name={item?.name}
                type={item?.type}
                handleChange={handleChange}
                selectValues={countriesList}
                select={item.component === "select" ? true : false}
                component={item.component}
                value={registerState.userInfo[item.name] || ""}
                mandatory={item.mandatory}
              />
            )}

            {item.component === DATE_PICKER && (
              <DatePicker
                label={item?.label}
                labelColor={"blue"}
                placeholder={intl.formatMessage({ id: item.placeholder })}
                name={item?.name}
                type={item?.type}
                handleChange={handleDate}
                selectValues={countriesList}
                maxDate={ageRestriction}
                select={item.component === SELECT ? true : false}
                component={item.component}
                value={registerState.userInfo[item.name] || ""}
              />
            )}
          </Grid>
        );
      })}
      <Grid
        item
        container
        xs={12}
        className={"page-RegisterPage-document-upload-wrapper"}
      >
        <Grid
          item
          xs={12}
          md={6}
          sm={6}
          className={"page-RegisterPage-document-upload-item"}
        >
          <div className="page-RegisterPage-document-upload-label">
            <p className="uploader-label">
              Profile picture <span> *</span>
            </p>
            <Uploader
              onDrop={(event) => handleUpload(event, LOGO)}
              edit={uploaded}
              previewImg={registerState?.userFiles?.previewImg}
              showText={showUploaderText}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sm={6}
          className={"page-RegisterPage-document-upload-item"}
        >
          <NdaCard
            upload={(event) => handleUpload(event, NDA)}
            ndaUploaded={ndaUploaded}
            handleDownloadFile={handleDownloadFile}
          />
        </Grid>

        <Grid item xs={12} className={"page-RegisterPage-terms-wrapper"}>
          <div className={"page-RegisterPage-terms-item"}>
            <Checkbox name={"terms"} onChange={handleTerms} />
            <p className="page-RegisterPage-terms-text">
              <Link to="/terms" target="_blank">
                <span className="page-RegisterPage-terms-span-text">
                  <FormattedMessage id={"page.register.terms.agree.text"} />
                </span>
              </Link>

              <FormattedMessage id={"page.register.terms.text"} />
            </p>
          </div>
          <div className={"page-RegisterPage-terms-item"}>
            <Checkbox name={"dataCollection"} onChange={handleTerms} />
            <p className="page-RegisterPage-terms-text">
              <FormattedMessage id={"page.register.collecting.data.text"} />
            </p>
          </div>
          <div className={"page-RegisterPage-terms-item"}>
            <Checkbox name={"ageConsent"} onChange={handleTerms} />
            <p className="page-RegisterPage-terms-text">
              <FormattedMessage id={"page.register.age.consent"} />
            </p>
          </div>
          <div className="page-RegisterPage-error-message-wrapper">
            <p className="error-message-text">{formState.errorMsg}</p>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} className={"page-RegisterPage-buttons-wrapper"}>
        <ButtonComponent
          buttonText={"page.register.nextButton"}
          onClick={handleRegister}
          selected={mobileWidth && true}
          color="gold"
        />
      </Grid>
    </Grid>
  );
};

Step1.prototype = {
  intl: PropTypes.shape(),
  userInfoState: PropTypes.shape(),
  registerState: PropTypes.shape(),
  setRegisterState: PropTypes.func,
  selectedRole: PropTypes.string,
};

export default injectIntl(Step1);
