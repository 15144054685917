import React, { useEffect, useState } from "react";

// STYLES
import "./ProceedToPayForViewIdea.scss";

// LIBRARIES
import { FormattedMessage } from "react-intl";
import { loadStripe } from "@stripe/stripe-js";

// CONSTANTS & MOCKS

// REDUX
import { savePaymentPayload } from "slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  currentBrainerFees,
  handleChangeCurrentBrainerFees,
} from "slices/sessionSlice";

// COMPONENTS
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import { decodeJwtToken } from "helpers/decoder";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import { paymentVerify } from "api/projectApi";

const ProceedToPayForViewIdea = () => {
  // PROPS

  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectId, emailAddress } = useParams();
  const currentBrainerFeesData = useSelector(currentBrainerFees);

  // CONSTANTS USING HOOKS
  const [emailAddressVerified, setEmailAddressVerified] = useState(false);
  const [paymentAlreadyDone, setPaymentAlreadyDone] = useState(false);

  // GENERAL CONSTANTS
  const payToViewProject = {
    price: "price_1L989SECdTSmedexKOvcZP89",
    quantity: 1,
  };

  const checkoutOptions = {
    lineItems: [payToViewProject],
    mode: "payment",
    successUrl: `${window.location.origin}/payment-success`,
    cancelUrl: `${window.location.origin}/payment-cancel`,
  };

  let stripePromise;

  // USE EFFECT FUNCTION
  useEffect(() => {
    verifyPayment();
    // eslint-disable-next-line
  }, [emailAddress]);

  // REQUEST API FUNCTIONS
  const verifyPayment = () => {
    dispatch(paymentVerify(projectId))
      .unwrap()
      .then((answer) => {
        if (answer === true) {
          setPaymentAlreadyDone(true);
        } else {
          setEmailAddressVerified(false);
          if (emailAddress === decodeJwtToken().sub) {
            setEmailAddressVerified(true);
          } else {
            setEmailAddressVerified(false);
          }
        }
      });
  };

  const redirectToCheckout = async () => {
    let successPayload = {
      projectId: projectId,
      product: "VIEW",
      emailAddress: decodeJwtToken().sub,
    };

    localStorage.setItem("paymentPayload", JSON.stringify(successPayload));
    dispatch(savePaymentPayload(successPayload));

    const stripe = await getStripe();
    await stripe.redirectToCheckout(checkoutOptions);
  };

  // HANDLERS FUNCTIONS
  const getStripe = () => {
    if (!stripePromise) {
      stripePromise = loadStripe(
        "pk_test_51KxUmbECdTSmedexNh7rtoUWOad618nZ7HEeaMOnlkanqihsP4rqGXFsrPraDZ33k1bFWcHWEYAocu3uDbV9tpWC00cIYgSSsO"
      );
    }
    return stripePromise;
  };

  const handleNavigate = (path) => {
    dispatch(handleChangeCurrentBrainerFees("NULL"));
    navigate(path);
  };

  return (
    <div className="page-proceed-to-pay-for-view-ideea-container">
      {!paymentAlreadyDone && emailAddressVerified && (
        <div className="component-modal-payment-required-content-container">
          <div className="component-modal-payment-required-content-title-subtitle">
            <p className="title">
              <FormattedMessage id={"modal.payment.required.content.title"} />
            </p>
            <p className="subtitle">
              <FormattedMessage
                id={"modal.payment.required.content.subtitle"}
              />
            </p>
          </div>
          <div className="component-modal-payment-required-content-number">
            <p className="number">{currentBrainerFeesData}</p>
          </div>
          <div className="buttons">
            <ButtonComponent
              color="blue"
              buttonText={"modal.payment.required.content.decline.button"}
              onClick={() => handleNavigate("/")}
            />
            <ButtonComponent
              color="blue"
              buttonText={"modal.payment.required.content.accept.button"}
              onClick={redirectToCheckout}
            />
          </div>
        </div>
      )}
      {!paymentAlreadyDone && !emailAddressVerified && (
        <div
          className={classNames(
            "component-modal-payment-required-content-container",
            "marginBottom"
          )}
        >
          <div className="component-modal-payment-required-content-title-subtitle">
            <p className="title">
              <FormattedMessage
                id={"modal.payment.required.mail.unverified.content.title"}
              />
            </p>
            <p className="subtitle">
              <FormattedMessage
                id={"modal.payment.required.mail.unverified.content.subtitle"}
              />
            </p>
          </div>
        </div>
      )}
      {paymentAlreadyDone && (
        <div
          className={classNames(
            "component-modal-payment-required-content-container",
            "marginBottom"
          )}
        >
          <div className="component-modal-payment-required-content-title-subtitle">
            <p className="title">
              <FormattedMessage
                id={"modal.payment.required.payment.already.done.content.title"}
              />
            </p>
            <p className="subtitle">
              <FormattedMessage
                id={
                  "modal.payment.required.payment.already.done.content.subtitle"
                }
              />
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
export default ProceedToPayForViewIdea;
