import React, { useState } from "react";

// STYLES
import "./PartnersMeetingModalContent.scss";

// LIBRARIES
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import TextInput from "components/Atoms/TextInput/TextInput";
import { rejectRequest } from "api/adminApi";
import { useDispatch, useSelector } from "react-redux";
import {
  handleSaveRequestId,
  paginationDetails,
  requestDetails,
} from "slices/adminSlice";
import { handleTableDataRequest } from "helpers/adminTablesFunctions";
import { AdminTabs, RequestStatus } from "constants/constants";

const RejectProjectRequestContent = (props) => {
  // PROPS
  const { setShowModal = () => {}, type = "" } = props;

  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch();
  const paginationData = useSelector(paginationDetails);
  const requestDetailsData = useSelector(requestDetails);

  // CONSTANTS USING HOOKS
  const [rejectRequestReason, setRejectRequestReason] = useState("");

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  const handleRejectRequest = () => {
    dispatch(
      rejectRequest({
        requestId: requestDetailsData.requestId,
        rejectRequestReason,
      })
    )
      .unwrap()
      .then(() => {
        switch (type) {
          case "VIEW":
            handleTableDataRequest(
              { mainTab: AdminTabs.VIEW_PROJECT_REQUESTS, secondaryTab: RequestStatus.PENDING },
              paginationData
            );
            break;
          case "IDEA":
            handleTableDataRequest(
              { mainTab: AdminTabs.PROJECT_REQUESTS, secondaryTab: RequestStatus.PENDING },
              paginationData
            );
            break;
          default:
            break;
        }
        dispatch(handleSaveRequestId(null));
        setShowModal(false);
      });
  };

  return (
    <div className="component-modal-validation-project-container">
      <p className="component-modal-validation-project-title">
        {type === "VIEW" && (
          <FormattedMessage id="modal.meeting.project.view.request.reject.title" />
        )}
        {type === "IDEA" && (
          <FormattedMessage id="modal.meeting.project.request.reject.title" />
        )}
      </p>
      <p className="component-modal-validation-project-text">
        {type === "VIEW" && (
          <FormattedMessage id="modal.meeting.project.view.request.reject.text" />
        )}
        {type === "IDEA" && (
          <FormattedMessage id="modal.meeting.project.request.reject.text" />
        )}
      </p>

      <div className="reject-reason-wrapper">
        <TextInput
          labelColor="blue"
          placeholder="Please argue your reject reason..."
          minRows={15}
          multiline={true}
          handleChange={(event) => setRejectRequestReason(event.target.value)}
          value={rejectRequestReason}
        />
      </div>

      <div className="component-modal-validation-project-buttons">
        <ButtonComponent
          buttonText="Cancel"
          color="blue"
          onClick={() => setShowModal(false)}
        />
        <ButtonComponent
          buttonText="Yes"
          color="blue"
          onClick={handleRejectRequest}
        />
      </div>
    </div>
  );
};

RejectProjectRequestContent.propTypes = {
  setShowModal: PropTypes.func,
  type: PropTypes.string,
};

export default RejectProjectRequestContent;
