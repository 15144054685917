import React from "react";

// STYLES
import "./ModalContent.scss";

// LIBRARIES
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

// CONSTANTS & MOCKS

// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  currentBrainerFees,
  handleChangeCurrentBrainerFees,
} from "slices/sessionSlice";

// COMPONENTS
import ButtonComponent from "components/ButtonComponent/ButtonComponent";

const ProjectUploadedContent = (props) => {
  // PROPS

  // CONSTANTS USING LIBRARYS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentBrainerFeesData = useSelector(currentBrainerFees);

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  const handleNavigate = (path) => {
    dispatch(handleChangeCurrentBrainerFees("NULL"));
    navigate(path);
  };

  return (
    <div className="component-modal-project-uploaded-container">
      <p className="title">
        <FormattedMessage id={"modal.validation.project.title"} />
      </p>
      <p className="text">
        <FormattedMessage id={"modal.validation.project.text"} />
      </p>
      <p className="number">{currentBrainerFeesData}</p>

      <div className="buttons">
        <ButtonComponent
          color="blue"
          buttonText={"modal.validation.project.go.to.home.button"}
          onClick={() => handleNavigate("/homepage")}
        />
        <ButtonComponent
          color="blue"
          buttonText={"modal.validation.project.go.to.profile.button"}
          onClick={() => handleNavigate("/profile-page")}
        />
      </div>
    </div>
  );
};

ProjectUploadedContent.propTypes = {
  setShowModal: PropTypes.func,
  showNumber: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  showSecondButton: PropTypes.bool,
  secondButtonText: PropTypes.string,
};

export default ProjectUploadedContent;
