import {
  getInvestmentReport,
  getInvestmentRequest,
  getPartnersRequest,
  getProjectApproval,
  getProjectRequests,
  getSignUpRequests,
} from "api/adminApi";
import { AdminTabs } from "constants/constants";

import store from "store/store";

export const handleAdminTableData = (data, type) => {
  let localMockList = [];
  let localKeys = [];
  let localRowCellList = [];
  let localCell = {};

  data?.length > 0 &&
    data?.forEach((rowItem, index) => {
      if (type === AdminTabs.PROJECT_APPROVAL) {
        localKeys = [
          "projectName",
          "submissionBy",
          "emailAddress",
          "categoryName",
        ];
      } else if (type === AdminTabs.PARTNERS_MEETING) {
        localKeys = [
          "requestFromEmail",
          "requestFromName",
          "requestToEmail",
          "requestToName",
        ];
      } else if (type === AdminTabs.INVESTMENT) {
        localKeys = ["partnerName", "projectName", "partnerPledgedFunds"];
      } else if (type === AdminTabs.INVESTMENT_REPORT) {
        localKeys = [
          "partnerName",
          "partnerEmail",
          "country",
          "moneyPledged",
          "projectTotalViews",
          "partnershipsClosed",
        ];
      } else if (type === AdminTabs.PROJECT_REQUESTS) {
        localKeys = [
          "brainPartnerName",
          "brainPartnerEmail",
          "projectName",
          "nda",
          "agreement",
        ];
      } else if (type === AdminTabs.BRAINER_SIGN_UP_REQUESTS || type === AdminTabs.PARTNER_SIGN_UP_REQUESTS) {
        localKeys = [
          "brainPartnerName",
          "brainPartnerEmail",
          "signUpNda",
          "agreement",
        ];
      }
      localRowCellList = [];

      Object.entries(rowItem && rowItem).forEach((key, value) => {
        if (localKeys.includes(key[0])) {
          localCell = {
            name: key[1],
            type: "text",
            action: () => {},
          };

          if (key[0] === "nda") {
            localCell = {
              ...localCell,
              documentType: key[0],
            };
          }

          if (key[0] === "agreement") {
            localCell = {
              ...localCell,
              documentType: key[0],
            };
          }

          if (key[0] === "signUpNda") {
            localCell = {
              ...localCell,
              documentType: key[0],
            };
          }

          localRowCellList.push(localCell);
        }
      });

      let localRow = {};

      switch (type) {
        case AdminTabs.PROJECT_APPROVAL:
          localRow = {
            id: rowItem.projectId,
            row: localRowCellList,
          };
          break;
        case AdminTabs.PROJECT_REQUESTS:
        case AdminTabs.BRAINER_SIGN_UP_REQUESTS:
        case AdminTabs.PARTNER_SIGN_UP_REQUESTS:
          localRow = {
            id: rowItem.requestId,
            row: localRowCellList,
          };
          break;
        default:
          localRow = {
            id: rowItem.id,
            row: localRowCellList,
          };
          break;
      }

      localMockList.push(localRow);
    });

  return localMockList;
};

export const handleTableDataRequest = (selectedTabsData, paginationData) => {
  switch (selectedTabsData.mainTab) {
    case AdminTabs.BRAINER_SIGN_UP_REQUESTS:
      let brainerSignUpRequests = {
        signUpRequestStatus: selectedTabsData.secondaryTab,
        pageNumber: paginationData.pageNumber,
        pageSize: paginationData.pageSize,
        role: "BRAINER"
      };
      store.dispatch(getSignUpRequests(brainerSignUpRequests));
      break;
    case AdminTabs.PARTNER_SIGN_UP_REQUESTS:
      let partnerSignUpRequests = {
        signUpRequestStatus: selectedTabsData.secondaryTab,
        pageNumber: paginationData.pageNumber,
        pageSize: paginationData.pageSize,
        role: "PARTNER"
      };
      store.dispatch(getSignUpRequests(partnerSignUpRequests));
      break;
    case AdminTabs.PROJECT_REQUESTS:
    case AdminTabs.VIEW_PROJECT_REQUESTS:
      let projectRequests = {
        status: selectedTabsData.secondaryTab,
        requestType:
          selectedTabsData.mainTab === AdminTabs.PROJECT_REQUESTS
            ? "IDEA"
            : "VIEW",
        pageNumber: paginationData.pageNumber,
        pageSize: paginationData.pageSize,
      };
      store.dispatch(getProjectRequests(projectRequests));
      break;
    case AdminTabs.PROJECT_APPROVAL:
      let projectApprovalPayload = {
        pageNumber: paginationData.pageNumber,
        pageSize: paginationData.pageSize,
      };
      store.dispatch(getProjectApproval(projectApprovalPayload));
      break;
    case AdminTabs.INVESTMENT:
      if (selectedTabsData.secondaryTab === AdminTabs.INVESTMENT_REPORT) {
        store.dispatch(
          getInvestmentReport({
            pageNumber: paginationData.pageNumber,
            pageSize: paginationData.pageSize,
          })
        );
      } else if (
        selectedTabsData.secondaryTab === AdminTabs.INVESTMENT_REQUEST ||
        selectedTabsData.secondaryTab === AdminTabs.DEALS_DONE
      ) {
        let investmentPayload = {
          type: selectedTabsData.secondaryTab,
          pageNumber: paginationData.pageNumber,
          pageSize: paginationData.pageSize,
        };
        store.dispatch(getInvestmentRequest(investmentPayload));
      }
      break;
    case AdminTabs.PARTNERS_MEETING:
      let parnersPayload = {
        pageNumber: paginationData.pageNumber,
        pageSize: paginationData.pageSize,
      };
      store.dispatch(getPartnersRequest(parnersPayload));
      break;
    default:
      break;
  }
};
