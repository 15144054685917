import React from "react";

// STYLES
import "./IdeaCard.scss";

// LIBRARIES
import PropTypes from "prop-types";
import classNames from "classnames";
// CONSTANTS & MOCKS

// REDUX

// COMPONENTS
import submitIdeaOne from "assets/pictures/submitIdeaOne.webp";
import submitIdeaTwo from "assets/pictures/submitIdeaTwo.webp";
import submitIdeaThree from "assets/pictures/submitIdeaThree.webp";

const IdeaCard = (props) => {
  // PROPS
  const {
    title = "",
    subtitle = "",
    text = "",
    number = "",
    index = 0,
  } = props;
  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS
  const isReversed = index % 2 === 0 ? false : true;
  const imageArr = [submitIdeaOne, submitIdeaTwo, submitIdeaThree];
  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div
      className={classNames(
        `component-idea-card-container  ${isReversed && "idea-card-reverse"} `
      )}
    >
      <div className="component-idea-card-image-wrapper">
        <img
          className="component-idea-card-image"
          src={imageArr[index]}
          alt=""
        />
      </div>
      <div className="component-idea-card-content-container">
        <div className="component-idea-card-title-container">
          <div className="component-idea-card-number-wrapper">
            <p className="component-idea-card-number">{number}</p>
          </div>
          <div className="component-idea-card-title-text-container">
            <div className="component-idea-card-title-wrapper">
              <p className="component-idea-card-title">{title}</p>
            </div>
            {/* <div className="component-idea-card-subtitle-wrapper">
              <p className="component-idea-card-subtitle">{subtitle}</p>
            </div> */}
          </div>
        </div>
        <div className="component-idea-card-text-wrapper">
          <p className="component-idea-card-text">{text}</p>
        </div>
      </div>
    </div>
  );
};

IdeaCard.propTypes = {
  titleInvisible: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  number: PropTypes.string,
  index: PropTypes.number,
  isTitle: PropTypes.bool,
};

export default IdeaCard;
