import { downloadReport, getProjectApproval } from "api/adminApi";
import AdminCategoriesListComponent from "components/AdminCategoriesListComponent/AdminCategoriesListComponent";
import AdminDownloadButton from "components/AdminDownloadButton/AdminDownloadButton";
import AdminTableComponent from "components/AdminTableComponent/AdminTableComponent";
// COMPONENTS
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import InvestmentSubcategories from "components/InvestmentSubcategories/InvestmentSubcategories";
import RequestsSubcategories from "components/RequestsSubcategories/RequestsSubcategories";
import { AdminTabs } from "constants/constants";
// LIBRARIES
import { saveAs } from "file-saver";
import { handleTableDataRequest } from "helpers/adminTablesFunctions";
import React, { useEffect } from "react";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  handleChangePage, handleSelectAdminTab, investmentContent,
  paginationDetails,
  partnersMeetingContent,
  projectApprovalContent, projectRequestContent, selectedTabs, signUpRequestsContent
} from "slices/adminSlice";
// STYLES
import "./AdminPage.scss";
// CONSTANTS & MOCKS
import {
  buttonName, investmentClosedDealsHeader, investmentReportHeader, investmentTableHeader, partnersMeetingTableHeader, projectApprovalTableHeader, projectRequestTableHeader,
  signUpRequestsTableHeader
} from "./Mocks/AdminPageMocks";






const AdminPage = () => {
  // PROPS

  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch();
  const projectApprovalData = useSelector(projectApprovalContent);
  const projectRequestData = useSelector(projectRequestContent);
  const signUpRequestData = useSelector(signUpRequestsContent);
  const investmentData = useSelector(investmentContent);
  const partnersMeetingData = useSelector(partnersMeetingContent);
  const paginationData = useSelector(paginationDetails);
  const selectedTabsData = useSelector(selectedTabs);

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION
  useEffect(() => {
    handleAction();
    handleTableHeader();
    handleTableData();
    // eslint-disable-next-line
  }, [selectedTabsData]);

  useEffect(() => {
    handleTableHeader();
    handleTableData();
    dispatch(
      getProjectApproval({
        pageNumber: paginationData.pageNumber,
        pageSize: paginationData.pageSize,
      })
    );
    // eslint-disable-next-line
  }, []);

  // REQUEST API FUNCTIONS
  const handleAction = () => {
    handleTableDataRequest(selectedTabsData, paginationData);
  };

  // HANDLERS FUNCTIONS
  const handleSelectTab = (item) => {
    let pagePayload = {
      pageNumber: 0,
      pageSize: 10,
      totalCount: 0,
    };
    dispatch(handleChangePage(pagePayload));
    dispatch(handleSelectAdminTab({ value: item.clickDetail, type: "main" }));
  };

  const handleTableData = () => {
    if (selectedTabsData.mainTab === AdminTabs.INVESTMENT) {
      switch (selectedTabsData.secondaryTab) {
        case AdminTabs.INVESTMENT_REQUEST:
          return investmentData;
        case AdminTabs.DEALS_DONE:
          return investmentData;
        case AdminTabs.INVESTMENT_REPORT:
          return investmentData;
        default:
          break;
      }
    } else {
      switch (selectedTabsData.mainTab) {
        case AdminTabs.BRAINER_SIGN_UP_REQUESTS:
        case AdminTabs.PARTNER_SIGN_UP_REQUESTS:
          return signUpRequestData;
        case AdminTabs.PROJECT_REQUESTS:
        case AdminTabs.VIEW_PROJECT_REQUESTS:
          return projectRequestData;
        case AdminTabs.PROJECT_APPROVAL:
          return projectApprovalData;
        case AdminTabs.INVESTMENT:
          return investmentData;
        case AdminTabs.PARTNERS_MEETING:
          return partnersMeetingData;
        case AdminTabs.INVESTMENT_REPORT:
          return investmentData;
        default:
          return projectApprovalData;
      }
    }
  };

  const handleTableHeader = () => {
    if (selectedTabsData.mainTab === AdminTabs.INVESTMENT) {
      switch (selectedTabsData.secondaryTab) {
        case AdminTabs.INVESTMENT_REQUEST:
          return investmentTableHeader;
        case AdminTabs.DEALS_DONE:
          return investmentClosedDealsHeader;
        case AdminTabs.INVESTMENT_REPORT:
          return investmentReportHeader;
        default:
          return projectApprovalTableHeader;
      }
    } else {
      switch (selectedTabsData.mainTab) {
        case AdminTabs.BRAINER_SIGN_UP_REQUESTS:
        case AdminTabs.PARTNER_SIGN_UP_REQUESTS:
          return signUpRequestsTableHeader;
        case AdminTabs.PROJECT_REQUESTS:
        case AdminTabs.VIEW_PROJECT_REQUESTS:
          return projectRequestTableHeader;
        case AdminTabs.PROJECT_APPROVAL:
          return projectApprovalTableHeader;
        case AdminTabs.PARTNERS_MEETING:
          return partnersMeetingTableHeader;
        default:
          return projectApprovalTableHeader;
      }
    }
  };

  const handleDownloadReport = () => {
    switch (selectedTabsData.secondaryTab) {
      case AdminTabs.INVESTMENT_REQUEST:
        dispatch(
          downloadReport({
            path: "REQUEST",
            pageSize: paginationData?.pageSize,
            pageNumber: paginationData?.pageNumber,
          })
        ).then((answer) => {
          const file = new Blob([answer.data], {
            type: "application/vnd.ms-excel",
          });
          saveAs(file, "INVESTMENT_REQUEST Report");
        });
        break;
      case AdminTabs.DEALS_DONE:
        dispatch(
          downloadReport({
            path: "DEALS_DONE",
            pageSize: paginationData?.pageSize,
            pageNumber: paginationData?.pageNumber,
          })
        ).then((answer) => {
          const file = new Blob([answer], {
            type: "application/vnd.ms-excel",
          });
          saveAs(file, "INVESTMENT_REQUEST Report");
        });
        break;
      case AdminTabs.INVESTMENT_REPORT:
        dispatch(
          downloadReport({
            path: "REPORT",
            pageSize: paginationData?.pageSize,
            pageNumber: paginationData?.pageNumber,
          })
        ).then((answer) => {
          const file = new Blob([answer], {
            type: "application/vnd.ms-excel",
          });
          saveAs(file, "INVESTMENT_REQUEST Report");
        });
        break;
      default:
        dispatch(
          downloadReport({
            path: "REQUEST",
            pageSize: paginationData?.pageSize,
            pageNumber: paginationData?.pageNumber,
          })
        ).then((answer) => {
          const file = new Blob([answer], {
            type: "application/vnd.ms-excel",
          });
          saveAs(file, "INVESTMENT_REQUEST Report");
        });
        break;
    }
  };

  return (
    <div className="page-admin-container">
      <div className="page-admin-buttons">
        {buttonName?.map((tab, index) => {
          return (
            <div className="buttons" key={`key--admin-button ${index}`}>
              <ButtonComponent
                isInvesting={tab?.action === "getInvestment" && true}
                buttonText={tab?.buttonText}
                color={tab?.color}
                onClick={() => handleSelectTab(tab)}
                selected={tab?.clickDetail === selectedTabsData?.mainTab}
              />
            </div>
          );
        })}
      </div>
      {selectedTabsData?.mainTab === AdminTabs.INVESTMENT && (
        <div className="page-admin-investment-subcategories">
          <InvestmentSubcategories />
        </div>
      )}
      {(selectedTabsData?.mainTab === AdminTabs.BRAINER_SIGN_UP_REQUESTS ||
        selectedTabsData?.mainTab === AdminTabs.PARTNER_SIGN_UP_REQUESTS ||
        selectedTabsData?.mainTab === AdminTabs.PROJECT_REQUESTS ||
        selectedTabsData?.mainTab === AdminTabs.VIEW_PROJECT_REQUESTS) && (
        <div className="page-admin-investment-subcategories">
          <RequestsSubcategories />
        </div>
      )}
      {/* {selectedTabsData?.mainTab !== AdminTabs.CATEGORY_LIST && (
        <div className="page-admin-search">
          <SearchComponent hide="hide" big="big" />
        </div>
      )} */}
      <div className="page-admin-content">
        {selectedTabsData?.mainTab === AdminTabs.CATEGORY_LIST && (
          <AdminCategoriesListComponent />
        )}
        {selectedTabsData?.mainTab !== AdminTabs.CATEGORY_LIST && (
          <div style={{ marginTop: "5rem" }}>
            <AdminTableComponent
              header={handleTableHeader()}
              data={handleTableData()}
              handleAction={handleAction}
              status={
                selectedTabsData.mainTab === AdminTabs.PROJECT_REQUESTS ||
                selectedTabsData.mainTab === AdminTabs.VIEW_PROJECT_REQUESTS ||
                selectedTabsData.mainTab ===
                  AdminTabs.BRAINER_SIGN_UP_REQUESTS ||
                selectedTabsData.mainTab === AdminTabs.PARTNER_SIGN_UP_REQUESTS
                  ? selectedTabsData.secondaryTab
                  : false
              }
            />
          </div>
        )}
      </div>

      {selectedTabsData?.mainTab === AdminTabs.INVESTMENT && (
        <div
          className="page-admin-download-button"
          onClick={handleDownloadReport}
        >
          <AdminDownloadButton title={"admin.page.download.button.text"} />
        </div>
      )}
    </div>
  );
};
export default AdminPage;
