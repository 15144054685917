import advertisementImageBrainer from "assets/components/advertisementImages/advertisementImageBrainer.svg";
import advertisementImagePartner from "assets/components/advertisementImages/advertisementImagePartner.svg";

export const cardData = [
  {
    id: 1,
    title: "brainercard.advertisment.card.title",
    description: "brainercard.advertisment.card.description",
    buttonText: "brainercard.advertisment.button.text",
    image: advertisementImageBrainer,
    reverse: false,
    page: "consulting",
  },
  {
    id: 2,
    title: "meetingcard.advertisment.card.title",
    description: "meetingcard.advertisment.card.description",
    buttonText: "meetingcard.advertisment.button.text",
    image: advertisementImagePartner,
    reverse: true,
    page: "consulting",
  },
];
