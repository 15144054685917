import React, { useEffect, useState } from "react";

// STYLES
import "./TrendingProjects.scss";
// LIBRARIES
import { useNavigate } from "react-router-dom";

// CONSTANTS & MOCKS

// REDUX
import { useDispatch } from "react-redux";
import { getTrendingProjects } from "api/exploreApi";

// COMPONENTS
import PartnerProjectCard from "components/PartnerProjectCard/PartnerProjectCard";
import { tabletResolutionHigh } from "constants/constants";
import { FormattedMessage } from "react-intl";

const TrendingProjects = () => {
  // PROPS

  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // CONSTANTS USING HOOKS
  const [trendingProjectsList, setTrendingProjectsList] = useState([]);

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION
  useEffect(() => {
    dispatch(getTrendingProjects())
      .unwrap()
      .then((answer) => {
        setTrendingProjectsList(answer);
      });
    // eslint-disable-next-line
  }, []);

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className="component-trending-projects-container">
      <div className="component-trending-projects-title-subtitle-wrapper">
        <p className="title">
          <FormattedMessage id="component.trending.projects.title" />
        </p>
        <p className="subtitle">
          <FormattedMessage id="component.trending.projects.subtitle" />
        </p>
      </div>
      <div className="component-trending-projects-project-cards-wrapper">
        {trendingProjectsList?.map((card, index) => {
          if (index && index < 3 && window.innerWidth < tabletResolutionHigh) {
            return (
              <div
                key={`trending -project--${card?.projectId}`}
                onClick={() => {
                  navigate(`/view-idea/${card?.projectId}`);
                }}
                className={"component-trending-projects-project-card-item"}
              >
                <PartnerProjectCard project={card} profile={false} />
              </div>
            );
          } else if (window.innerWidth > tabletResolutionHigh) {
            return (
              <div
                key={`trending -project--${card?.projectId}`}
                onClick={() => {
                  navigate(`/view-idea/${card?.projectId}`);
                }}
                className={"component-trending-projects-project-card-item"}
              >
                <PartnerProjectCard project={card} profile={false} />
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};

export default TrendingProjects;
