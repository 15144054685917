import React, { useEffect, useState } from "react";

// STYLES
import "./PartnersMeeting.scss";

// LIBRARIES

// CONSTANTS & MOCKS
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { getSearchPartners } from "api/exploreApi";
import { partnersMeetingContent } from "slices/exploreSlice";

// COMPONENTS
import PartnersMeetingSearchComponent from "components/PartnersMeetingSearchComponent/PartnersMeetingSearchComponent";
import PartnerCard from "components/PartnerCard/PartnerCard";
import { FormattedMessage } from "react-intl";

const PartnersMeeting = () => {
  // PROPS

  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch();
  const meetingContent = useSelector(partnersMeetingContent);

  // CONSTANTS USING HOOKS
  const [searchState, setSearchState] = useState({
    values: {
      pageNumber: 0,
      pageSize: 10,
      nameStartsWith: "",
      country: "",
    },
  });

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION
  useEffect(() => {
    getContent();
    // eslint-disable-next-line
  }, []);

  // REQUEST API FUNCTIONS

  const getContent = () => {
    dispatch(
      getSearchPartners({
        name: searchState?.values?.nameStartsWith,
        country: searchState?.values?.country,
        pageNumber: searchState?.values?.pageNumber,
        pageSize: searchState?.values?.pageSize,
      })
    );
  };

  // HANDLERS FUNCTIONS
  return (
    <div className="page-partners-meeting-container">
      <div className="page-partners-search-container">
        <PartnersMeetingSearchComponent
          searchState={searchState}
          setSearchState={setSearchState}
          getContent={getContent}
        />
      </div>
      <div className="page-partners-cards-first-container">
        <p className="page-partners-cards-first-title">
          <FormattedMessage id="partners.meeting.partners.text" />
        </p>
        {meetingContent?.items?.map((countryObj, index) => {
          return (
            <div
              className={"partner-meeting-partners-list-wrapper"}
              key={`items--${countryObj.country}`}
            >
              <div className="page-partners-cards-first-title-container">
                <p className="page-partners-cards-first-subtitle">
                  <FormattedMessage id="partners.meeting.partners.from" />
                  {countryObj?.country}
                </p>
              </div>
              <div className="page-partners-cards-first-wrapper">
                {countryObj?.partnersSearchModels?.map((partner) => {
                  return (
                    <PartnerCard
                      key={partner.fullName}
                      image={partner.documentModel.content}
                      profilePictureActive={false}
                      fullName={partner.fullName}
                      companyName={partner.companyName}
                      title={partner.fullName}
                      aboutMe={partner.aboutMe}
                      email={partner.emailAddress}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PartnersMeeting;
