import React from "react";

// STYLES
import "./RequestProjectCard.scss";

// LIBRARIES
import PropTypes from "prop-types";
import moment from "moment";

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS

const RequestProjectCard = (props) => {
  // PROPS
  const { requestData = {} } = props;

  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className="component-request-project-card-container">
      <div className="project-name">{requestData.projectName}</div>
      <div className="project-date">Created at: {moment(requestData.createdAt).format("DD.MM.YYYY")}</div>
    </div>
  );
};
RequestProjectCard.propTypes = {
  requestData: PropTypes.object,
};

export default RequestProjectCard;
