import React from "react";

// STYLES
import "./OverviewProjectTitleCard.scss";

// LIBRARIES
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS

const OverviewProjectTitleCard = (props) => {
  // PROPS
  const { title = "" } = props;
  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className="component-overview-project-title-card-container">
      <p className="project-title-card">
        <FormattedMessage id={title} />
      </p>
    </div>
  );
};

OverviewProjectTitleCard.propTypes = {
  title: PropTypes.string,
};

export default OverviewProjectTitleCard;
