export const initialProjectState = {
  isLoading: false,
  errorMessage: "",
  projectData: {
    projectName: "",
    projectShortDescription: "",
    categoryModel: {
      id: 0,
      name: "",
    },
    desiredInvestment: 0,
    currency: "",
    shares: 0,
    projectDescription: "",
    country: "",
    companyProjectModel: {
      companyName: "",
      documentModel: {
        content: "",
      },
      registeredOffice: "",
    },
    documents: [],
    links: [],
    members: [],
  },
  buildProject: {},
  projects: [],
  projectRequests: [],
  investmentProjectId: null,
  payToViewProjectId: null,
  paginationData: {
    pageNumber: 0,
    pageSize: 16,
    totalCount: 0,
  },
};
