import React from "react";

// STYLES
import "./BusinessPlanCard.scss";
import businessPlanCardLine from "assets/components/businessPlanCardImages/businessPlanCardLine.svg";

// LIBRARIES
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS
import OverviewCardButton from "components/Atoms/OverviewCardButton/OverviewCardButton";
import { convertBase64ToFile } from "helpers/converters";

const BusinessPlanCard = (props) => {
  // PROPS
  const { image = "", title = "", text = "", document = {} } = props;

  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  const handleShowPdf = () => {
    let file = convertBase64ToFile(
      document.content,
      document.fileName,
      document.fileExtension
    );
    
    const fileURL = URL.createObjectURL(file);
    const pdfWindow = window.open();
    pdfWindow.location.href = fileURL;
  };

  return (
    <div className="component-business-plan-card-container">
      <div className="component-business-plan-card-wrapper">
        <div className="component-business-plan-card-image">
          <img src={image} alt="" />
        </div>
        <div className="component-business-plan-card-details">
          <div className="plan-card-title-lines">
            <img className="line" src={businessPlanCardLine} alt="" />
            <p className="title">
              <FormattedMessage id={title} />
            </p>
            <img className="line" src={businessPlanCardLine} alt="" />
          </div>
          <p className="plan-card-text">
            <FormattedMessage id={text} />
          </p>
          <div className="plan-card-button">
            <OverviewCardButton
              buttonText={"component.overview.card.button.text"}
              onClick={handleShowPdf}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

BusinessPlanCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  document: PropTypes.shape({
    content: PropTypes.string,
    fileName: PropTypes.string,
    fileExtension: PropTypes.string,
  }),
};

export default BusinessPlanCard;
