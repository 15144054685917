import React from "react";

// STYLES
import "./SubmitIdea.scss";

// LIBRARIES
import { useIntl } from "react-intl";

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS
import IdeaCard from "components/IdeaCard/IdeaCard";
import IdeaComponent from "components/IdeaComponent/IdeaComponent";

const SubmitIdea = () => {
  // PROPS

  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS
  const intl = useIntl();
  // GENERAL CONSTANTS
  const dataForIdeaCard = [
    {
      number: intl.formatMessage({
        id: "card.idea.number.firstCard",
      }),
      title: intl.formatMessage({
        id: "card.idea.title.firstCard",
      }),
      subtitle: intl.formatMessage({
        id: "card.idea.subtitle.firstCard",
      }),
      text: intl.formatMessage({
        id: "card.idea.text.firstCard",
      }),
    },
    {
      number: intl.formatMessage({
        id: "card.idea.number.secondCard",
      }),
      title: intl.formatMessage({
        id: "card.idea.title.secondCard",
      }),
      subtitle: intl.formatMessage({
        id: "card.idea.subtitle.secondCard",
      }),
      text: intl.formatMessage({
        id: "card.idea.text.secondCard",
      }),
    },
    {
      number: intl.formatMessage({
        id: "card.idea.number.thirdCard",
      }),
      title: intl.formatMessage({
        id: "card.idea.title.thirdCard",
      }),
      subtitle: intl.formatMessage({
        id: "card.idea.subtitle.thirdCard",
      }),
      text: intl.formatMessage({
        id: "card.idea.text.thirdCard",
      }),
    },
  ];
  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className="page-submit-idea-container">
      <div className="page-submit-idea-idea-container">
        <IdeaComponent
          title={intl.formatMessage({
            id: "component.submitIdea.idea.title",
          })}
          text={intl.formatMessage({
            id: "component.submitIdea.idea.text",
          })}
          subtext={intl.formatMessage({
            id: "component.submitIdea.idea.subtext",
          })}
          buttonText={intl.formatMessage({
            id: "component.submitIdea.button",
          })}
        />
      </div>

      <div className="component-idea-card-big-title-wrapper">
        <p className="component-idea-card-big-title">
          {intl.formatMessage({
            id: "card.idea.titleInvisible",
          })}
        </p>
      </div>

      <div className="page-submit-idea-card-container">
        {dataForIdeaCard?.map((card, index) => {
          return (
            <div
              className="page-submit-idea-card-wrapper"
              key={index + 10}
              style={{ marginBottom: "7rem" }}
            >
              <IdeaCard
                key={index}
                number={card.number}
                title={card.title}
                subtitle={card.subtitle}
                text={card.text}
                index={index}
                isTitle={index === 0 ? true : false}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SubmitIdea;
