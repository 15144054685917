import React, { useState } from "react";

// STYLES
import "./LogIn.scss";
import heroImageDesktop from "assets/components/registerPage/X.svg";
import heroImageMobile from "assets/components/HeroComponent/heroImageMobile.jpg";
import heroImageTablet from "assets/components/HeroComponent/heroImageTablet.jpg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// LIBRARIES
import { FormattedMessage, injectIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

// CONSTANTS & MOCKS
import { loginInputs } from "../SelectRolePage/Mocks/inputsMock";
import { badCredentials } from "constants/constants";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { getCompanyInfo, getPersonalInfo, logIn } from "api/sessionApi";
import { handleViewAsGuest, projectIdGuest } from "slices/sessionSlice";

// COMPONENTS
import TextInput from "components/Atoms/TextInput/TextInput";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import { decodeJwtToken } from "helpers/decoder";
// import { useBackListener } from "helpers/hooks/useBackListener";

const LogIn = (props) => {
  // PROPS
  const { intl } = props;

  // CONSTANTS USING LIBRARIES
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const projectId = useSelector(projectIdGuest);

  // CONSTANTS USING HOOKS
  const [formState, setFormState] = useState({
    values: {
      email: "",
      password: "",
    },
  });
  const [showError, setShowError] = useState([]);
  // eslint-disable-next-line
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  // eslint-disable-next-line
  const [loginContentSteps, setLoginContentSteps] = useState("loginStep");

  const [showPassword, setShowPassword] = useState(false);

  // GENERAL CONSTANTS
  const contentToMap = loginInputs;

  // USE EFFECT FUNCTION
  // useBackListener(({ location }) => {
  //   navigate("/login", { replace: false });
  // });

  // HANDLERS FUNCTIONS
  const handleChange = (event) => {
    if (showError.length > 0) {
      setShowError([]);
    }

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleLogIn = () => {
    if (projectId === null) {
      dispatch(logIn(formState.values))
        .unwrap()
        .then(() => {
          let decode = decodeJwtToken();
          dispatch(getPersonalInfo(decode?.sub));
          dispatch(getCompanyInfo(decode?.sub));
        })
        .then(() => {
          handleRedirect();
        })
        .catch((error) => {
          setShowError(error.errors);
        });
    } else {
      dispatch(logIn(formState.values))
        .unwrap()
        .then(() => {
          let decode = decodeJwtToken();
          dispatch(getPersonalInfo(decode?.sub));
          dispatch(getCompanyInfo(decode?.sub));
        })
        .catch((error) => {
          setShowError(error.errors);
        });
      let id = projectId;
      dispatch(handleViewAsGuest(null));
      navigate(`/view-idea/${id}`);
    }
  };

  const handleRedirect = () => {
    if (decodeJwtToken().role !== "BRAINER") {
      navigate("/homepage");
    }
    if (decodeJwtToken().role === "ADMIN") {
      navigate("/admin");
    }
    if (decodeJwtToken().role === "BRAINER") {
      navigate("/profile-page");
    }
  };

  const handleForgotPassword = () => {
    navigate("/forgot-password");
  };

  const handleKeyPress = () => {
    handleLogIn();
  };

  return (
    <div className="page-log-in-container">
      <img
        className="page-log-in-image"
        src={
          window.innerWidth <= 599
            ? heroImageMobile
            : window.innerWidth <= 1279
            ? heroImageTablet
            : heroImageDesktop
        }
        alt=""
      />
      <div className="page-log-in-card-wrapper">
        <div className={"page-log-in-title-wrapper"}>
          <p className="page-log-in-title-text">
            <FormattedMessage
              id={
                loginContentSteps === "loginStep"
                  ? "card.login.titleText"
                  : "card.login.forgotPasswordText"
              }
            />
          </p>
          {loginContentSteps === "loginStep" && (
            <p className="page-log-in-subtitle-text">
              <FormattedMessage id={"card.login.subtitle"} />
            </p>
          )}
        </div>

        <div className={"page-log-in-card-inputs-wrapper"}>
          {loginContentSteps === "forgotPassword" && (
            <p className="page-log-in-subtitle-forgot-pass-text">
              <FormattedMessage id={"card.login.subtitle.forgotPassword"} />
            </p>
          )}
          {contentToMap?.map((item, index) => {
            return (
              <div
                className="page-log-in-card-inputs-item"
                key={`log-in-card-inputs-${index}`}
              >
                <TextInput
                  key={`item--${index}--${item.label}`}
                  label={item?.label}
                  labelColor={"blue"}
                  placeholder={intl.formatMessage({ id: item.placeholder })}
                  name={item?.name}
                  type={
                    item?.name === "email"
                      ? "text"
                      : showPassword
                      ? "text"
                      : "password"
                  }
                  handleChange={handleChange}
                  select={item.component === "select" ? true : false}
                  component={item.component}
                  handleKeyPress={handleKeyPress}
                  value={formState.values[item.name] || ""}
                  labelStyle="thin"
                />
                {item?.name === "password" && (
                  <div
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    style={{
                      marginTop: "2rem",
                      position: "absolute",
                      right: "5px",
                    }}
                  >
                    {showPassword ? (
                      <VisibilityOffIcon style={{ fontSize: "3rem" }} />
                    ) : (
                      <VisibilityIcon style={{ fontSize: "3rem" }} />
                    )}
                  </div>
                )}
              </div>
            );
          })}
          {loginContentSteps === "loginStep" && (
            <div className="page-log-in-card-inputs-forgot-wrapper">
              {/* <div
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? (
                  <VisibilityOffIcon style={{ fontSize: "3rem" }} />
                ) : (
                  <VisibilityIcon style={{ fontSize: "3rem" }} />
                )}
              </div> */}

              <p className="forgot-text" onClick={handleForgotPassword}>
                <FormattedMessage
                  id={"card.login.label.forgotPassword.button"}
                />
              </p>
            </div>
          )}
          {showError?.length > 0 &&
            showError?.includes(badCredentials.BAD_CREDENTIALS) && (
              <div className="bad-credentials-message">
                <FormattedMessage
                  id={"wrong.password.e-mail.bad.credentials.message"}
                />
              </div>
            )}
        </div>

        <div className="page-log-in-button-and-text-container">
          {<p className="page-log-in-text">{emailErrorMessage}</p>}
          <div className="page-log-in-card-button-container">
            <div className="page-log-in-card-button-wrapper">
              <ButtonComponent
                buttonText={"card.login.label.logIn.button"}
                color={"blue"}
                onClick={handleLogIn}
              />
            </div>
          </div>

          {loginContentSteps === "loginStep" && (
            <div className="page-log-in-card-register-wrapper">
              <p className="item-text">
                <FormattedMessage id={"card.login.noAccount.text"} />
              </p>
              <p
                className="item-button"
                onClick={() => navigate("/select-role")}
              >
                <FormattedMessage id={"card.login.registration.button"} />
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

LogIn.prototype = {
  //TODO fix this patzzz
  intl: PropTypes.shape(),
};

export default injectIntl(LogIn);
