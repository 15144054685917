// @ts-nocheck
import React, { useState, useEffect } from "react";

// STYLES
import "./BuildProject.scss";

// LIBRARIES
import { Navigate, useParams, useNavigate } from "react-router-dom";

// CONSTANTS & MOCKS
import {
  BUSINESS_PLAN,
  FileExtensions,
  fileTypes,
  modalContentType,
  PROJECT_MAIN_IMAGE,
  VISUAL_PRESENTATION,
} from "constants/constants";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  getSubcategories,
  getPersonalInfo,
  getCompanyInfo,
} from "api/sessionApi";
import {
  categories,
  handleChangeCurrentBrainerFees,
  loginState,
} from "slices/sessionSlice";
import { projectData } from "slices/projectSlice";
import {
  createProject,
  getProject,
  getProjectDocuments,
  getProjectLinks,
  getTeamMembers,
  postDocuments,
  postProjectLinks,
  postTeamMembers,
  updateProject,
  uploadDocument,
  verifyCode,
} from "api/projectApi";

// COMPONENTS
import MandatoryFields from "components/SubmitIdeaForm/MandatoryFields";
import PresentationContent from "components/SubmitIdeaForm/PresentationContent";
import VisualPresentation from "components/SubmitIdeaForm/VisualPresentation";
import CompanyInformation from "components/SubmitIdeaForm/CompanyInformation";
import YourTeamComponent from "components/SubmitIdeaForm/YourTeamComponent";
import BusinessPlanComponent from "components/SubmitIdeaForm/BusinessPlanComponent";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import CustomModal from "components/CustomModal/CustomModal";
import { convertBase64ToFile, convertFileToFormData } from "helpers/converters";
import PreviewProject from "view/PreviewProject/PreviewProject";
import { FormattedMessage } from "react-intl";
import CodeVerification from "view/RegisterPage/Components/CodeVerification";

const BuildProject = () => {
  // PROPS

  // CONSTANTS USING LIBRARIES
  const dispatch = useDispatch();
  const categoryList = useSelector(categories);
  const account = useSelector(loginState);
  const projectInfo = useSelector(projectData);
  // TODO: use it for showing the exact error
  // eslint-disable-next-line
  const { projectId } = useParams();
  const navigate = useNavigate();

  // CONSTANTS USING HOOKS
  const [formState, setFormState] = useState({
    values: {},
    cat: 0,
    subCat: 0,
    categoryList: [],
    subcategoryList: [],
    currency: ["EUR", "USD"],
    links: [],
    companyInfo: {},
    projectMainImage: {
      fileName: "",
      fileExtension: "",
      file: null,
    },
    visualPresentation: {
      videosFile: [],
      imagesFile: [],
    },
    teamMembers: [],
    businessPlan: {
      businessPlanFile: [],
    },
    validations: {
      projectName: false,
      projectShortDescription: false,
      categoryId: false,
      subcategoriesId: false,
      desiredInvestment: false,
      currency: false,
      shares: false,
      projectDescription: false,
      projectMainImage: false,
    },
    valid: false,
  });
  const [pageValidation, setPageValidation] = useState({
    error: false,
    errorMessage: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [currentModalContent, setCurrentModalContent] = useState("");
  const [currentState, setCurrentState] = useState("build");
  const [verificationCode, setVerificationCode] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // USE EFFECT FUNCTION
  useEffect(() => {
    dispatch(getPersonalInfo(account?.email));
    dispatch(getCompanyInfo(account?.email));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (projectId) {
      getProjectData(projectId);
    }
    // eslint-disable-next-line
  }, [projectId]);

  useEffect(() => {
    if (formState?.cat)
      dispatch(getSubcategories(formState?.cat))
        .unwrap()
        .then((answer) => {
          setFormState((formState) => ({
            ...formState,
            subcategoryList: answer,
          }));
        });
    // eslint-disable-next-line
  }, [formState?.cat]);

  useEffect(() => {
    let isContentValid = true;
    if (Object.keys(formState.validations).length > 0) {
      // eslint-disable-next-line
      for (const [key, value] of Object.entries(formState.validations)) {
        if (!value) {
          isContentValid = false;
        }
      }
      setFormState({
        ...formState,
        valid: isContentValid ? true : false,
      });
    }
    // eslint-disable-next-line
  }, [formState.validations]);

  // REQUEST API FUNCTIONS
  const postProject = () => {
    setPageValidation({
      error: false,
      errorMessage: "",
    });
    if (formState.valid) {
      const projectModel = {
        projectName: formState.values.projectName,
        projectShortDescription: formState.values.projectShortDescription,
        projectCategoryModel: {
          categoryId: formState?.cat,
          // TODO: refactor category and subcategory logic
          subcategoryId: formState.subCat,
        },
        desiredInvestment: formState.values.desiredInvestment,
        currency: formState.values.currency,
        shares: formState.values.shares,
        projectDescription: formState.values.projectDescription,
        projectId,
      };

      const formData = new FormData();
      formData.append("projectModel", projectModel);

      // setShowModal(true);
      // setCurrentModalContent(modalContentType.UPLOADING_PROJECT);
      navigate('/profile-page');

      let action = projectId ? updateProject : createProject;

      // 1. createProject    -- PROJECT_MODEL
      dispatch(action(projectModel))
        .unwrap()
        .then((answer) => {
          let projectId = answer;
          // 2. upload documents -- MAIN IMAGE
          let projectMainImageFormData = convertFileToFormData(
            formState.projectMainImage.file,
            PROJECT_MAIN_IMAGE
          );
          dispatch(
            uploadDocument({
              id: projectId,
              data: projectMainImageFormData,
            })
          ).then(() => {
            // 3. upload documents -- VISUAL_PRESENTATION - video
            let visualPresentationVideosFormData = convertFileToFormData(
              formState.visualPresentation.videosFile,
              VISUAL_PRESENTATION
            );
            dispatch(
              postDocuments({
                id: projectId,
                files: visualPresentationVideosFormData,
              })
            ).then(() => {
              // 4. upload documents -- VISUAL_PRESENTATION - image
              let visualPresentationImagesFormData = convertFileToFormData(
                formState.visualPresentation.imagesFile,
                VISUAL_PRESENTATION
              );
              dispatch(
                postDocuments({
                  id: projectId,
                  files: visualPresentationImagesFormData,
                })
              ).then(() => {
                // 5. upload documents -- BUSINESS_PLAN
                let businessPlanFormData = convertFileToFormData(
                  formState.businessPlan.businessPlanFile,
                  BUSINESS_PLAN
                );
                dispatch(
                  postDocuments({
                    id: projectId,
                    files: businessPlanFormData,
                  })
                ).then(() => {
                  // 6. postProjectLink -- LINK  ( SEND ARRAY )
                  dispatch(
                    postProjectLinks({
                      projectId: projectId,
                      links: formState.links,
                    })
                  ).then(() => {
                    // 7. postTeamMember -- TEAM_MEMBERS ( SEND ARRAY )
                    dispatch(
                      postTeamMembers({
                        projectId: projectId,
                        members: formState.teamMembers,
                      })
                    ).then(() =>
                      setCurrentModalContent(modalContentType.PROJECT_SUBMIT)
                    );
                  });
                });
              });
            });
          });
        })
        .catch((error) => {
          // TODO: find a better aproach
          console.log("ERR:", error);
        });
    } else {
      setPageValidation({
        error: true,
        errorMessage: "Not all required fields was filled!",
      });
      setTimeout(() => {
        setPageValidation({
          error: false,
          errorMessage: "",
        });
      }, 2000);
    }
  };

  const getProjectData = (id) => {
    setShowModal(true);
    setCurrentModalContent(modalContentType.LOADING_PROJECT);

    // 1. getProject -- PROJECT_MODEL
    dispatch(getProject(id))
      .unwrap()
      .then((projectModelAnswer) => {
        let projectModel = {
          ...projectModelAnswer,
          categoryId: projectModelAnswer?.categoryModel?.name,
          subcategoriesId: projectModelAnswer?.subcategoryResponseModel?.name,
        };
        delete projectModel.categoryModel;
        delete projectModel.subcategoryResponseModel;

        // 2. get project documents -- PROJECT_MAIN_IMAGE -- VISUAL_PRESENTATION - video&image -- BUSINESS_PLAN
        dispatch(
          getProjectDocuments({
            id: id,
          })
        )
          .unwrap()
          .then((documentsAnswer) => {
            let projectMainImageBase64 = documentsAnswer.find(
              (documentModel) =>
                documentModel.documentType === PROJECT_MAIN_IMAGE
            );
            let visualPresentationImagesBase64 = documentsAnswer.filter(
              (documentModel) =>
                documentModel.documentType === VISUAL_PRESENTATION &&
                FileExtensions.IMAGE.includes(documentModel.fileExtension)
            );
            let visualPresentationVideosBase64 = documentsAnswer.filter(
              (documentModel) =>
                documentModel.documentType === VISUAL_PRESENTATION &&
                FileExtensions.VIDEO.includes(documentModel.fileExtension)
            );
            let businessPlanBase64 = documentsAnswer.filter(
              (documentModel) =>
                documentModel.documentType === BUSINESS_PLAN &&
                FileExtensions.DOCUMENTS.includes(documentModel.fileExtension)
            );
            // 3. get project links
            dispatch(
              getProjectLinks({
                id: id,
              })
            )
              .unwrap()
              .then((linksAnswer) => {
                let links = linksAnswer;
                dispatch(
                  getTeamMembers({
                    id: id,
                  })
                )
                  .unwrap()
                  .then((teamMembersAnswer) => {
                    let projectMainImage;
                    let imagesFile;
                    let videosFile;
                    let teamMembers;
                    let businessPlanFile;

                    try {
                      projectMainImage = {
                        fileName: projectMainImageBase64.fileName,
                        fileExtension: projectMainImageBase64.fileExtension,
                        file: convertBase64ToFile(
                          projectMainImageBase64.content,
                          projectMainImageBase64.fileName,
                          projectMainImageBase64.fileExtension
                        ),
                      };
                    } catch (err) {}

                    try {
                      imagesFile = visualPresentationImagesBase64.map(
                        (documentModel) => {
                          return {
                            fileName: documentModel.fileName,
                            fileExtension: documentModel.fileExtension,
                            file: convertBase64ToFile(
                              documentModel.content,
                              documentModel.fileName,
                              documentModel.fileExtension
                            ),
                          };
                        }
                      );
                    } catch (err) {}

                    try {
                      videosFile = visualPresentationVideosBase64.map(
                        (documentModel) => {
                          return {
                            fileName: documentModel.fileName,
                            fileExtension: documentModel.fileExtension,
                            file: convertBase64ToFile(
                              documentModel.content,
                              documentModel.fileName,
                              documentModel.fileExtension
                            ),
                          };
                        }
                      );
                    } catch (err) {}
                    teamMembers = teamMembersAnswer;
                    try {
                      businessPlanFile = businessPlanBase64.map(
                        (documentModel) => {
                          return {
                            fileName: documentModel.fileName,
                            fileExtension: documentModel.fileExtension,
                            file: convertBase64ToFile(
                              documentModel.content,
                              documentModel.fileName,
                              documentModel.fileExtension
                            ),
                          };
                        }
                      );
                    } catch (err) {}

                    setFormState({
                      ...formState,
                      cat: projectModelAnswer?.categoryModel?.id,
                      subCat: projectModelAnswer?.subcategoryResponseModel?.id,
                      values: projectModel,
                      projectMainImage,
                      visualPresentation: {
                        imagesFile,
                        videosFile,
                      },
                      businessPlan: {
                        businessPlanFile,
                      },
                      teamMembers,
                      links,
                      validations: {
                        projectName: true,
                        projectShortDescription: true,
                        categoryId: true,
                        subcategoriesId: true,
                        desiredInvestment: true,
                        currency: true,
                        shares: true,
                        projectDescription: true,
                        projectMainImage: true,
                      },
                    });
                  });
              });
          });
      })
      .catch((error) => {
        console.log("ERR:", error);
      });

    setShowModal(false);
  };

  // HANDLERS FUNCTIONS
  const handleChange = (event, valid, field) => {
    if (event.target?.name === "desiredInvestment") {
      if (event.target.value >= 0 && event.target.value <= 200000) {
        dispatch(handleChangeCurrentBrainerFees("250 EUR"));
      } else if (event.target.value >= 200001 && event.target.value <= 500000) {
        dispatch(handleChangeCurrentBrainerFees("400 EUR"));
      } else if (
        event.target.value >= 500001 &&
        event.target.value <= 1000000
      ) {
        dispatch(handleChangeCurrentBrainerFees("650 EUR"));
      } else if (event.target.value >= 1000001) {
        dispatch(handleChangeCurrentBrainerFees("1000 EUR"));
      } else {
        dispatch(handleChangeCurrentBrainerFees("NULL"));
      }
    }

    if (event.target?.name === "categoryId") {
      let category = categoryList.find(
        (category) => category.name === event.target.value
      );

      setFormState({
        ...formState,
        cat: category.id,
        values: {
          ...formState.values,
          [event.target.name]: event.target.value,
        },
      });
    } else if (event.target?.name === "subcategoriesId") {
      let subcategoryId = formState.subcategoryList.find(
        (subcategory) => subcategory.name === event.target.value
      ).id;

      setFormState((formState) => ({
        ...formState,
        subCat: subcategoryId,
        values: {
          ...formState.values,
          [event.target.name]: event.target.value,
        },
      }));
    } else if (event.type === PROJECT_MAIN_IMAGE) {
      setFormState((formState) => ({
        ...formState,
        projectMainImage: {
          ...event,
        },
        validations: {
          projectMainImage: true,
        },
      }));
    } else if (event.name === VISUAL_PRESENTATION) {
      switch (event.type) {
        case fileTypes.VIDEO:
          setFormState((formState) => ({
            ...formState,
            visualPresentation: {
              ...formState.visualPresentation,
              videosFile: event.videosFile,
            },
          }));
          break;
        case fileTypes.IMG:
          setFormState((formState) => ({
            ...formState,
            visualPresentation: {
              ...formState.visualPresentation,
              imagesFile: event.imagesFile,
            },
          }));
          break;
        default:
          break;
      }
    } else if (event.name === "team") {
      setFormState((formState) => ({
        ...formState,
        teamMembers: event.members,
      }));
    } else if (event.type === BUSINESS_PLAN) {
      setFormState((formState) => ({
        ...formState,
        businessPlan: {
          ...formState.businessPlan,
          businessPlanFile: event.businessPlanFile,
        },
      }));
    } else {
      setFormState((formState) => ({
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]:
            field?.name === "shares" && Number(event.target.value) > 100
              ? 100
              : event.target.value,
        },
        validations: {
          ...formState.validations,
          [event.target.name]: valid ? true : false,
        },
      }));
    }
  };

  const handleInsertCode = (code) => {
    setVerificationCode(code);
  };

  const handleVerifyCode = () => {
    setErrorMessage(false);
    dispatch(verifyCode(verificationCode))
      .unwrap()
      .then((answer) => {
        if (answer === true) {
          setCurrentState("build");
        } else {
          setErrorMessage(true);
        }
      });
  };

  if (currentState === "build") {
    return (
      <div className="page-build-project-container">
        <div className="page-build-project-card-wrapper">
          <MandatoryFields
            formState={formState}
            handleChange={handleChange}
            categoryList={categoryList}
            subcategoryList={formState.subcategoryList}
          />
          <PresentationContent
            formState={formState}
            setFormState={setFormState}
            handleChange={handleChange}
            projectId={projectInfo?.projectId}
          />
          <VisualPresentation
            formState={formState}
            setFormState={setFormState}
            handleChange={handleChange}
            projectId={projectInfo?.projectId}
          />
          <CompanyInformation
            handleChange={handleChange}
            formState={formState}
            account={account}
          />
          <YourTeamComponent
            formState={formState}
            setFormState={setFormState}
            handleChange={handleChange}
            projectId={projectInfo?.projectId}
          />
          <BusinessPlanComponent
            formState={formState}
            setFormState={setFormState}
            handleChange={handleChange}
            projectId={projectInfo?.projectId}
          />
          <div>
            <p className="error-message">
              {pageValidation.error && pageValidation.errorMessage}
            </p>
            <div className="page-build-project-buttons-wrrapper">
              <ButtonComponent
                buttonText={"component.project.builder.button.preview"}
                color={"blue"}
                onClick={() => setCurrentState("preview")}
              />
              <ButtonComponent
                buttonText={"component.project.builder.button.submit"}
                color={"blue"}
                onClick={postProject}
              />
            </div>
          </div>
        </div>

        <CustomModal
          showModal={showModal}
          onClose={() => setShowModal(false)}
          directionTo="top"
          modalContent={currentModalContent}
          setShowModal={setShowModal}
          hideBackdrop={true}
        />
      </div>
    );
  } else if (currentState === "preview") {
    return (
      <>
        <PreviewProject
          projectData={formState}
          setCurrentState={setCurrentState}
          postProject={postProject}
        />
        <CustomModal
          showModal={showModal}
          onClose={() => setShowModal(false)}
          directionTo="top"
          modalContent={currentModalContent}
          setShowModal={setShowModal}
          hideBackdrop={true}
        />
      </>
    );
  } else if (currentState === "verifyCode") {
    return (
      <div className="page-build-project-container">
        <div className="verify-code-text">
          <p className="title">
            <FormattedMessage id={"page.verify.code.build.project.title"} />
          </p>
          <p className="subtitle">
            <FormattedMessage id={"page.verify.code.build.project.subtitle"} />
          </p>
        </div>
        <CodeVerification
          length={6}
          label=""
          onComplete={handleInsertCode}
          setNewCode={setVerificationCode}
        />
        <div className="verify-code-button-wrapper">
          {errorMessage && (
            <div className="code-error">Invalid code, please try again!</div>
          )}
          <div className="button-wrapper">
            <ButtonComponent
              buttonText={"page.register.nextButton"}
              onClick={handleVerifyCode}
              // selected={mobileWidth && true}
              color="gold"
            />
          </div>
        </div>
      </div>
    );
  } else {
    return <h1>Something wrong happened</h1>;
  }
};

export default BuildProject;
