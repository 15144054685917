import jwt from "jwt-decode";
import store from "store/store";

export const getUserRoleHelper = (token) => {
  let decode = {};
  if (token) {
    decode = jwt(token);
  } else {
    decode = "";
  }

  let currentRole = decode.role;
  return token ? currentRole : "GUEST";
};

export const decodeJwtToken = () => {
  const token = store.getState().session.loggedState.token;
  let decode;
  if (token) {
    decode = jwt(token);
  } else {
    decode = "";
  }
  return decode;
};
