import React from "react";

// STYLES
import "./ProjectTeamComponent.scss";

// LIBRARIES
import { FormattedMessage } from "react-intl";

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS
import ProjectTeamCard from "components/ProjectTeamCard/ProjectTeamCard";

const ProjectTeamComponent = (props) => {
  // PROPS
  const {members} = props;

  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className="component-project-team-component-container">
      <p className="project-team-component-title">
        <span>&#8226;</span>
        <FormattedMessage id={"component.project.team.comp.title"}/>
      </p>
      <div className="project-team-component-cards">
        <div className="brainer-card">
          {members?.map((item) => {
            return (
              <ProjectTeamCard
                image={item?.document}
                title={item?.jobTitle}
                name={item?.fullName}
              />
            )
          })}

        </div>
        {members?.length > 1 && (
          <div className="brainer-team-cards">
            {members?.forEach((member, index) => {
              if (index > 0) {
                return (
                  <div
                    className="team-card"
                    key={`projectTeamCard--key--${index}`}
                  >
                    <ProjectTeamCard
                      image={member.document}
                      title={member?.jobTitle || ""}
                      smallSize="small-card"
                    />
                  </div>
                );
              }
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectTeamComponent;
