import React, { useState, useEffect } from "react";

// STYLES

// LIBRARIES
import jwt from 'jwt-decode'
import { Grid } from "@mui/material"
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
// CONSTANTS & MOCKS
import { IMAGE_FALLBACK_URL } from "constants/constants"

// REDUX

import { useDispatch, useSelector } from "react-redux";
import { projectCompanyInfo } from "slices/sessionSlice";
import { getCompanyProject } from "api/sessionApi";

// COMPONENTS
import TextInput from "components/Atoms/TextInput/TextInput";
import TitleAndSubtitle from "components/TitleAndSubtitle/TitleAndSubtitle";

const CompanyInformation = (props) => {
  // PROPS
  const {
    intl = {},
    account = {}
  } = props

  // CONSTANTS USING LIBRARYS
  const company = useSelector(projectCompanyInfo)
  const dispatch = useDispatch()

  // CONSTANTS USING HOOKS
  const [data, setData] = useState(null)

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION
  useEffect(() => {
    let decode = {}
    if (account.token) {
      decode = jwt(account.token)
    }
    dispatch(getCompanyProject(decode.sub)).unwrap().then((answer) => {
      setData(answer)
    })
    // eslint-disable-next-line
  }, [])

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className="component-CompanyInformation-container">
      <div className="page-build-project-card-title-wrapper">
        <TitleAndSubtitle
          title={"component.project.builder.title.companyInformation"}
          subtitle={"component.project.builder.disclaimer.companyInformation"}
        />
      </div>

      <div className="component-CompanyInformation-inputs-wrapper">
        <div className="component-CompanyInformation-upload-inputs-wrapper">
          <div className="uploder-item">
            <p className={"component-text-label"}>
              <FormattedMessage id={"component.project.builder.label.companyLogo"}/>
            </p>
            {/*<Uploader/>*/}
            <img
              src={`data:image/jpeg;base64,${company?.documentModel?.content}`}
              alt={""}
              className={"company-logo-img"}
              onError={({currentTarget}) => {
                currentTarget.onerror = null
                currentTarget.src = `${IMAGE_FALLBACK_URL}`
              }}
            />
          </div>
        </div>
        <div className="component-CompanyInformation-input-wrapper">
          <Grid
            item
            xs={12}
            md={12}
            className={"component-MandatoryFields-item"}
          >
            <TextInput
              label={"component.project.builder.label.companyname"}
              labelColor={"blue"}
              placeholder={intl.formatMessage({id: "component.project.builder.placeholder.textInput"})}
              name={"companyName"}
              type={"text"}
              value={data?.companyName || ""}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            className={"component-MandatoryFields-item"}
          >
            <TextInput
              label={"component.project.builder.label.companyLocation"}
              labelColor={"blue"}
              placeholder={intl.formatMessage({id: "component.project.builder.placeholder.textInput"})}
              name={"registeredOffice"}
              type={"text"}
              value={data?.registeredOffice || ""}
            />
          </Grid>
        </div>
      </div>
    </div>
  );
};

CompanyInformation.prototype = {
  intl: PropTypes.shape(),
  account: PropTypes.shape(),
}

export default injectIntl(CompanyInformation);