import { createSlice } from "@reduxjs/toolkit";
import {
  logIn,
  getPersonalInfo,
  getCategories,
  getCategoryById,
  getCompanyProject,
  postContactForm,
  getSubcategories,
  getCompanyInfo,
  getViewIdeaData,
  payment,
  getNewValidationCode,
} from "api/sessionApi";
import {
  getTrendingProjects,
  getTrendingPartners,
  getExplorePartners,
  getExploreProjects,
  getExploreSubcategories,
  getFilteredProjects,
  getFilteredPartners,
} from "api/exploreApi";
import { GUEST } from "constants/constants";
import { getUserRoleHelper } from "helpers/decoder";
import jwt from "jwt-decode";

export const sessionSlice = createSlice({
  name: "session",
  initialState: {
    isLoading: false,
    language: "en",
    errorMessage: "",
    registrationType: "",
    userRole: GUEST,
    categories: [],
    subcategoryList: [],
    currentBrainerFees: "NULL",
    loggedState: {
      loggedIn: false,
      token: null,
      email: "",
      role: "",
    },

    explorePage: {
      projectsPageNr: 0,
      projectsPageSize: 16,
      projectsCategories: {
        items: [],
        totalCount: 0,
      },
      partnersPageNr: 0,
      partnersPageSize: 16,
      partnersCategories: {
        items: [],
        totalCount: 0,
      },
      projectsFilter: {
        nameStartsWith: "",
        categoryIds: [],
        country: "",
        currency: "",
        rangeGraterOrEqualThan: "",
        rangeLessOrEqualThan: "",
        subcategoryIds: [],
        sort: "EMPTY",
      },
      partnersFilter: {
        nameStartsWith: "",
        country: "",
      },
      filterType: "projects",
    },

    personalInfo: {},
    companyInfo: {},
    projectCompanyInfo: {},
    tredingPartners: [],
    paymentPayload: {},
    viewIdeaProject: {},
    projectIdToInvest: 0,
    projectIdGuest: null,
    consultingPage: {
      helpingBrainer: false,
      helpingPartner: false,
    },
  },
  reducers: {
    handleChangeFitlerType: (state, action) => {
      state.explorePage.filterType = action.payload;
    },
    handleChangeCountry: (state, action) => {
      state.explorePage.partnersFilter.country = action.payload;
    },
    handleChangeLanguage: (state, action) => {
      state.language = action.payload;
    },
    handleChangeCurrentBrainerFees: (state, action) => {
      state.currentBrainerFees = action.payload;
    },
    handleRegistrationType: (state, action) => {
      state.registrationType = action.payload;
    },
    handleUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    handleLogOutUser: (state) => {
      state.userRole = GUEST;
      state.loggedState = {};
      state.personalInfo = {};
      state.projectCompanyInfo = {};
      state.categories = [];
    },
    savePaymentPayload: (state, action) => {
      state.paymentPayload = action.payload;
    },
    removePaymentPayload: (state) => {
      state.paymentPayload = {};
    },
    // set explore page Nr
    handleExplorePageNr: (state, action) => {
      const type = `${action.payload.type}PageNr`;
      state.explorePage[type] = action.payload.value;
    },
    // handle change for explore page
    handleExploreFilterChange: (state, action) => {
      const type = `${action.payload.type}Filter`;
      state.explorePage[type] = {
        ...state.explorePage[type],
        [action.payload.name]: action.payload.value,
      };
    },
    handleResetFilters: (state, action) => {
      state.explorePage.projectsFilter.nameStartsWith = "";
      state.explorePage.projectsFilter.categoryIds = [];
      state.explorePage.projectsFilter.country = "";
      state.explorePage.projectsFilter.currency = "";
      state.explorePage.projectsFilter.rangeGraterOrEqualThan = "";
      state.explorePage.projectsFilter.rangeLessOrEqualThan = "";
      state.explorePage.projectsFilter.subcategoryIds = [];
      state.explorePage.projectsFilter.sort = "EMPTY";
    },
    handleResetPartnerFilters: (state, action) => {
      state.explorePage.partnersFilter.nameStartsWith = "";
      state.explorePage.partnersFilter.country = "";
    },
    handleSetProjectId: (state, action) => {
      state.projectIdToInvest = action.payload;
    },
    handleViewAsGuest: (state, action) => {
      state.projectIdGuest = action.payload;
    },
    handleSetStatusBrainer: (state, action) => {
      state.consultingPage.helpingBrainer = action.payload;
    },
    handleSetStatusPartner: (state, action) => {
      state.consultingPage.helpingPartner = action.payload;
    },
  },
  extraReducers: {
    // ============= LOGIN REQUEST HANDLERS ============= //
    [logIn.pending]: (state) => {
      state.isLoading = true;
    },
    [logIn.fulfilled]: (state, action) => {
      // TODO: use only one method for decoding per project
      let decode = {};
      if (action.payload) {
        decode = jwt(action.payload);
      }
      state.loggedState = {
        token: action.payload,
        loggedIn: true,
        email: decode.sub,
        role: decode.role,
      };
      // TODO: do not decode token twice for the same purpose
      state.userRole = getUserRoleHelper(action.payload);
      state.isLoading = false;
    },
    [logIn.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },

    // ======= GET PERSONAL INFO REQUEST HANDLERS ======= //
    [getPersonalInfo.pending]: (state) => {
      state.isLoading = true;
    },
    [getPersonalInfo.fulfilled]: (state, action) => {
      state.personalInfo = action.payload;
      state.isLoading = false;
    },
    [getPersonalInfo.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },

    // ======= GET COMPANY INFO REQUEST HANDLERS ======= //
    [getCompanyInfo.pending]: (state) => {
      state.isLoading = true;
    },
    [getCompanyInfo.fulfilled]: (state, action) => {
      state.companyInfo = action.payload;
      state.isLoading = false;
    },
    [getCompanyInfo.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },

    // ======= GET CATEGORIES REQUEST HANDLERS ======= //
    [getCategories.pending]: (state) => {
      state.isLoading = true;
    },
    [getCategories.fulfilled]: (state, action) => {
      state.categories = action.payload;
      state.isLoading = false;
    },
    [getCategories.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },

    // ======= GET CATEGORY REQUEST HANDLERS ======= //
    [getCategoryById.pending]: (state) => {
      state.isLoading = true;
    },
    [getCategoryById.fulfilled]: (state, action) => {
      const subcategories = action.payload.map((item) => {
        return item.subcategories;
      });
      state.subcategoryList = subcategories;
      state.isLoading = false;
    },
    [getCategoryById.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },

    // ======= GET COMPANY PROJECT REQUEST HANDLERS ======= //
    [getCompanyProject.pending]: (state) => {
      state.isLoading = true;
    },
    [getCompanyProject.fulfilled]: (state, action) => {
      state.projectCompanyInfo = action.payload;
      state.isLoading = false;
    },
    [getCompanyProject.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },

    // ======= GET CONTACT FORM REQUEST HANDLERS ======= //
    [postContactForm.pending]: (state) => {
      state.isLoading = true;
    },
    [postContactForm.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [postContactForm.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },

    // ======= GET SUBCATEGORIES REQUEST HANDLERS ======= //
    [getSubcategories.pending]: (state) => {
      state.isLoading = true;
    },
    [getSubcategories.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.subcategoryList = action.payload;
    },
    [getSubcategories.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },
    // ======= GET EXPLORE PROJECTS REQUEST HANDLERS ======= //
    [getExploreProjects.pending]: (state) => {
      state.isLoading = true;
    },
    [getExploreProjects.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.explorePage.projectsCategories = action.payload;
    },
    [getExploreProjects.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },
    // ======= GET Last Projects REQUEST HANDLERS ======= //
    [getTrendingProjects.pending]: (state) => {
      state.isLoading = true;
    },
    [getTrendingProjects.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [getTrendingProjects.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },
    // ======= GET TRENDING PARTNERS REQUEST HANDLERS ======= //
    [getTrendingPartners.pending]: (state) => {
      state.isLoading = true;
    },
    [getTrendingPartners.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.tredingPartners = action.payload;
    },
    [getTrendingPartners.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },
    // ======= GET EXPLORE PARTNERS REQUEST HANDLERS ======= //
    [getExplorePartners.pending]: (state) => {
      state.isLoading = true;
    },
    [getExplorePartners.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.explorePage.partnersCategories = action.payload;
    },
    [getExplorePartners.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },

    // ======= GET EXPLORE SUBCATEGORIES REQUEST HANDLERS ======= //
    [getExploreSubcategories.pending]: (state) => {
      state.isLoading = true;
    },
    [getExploreSubcategories.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.subcategoryList = action.payload;
    },
    [getExploreSubcategories.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },

    // ======= GET EXPLORE SUBCATEGORIES FILTER ======= //
    [getFilteredPartners.pending]: (state) => {
      state.isLoading = true;
    },
    [getFilteredPartners.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.explorePage.partnersCategories = action.payload;
    },
    [getFilteredPartners.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },

    // ======= GET FILTERED PROJECTS REQUEST HANDLERS ======= //
    [getFilteredProjects.pending]: (state) => {
      state.isLoading = true;
    },
    [getFilteredProjects.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.explorePage.projectsCategories = action.payload;
    },
    [getFilteredProjects.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },

    // =========== PAY PROJECT API =============== //
    [payment.pending]: (state) => {
      state.isLoading = true;
    },
    [payment.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [payment.rejected]: (state, action) => {
      state.isLoading = false;
      // state.errorMessage = action.error.message;
    },

    // =========== PAY PROJECT API =============== //
    [getViewIdeaData.pending]: (state) => {
      state.isLoading = true;
    },
    [getViewIdeaData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.viewIdeaProject = action.payload;
    },
    [getViewIdeaData.rejected]: (state, action) => {
      state.isLoading = false;
      // state.errorMessage = action.error.message;
    },

    // =========== RESEND EMAIL VALIDATION CODE =============== //
    [getNewValidationCode.pending]: (state) => {
      state.isLoading = true;
    },
    [getNewValidationCode.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [getNewValidationCode.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export const isLoading = (state) => state.session.isLoading;
export const filterType = (state) => state.session.explorePage.filterType;
export const language = (state) => state.session.language;
export const userType = (state) => state.session.registrationType;
export const loginState = (state) => state.session.loggedState;
export const registerFlow = (state) => state.session.registerFlow;
export const registrationType = (state) => state.session.registrationType;
export const userRole = (state) => state.session.userRole;
export const accountInfo = (state) => state.session.personalInfo;
export const companyInfo = (state) => state.session.companyInfo;
export const categories = (state) => state.session.categories;
export const projectCompanyInfo = (state) => state.session.projectCompanyInfo;
export const subcategoryList = (state) => state.session.subcategoryList;
export const paymentPayload = (state) => state.session.paymentPayload;
export const explorePage = (state) => state.session.explorePage;
export const viewIdeaProject = (state) => state.session.viewIdeaProject;
export const projectIdToInvest = (state) => state.session.projectIdToInvest;
export const projectIdGuest = (state) => state.session.projectIdGuest;
export const consultingPage = (state) => state.session.consultingPage;
export const currentBrainerFees = (state) => state.session.currentBrainerFees;
export const {
  handleChangeLanguage,
  handleUserRole,
  handleLogOutUser,
  savePaymentPayload,
  removePaymentPayload,
  handleExplorePageNr,
  handleExploreFilterChange,
  handleResetFilters,
  handleSetProjectId,
  handleViewAsGuest,
  handleSetStatusBrainer,
  handleSetStatusPartner,
  handleChangeCurrentBrainerFees,
  handleChangeCountry,
  handleResetPartnerFilters,
  handleChangeFitlerType,
} = sessionSlice.actions;

export default sessionSlice.reducer;
