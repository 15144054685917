export const DATE_PICKER = "datePicker";
export const SELECT = "select";
export const TEXT_INPUT = "textInput";
export const FILE_TYPE_IMG = "image";
export const FILE_TYPE_PDF = "pdf";
export const NDA = "NDA";
export const BRAINER_AGREEMENT = "BRAINER_AGREEMENT";
export const LOGO = "LOGO";
export const VISUAL_PRESENTATION = "VISUAL_PRESENTATION";
export const PROJECT_MAIN_IMAGE = "PROJECT_MAIN_IMAGE";
export const BUSINESS_PLAN = "BUSINESS_PLAN";
export const GUEST = "GUEST";
export const IMAGE_FALLBACK_URL =
  "http://3.bp.blogspot.com/-bjsi7thI5G0/UbQnOs0lvyI/AAAAAAAAPDI/s4IWLG_SxME/s257/Fara+imagine.jpg";

export const ROUND_UPLOADER = "round";
export const SQUARE_UPLOADER = "square";
export const BYTES = 1048576;

export const projectType = {
  DRAFTS: "DRAFT",
  APPROVED: "APPROVED",
  INVESTED: "INVESTED",
  PUBLISHED: "PUBLISHED",
  BLOCKED: "BLOCKED",
  VIEW: "BLOCKED",
};

export const ProjectRequestType = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

export const projectDocuments = {
  VISUAL_PRESENTATION: "VISUAL_PRESENTATION",
  PROJECT_MAIN_IMAGE: "PROJECT_MAIN_IMAGE",
  BUSINESS_PLAN: "BUSINESS_PLAN",
};

export const FileExtensions = {
  IMAGE: ["jpeg", "jpg", "png"],
  VIDEO: ["mp4", "mov", "avi"],
  // TODO: define documents type
  DOCUMENTS: ["pdf", "txt", "doc", "docx", "mp4", "png", "jpg"],
  LINK: "LINK",
};

export const fileTypes = {
  IMG: "image",
  PDF: "pdf",
  VIDEO: "video",
};

export const currency = [
  {
    name: "EUR",
  },
  {
    name: "USD",
  },
];

export const languages = [
  {
    language: "English",
    abbreviation: "en",
  },
  {
    language: "Romanian",
    abbreviation: "ro",
  },
  {
    language: "German",
    abbreviation: "de",
  },
  {
    language: "Spanish",
    abbreviation: "es",
  },
  {
    language: "French",
    abbreviation: "fr",
  },
];

export const hardcodedFilterData = [
  { name: "category", text: "Choose a category" },
  { name: "subcategory", text: "Choose a subcategory" },
  {
    name: "amount",
    text: "Choose an amount",
  },
  {
    name: "country",
    text: "Choose a country",
  },
  {
    name: "currency",
    text: "Choose a currency",
    values: ["USD", "EUR"],
  },
  {
    name: "sort",
    text: "Sorted by",
    values: ["Trending", "Ascending", "Descending"],
  },
];

export const CATEGORY = "category";
export const SUBCATEGORY = "subcategory";

export const modalContentType = {
  PARTNER_MEETING_CARD: "partner-meeting-card-button-modal",
  PARTNER_PROFILE_CARD_INVEST: "partner-profile-card-invest-button-modal",
  PARTNER_PROFILE_CARD_REJECT: "partner-profile-card-reject-button-modal",
  INVEST: "invest",
  REJECT: "reject",
  PROJECT_SUBMIT: "project-submit",
  UPLOADING_PROJECT: "uploading-project",
  LOADING_PROJECT: "loading-project",
  PARTNERS_MEETING: "partners-meeting",
  INVESTMENT_REQUEST: "investment-request",
  INVESTMENT_REPORT: "investment-report",
  RESET_VALIDATION_CODE: "reset-validation-code",
  REJECT_PROJECT_REQUEST: "reject-project-request",
  APPROVE_PROJECT_REQUEST: "approve-project-request",
  REJECT_PROJECT_VIEW_REQUEST: "reject-project-view-request",
  APPROVE_PROJECT_VIEW_REQUEST: "approve-project-view-request",
  REJECT_SIGN_UP_REQUEST: "reject-sign-up-request",
  APPROVE_SIGN_UP_REQUEST: "approve-sign-up-request",
  HELPING_BRAINERS_ADVICE: "helping-brainers-advice",
  ACCEPTED_PARTNER_INTERESTS: "accepting-partner-interests",
};

export const AdminTabs = {
  BRAINER_SIGN_UP_REQUESTS: "BRAINER_SIGN_UP_REQUESTS",
  PARTNER_SIGN_UP_REQUESTS: "PARTNER_SIGN_UP_REQUESTS",
  PROJECT_REQUESTS: "PROJECT_REQUESTS",
  PROJECT_APPROVAL: "PROJECT_APPROVAL",
  VIEW_PROJECT_REQUESTS: "VIEW_PROJECT_REQUESTS",
  INVESTMENT: "INVESTMENT",
  INVESTMENT_REPORT: "REPORT",
  INVESTMENT_REQUEST: "REQUEST",
  DEALS_DONE: "CLOSED_DEAL",
  PARTNERS_MEETING: "PARTNERS_MEETING",
  CATEGORIES: "CATEGORIES",
  CATEGORY_LIST: "categoriesList",
};

export const projectsCardConstants = {
  FULL_ONE: "full-one",
  FULL_THREE: "full-three",
};

export const tabletResolutionHigh = 1279;
export const tabletResolutionLow = 600;

export const badCredentials = {
  BAD_CREDENTIALS: "Bad credentials",
};

export const BuildIdeaApplicationStatusTypes = {
  UNSUBMITTED: "UNSUBMITTED",
  PENDING: "PENDING",
  REJECTED: "REJECTED",
  APPROVED: "APPROVED",
};

export const ProfileTabs = {
  PERSONAL: "Personal Information",
  ABOUT: "About",
  COMPANY: "Company",
  PROJECTS: "Projects",
  REQUESTS: "Requests",
  INTERESTS: "Interests",
};

export const RequestStatus = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

export const DocumentTypes = {
  NDA: "NDA",
  PARTNERSHIP_AGREEMENT: "PARTNERSHIP_AGREEMENT",
};
