import React from "react";

// LIBRARIES
import { BrowserRouter } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { StylesProvider } from "@material-ui/core";

// COMPONENTS
import AppRoutes from "config/appRoutes";
import { language } from "./slices/sessionSlice";
import en from "locale/en.json";
import ro from "locale/ro.json";
import de from "locale/de.json";
import fr from "locale/fr.json";
import es from "locale/es.json";

const App = () => {
  // CONSTANTS USING LIBRARYS
  const currentLanguageData = useSelector(language);

  // GENERAL CONSTANTS
  const message = { en, ro, de, fr, es };

  return (
    <StylesProvider injectFirst>
      <IntlProvider
        locale={currentLanguageData}
        messages={message[currentLanguageData]}
      >
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </IntlProvider>
    </StylesProvider>
  );
};

export default App;
