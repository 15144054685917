import React, { useEffect } from "react";

// STYLES
import "./InvestmentReport.scss";

// LIBRARIES
import PropTypes from "prop-types";

// CONSTANTS & MOCKS

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { modalContentReport } from "slices/adminSlice";
import { getPartnersNda } from "api/userApi"
import fileLogo from "assets/projectBuilder/fileLogo.svg";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import { partnerNdsList } from "slices/userSlice";
import Download from "assets/pictures/download.svg";
import { FormattedMessage } from "react-intl";

// COMPONENTS

const InvestmentReport = (props) => {
  // PROPS
  const {
    setShowModal = () => {
    }
  } = props

  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch()
  const reportData = useSelector(modalContentReport)
  const ndsList = useSelector(partnerNdsList)

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION
  useEffect(() => {
    const email = reportData?.row?.[5]?.name
    dispatch(getPartnersNda({email}))
    // eslint-disable-next-line
  }, [])

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className="page-InvestmentReport-container">
      {ndsList?.map((item, index) => {
        return (
          <div className="upload-card" key={index}>
            <div className="logo-wrapper">
              <img src={fileLogo} alt=""/>
            </div>
            <div className="document-description-wrapper">
              <p className="document-description">
                {/*Document {documentIndex + 1}:{" "}*/}
                {/*{document.fileName && document.fileName}*/}
                {item?.fileName}
              </p>
            </div>
            <div className="upload-btn-wrapper">
              <div className="download">
                <div className="underline"></div>
                <div className="image">
                  <img src={Download} alt=""/>
                </div>
                <p className="button-name">
                  <FormattedMessage
                    id={"modal.payment.required.content.download.button"}
                  />
                </p>
              </div>
            </div>
          </div>
        )
      })}

      <div className="back-btn-wrapper">
        <ButtonComponent
          buttonText={"admin.project.overview.card.back.button"}
          color={"blue"}
          onClick={() => setShowModal(false)}
        />
      </div>
    </div>
  );
};

InvestmentReport.propTypes = {
  setShowModal: PropTypes.func,
};

export default InvestmentReport;