import React from "react";

// STYLES
import "./Footer.scss";
import roTerms from "assets/Files/ro-terms.docx";
import enTerms from "assets/Files/en-terms.docx";

// LIBRARIES
import { FormattedMessage } from "react-intl";
import useMediaQuery from "@mui/material/useMediaQuery";

// CONSTANTS & MOCKS
import fb from "assets/components/footer/fb.svg";
import ig from "assets/components/footer/ig.svg";
import tw from "assets/footer/twitter.svg";
import logo from "assets/footer/bp-footer-logo.png";

// REDUX
import { useSelector } from "react-redux";
import { language } from "slices/sessionSlice";

// COMPONENTS

const Footer = () => {
  // PROPS

  // CONSTANTS USING LIBRARIES
  const languageData = useSelector(language);

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS
  const isMobile = useMediaQuery("(max-width:700px)");

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className="component-footer-container">
      {!isMobile && (
        <div className="component-footer-find">
          <div className="component-footer-contact-section">
            <p className="contact-section-title">
              <FormattedMessage id={"component.footer.findUs"} />
            </p>
            <div className="contact-section-address-wrapper">
              <p className="contact-section-address">
                <FormattedMessage id={"component.footer.company.name"} />
              </p>
              <p className="contact-section-address">
                <FormattedMessage id={"component.footer.address.line1"} />
              </p>
              <p className="contact-section-address">
                <FormattedMessage id={"component.footer.address.line2"} />
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="component-footer-logo">
        <div className="component-footer-logo-wrapper">
          <img src={logo} alt={""} className={"component-footer-logo"} />
        </div>

        {isMobile && (
          <>
            <div className="component-footer-find">
              <div className="component-footer-contact-section">
                <p className="contact-section-title">
                  <FormattedMessage id={"component.footer.findUs"} />
                </p>
                <div className="contact-section-address-wrapper">
                  <p className="contact-section-address">
                    <FormattedMessage id={"component.footer.company.name"} />
                  </p>
                  <p className="contact-section-address">
                    <FormattedMessage id={"component.footer.address.line1"} />
                  </p>
                  <p className="contact-section-address">
                    <FormattedMessage id={"component.footer.address.line2"} />
                  </p>
                </div>
              </div>
            </div>

            <div className="component-footer-contact">
              <div className="component-footer-contact-section">
                <p className="contact-section-title">
                  <FormattedMessage id={"component.footer.contact.title"} />
                </p>
                <div className="contact-section-address-wrapper">
                  <p className="contact-section-address">
                    <FormattedMessage id={"component.footer.contact.email"} />
                  </p>
                  <div className="contact-section-social-media-wrapper">
                    <a href="" target="_blank">
                      <img
                        src={fb}
                        alt={""}
                        className={"contact-section-social-media-item"}
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/brain_partner/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={ig}
                        alt={""}
                        className={"contact-section-social-media-item"}
                      />
                    </a>
                    <a
                      href="https://twitter.com/brain__partner"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={tw}
                        alt={""}
                        className={"contact-section-social-media-item"}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="component-footer-terms-wrapper">
          <a href={languageData === "ro" ? roTerms : enTerms} download>
            <p className="component-footer-terms-section-text-item">
              <FormattedMessage id={"component.footer.terms"} />
            </p>
          </a>
          <a href="/terms">
            <p className="component-footer-terms-section-text-item">
              <FormattedMessage id={"component.footer.rights"} />
            </p>
          </a>
        </div>
      </div>

      {!isMobile && (
        <div className="component-footer-contact">
          <div className="component-footer-contact-section">
            <p className="contact-section-title">
              <FormattedMessage id={"component.footer.contact.title"} />
            </p>
            <div className="contact-section-address-wrapper">
              <p className="contact-section-address">
                <FormattedMessage id={"component.footer.contact.email"} />
              </p>
              <div className="contact-section-social-media-wrapper">
                <a href="" target="_blank">
                  <img
                    src={fb}
                    alt={""}
                    className={"contact-section-social-media-item"}
                  />
                </a>
                <a href="" target="_blank">
                  <img
                    src={ig}
                    alt={""}
                    className={"contact-section-social-media-item"}
                  />
                </a>
                <a href="" target="_blank">
                  <img
                    src={tw}
                    alt={""}
                    className={"contact-section-social-media-item"}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;
