import React from "react";

// STYLES
import "./ConsultingPage.scss";

// LIBRARIES

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS
import ContentHeroComponent from "components/ContentHeroComponent/ContentHeroComponent";
import TrendingInvestors from "components/TrendingInvestors/TrendingInvestors";
import Professionals from "components/Professionals/Professionals";

const ConsultingPage = () => {
  // PROPS

  // CONSTANTS USING LIBRARieS

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  return (
    <div className="page-consulting-container">
      <div className="page-consulting-hero-container">
        {/* <ContentHeroComponent /> */}
      </div>
      <Professionals />
    </div>
  );
};

export default ConsultingPage;
