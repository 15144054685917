import React from "react";

// STYLES
import "./TeamComponentMobile.scss";

// LIBRARIEs

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS

const TeamComponentMobile = (props) => {
  // PROPS
  const { name = "", position = "", text = "", picture = "" } = props;
  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS
  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className={`component-team-mobile-container `}>
      <div className="component-team-mobile-picture-container">
        <div className="component-team-mobile-image-wrapper">
          <img
            className={`component-team-mobile-image `}
            src={picture}
            alt=""
          />
        </div>
        <div className={`component-team-mobile-info-wrapper`}>
          <div className="component-team-mobile-name-wrapper">
            <p className="component-team-mobile-name">{name}</p>
          </div>
          <div className="component-team-mobile-position-wrapper">
            <div className="component-team-mobile-line"></div>
            <p className="component-team-mobile-position">{position}</p>
            <div className="component-team-mobile-line"></div>
          </div>
        </div>
      </div>
      <div className={`component-team-mobile-text-container`}>
        <p className="component-team-mobile-text">{text}</p>
      </div>
    </div>
  );
};

export default TeamComponentMobile;
