import React, { useEffect } from "react";

// STYLES
import "./HomePage.scss";
import { Grid } from "@mui/material";

// LIBRARIES
import { useA2HS } from "react-use-a2hs";

// CONSTANTS & MOCKS
import { cardData } from "components/AdvertisementCard/Mocks/cardData";
// REDUX

// COMPONENTS
import AdvertisementCard from "components/AdvertisementCard/AdvertisementCard";
import HeroComponent from "components/HeroComponent/HeroComponent";
import TrendingProjects from "components/TrendingProjects/TrendingProjects";
import TrendingInvestors from "components/TrendingInvestors/TrendingInvestors";
import { decodeJwtToken } from "helpers/decoder";
import roNdaPartnerFile from "assets/Files/ro-nda-partner.docx";
import enNdaPartnerFile from "assets/Files/en-nda-partner.docx";
import roNdaBrainerFile from "assets/Files/ro-nda-brainer.docx";
import enNdaBrainerFile from "assets/Files/en-nda-brainer.docx";
import FeesCards from "components/FeesCards/FeesCards";
import HeroSliderComponent from "components/HeroSliderComponent/HeroSliderComponent";

const HomePage = () => {
  // PROPS

  // CONSTANTS USING LIBRARIES
  const [promptEvent, promptToInstall] = useA2HS();

  useEffect(() => {
    let timer1 = setTimeout(() => {
      const link = document.createElement("button");

      link.onclick = promptToInstall();

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }, 5000);

    // this will clear Timeout
    // when component unmount like in willComponentUnmount
    // and show will not change to true
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <Grid container className="page-homepage-container">
      <Grid item xs={12} className="page-homepage-hero-image-container">
        <HeroSliderComponent />
        {/* <HeroComponent /> */}
      </Grid>
      <Grid item xs={12} className="page-homepage-hero-trending-container">
        <TrendingProjects />
      </Grid>
      <Grid
        item
        xs={12}
        className="page-homepage-hero-trending-investors-container"
      >
        {/* <TrendingInvestors /> */}
      </Grid>
      {/* <Grid item xs={12} className="page-homepage-hero-trending-fees-container">
        <FeesCards />
      </Grid> */}

      {/* TODO: Add statistics component after there is something to display */}
      <div className="page-homepage-advertisement-cards">
        {decodeJwtToken().role === "BRAINER" && (
          <AdvertisementCard
            reverse={cardData[0].reverse}
            title={cardData[0].title || ""}
            description={cardData[0].description || ""}
            buttonText={cardData[0].buttonText || ""}
            image={cardData[0].image || ""}
            page={cardData[0].page || ""}
          />
        )}
      </div>
    </Grid>
  );
};

export default HomePage;
