import { createSlice } from "@reduxjs/toolkit";
import {
  createBlankProject,
  uploadDocument,
  getProjectLinks,
  deleteProjectLink,
  postTeamMembers,
  getTeamMembers,
  postDocuments,
  createProject,
  postProjectLinks,
  getBrainerProjects,
  getPartnerBlockedProjects,
  getProject,
  getProjectDocuments,
  updateProject,
  investInProject,
  createProjectRequest,
  verifyCode,
  getProjectRequests,
  paymentVerify,
} from "api/projectApi";
import { initialProjectState } from "constants/reduxConstants";

export const projectSlice = createSlice({
  name: "project",
  initialState: initialProjectState,
  reducers: {
    setProjectDocuments: (state, action) => {
      state.projectData.documents = action.payload;
    },
    setProjectMembers: (state, action) => {
      state.projectData.members = action.payload;
    },
    setProjectLinks: (state, action) => {
      state.projectData.links = action.payload;
    },
    handleSaveBuildProject: (state, action) => {
      state.buildProject = action.payload;
    },
    handleSaveInvestmentProjectId: (state, action) => {
      state.investmentProjectId = action.payload;
    },
    handleSavePayToViewProjectId: (state, action) => {
      state.payToViewProjectId = action.payload;
    },
    handleChangePage: (state, action) => {
      state.paginationData = {
        ...state.paginationData,
        [action.payload.name]: action.payload.value,
      };
    },
  },
  extraReducers: {
    // =========== CREATE PROJECT API =============== //
    [createProject.pending]: (state) => {
      state.isLoading = true;
    },
    [createProject.fulfilled]: (state, action) => {
      // state.isLoading = false;
    },
    [createProject.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.error.message;
    },

    [updateProject.pending]: (state) => {
      state.isLoading = true;
    },
    [updateProject.fulfilled]: (state, action) => {
      // state.isLoading = false;
    },
    [updateProject.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.error.message;
    },

    [uploadDocument.pending]: (state) => {
      state.isLoading = true;
    },
    [uploadDocument.fulfilled]: (state, action) => {
      // state.isLoading = false;
    },
    [uploadDocument.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.error.message;
    },

    [postDocuments.pending]: (state) => {
      state.isLoading = true;
    },
    [postDocuments.fulfilled]: (state, action) => {
      // state.isLoading = false;
    },
    [postDocuments.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.error.message;
    },

    [postProjectLinks.pending]: (state) => {
      state.isLoading = true;
    },
    [postProjectLinks.fulfilled]: (state, action) => {
      // state.isLoading = false;
    },
    [postProjectLinks.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.error.message;
    },

    [postTeamMembers.pending]: (state) => {
      state.isLoading = true;
    },
    [postTeamMembers.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [postTeamMembers.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.error.message;
    },

    [createBlankProject.pending]: (state) => {
      state.isLoading = true;
    },
    [createBlankProject.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [createBlankProject.rejected]: (state, action) => {
      state.isLoading = false;
      // state.errorMessage = action.error.message;
    },

    // =========== GET PROJECT API =============== //
    [getBrainerProjects.pending]: (state) => {
      state.isLoading = true;
    },
    [getBrainerProjects.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.paginationData = {...state.paginationData,
        totalCount: action.payload.totalCount
      }
      state.projects = action.payload.items;
    },
    [getBrainerProjects.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.error.message;
    },

    [getPartnerBlockedProjects.pending]: (state) => {
      state.isLoading = true;
    },
    [getPartnerBlockedProjects.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.paginationData = {...state.paginationData,
        totalCount: action.payload.totalCount
      }
      state.projects = action.payload.items;
    },
    [getPartnerBlockedProjects.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.error.message;
    },

    [getProject.pending]: (state) => {
      state.isLoading = true;
      state = initialProjectState;
    },
    [getProject.fulfilled]: (state, action) => {
      // state.isLoading = false;
      state.projectData = { ...action.payload };
    },
    [getProject.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [getProjectDocuments.pending]: (state) => {
      // state.isLoading = true;
    },
    [getProjectDocuments.fulfilled]: (state, action) => {
      // state.isLoading = false;
    },
    [getProjectDocuments.rejected]: (state, action) => {
      state.isLoading = false;
      // state.errorMessage = action.error.message;
    },

    [getProjectLinks.pending]: (state) => {
      // state.isLoading = true;
    },
    [getProjectLinks.fulfilled]: (state, action) => {
      // state.isLoading = false;
      state.projectData.links = action.payload;
    },
    [getProjectLinks.rejected]: (state, action) => {
      state.isLoading = false;
      // state.errorMessage = action.error.message;
    },

    [getTeamMembers.pending]: (state) => {
      // state.isLoading = true;
    },
    [getTeamMembers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.projectData.members = action.payload;
    },
    [getTeamMembers.rejected]: (state, action) => {
      state.isLoading = false;
      // state.errorMessage = action.error.message;
    },

    // =========== UPDATE PROJECT API =============== //
    [deleteProjectLink.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteProjectLink.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [deleteProjectLink.rejected]: (state, action) => {
      state.isLoading = false;
      // state.errorMessage = action.error.message;
    },

    // =========== INVEST IN PROJECT API =============== //
    [investInProject.pending]: (state) => {
      state.isLoading = true;
    },
    [investInProject.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [investInProject.rejected]: (state, action) => {
      state.isLoading = false;
      // state.errorMessage = action.error.message;
    },

    // =========== REQUEST PROJECT API =============== //
    [createProjectRequest.pending]: (state) => {
      state.isLoading = true;
    },
    [createProjectRequest.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [createProjectRequest.rejected]: (state, action) => {
      state.isLoading = false;
      // state.errorMessage = action.error.message;
    },

    // =========== VERIFY BUILD PROJECT CODE API =============== //
    [verifyCode.pending]: (state) => {
      state.isLoading = true;
    },
    [verifyCode.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [verifyCode.rejected]: (state, action) => {
      state.isLoading = false;
      // state.errorMessage = action.error.message;
    },

    // =========== GET PROJECT REQUESTS API =============== //
    [getProjectRequests.pending]: (state) => {
      state.isLoading = true;
    },
    [getProjectRequests.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.projectRequests = action.payload;
    },
    [getProjectRequests.rejected]: (state, action) => {
      state.isLoading = false;
      // state.errorMessage = action.error.message;
    },

    // =========== PAYMENT VERIFY API =============== //
    [paymentVerify.pending]: (state) => {
      state.isLoading = true;
    },
    [paymentVerify.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [paymentVerify.rejected]: (state, action) => {
      state.isLoading = false;
      // state.errorMessage = action.error.message;
    },
  },
});

export const {
  setProjectDocuments,
  setProjectMembers,
  setProjectLinks,
  handleSaveBuildProject,
  handleSaveInvestmentProjectId,
  handleSavePayToViewProjectId,
  handleChangePage,
} = projectSlice.actions;

export const projectData = (state) => state.project.projectData;
export const isLoading = (state) => state.project.isLoading;
export const errorMessage = (state) => state.project.errorMessage;
export const projectsData = (state) => state.project.projects;
export const buildProject = (state) => state.project.buildProject;
export const investmentProjectId = (state) => state.project.investmentProjectId;
export const payToViewProjectId = (state) => state.project.payToViewProjectId;
export const paginationDetails = (state) => state.project.paginationData;
export const projectRequestsData = (state) => state.project.projectRequests;
export const pageNumber = (state) => state.project.paginationData.pageNumber;

export default projectSlice.reducer;
