import React, { useState, useEffect, useRef } from "react";

// STYLES
import "./HeaderMobile.scss";

// LIBRARIES
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

// CONSTANTS & MOCKS
import { getMobileHeaderLinks } from "./Mocks/mobileLinksMock";
import { languages } from "constants/constants";

// REDUX
import {
  handleLogOutUser,
  handleChangeLanguage,
  language,
  loginState,
  userRole,
  accountInfo,
} from "slices/sessionSlice";
import { useDispatch } from "react-redux";
import { userRolesTypes } from "constants/userRoles";

// COMPONENTS
import { decodeJwtToken } from "helpers/decoder";
import profile from "assets/header/profile-placeholder.png";
import logo from "assets/header/bp-logo.png";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { getPersonalInfo } from "api/sessionApi";

const HeaderMobile = () => {
  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch();
  const currentLanguageData = useSelector(language);
  const navigate = useNavigate();
  const loggedState = useSelector(loginState);
  const currentUserRole = useSelector(userRole);
  const account = useSelector(loginState);
  const personalInfoData = useSelector(accountInfo);

  // CONSTANTS USING HOOKS
  const [showLanguages, setShowLanguages] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION
  useEffect(() => {
    if (openMenu) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";

    if (loggedState.token) {
      dispatch(getPersonalInfo(account?.email));
    }
  }, [openMenu]);

  useEffect(() => {
    const closeLanguageSelector = (e) => {
      const isNot = !e.target.className.includes("languageSelector");
      isNot && setShowLanguages(false);
    };

    document.addEventListener("click", closeLanguageSelector, false);

    return () =>
      window.removeEventListener("click", closeLanguageSelector, false);
  }, []);

  let menuRef = useRef();

  useEffect(() => {
    let handler = (event) => {
      if (!menuRef.current?.contains(event.target)) {
        setOpenMenu(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  const handleShowLanguages = () => {
    setShowLanguages(!showLanguages);
  };

  const handleLanguage = (item) => {
    dispatch(handleChangeLanguage(item));
  };

  const handleNavLinks = (path) => {
    navigate(path);
    setOpenMenu(false);
  };

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleLogOut = () => {
    dispatch(handleLogOutUser());
    navigate("/homepage");
    setOpenMenu(false);
  };

  const handleLogoClick = () => {
    navigate("/homepage");
  };
  return (
    <>
      <div className="hamburger-menu-button-wrapper" onClick={handleOpenMenu}>
        {openMenu ? (
          <CloseRoundedIcon style={{ fill: "white" }} />
        ) : (
          <MenuRoundedIcon style={{ fill: "#002365" }} />
        )}
      </div>
      <div className="hamburger-menu-logo-wrapper">
        <img src={logo} alt="" onClick={handleLogoClick} />
      </div>
      {openMenu && (
        <div className="component-header-mobile-container" ref={menuRef}>
          <div className="component-header-links-wrapper">
            {currentUserRole &&
              getMobileHeaderLinks(currentUserRole).map((item, index) => {
                return (
                  <ul key={`key--${index}--${item.label}`}>
                    {item?.isVisible && (
                      <li onClick={() => handleNavLinks(item.path)}>
                        <FormattedMessage id={item.label} />
                      </li>
                    )}
                  </ul>
                );
              })}
            {(decodeJwtToken().role === userRolesTypes.BRAINER ||
              decodeJwtToken().role === userRolesTypes.PARTNER) && (
              <p
                className="hamburger-menu-logout-button"
                onClick={handleLogOut}
              >
                <FormattedMessage
                  id={"component.profile.side.profile.logout"}
                />
              </p>
            )}
          </div>

          <div className="component-header-account-options-wrapper">
            <img
              src={require(`assets/header/languages/${currentLanguageData}.svg`)}
              alt=""
              onClick={handleShowLanguages}
              className="header-languages-icon languageSelector"
            />
            {loggedState?.loggedIn ? (
              <div className="component-header-logged-in-options">
                <img
                  src={`data:image/png;base64,${personalInfoData?.documentModels?.[0]?.content}`}
                  alt=""
                  className="header-options-profile-icon"
                />
                <p
                  className="view-profile-button"
                  onClick={() => handleNavLinks("/profile-page")}
                >
                  <FormattedMessage
                    id={"component.header.mobile.view.profile.buton"}
                  />
                </p>
              </div>
            ) : (
              <div className="component-header-mobile-log-in-sign-up-buttons">
                <p
                  className="component-header-mobile-log-in-button"
                  onClick={() => handleNavLinks("/login")}
                >
                  <FormattedMessage id={"component.header.login"} />
                </p>
                <p
                  className="component-header-mobile-sign-up-button"
                  onClick={() => handleNavLinks("/select-role")}
                >
                  <FormattedMessage id={"component.header.signin"} />
                </p>
              </div>
            )}
          </div>
          {showLanguages && (
            <div className={`languages-wrapper slideInDown languageSelector`}>
              {languages?.map((item, index) => {
                return (
                  <p
                    key={`item--${index}`}
                    className="languages-wrapper-item"
                    onClick={() => handleLanguage(item.abbreviation)}
                  >
                    {item.language}
                  </p>
                );
              })}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default HeaderMobile;
