import React, { useEffect, useState } from "react";

// STYLES;

// LIBRARIES
import { FormattedMessage, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

// CONSTANTS & MOCKS
import { fileTypes, VISUAL_PRESENTATION } from "constants/constants";
import photo from "assets/uploader/projectMainImg.png";
import videoMockImg from "assets/uploader/videoUpload.png";

// REDUX

// COMPONENTS
import TitleAndSubtitle from "components/TitleAndSubtitle/TitleAndSubtitle";
import TextInput from "components/Atoms/TextInput/TextInput";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import Uploader from "components/Uploader/Uploader";

const VisualPresentation = (props) => {
  // PROPS
  const {
    intl = {},
    formState = {},
    setFormState = () => {},
    handleChange = () => {},
  } = props;
  // CONSTANTS USING LIBRARYS

  const [videoUrl, setVideoUrl] = useState("");
  const [imagesPreview, setImagesPreview] = useState([]);
  const [videosPreview, setVideosPreview] = useState([]);

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION
  useEffect(() => {
    if (formState.visualPresentation?.imagesFile?.length > 0) {
      let newImagesPreview = formState.visualPresentation.imagesFile.map(
        (image) => {
          let object = URL.createObjectURL(image.file);
          return object;
        }
      );

      setImagesPreview(newImagesPreview);
    } else {
      setImagesPreview([]);
    }
    // eslint-disable-next-line
  }, [formState.visualPresentation.imagesFile]);

  useEffect(() => {
    if (formState.visualPresentation?.videosFile?.length > 0) {
      let newVideosPreview = formState.visualPresentation.videosFile.map(
        (video) => {
          let object = URL.createObjectURL(video.file);
          return object;
        }
      );

      setVideosPreview(newVideosPreview);
    } else {
      setVideosPreview([]);
    }
    // eslint-disable-next-line
  }, [formState.visualPresentation.videosFile]);

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  const handleChangeUrl = (event) => {
    setVideoUrl(event.target.value);
  };

  const handleAddVideoUrl = () => {
    const videoLink = {
      link: videoUrl,
      id: -1,
    };

    setVideoUrl("");
    // TODO: USE HANDLE CHANGE FUNCTION
    setFormState({
      ...formState,
      links: [...formState.links, videoLink],
    });
  };

  const handleRemoveVideoUrl = (videoLink) => {
    let newLinksArr = formState.links.filter((link) => link !== videoLink);

    // TODO: USE HANDLE CHANGE FUNCTION
    setFormState({
      ...formState,
      links: newLinksArr,
    });
  };

  const handleUploadVideoOrPhoto = (files, type) => {
    switch (type) {
      case fileTypes.VIDEO:
        let newVideosDocuments = [...formState.visualPresentation.videosFile];

        files.forEach((file) => {
          let extension = file.type.split("/");
          let documentModel = {
            type: VISUAL_PRESENTATION,
            file: file,
            fileName: file.name,
            fileExtension: extension[1],
          };

          newVideosDocuments.push(documentModel);
        });

        let newVideoVisualPresentationObject = {
          name: VISUAL_PRESENTATION,
          type: fileTypes.VIDEO,
          videosFile: newVideosDocuments,
        };

        handleChange(newVideoVisualPresentationObject);
        break;
      case fileTypes.IMG:
        let newImageDocuments = [...formState.visualPresentation.imagesFile];
        files.forEach((file) => {
          let extension = file.type.split("/");
          let documentModel = {
            type: VISUAL_PRESENTATION,
            file: file,
            fileName: file.name,
            fileExtension: extension[1],
          };

          newImageDocuments.push(documentModel);
        });

        let newImageVisualPresentationObject = {
          name: VISUAL_PRESENTATION,
          type: fileTypes.IMG,
          imagesFile: newImageDocuments,
        };

        handleChange(newImageVisualPresentationObject);
        break;
      default:
        break;
    }
  };

  const handleRemoveVideoOrImage = (index, type) => {
    switch (type) {
      case fileTypes.VIDEO:
        let newVisualPresentationVideosFile = [
          ...formState.visualPresentation.videosFile,
        ];
        newVisualPresentationVideosFile.splice(index, 1);

        let newVisualPresentationVideosData = {
          name: VISUAL_PRESENTATION,
          type: type,
          videosFile: newVisualPresentationVideosFile,
        };

        handleChange(newVisualPresentationVideosData);
        break;
      case fileTypes.IMG:
        let newVisualPresentationImagesFile = [
          ...formState.visualPresentation.imagesFile,
        ];
        newVisualPresentationImagesFile.splice(index, 1);

        let newVisualPresentationImagesData = {
          name: VISUAL_PRESENTATION,
          type: type,
          imagesFile: newVisualPresentationImagesFile,
        };

        handleChange(newVisualPresentationImagesData);
        break;
      default:
        break;
    }
  };

  return (
    <div className="component-VisualPresentation-container">
      <div className="page-build-project-card-title-wrapper">
        <TitleAndSubtitle
          title={"component.project.builder.title.visualPresentation"}
          subtitle={"component.project.builder.disclaimer.visualPresentation"}
        />
      </div>

      <div className="component-PresentationContent-input-wrapper">
        <div className="input-item-wrapper">
          <TextInput
            label={"component.project.builder.label.projectLink"}
            labelColor={"blue"}
            placeholder={intl.formatMessage({
              id: "component.project.builder.placeholder.textInput",
            })}
            name={"link"}
            type={"text"}
            handleChange={handleChangeUrl}
            multiline={false}
            value={videoUrl}
          />
        </div>
        <div className="button-item-wrapper">
          <ButtonComponent
            buttonText={"page.register.uploadButton"}
            onClick={() => handleAddVideoUrl()}
          />
        </div>
      </div>
      <div className="component-presentationContent-links-wrapper">
        {formState?.links?.map((link, index) => {
          return (
            <div key={`link--${link.id}`} className="row">
              <span className="link-item">
                {index + 1}.{link.link}
              </span>
              <DeleteForeverIcon
                className="delete-icon"
                onClick={() => handleRemoveVideoUrl(link)}
              />
            </div>
          );
        })}
      </div>
      <div className="component-PresentationContent-upload-inputs-wrapper">
        <div className="uploder-item">
          <p className={"component-text-label-preview"}>
            <FormattedMessage
              id={"component.project.builder.label.projectVideos"}
            />
          </p>
          <Uploader
            accept={"video/mp4,video/mov,video/avi"}
            onDrop={(files) => handleUploadVideoOrPhoto(files, fileTypes.VIDEO)}
            previewImg={videoMockImg}
            edit={false}
            showText={false}
          />
        </div>

        <div className="uploder-item">
          <p className={"component-text-label-preview"}>
            <FormattedMessage
              id={"component.project.builder.label.projectImages"}
            />
          </p>
          <Uploader
            accept={"image/jpeg,image/jpg,image/png"}
            onDrop={(files) => handleUploadVideoOrPhoto(files, fileTypes.IMG)}
            previewImg={photo}
            edit={false}
            showText={false}
          />
        </div>
      </div>
      {videosPreview?.length > 0 && (
        <>
          <p className={"component-text-label-preview"}>
            <FormattedMessage
              id={"component.project.builder.label.videos.title"}
            />
          </p>
          <div className="component-PresentationContent-upload-inputs-wrapper">
            <div className="component-PresentationContent-upload-preview-wrapper">
              {videosPreview.map((preview, index) => (
                <div
                  className={"preview-image-wrapper"}
                  key={`videos-preview--${index}`}
                >
                  <video
                    width={window.innerWidth > 1000 ? "500" : "97%"}
                    height={window.innerWidth > 1000 ? "300" : "100%"}
                    controls
                    src={`${preview}`}
                    className="video-placeholder-image"
                  >
                    Not working
                  </video>
                  <DeleteForeverIcon
                    className="remove-icon"
                    onClick={() =>
                      handleRemoveVideoOrImage(index, fileTypes.VIDEO)
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </>
      )}

      {imagesPreview?.length > 0 && (
        <>
          <p className={"component-text-label-preview"}>
            <FormattedMessage
              id={"component.project.builder.label.images.title"}
            />
          </p>
          <div className="component-PresentationContent-upload-inputs-wrapper">
            <div className="component-PresentationContent-upload-preview-wrapper">
              {imagesPreview.map((preview, index) => (
                <div
                  className={"preview-image-wrapper"}
                  key={`images-preview--${index}`}
                >
                  <img
                    id={"preview"}
                    src={preview}
                    alt={""}
                    className={"preview-img"}
                  />
                  <DeleteForeverIcon
                    className="remove-icon"
                    onClick={() =>
                      handleRemoveVideoOrImage(index, fileTypes.IMG)
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

VisualPresentation.propTypes = {
  intl: PropTypes.shape(),
  formState: PropTypes.shape(),
  setFormState: PropTypes.func,
  handleChange: PropTypes.func,
};

export default injectIntl(VisualPresentation);
