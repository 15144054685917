import { createSlice } from "@reduxjs/toolkit";
import {
  getSearchPartners,
  getPartnersMeetingContent,
  sendPartnerContactRequest
} from "api/exploreApi";

export const exploreSlice = createSlice({
  name: "explore",
  initialState: {
    partnersMeetingContent: []
  },
  reducers: {},
  extraReducers: {
    // ======= GET SEARCH PARTNERS REQUEST HANDLERS ======= //
    [getSearchPartners.pending]: (state) => {
      state.isLoading = true
    },
    [getSearchPartners.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.partnersMeetingContent = action.payload
    },
    [getSearchPartners.rejected]: (state, action) => {
      state.errorMessage = action.error.message
    },
    // ======= GET FILTERED PROJECTS REQUEST HANDLERS ======= //
    // [getFilteredProjects.pending]: (state) => {
    //   state.isLoading = true
    // },
    // [getFilteredProjects.fulfilled]: (state, action) => {
    //   state.isLoading = false;
    // },
    // [getFilteredProjects.rejected]: (state, action) => {
    //   state.errorMessage = action.error.message
    // },
    // ======= GET EXPLORE SUBCATEGORIES REQUEST HANDLERS ======= //
    // [getExploreSubcategories.pending]: (state) => {
    //   state.isLoading = true
    // },
    // [getExploreSubcategories.fulfilled]: (state, action) => {
    //   state.isLoading = false;
    //   state.tredingPartners = action.payload
    // },
    // [getExploreSubcategories.rejected]: (state, action) => {
    //   state.errorMessage = action.error.message
    // },
    // ======= GET PARTNERS MEETING CONTENT REQUEST HANDLERS ======= //
    [getPartnersMeetingContent.pending]: (state) => {
      state.isLoading = true
    },
    [getPartnersMeetingContent.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [getPartnersMeetingContent.rejected]: (state, action) => {
      state.errorMessage = action.error.message
    },
    // ======= GET PARTNERS MEETING CONTENT REQUEST HANDLERS ======= //
    [sendPartnerContactRequest.pending]: (state) => {
      state.isLoading = true
    },
    [sendPartnerContactRequest.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [sendPartnerContactRequest.rejected]: (state, action) => {
      state.errorMessage = action.error.message
    },
  }
})

export const partnersMeetingContent = (state) => state.explore.partnersMeetingContent

export default exploreSlice.reducer;