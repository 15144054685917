import React from "react";

// STYLES
import "./SearchMobile.scss";

// LIBRARIES
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import FilterMobile from "components/FilterMobile/FilterMobile";
import {
  explorePage,
  filterType,
  handleChangeFitlerType,
  handleExploreFilterChange,
  handleResetFilters,
} from "slices/sessionSlice";
import { useDispatch, useSelector } from "react-redux";

const SearchMobile = (props) => {
  // PROPS
  const {
    showFilter = false,
    setShowFilter = () => {},
    projectNumbers = 0,
    handleSearch = () => {},
  } = props;

  // CONSTANTS USING LIBRARYS
  let categoriesArray = [];
  const dispatch = useDispatch();
  const explorePageData = useSelector(explorePage);
  const exploreFilter = useSelector(filterType);

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS
  const categoryType = `${exploreFilter}Filter`;

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  const localHandleChange = (event) => {
    dispatch(
      handleExploreFilterChange({
        value: event.target.value,
        name: event.target.name,
        type: exploreFilter,
      })
    );
  };
  return (
    <div className="component-search-mobile-container">
      <div
        className={
          !showFilter
            ? "component-search-mobile-input-container"
            : "component-search-mobile-invisible"
        }
      >
        <div className="component-search-mobile-input-wrapper">
          <TextField
            variant="standard"
            InputProps={{
              disableUnderline: true,
            }}
            fullWidth
            name={"nameStartsWith"}
            onChange={localHandleChange}
            value={explorePageData?.[categoryType]?.nameStartsWith}
          />
        </div>
        <div className="component-search-mobile-search-button-wrapper">
          <ButtonComponent
            onClick={() => {
              handleSearch();
              // dispatch(handleResetFilters());
            }}
            buttonText="component.explore.search.button"
            color="gold"
            selected={true}
          />
        </div>
      </div>
      {/* TODO: change it to button */}
      <div className="component-search-mobile-filter-buttons-container">
        <div
          className={
            !showFilter
              ? "component-search-mobile-filter-button-wrapper"
              : "component-search-mobile-invisible"
          }
          onClick={() => {
            setShowFilter(true);
          }}
        >
          <p>Apply Filters</p>
        </div>

        {/* AICI BRO -------------------------------------------------------------------- */}
        <div
          className={
            !showFilter
              ? "component-search-mobile-filter-button-wrapper"
              : "component-search-mobile-invisible"
          }
          onClick={() => {
            exploreFilter === "partners"
              ? dispatch(handleChangeFitlerType("projects"))
              : dispatch(handleChangeFitlerType("partners"));
          }}
        >
          <p>{exploreFilter === "projects" ? "Partners" : "Projects"}</p>
        </div>
      </div>
      <div className="component-search-mobile-filter-wrapper">
        {showFilter && (
          <FilterMobile
            filterFunction={setShowFilter}
            handleSearch={handleSearch}
            categoriesArray={categoriesArray}
            projectNumbers={projectNumbers}
            btnType={exploreFilter}
          />
        )}
      </div>
    </div>
  );
};

SearchMobile.propTypes = {
  showFilter: PropTypes.bool,
  setShowFilter: PropTypes.func,
  handleSearch: PropTypes.func,
  projectNumbers: PropTypes.number,
};

export default SearchMobile;
