export const projectDescription = [
  {
    label: 'component.project.builder.label.projectName',
    placeholder: "component.project.builder.placeholder.textInput",
    type: "text",
    name: "projectName",
    required: true,
    component: "textInput",
    multiline: false,
    xs: 12,
    md: 12,
    allowEmpty: false,
    validation: null,
  },
  {
    label: 'component.project.builder.label.shortDescription',
    placeholder: "component.project.builder.placeholder.textInput",
    type: "text",
    name: "projectShortDescription",
    required: true,
    component: "textInput",
    multiline: true,
    xs: 12,
    md: 12,
    allowEmpty: false,
    validation: null,
  },
  {
    label: 'component.project.builder.label.projectDomain',
    placeholder: "component.project.builder.placeholder.select",
    type: "text",
    name: "categoryId",
    required: true,
    component: "select",
    multiline: false,
    xs: 12,
    md: 5,
    content: "categories",
    allowEmpty: false,
    validation: null,
  },
  {
    label: 'component.project.builder.label.projectSubDomain',
    placeholder: "component.project.builder.placeholder.select",
    type: "text",
    name: "subcategoriesId", //array de id-uri pt subcategorii
    required: true,
    component: "select",
    multiline: false,
    xs: 12,
    md: 5,
    content: "subcategories",
    allowEmpty: false,
    validation: null,
  },
  {
    label: 'component.project.builder.label.desiredInvestment',
    placeholder: "component.project.builder.placeholder.textInput",
    type: "number",
    name: "desiredInvestment",
    required: true,
    component: "textInput",
    multiline: false,
    xs: 12,
    md: 3,
    allowEmpty: false,
    validation: "number",
  },
  {
    label: 'component.project.builder.label.selectCurrency',
    placeholder: "component.project.builder.placeholder.select",
    type: "text",
    name: "currency",
    required: true,
    component: "select",
    multiline: false,
    xs: 12,
    md: 3,
    content: "currency",
    allowEmpty: false,
    validation: null,
    inputType: "dropdown",
  },
  {
    label: 'component.project.builder.label.yourShares',
    placeholder: "component.project.builder.placeholder.textInput",
    type: "number",
    name: "shares",
    required: true,
    component: "textInput",
    multiline: false,
    xs: 12,
    md: 2,
    allowEmpty: false,
    validation: "number",
  },
]

export const companyInformation = [
  {
    label: 'component.project.builder.label.companyname',
    placeholder: "component.project.builder.placeholder.textInput",
    type: "text",
    name: "companyName",
    required: true,
    component: "textInput",
    multiline: false,
    xs: 12,
    md: 12
  },
  {
    label: 'component.project.builder.label.companyLocation',
    placeholder: "component.project.builder.placeholder.textInput",
    type: "text",
    name: "registeredOffice",
    required: true,
    component: "textInput",
    multiline: false,
    xs: 12,
    md: 12
  },
]

export const yourTeam = [
  {
    label: 'component.project.builder.label.fullName',
    placeholder: "component.project.builder.placeholder.textInput",
    type: "text",
    name: "fullName",
    required: true,
    component: "textInput",
    multiline: false,
    xs: 12,
    md: 12
  },
  {
    label: 'component.project.builder.label.jobTitle',
    placeholder: "component.project.builder.placeholder.textInput",
    type: "text",
    name: "jobTitle",
    required: true,
    component: "textInput",
    multiline: false,
    xs: 12,
    md: 12
  },
]