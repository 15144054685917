import React from "react";

// STYLES
import "./AboutUsPage.scss";

// LIBRARIES
import { injectIntl, useIntl } from "react-intl";
import useMediaQuery from "@mui/material/useMediaQuery";
// CONSTANTS & MOCKS

// REDUX

// COMPONENTS
import AboutUsCard from "components/AboutUsCard/AboutUsCard";
import ContactComponent from "components/ContactComponent/ContactComponent";
import TeamComponent from "components/TeamComponent/TeamComponent";
import TermsOfServiceCard from "components/TermsOfServiceCard/TermsOfServiceCard";
import stefan from "assets/components/consultingCardImages/stefan.JPG";
import codrin from "assets/components/consultingCardImages/codrin.JPG";
import TeamComponentWeb from "components/TeamComponent/TeamComponentWeb";
import TeamComponentMobile from "components/TeamComponent/TeamComponentMobile";
import ContentHeroComponent from "components/ContentHeroComponent/ContentHeroComponent";

const AboutUsPage = () => {
  // PROPS

  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS
  const intl = useIntl();
  const isMobile = useMediaQuery("(max-width:1350px)");

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  const dataForAboutUsCard = [
    {
      number: intl.formatMessage({
        id: "card.about.number.firstCard",
      }),
      title: intl.formatMessage({
        id: "card.about.title.firstCard",
      }),
      subtitle: intl.formatMessage({
        id: "card.about.subtitle.firstCard",
      }),
      text: intl.formatMessage({
        id: "card.about.text.firstCard",
      }),
    },
    {
      number: intl.formatMessage({
        id: "card.about.number.secondCard",
      }),
      title: intl.formatMessage({
        id: "card.about.title.secondCard",
      }),
      subtitle: intl.formatMessage({
        id: "card.about.subtitle.secondCard",
      }),
      text: intl.formatMessage({
        id: "card.about.text.secondCard",
      }),
    },
    {
      number: intl.formatMessage({
        id: "card.about.number.thirdCard",
      }),
      title: intl.formatMessage({
        id: "card.about.title.thirdCard",
      }),
      subtitle: intl.formatMessage({
        id: "card.about.subtitle.thirdCard",
      }),
      text: intl.formatMessage({
        id: "card.about.text.thirdCard",
      }),
    },
  ];

  const dataForTeamComponent = [
    {
      title: intl.formatMessage({
        id: "card.team.title.firstCard",
      }),
      name: intl.formatMessage({
        id: "card.team.name.firstCard",
      }),
      position: intl.formatMessage({
        id: "card.team.position.firstCard",
      }),
      text: intl.formatMessage({
        id: "card.team.text.firstCard",
      }),
      isTitle: true,
    },
    {
      title: intl.formatMessage({
        id: "card.team.title.secondCard",
      }),
      name: intl.formatMessage({
        id: "card.team.name.secondCard",
      }),
      position: intl.formatMessage({
        id: "card.team.position.secondCard",
      }),
      text: intl.formatMessage({
        id: "card.team.text.secondCard",
      }),
      isTitle: false,
    },
  ];

  return (
    <div className="page-about-us-container">
      {/* <div className="page-about-us-hero-container">
        <ContentHeroComponent />
      </div> */}
      <div className="page-about-us-about-card-container">
        {dataForAboutUsCard.map((card, index) => {
          return (
            <div
              className="page-about-us-about-card-wrapper"
              key={`page-about-us-about-card-wrapper--${index}`}
            >
              <AboutUsCard
                key={index}
                number={card.number}
                title={card.title}
                subtitle={card.subtitle}
                text={card.text}
              />
            </div>
          );
        })}
      </div>
      <div className="page-about-us-team-component-container">
        <p className="page-about-us-our-team-text">
          {intl.formatMessage({
            id: "card.team.title.firstCard",
          })}
        </p>
        {dataForTeamComponent.map((card, index) => {
          return (
            <div
              className="page-about-us-team-component-wrapper"
              key={`page-about-us-team-component--${index}`}
            >
              {isMobile ? (
                <div className="page-about-us-team-mobile-container">
                  <TeamComponentMobile
                    name={card.name}
                    position={card.position}
                    text={card.text}
                    picture={index === 0 ? stefan : codrin}
                  />
                </div>
              ) : (
                <TeamComponentWeb
                  name={card.name}
                  position={card.position}
                  index={index}
                  text={card.text}
                  picture={index === 0 ? stefan : codrin}
                />
              )}
            </div>
          );
        })}
      </div>
      <div className="page-about-us-term-of-service-card-container">
        <TermsOfServiceCard />
      </div>
      <div className="page-about-us-contact-component-container">
        <ContactComponent />
      </div>
    </div>
  );
};

export default injectIntl(AboutUsPage);
