import React from "react";

// STYLES
import "./OverviewCardButton.scss";
import overviewCardButtonImage from "assets/components/overviewCardButtonImage/overviewCardButtonImage.svg";

// LIBRARIES
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS

const OverviewCardButton = (props) => {
  // PROPS
  const { buttonText = "", onClick = () => {} } = props;

  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className="component-overview-card-button-container" onClick={onClick}>
      <div className="overview-card-button-image">
        <img src={overviewCardButtonImage} alt="" />
      </div>
      <p className="overview-card-button-text">
        <FormattedMessage id={buttonText} />
      </p>
    </div>
  );
};

OverviewCardButton.propTypes = {
  buttonText: PropTypes.string,
};

export default OverviewCardButton;
