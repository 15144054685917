export const investmentRequest = [
  {
    label: "component.investment.request.modal.partner.name",
    name: "partnerName",
    id: "1",
    multiline: false,
  },
  {
    label: "component.investment.request.modal.pledged.founds",
    name: "pledgedFunds",
    id: "2",
    multiline: false,
  },
  {
    label: "component.investment.request.modal.project.name",
    name: "projectName",
    id: "3",
    multiline: false,
  },
]