import React, { useState } from "react";

// STYLES
import "./EditableProjectCard.scss";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import blueTint from "assets/components/EditableProjectCard/blueTint.png";

// LIBRARIES
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS
import ProfileButton from "components/ProfileButton/ProfileButton";
import { decodeJwtToken } from "helpers/decoder";
import { useDispatch } from "react-redux";
import { savePaymentPayload } from "slices/userSlice";
import { projectType } from "constants/constants";

const EditableProjectCard = (props) => {
  // PROPS
  const {
    time = "",
    projectStatus = "",
    projectDetails = {
      projectName: "",
      projectDocument: {
        content: "",
      },
    },
  } = props;
  // CONSTANTS USING LIBRARYS
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // CONSTANTS USING HOOKS
  const [isShown, setIsShown] = useState(false);

  // GENERAL CONSTANTS
  const payToPublishItem = {
    price: "price_1L98A4ECdTSmedexmOtZlzqR",
    quantity: 1,
  };

  const checkoutOptions = {
    lineItems: [payToPublishItem],
    mode: "payment",
    successUrl: `${window.location.origin}/payment-success`,
    cancelUrl: `${window.location.origin}/payment-cancel`,
  };

  let stripePromise;

  const getStripe = () => {
    if (!stripePromise) {
      stripePromise = loadStripe(
        "pk_test_51KxUmbECdTSmedexNh7rtoUWOad618nZ7HEeaMOnlkanqihsP4rqGXFsrPraDZ33k1bFWcHWEYAocu3uDbV9tpWC00cIYgSSsO"
      );
    }
    return stripePromise;
  };

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS
  const redirectToCheckout = async () => {
    let successPayload = {
      projectId: projectDetails.projectId,
      product: "PUBLISH",
      emailAddress: decodeJwtToken().sub,
    };

    dispatch(savePaymentPayload(successPayload));

    localStorage.setItem("paymentPayload", JSON.stringify(successPayload));

    const stripe = await getStripe();
    await stripe.redirectToCheckout(checkoutOptions);
  };

  // HANDLERS FUNCTIONS
  const handleEditProject = () => {
    navigate(`/build/${projectDetails.projectId}`);
  };

  const handleViewProject = () => {
    navigate(`/overview/${projectDetails.projectId}`);
  };

  return (
    <div className="component-project-card-container">
      <div
        onMouseEnter={() => {
          if (isShown) setIsShown(true);
        }}
        onMouseLeave={() => {
          if (isShown) setIsShown(false);
        }}
        className={classNames(
          isShown ? "slide-project-card-down" : "slide-project-card-up",
          "component-project-menu-container"
        )}
      >
        {projectStatus !== projectType.DRAFTS &&
          projectStatus !== projectType.PUBLISHED &&
          projectStatus !== projectType.INVESTED && (
            <ProfileButton
              icon={<CreateOutlinedIcon />}
              text={"component.profile.card.project.button.publish"}
              onClick={redirectToCheckout}
            />
          )}
        {projectStatus !== projectType.PUBLISHED &&
          projectStatus !== projectType.APPROVED &&
          projectStatus !== projectType.INVESTED && (
            <ProfileButton
              icon={<CreateOutlinedIcon />}
              text={"component.profile.card.project.button.edit"}
              onClick={handleEditProject}
            />
          )}
        <ProfileButton
          icon={<CreateOutlinedIcon />}
          text={"component.profile.card.project.button.view"}
          onClick={handleViewProject}
        />
        <ProfileButton
          icon={<CancelOutlinedIcon />}
          text={"component.profile.card.project.button.deactivate"}
        />
      </div>
      <div
        className="component-project-image-container"
        onMouseEnter={() => {
          setIsShown(true);
        }}
        onMouseLeave={() => {
          setIsShown(false);
        }}
      >
        <div className="component-project-image-wrapper">
          <img
            src={`data:image/png;base64,${projectDetails.projectDocument.content}`}
            alt=""
          />
          <img src={blueTint} alt="" />
          <p>{projectDetails.projectName}</p>
          <div className="component-project-time-container">
            <p className="component-project-time-wrapper">
              {<AccessTimeOutlinedIcon />} {time}{" "}
              <FormattedMessage
                id={"component.profile.card.project.time.period"}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
EditableProjectCard.propTypes = {
  name: PropTypes.string,
  time: PropTypes.string,
  projectStatus: PropTypes.string,
};

export default EditableProjectCard;
