import React from "react";

// STYLES
import "./ProjectTeamCard.scss";

// LIBRARIES

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS

const ProjectTeamCard = (props) => {
  // PROPS
  const {image = "", title = "", smallSize = "", name} = props;
  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className={`component-project-team-card-container ${smallSize}`}>
      <div className="component-project-team-card-image">
        <img src={`data:image/png;base64,${image}`} alt=""/>
      </div>
      <div className="component-project-team-card-title-wrapper">
        <div className="component-project-team-card-title">
          <p className="name">{name}</p>
          <p className="title">{title}</p>
        </div>
      </div>
    </div>
  );
};

// TODO: add prop types

export default ProjectTeamCard;
