import React, { useState } from "react";

// STYLES
// import "./Step3.scss";

// LIBRARIES
import { Grid } from "@mui/material";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// CONSTANTS & MOCKS
import { modalContentType } from "constants/constants";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { verifyAccount } from "api/userApi";
import { registerFlow } from "slices/userSlice";
import {
  getCompanyInfo,
  getNewValidationCode,
  getPersonalInfo,
} from "api/sessionApi";

import { logIn } from "api/sessionApi";

// COMPONENTS
import CodeVerification from "../Components/CodeVerification";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import { decodeJwtToken } from "helpers/decoder";
import CustomModal from "components/CustomModal/CustomModal";

const Step3 = (props) => {
  // PROPS
  const { handleStep = () => {}, registerState = {} } = props;

  // CONSTANTS USING LIBRARYS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const accInfo = useSelector(registerFlow);

  // CONSTANTS USING HOOKS
  const [newCode, setNewCode] = useState("");

  // eslint-disable-next-line
  const [showError, setShowError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(
    modalContentType.PARTNERS_MEETING
  );
  // GENERAL CONSTANTS
  const mobileWidth = window.innerWidth <= 600;

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  const methodForOnComplete = (e) => {
    setNewCode(e);
  };

  const handleVerify = () => {
    const body = {
      emailAddress: registerState.userInfo.emailAddress,
      verificationCode: newCode,
    };
    let userCredentials = {
      email: registerState.userInfo.emailAddress,
      password: registerState.userInfo.password,
    };
    dispatch(verifyAccount(body)).then(() => {
      dispatch(logIn(userCredentials))
        .unwrap()
        .then((answer) => {
          let decode = decodeJwtToken();
          dispatch(getPersonalInfo(decode?.sub))
            .unwrap()
            .then(() => {
              dispatch(getCompanyInfo(decode?.sub));
              if (decode.role === "BRAINER") {
                navigate("/profile-page");
              } else if (decode.role === "PARTNER") {
                navigate("/interests");
              }
            });
        });
    });
  };

  const handleResetCode = () => {
    dispatch(
      getNewValidationCode({ email: registerState.userInfo.emailAddress })
    ).then(() => {
      setModalContent(modalContentType.RESET_VALIDATION_CODE);
      setShowModal(true);
    });
  };

  return (
    <Grid item xs={12} className={"page-RegisterPage-codeVerification-wrapper"}>
      <div className={"page-RegisterPage-codeVerification-title-wrapper"}>
        <p className="page-RegisterPage-codeVerification-title-text">
          <FormattedMessage id={"page.register.verify.account"} />
        </p>
        <p className="page-RegisterPage-codeVerification-title-description">
          <FormattedMessage id={"page.register.enter.code"} />
        </p>
      </div>

      <CodeVerification
        length={6}
        label=""
        // loading={loading}
        onComplete={methodForOnComplete}
        setNewCode={setNewCode}
      />

      <div className="page-RegisterPage-codeVerification-resetCode-wrapper">
        <p
          className="page-RegisterPage-codeVerification-resetCode-btn"
          onClick={handleResetCode}
        >
          <FormattedMessage id={"card.register.resendEmail"} />
        </p>
      </div>

      <Grid item xs={12} className={"page-RegisterPage-buttons-wrapper"}>
        <ButtonComponent
          buttonText={"page.register.backButton"}
          onClick={() => handleStep(2)}
          selected={mobileWidth && true}
          color="gold"
        />

        <ButtonComponent
          buttonText={"page.register.nextButton"}
          onClick={handleVerify}
          selected={mobileWidth && true}
          color="gold"
        />
      </Grid>
      <CustomModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        directionTo="top"
        modalContent={modalContent}
        setShowModal={setShowModal}
      />
    </Grid>
  );
};
Step3.prototype = {
  handleStep: PropTypes.func,
  userInfoState: PropTypes.shape(),
  registerState: PropTypes.shape(),
};

export default Step3;
