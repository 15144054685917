import React, { StrictMode } from "react";

// LIBRARIES
import ReactDOM from "react-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// STYLE
import "index.scss";

// REDUX
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Provider } from "react-redux";
import store from "store/store";

// COMPONENTS
import App from "./App";

let persistor = persistStore(store);

const stripePromise = loadStripe(
  "pk_test_51KxUmbECdTSmedexNh7rtoUWOad618nZ7HEeaMOnlkanqihsP4rqGXFsrPraDZ33k1bFWcHWEYAocu3uDbV9tpWC00cIYgSSsO"
);

const options = {
  // passing the client secret obtained from the server
  // clientSecret: "pi_3L8SQnECdTSmedex0QaR4Lqq_secret_8dCfmrlK0l7fzBqAD0uHR7DtE",
};

ReactDOM.render(
  <StrictMode>
    <Elements stripe={stripePromise} options={options}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </Elements>
  </StrictMode>,
  document.getElementById("root")
);
