import React, { useEffect, useState } from "react";

// STYLES
import "./ViewIdeaDetailsCard.scss";

// LIBRARIES
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";

// CONSTANTS & MOCKS
import { userRolesTypes } from "constants/userRoles";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { getViewIdeaData } from "api/sessionApi";
import { viewIdeaProject, handleViewAsGuest } from "slices/sessionSlice";

// COMPONENTS
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import CustomModal from "components/CustomModal/CustomModal";
import ModalRegistrationContent from "components/ModalRegistrationContent/ModalRegistrationContent";
import { decodeJwtToken } from "helpers/decoder";
import { handleSavePayToViewProjectId } from "slices/projectSlice";

const ViewIdeaDetailsCard = () => {
  // PROPS

  // CONSTANTS USING LIBRARYS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const viewIdeaData = useSelector(viewIdeaProject);
  const id = useParams();

  // CONSTANTS USING HOOKS
  const [showModal, setShowModal] = useState(false);
  const [addClass, setAddClass] = useState(false);

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION
  useEffect(() => {
    dispatch(getViewIdeaData(id.id));
    // eslint-disable-next-line
  }, []);

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  const handleViewProject = () => {
    switch (decodeJwtToken().role) {
      case userRolesTypes.ADMIN:
        return navigate(`/overview/${id.id}`);
      default: {
        // dispatch(handleSavePayToViewProjectId(id.id));
        // setShowModal(true);
        return navigate(`/overview/${id.id}`);
      }
    }
  };

  const handleLogin = () => {
    if (userRolesTypes.GUEST) {
      dispatch(handleViewAsGuest(id.id));
    }
    navigate("/login");
  };

  const showIdeaModalContent = () => {
    // TODO: use custom modal with contentForModal
    if (decodeJwtToken().role !== null || decodeJwtToken().role !== undefined) {
      switch (decodeJwtToken().role) {
        case userRolesTypes.GUEST:
        case userRolesTypes.BRAINER:
          return <ModalRegistrationContent setShowModal={setShowModal} />;
        case userRolesTypes.PARTNER:
          dispatch(handleSavePayToViewProjectId(id.id));
          navigate("/upload-documents-for-view-idea");
          break;
        default:
          return (
            <ButtonComponent
              buttonText={"component.redirect.login.btn"}
              onClick={handleLogin}
            />
          );
      }
    } else {
      return (
        <ButtonComponent
          buttonText={"component.redirect.login.btn"}
          onClick={handleLogin}
        />
      );
    }
  };

  return (
    <div className="component-view-idea-details-card-container">
      <div className="component-view-idea-details-card-image-wrapper">
        <div className="project-image">
          <img
            src={`data:image/png;base64,${viewIdeaData?.projectMainImage?.content}`}
            alt=""
          />
        </div>
        <div className="brainer-profile-image">
          <img
            className={classNames(
              addClass && "big-brainer-profile-image-not-hovered",
              "big-brainer-profile-image"
            )}
            src={`data:image/png;base64,${viewIdeaData?.teamMembers?.[0]}`}
            alt=""
          />
          {viewIdeaData?.teamMembers?.length > 1 &&
            viewIdeaData?.teamMembers?.map((element, index) => (
              <img
                className="small-brainer-profile-image"
                key={`item--key--${index}`}
                src={`data:image/png;base64,${element}`}
                alt=""
                onMouseEnter={() => {
                  setAddClass(true);
                }}
                onMouseLeave={() => {
                  setAddClass(false);
                }}
              />
            ))}
        </div>
      </div>
      <div className="component-view-idea-details-card-details-wrapper">
        <p className="title">{viewIdeaData?.projectName}</p>
        <p className="text">{viewIdeaData?.projectDescription}</p>
        <div className="buttons">
          <ButtonComponent
            buttonText={"view.idea.details.card.back.button"}
            onClick={() => navigate("/explore")}
          />
          <ButtonComponent
            buttonText={"view.idea.details.card.viewProject.button"}
            onClick={handleViewProject}
          />
        </div>
      </div>
      <CustomModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        directionTo="top"
        showIdeaModalContent={showIdeaModalContent}
        ideaModal={true}
      />
    </div>
  );
};
export default ViewIdeaDetailsCard;
