import React, { useState } from "react";

// STYLES
import "./ModalContent.scss";

// LIBRARIES
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

// CONSTANTS & MOCKS

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { investInProject } from "api/projectApi";
import { investmentProjectId } from "slices/projectSlice";

// COMPONENTS
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import { decodeJwtToken } from "helpers/decoder";

const InvestInProjectModalContent = (props) => {
  // PROPS
  const { setShowModal = () => {} } = props;
  // CONSTANTS USING LIBRARYS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const projectId = useSelector(investmentProjectId);

  // CONSTANTS USING HOOKS
  const [invested, setInveste] = useState(false);

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS
  const handleInvest = () => {
    let payload = {
      projectId,
      partnerEmail: decodeJwtToken().sub,
    };
    dispatch(investInProject(payload)).then(() => {
      // navigate("/profile-page");
      setInveste(true);
    });
  };

  // HANDLERS FUNCTIONS

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleReject = () => {
    navigate("/homepage");
  };

  return (
    <div className="component-modal-project-uploaded-container">
      {!invested && (
        <>
          <p className="title">
            <FormattedMessage
              id={"project.overview.details.partner.invest.modal.title"}
            />
          </p>
          <p className="text">
            <FormattedMessage
              id={"project.overview.details.partner.invest.modal.text"}
            />
          </p>

          <div className="buttons">
            <ButtonComponent
              color="blue"
              buttonText={"modal.validation.project.go.to.home.button"}
              onClick={handleReject}
            />
            <ButtonComponent
              color="blue"
              buttonText={"modal.validation.project.invest.button"}
              onClick={handleInvest}
            />
          </div>
        </>
      )}
      {invested && (
        <>
          <p className="title">
            <FormattedMessage
              id={"project.overview.details.partner.invested.modal.title"}
            />
          </p>
          <p className="text">
            <FormattedMessage
              id={"project.overview.details.partner.invested.modal.text"}
            />
          </p>

          <div className="buttons">
            <ButtonComponent
              color="blue"
              buttonText={"modal.component.ok.button"}
              onClick={handleCloseModal}
            />
          </div>
        </>
      )}
    </div>
  );
};

InvestInProjectModalContent.propTypes = {
  setShowModal: PropTypes.func,
  showNumber: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  showSecondButton: PropTypes.bool,
  secondButtonText: PropTypes.string,
};

export default InvestInProjectModalContent;
