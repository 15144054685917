import React, { useState } from "react";

// STYLES
import "./OverviewProjectVisualPresentation.scss";

// LIBRARIES
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

// CONSTANTS & MOCKS
import { FileExtensions } from "constants/constants";
// REDUX

// COMPONENTS
import DefaultVideoImage from "assets/pictures/DefaultVideo.jpeg";

const OverviewProjectVisualPresentation = (props) => {
  // PROPS
  const { contentToDisplay = [] } = props;
  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS
  const [selectedIndex, setSelectedIndex] = useState(0);

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  const handleIncrementIndex = () => {
    if (selectedIndex < contentToDisplay.length - 1) {
      setSelectedIndex((prevState) => prevState + 1);
    } else {
      setSelectedIndex(0);
    }
  };

  const handleDecrementIndex = () => {
    if (selectedIndex > 0) {
      setSelectedIndex((prevState) => prevState - 1);
    } else {
      setSelectedIndex(contentToDisplay.length - 1);
    }
  };

  const handleRenderDocument = (documentModel) => {
    return (
      <>
        <ArrowCircleLeftIcon
          className="left-arrow"
          onClick={() => handleDecrementIndex()}
        />
        {documentModel &&
          FileExtensions.VIDEO.includes(documentModel.fileExtension) && (
            <video
              controls
              src={`data:video/mp4;base64,${documentModel.content}`}
              id="preview"
              className="video"
            />
          )}
        {documentModel &&
          FileExtensions.IMAGE.includes(documentModel.fileExtension) && (
            <img
              src={`data:image/png;base64,${documentModel.content}`}
              alt=""
              id="preview"
            />
          )}
        {documentModel &&
          FileExtensions.LINK.includes(documentModel.fileExtension) && (
            <ReactPlayer url={documentModel.content} width={"100%"} height={"100%"}/>
          )}
        <ArrowCircleRightIcon
          className="right-arrow"
          onClick={() => handleIncrementIndex()}
        />
      </>
    );
  };

  const handleRenderDocumentPreview = (document, index) => {
    return (
      <>
        {document && FileExtensions.VIDEO.includes(document.fileExtension) && (
          <img
            src={DefaultVideoImage}
            alt=""
            className={
              selectedIndex === index
                ? "preview-image selected"
                : "preview-image not-selected"
            }
            onClick={() => setSelectedIndex(index)}
            draggable={false}
            key={`document--${index}`}
          />
        )}
        {document && FileExtensions.LINK.includes(document.fileExtension) && (
          <img
            src={DefaultVideoImage}
            alt=""
            className={
              selectedIndex === index
                ? "preview-image selected"
                : "preview-image not-selected"
            }
            onClick={() => setSelectedIndex(index)}
            draggable={false}
            key={`document--${index}`}
          />
        )}
        {document && FileExtensions.IMAGE.includes(document.fileExtension) && (
          <img
            src={`data:image/png;base64,${document.content}`}
            alt=""
            className={
              selectedIndex === index
                ? "preview-image selected"
                : "preview-image not-selected"
            }
            onClick={() => setSelectedIndex(index)}
            draggable={false}
            key={`document--${index}`}
          />
        )}
      </>
    );
  };

  return (
    <div className="component-overview-project-video-images-container">
      <div className="shown-component-container">
        {contentToDisplay &&
          contentToDisplay.length > 0 &&
          handleRenderDocument(contentToDisplay?.[selectedIndex])}
      </div>
      <div className="component-overview-project-images-wrapper">
        {contentToDisplay &&
          contentToDisplay.length > 1 &&
          contentToDisplay.map((documentModel, index) =>
            handleRenderDocumentPreview(documentModel, index)
          )}
      </div>
    </div>
  );
};

OverviewProjectVisualPresentation.propTypes = {
  contentToDisplay: PropTypes.array,
};

export default OverviewProjectVisualPresentation;
