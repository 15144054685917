import React, { useState } from "react";

// STYLES
import "./PartnerProjectCard.scss";

// LIBRARIES
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

// CONSTANTS & MOCKS
import {
  LOGO,
  modalContentType,
  projectDocuments,
} from "constants/constants";
// REDUX
import { useDispatch } from "react-redux";
import { handleSaveInvestmentProjectId } from "slices/projectSlice";

// COMPONENTS
import clock from "assets/pictures/clock.svg";
import CustomModal from "components/CustomModal/CustomModal";

const PartnerProjectCard = (props) => {
  // PROPS
  const {
    time = "",
    // TODO: add fallbacks
    profile = false,
    project = {},
  } = props;
  // CONSTANTS USING LIBRARYS
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // CONSTANTS USING HOOKS
  const [showModal, setShowModal] = useState(false);
  const [currentModalContent, setCurrentModalContent] = useState("");
  const [showButtons, setShowButtons] = useState(false);
  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  const handleOpenModal = (modalContentCase) => {
    dispatch(handleSaveInvestmentProjectId(project?.projectId));
    setShowModal(true);
    setCurrentModalContent(modalContentCase);
  };

  return (
    <div
      className="component-partner-card-container"
      onMouseEnter={() => {
        setShowButtons(true);
      }}
      onMouseLeave={() => {
        setShowButtons(false);
      }}
      onClick={() => {
        window.screen.width < 720 && setShowButtons(!showButtons);
      }}
    >
      {profile && showButtons && (
        <div className="component-partner-card-buttons-container">
          <div className="component-partner-card-clock-container">
            <div className="component-partner-card-clock">
              <img src={clock} alt="" />
            </div>
            <p className="component-partner-card-clock-time">{time} d</p>
          </div>
          <p
            className={classNames("component-partner-buttons", "green")}
            onClick={() =>
              handleOpenModal(modalContentType.PARTNER_PROFILE_CARD_INVEST)
            }
          >
            <FormattedMessage
              id={"projects.card.overlayThree.green.button.text"}
            />
          </p>
          <p
            className={classNames("component-partner-buttons", "gold")}
            onClick={() =>
              handleOpenModal(modalContentType.PARTNER_PROFILE_CARD_REJECT)
            }
          >
            <FormattedMessage
              id={"projects.card.overlayThree.gold.button.text"}
            />
          </p>
          <p
            className={classNames("component-partner-buttons", "blue")}
            onClick={() => navigate(`/overview/${project?.projectId}`)}
          >
            <FormattedMessage
              id={"projects.card.overlayThree.blue.button.text"}
            />
          </p>
        </div>
      )}
      <div className="component-partner-card-project-image-container">
        <img
          className={"component-partner-card-project-image"}
          src={`data:image/png;base64,${
            project?.projectMainImage?.content ||
            project?.documents?.find(
              (document) =>
                document.documentType === projectDocuments.PROJECT_MAIN_IMAGE
            )?.content
          }`}
          alt="#"
        />
      </div>
      <div className="component-partner-card-profile-image-container">
        <img
          className={classNames(
            "component-partner-card-profile-image",
            showButtons && "removed-absolute"
          )}
          src={`data:image/png;base64,${
            project?.brainerDocument?.content ||
            project?.documents?.find(
              (document) => document.documentType === LOGO
            )?.content
          }`}
          alt=""
        />
      </div>
      <div className="component-partner-card-project-details-container">
        <p className="component-partner-card-project-details-title">
          {project.projectName}
        </p>
        <p className="component-partner-card-project-details-text">
          {project.projectDescription || project.projectShortDescription}
        </p>
      </div>

      <CustomModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        directionTo="top"
        modalContent={currentModalContent}
        setShowModal={setShowModal}
      />
    </div>
  );
};

export default PartnerProjectCard;
