import React, { useState } from "react";

// STYLES

// LIBRARIES
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage } from "react-intl";
import Dropzone from "react-dropzone";

// CONSTANTS & MOCKS
import { BUSINESS_PLAN, BYTES } from "constants/constants";

// REDUX

// COMPONENTS
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import fileLogo from "assets/projectBuilder/fileLogo.svg";

const BusinessPlanComponent = (props) => {
  // PROPS
  const { formState = {}, handleChange = () => {} } = props;

  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS
  const [numberOfDocuments, setNumberOfDocuments] = useState(1);

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  const handleUploadDocument = (file) => {
    let extension = file[0].type.split("/");

    let documentModel = {
      type: BUSINESS_PLAN,
      file: file[0],
      fileName: file[0].name,
      fileExtension: extension[1],
    };

    let newBusinessPlanFile = [
      ...formState.businessPlan.businessPlanFile,
      documentModel,
    ];

    let newBusinessPlanObject = {
      type: BUSINESS_PLAN,
      businessPlanFile: newBusinessPlanFile,
    };

    handleChange(newBusinessPlanObject);
  };

  const handleRemoveDocument = (documentIndex) => {
    let newBusinessPlanFile = [...formState.businessPlan.businessPlanFile];
    newBusinessPlanFile.splice(documentIndex, 1);

    let newBusinessPlanObject = {
      type: BUSINESS_PLAN,
      businessPlanFile: newBusinessPlanFile,
    };

    handleChange(newBusinessPlanObject);
  };

  // TODO: move this function into a .js file
  const UploadDocument = ({ documentIndex }) => {
    let document = {};
    if (formState?.businessPlan.businessPlanFile[documentIndex]) {
      document = formState?.businessPlan.businessPlanFile[documentIndex];
    }

    return (
      <div className="upload-card">
        <div className="logo-wrapper">
          <img src={fileLogo} alt="" />
        </div>
        <div className="document-description-wrapper">
          <p className="document-description">
            Document {documentIndex + 1}:{" "}
            {document.fileName && document.fileName}
          </p>
        </div>
        <div className="upload-btn-wrapper">
          <div className="component-NdaCard-buttons-wrapper">
            {!document.fileName ? (
              <Dropzone
                onDrop={handleUploadDocument}
                maxFiles={1}
                maxSize={10 * BYTES} // MB
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <ButtonComponent
                      buttonText={"page.register.uploadButton"}
                    />
                    <input {...getInputProps()} accept={"application/pdf"} />
                  </div>
                )}
              </Dropzone>
            ) : (
              <div>
                <ButtonComponent
                  // TODO: add intl to button text
                  buttonText={"Remove"}
                  onClick={() => handleRemoveDocument(documentIndex)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const handleIncreaseNumberOfDocuments = () => {
    setNumberOfDocuments((prevState) => prevState + 1);
  };

  return (
    <div className="component-BusinessPlanComponent-container">
      <div className="page-build-project-card-title-wrapper">
        <p className={"title-number"}>2.</p>
        <div className="title-text-wrapper">
          <p className="title">
            <FormattedMessage
              id={"component.project.builder.title.businessPlan"}
            />
          </p>
          <p className="subtitle">
            <FormattedMessage
              id={"component.project.builder.subTitle.mandatoryDocuments"}
            />
          </p>
        </div>
      </div>
      <div className="component-BusinessPlanComponent-disclaimer">
        <p className="business-plan-disclaimer">
          <FormattedMessage
            id={"component.project.builder.disclaimer.businessPlan"}
          />
        </p>
      </div>
      <div className="component-BusinessPlanComponent-upload-card-wrapper">
        {[...Array(numberOfDocuments)].map((e, i) => (
          <UploadDocument documentIndex={i} key={`upload--${i}`} />
        ))}
        <div className="add-button-wrapper">
          <ButtonComponent
            buttonText={"component.project.builder.button.projectLink"}
            onClick={handleIncreaseNumberOfDocuments}
          />
        </div>
      </div>
    </div>
  );
};

BusinessPlanComponent.propTypes = {
  projectId: PropTypes.number,
};

export default injectIntl(BusinessPlanComponent);
