import { createAsyncThunk } from "@reduxjs/toolkit";
import { REST, REST_WITH_UPLOAD } from "config/axiosConfig";

export const createUser = createAsyncThunk(
  "user/createUser",
  async (data, {rejectWithValue}) => {
    try {
      const response = await REST_WITH_UPLOAD.post("/account/register", data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createCompany = createAsyncThunk(
  "user/createCompany",
  async (data, {rejectWithValue}) => {
    try {
      const response = await REST_WITH_UPLOAD.post("/company/create", data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const verifyAccount = createAsyncThunk(
  "user/activate",
  async (data, {rejectWithValue}) => {
    try {
      const response = await REST.put("/account/activate", data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const changePassword = createAsyncThunk(
  "account/change-password",
  async (data, {rejectWithValue}) => {
    try {
      const response = await REST.put("/account/change-password", data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const editAccountInfo = createAsyncThunk(
  "account/update-personal-information",
  async (data, {rejectWithValue}) => {
    try {
      const response = await REST.put(
        "/account/update-personal-information",
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const editCompanyInfo = createAsyncThunk(
  "account/update-company-information",
  async (data, {rejectWithValue}) => {
    try {
      const response = await REST.put(
        "/company/update-company-information",
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getBrainerProjects = createAsyncThunk(
  "account/getBrainerProjects",
  async (data, {rejectWithValue}) => {
    try {
      const response = await REST.get(
        `/project/brainer/ideas?emailAddress=${data.email}&projectStatus=${data.projectStatus}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPartnerBlockedProjects = createAsyncThunk(
  "account/getPartnerBlockedProjects",
  async (data, {rejectWithValue}) => {
    try {
      const response = await REST.get(
        `/project/partner/blocked?emailAddress=${data.email}&projectStatus=${data.projectStatus}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPartnersNda = createAsyncThunk(
  "account/getPartnerBlockedProjects",
  async (data, {rejectWithValue}) => {
    try {
      const response = await REST.get(
        `/partner/ndas?emailAddress=${data.email}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);




