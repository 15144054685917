import React, { useState, useRef } from "react";

// STYLES
import "./CodeVerification.scss";

// LIBRARIES

// CONSTANTS & MOCKS

// REDUX

// COMPONENTS

const CodeVerification = ({
  length,
  label,
  loading,
  onComplete,
  setNewCode,
}) => {
  // PROPS

  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS
  const [code, setCode] = useState([...Array(length)].map(() => ""));
  const inputs = useRef([]);

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  const processInput = (e, slot) => {
    const num = e.target.value;
    if (/[^0-9]/.test(num)) return;
    const newCode = [...code];
    newCode[slot] = num;
    setCode(newCode);
    if (slot !== length - 1 && newCode[slot] !== "") {
      inputs.current[slot + 1].focus();
    }
    if (newCode.every((num) => num !== "")) {
      onComplete(newCode.join(""));
    }
  };

  const onKeyUp = (e, slot) => {
    if (e.keyCode === 8 && !code[slot] && slot !== 0) {
      const newCode = [...code];
      newCode[slot] = "";
      setCode(newCode);
      inputs.current[slot - 1].focus();
    }
  };

  return (
    <div className="component-CodeVerification-container">
      <label className="code-label">{label}</label>
      <div className="code-inputs">
        {code?.map((num, index) => {
          return (
            <div key={`item--${index}`}>
              <input
                key={index}
                type="text"
                inputMode="numeric"
                maxLength={1}
                value={num || ""}
                autoFocus={!code[0].length && index === 0}
                readOnly={loading}
                onChange={(e) => processInput(e, index)}
                onKeyUp={(e) => onKeyUp(e, index)}
                ref={(ref) => inputs.current.push(ref)}
                className={"single-digit-input"}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CodeVerification;
