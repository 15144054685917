export const loginInputs = [
  {
    label: 'card.login.label.emailInput',
    placeholder: "card.login.placeholder.emailInput",
    type: "text",
    name: "email",
    required: true,
    component: "textInput"
  },
  {
    label: 'card.login.label.passwordInput',
    placeholder: "card.login.placeholder.passwordInput",
    type: "password",
    name: "password",
    required: true,
    component: "textInput"
  },
]

export const forgotPasswordInputs = [
  {
    label: 'card.login.label.emailForgotInput',
    placeholder: "card.login.placeholder.emailForgotInput",
    type: "text",
    name: "forgotEmail",
    required: true,
    component: "textInput"
  },
]

export const createNewPasswordsInputs = [
  {
    label: 'card.reset.password.newPassText',
    placeholder: "card.reset.password.newPassText.placeholder",
    type: "password",
    name: "newPassword",
    required: true,
    component: "textInput"
  },
  {
    label: 'card.reset.password.confirmNewPassText',
    placeholder: "card.reset.password.confirmNewPassText.placeholder",
    type: "password",
    name: "confirmNewPassword",
    required: true,
    component: "textInput"
  },
]