import businessConsulting from "assets/helpingBrainers/businessConsulting/businessConsulting1.jpg";
import legalAdvice from "assets/helpingBrainers/legalAdvice/legalAdvice1.jpg";
import trademarkRegistration from "assets/helpingBrainers/trademarkRegistration/trademarkRegistration1.jpg";

export const consultingCards = [
  {
    topSubtitle: "component.consulting.card.registration.topsubtitle",
    bottomSubtitle: "component.consulting.card.registration.bottomsubtitle",
    text: "component.consulting.card.registration.text",
    image: businessConsulting,
  },
  {
    topSubtitle: "component.consulting.card.registration.topsubtitle1",
    bottomSubtitle: "component.consulting.card.registration.bottomsubtitle",
    text: "component.consulting.card.registration.text1",
    image: trademarkRegistration,
  },
  {
    topSubtitle: "component.consulting.card.registration.topsubtitle2",
    bottomSubtitle: "component.consulting.card.registration.bottomsubtitle",
    text: "component.consulting.card.registration.text2",
    image: legalAdvice,
  },
];
export const consultingCards2 = [
  {
    topSubtitle: "component.consulting.card.registration.topsubtitle-partner",
    bottomSubtitle: "component.consulting.card.registration.bottomsubtitle",
    text: "component.consulting.card.registration.text-partner",
  },
  {
    topSubtitle: "component.consulting.card.registration.topsubtitle-partner1",
    bottomSubtitle: "component.consulting.card.registration.bottomsubtitle",
    text: "component.consulting.card.registration.text-partner1",
  },
];
