import React, { useState } from "react";

// STYLES
import "./AdvertisementCard.scss";

// LIBRARIES
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

// CONSTANTS & MOCKS
import { userRolesTypes } from "constants/userRoles";

// REDUX
import {
  handleSetStatusBrainer,
  handleSetStatusPartner,
  userRole,
} from "slices/sessionSlice";

// COMPONENTS
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import CustomModal from "components/CustomModal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import ModalViewPartnersMeeting from "components/ModalViewPartnersMeeting/ModalViewPartnersMeeting";

const AdvertisementCard = (props) => {
  // PROPS
  const {
    reverse = false,
    image = "",
    color = "",
    title = "",
    description = "",
    buttonText = "",
    page = "",
  } = props;

  // CONSTANTS USING LIBRARYS
  const navigate = useNavigate();
  const role = useSelector(userRole);

  const dispatch = useDispatch();

  // CONSTANTS USING HOOKS
  const [showModal, setShowModal] = useState(false);

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  const handleNavigation = () => {
    if (role !== userRolesTypes.GUEST) {
      navigate(`/${page}`);

      if (page === "consulting") {
        if (role === userRolesTypes.BRAINER) {
          dispatch(handleSetStatusBrainer(true));
          dispatch(handleSetStatusPartner(false));
        } else if (role === userRolesTypes.PARTNER) {
          dispatch(handleSetStatusBrainer(false));
          dispatch(handleSetStatusPartner(true));
        }
      }
    } else {
      setShowModal(true);
    }
  };

  const handleModalContent = () => {
    if (role === userRolesTypes.GUEST) {
      return <ModalViewPartnersMeeting setShowModal={setShowModal} />;
    }
  };

  return (
    <div
      className={`component-advertisement-card-container ${
        reverse && "component-advertisement-card-container-reverse"
      }`}
    >
      <div className="component-advertisement-card-text">
        <div className="component-advertisement-title">
          <FormattedMessage id={title} />
        </div>
        <p className="component-advertisement-description">
          <FormattedMessage id={description} />
        </p>
        <div className="component-advertisement-button">
          <ButtonComponent
            buttonText={buttonText}
            color={color}
            // onClick={() => navigate(`/${page}`)}
            onClick={handleNavigation}
          />
        </div>
      </div>
      <div className="component-advertisement-card-image">
        <img src={image} alt="" />
      </div>
      <CustomModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        directionTo="top"
        showIdeaModalContent={handleModalContent}
        ideaModal={true}
      />
    </div>
  );
};

AdvertisementCard.propTypes = {
  reverse: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  image: PropTypes.string,
  color: PropTypes.string,
  page: PropTypes.string,
};

export default AdvertisementCard;
