import React, { useState } from "react";

// STYLES
import "view/ResetPassword/ResetPassword.scss";
import heroImageDesktop from "assets/components/registerPage/X.svg";
import heroImageMobile from "assets/components/HeroComponent/heroImageMobile.jpg";
import heroImageTablet from "assets/components/HeroComponent/heroImageTablet.jpg";

// LIBRARIES
import { FormattedMessage, injectIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

// CONSTANTS & MOCKS
import { createNewPasswordsInputs } from "../SelectRolePage/Mocks/inputsMock";

// REDUX
import { useDispatch } from "react-redux";
import { resetPassword } from "api/sessionApi";

// COMPONENTS
import TextInput from "components/Atoms/TextInput/TextInput";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";

const ResetPassword = (props) => {
  // PROPS
  const { intl } = props;

  // CONSTANTS USING LIBRARIES
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // CONSTANTS USING HOOKS
  const [formState, setFormState] = useState({
    values: {
      newPassword: "",
      confirmNewPassword: "",
    },
    error: "",
  });

  // GENERAL CONSTANTS

  // HANDLERS FUNCTIONS
  const handleChange = (event) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleResetPassword = () => {
    const urlEmail = window.location.href.split("=");

    const body = {
      ...formState.values,
      emailAddress: urlEmail[1],
    };

    dispatch(resetPassword(body))
      .unwrap()
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        const errorString = error.errors[0].split(":");
        setFormState((formState) => ({
          ...formState,
          error: errorString[0],
        }));
      });
  };

  return (
    <div className="page-reset-password-container">
      <img
        className="page-reset-password-image"
        src={
          window.innerWidth <= 599
            ? heroImageMobile
            : window.innerWidth <= 1279
            ? heroImageTablet
            : heroImageDesktop
        }
        alt=""
      />
      <div className="page-reset-password-card-wrapper">
        <div className={"page-reset-password-title-wrapper"}>
          <p className="page-reset-password-title-text">
            <FormattedMessage id={"card.reset.password.title"} />
          </p>
        </div>
        <div className={"page-reset-password-card-inputs-wrapper"}>
          <p className="page-reset-password-subtitle-forgot-pass-text">
            <FormattedMessage id={"card.reset.password.subtitle"} />
          </p>
          {createNewPasswordsInputs?.map((item, index) => {
            return (
              <div
                className="page-reset-password-card-inputs-item"
                key={`log-in-card-inputs-${index}`}
              >
                <TextInput
                  key={`item--${index}--${item.label}`}
                  label={item?.label}
                  labelColor={"blue"}
                  placeholder={intl.formatMessage({ id: item.placeholder })}
                  name={item?.name}
                  type={item?.type}
                  handleChange={handleChange}
                  select={item.component === "select" ? true : false}
                  component={item.component}
                  value={formState.values[item.name] || ""}
                  labelStyle="thin"
                />
              </div>
            );
          })}

          {formState?.error && (
            <p className="page-reset-password-error-msg">
              {formState.error || ""}
            </p>
          )}
        </div>

        <div className="page-reset-password-card-button-wrapper">
          <ButtonComponent
            buttonText={"card.reset.password.label.button"}
            color={"blue"}
            onClick={handleResetPassword}
          />
        </div>
      </div>
    </div>
  );
};

ResetPassword.prototype = {
  //TODO fix this patzzz
  intl: PropTypes.shape(),
};

export default injectIntl(ResetPassword);
