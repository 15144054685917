import classNames from "classnames";
import React, { useState } from "react";

// STYLES
import "./SelectRoleCard.scss";

// LIBRARIES
import useMediaQuery from "@mui/material/useMediaQuery";
import { FormattedMessage } from "react-intl";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";

// CONSTANTS & MOCKS
import { userRolesTypes } from "constants/userRoles";

// REDUX
import { useDispatch } from "react-redux";
import { handleUserRole } from "slices/sessionSlice";
import { useNavigate } from "react-router-dom";
// COMPONENTS

const SelectRoleCard = (props) => {
  // PROPS
  const {
    title = "",
    text = "",
    buttonText = "",
    question = "",
    isReversed = false,
    side = "",
    isHovered = false,
    setIsHovered = () => {},
    isBrainerHovered = false,
    setIsBrainerHovered = () => {},
    isPartnerHovered = false,
    setIsPartnerHovered = () => {},
    isBrainerClicked = false,
    setIsBrainerClicked = () => {},
    isPartnerClicked = false,
    setIsPartnerClicked = () => {},
    isClicked = false,
    setIsClicked = () => {},
  } = props;
  // CONSTANTS USING LIBRARYS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showInfo, setShowInfo] = useState(false);
  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS
  const isMobile = useMediaQuery("(max-width:600px)");
  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  const handleHover = () => {
    setIsHovered(true);
    if (side === "brainer") {
      if (isPartnerHovered) {
        setIsPartnerHovered(false);
        setTimeout(() => {
          setIsBrainerHovered(true);
        }, 300);
      } else if (!isPartnerHovered) {
        setIsBrainerHovered(true);
      }
    }

    if (side === "partner") {
      if (isBrainerHovered) {
        setIsBrainerHovered(false);
        setTimeout(() => {
          setIsPartnerHovered(true);
        }, 300);
      } else if (!isBrainerHovered) {
        setIsPartnerHovered(true);
      }
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleNav = (path, userType) => {
    if (side === "partner") {
      dispatch(handleUserRole(userRolesTypes.PARTNER));
      navigate("/register");
    } else {
      dispatch(handleUserRole(userRolesTypes.BRAINER));
      navigate("/register");
    }
  };

  const handleClickMobile = () => {
    if (!isClicked) {
      setIsClicked(true);
      if (side === "partner") {
        setIsPartnerClicked(true);
        setShowInfo(true);

        // setTimeout(() => {
        //   setShowInfo(true);
        // }, 1000);
      } else if (side === "brainer") {
        setIsBrainerClicked(true);
        setShowInfo(true);

        // setTimeout(() => {
        //   setShowInfo(true);
        // }, 1000);
      }
    }
  };

  const handleBackClickMobile = () => {
    setIsClicked(false);

    setIsPartnerClicked(false);
    setIsBrainerClicked(false);
    setShowInfo(false);
  };

  
  return (
    <div
      className={classNames(
        `component-select-role-container`,
        !isMobile && isReversed && "reversed",
        isBrainerHovered &&
          side === "brainer" &&
          isHovered &&
          !isMobile &&
          "animation-left",
        isPartnerHovered &&
          side === "partner" &&
          isHovered &&
          !isMobile &&
          "animation-right",
        isBrainerClicked &&
          side === "brainer" &&
          isMobile &&
          "animation-up-brainer",
        isPartnerClicked &&
          side === "partner" &&
          isMobile &&
          "animation-up-partner",
        isClicked &&
          side === "partner" &&
          isBrainerClicked &&
          "animation-go-down",
        isClicked &&
          side === "brainer" &&
          isPartnerClicked &&
          "animation-go-down"
      )}
      onMouseEnter={() => {
        !isMobile && handleHover();
      }}
      onMouseLeave={handleMouseLeave}
      onClick={handleClickMobile}
    >
      <div className="component-select-role-wrapper">
        <div className="component-select-role-title-wrapper">
          <p>
            <FormattedMessage id={title} />
          </p>
        </div>

        {!isMobile && (
          <>
            <div className="component-select-role-text-wrapper">
              <p>
                <FormattedMessage id={text} />
              </p>
            </div>
            <div className="component-select-role-button-wrapper">
              <ButtonComponent
                buttonText={buttonText}
                color={"blue"}
                onClick={() => {
                  side === "partner"
                    ? handleNav("/register", userRolesTypes.PARTNER)
                    : handleNav("/register", userRolesTypes.BRAINER);
                }}
              />
            </div>
            <div className="component-select-role-question-wrapper">
              <p>
                <FormattedMessage id={question} />
              </p>
              <p className="component-select-role-link"> Log in</p>
            </div>
          </>
        )}
        {isMobile && showInfo && isClicked && (
          <div className="component-select-role-mobile-selected">
            <p className="component-select-role-mobile-text">
              <FormattedMessage id={text} />
            </p>

            <ButtonComponent
              buttonText={buttonText}
              color={"gold"}
              selected={true}
              onClick={handleNav}
            />

            <ButtonComponent
              buttonText={"Back"}
              color={"blue"}
              selected={true}
              onClick={handleBackClickMobile}
            />

            <div className="component-select-role-question-wrapper">
              <p>
                <FormattedMessage id={question} />
              </p>
              <p className="component-select-role-link"> Log in</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectRoleCard;
