import React from "react";

// STYLES
import "./ProgressBar.scss";

// LIBRARIES
import PropTypes from "prop-types";
import classNames from "classnames";

// CONSTANTS & MOCKS

const ProgressBar = (props) => {
  // PROPS
  const { step = 0 } = props;
  const stepsArray = new Array(3).fill(" ");
  return (
    <div className="component-progress-bar-wrapper">
      {stepsArray?.map((element, index) => {
        return (
          <React.Fragment key={index}>
            <p
              className={classNames(
                "component-progress-bar-item",
                step === index + 1 ? "goldBackground" : "blueBackground"
              )}
            >
              {index + 1}
            </p>
            {index !== 2 && <div className="component-progress-bar-line"></div>}
          </React.Fragment>
        );
      })}
    </div>
  );
};

ProgressBar.prototype = {
  step: PropTypes.number,
};

export default ProgressBar;
