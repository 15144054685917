import React from "react";

// STYLES
import "./PartnersMeetingModalContent.scss";

// LIBRARIES
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

// CONSTANTS & MOCKS

// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  handleSaveRequestId,
  paginationDetails,
  requestDetails,
} from "slices/adminSlice";
import { approveRequest } from "api/adminApi";

// COMPONENTS
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import { handleTableDataRequest } from "helpers/adminTablesFunctions";
import { AdminTabs, RequestStatus } from "constants/constants";

const ApproveProjectRequestContent = (props) => {
  // PROPS
  const { setShowModal = () => {}, type = "" } = props;

  // CONSTANTS USING LIBRARYS
  const dispatch = useDispatch();
  const requestDetailsData = useSelector(requestDetails);
  const paginationData = useSelector(paginationDetails);

  // CONSTANTS USING HOOKS

  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS
  const handleApproveRequest = () => {
    dispatch(approveRequest(requestDetailsData.requestId))
      .unwrap()
      .then(() => {
        // TODO: refactor this code
        handleTableDataRequest(
          {
            mainTab:
              type === "VIEW"
                ? AdminTabs.VIEW_PROJECT_REQUESTS
                : AdminTabs.PROJECT_REQUESTS,
            secondaryTab: RequestStatus.PENDING,
          },
          paginationData
        );
        setShowModal(false);
        dispatch(handleSaveRequestId(null));
      });
  };

  return (
    <div className="component-modal-validation-project-container">
      <p className="component-modal-validation-project-title">
        {type === "VIEW" && (
          <FormattedMessage id="modal.meeting.project.view.request.approve.title" />
        )}
        {type === "IDEA" && (
          <FormattedMessage id="modal.meeting.project.request.approve.title" />
        )}
      </p>
      <p className="component-modal-validation-project-text">
        {type === "VIEW" && (
          <FormattedMessage id="modal.meeting.project.view.request.approve.text" />
        )}
        {type === "IDEA" && (
          <FormattedMessage id="modal.meeting.project.request.approve.text" />
        )}
      </p>
      <div className="component-modal-validation-project-buttons">
        <ButtonComponent
          buttonText="Cancel"
          color="blue"
          onClick={() => setShowModal(false)}
        />
        <ButtonComponent
          buttonText="Yes"
          color="blue"
          onClick={handleApproveRequest}
        />
      </div>
    </div>
  );
};

ApproveProjectRequestContent.propTypes = {
  setShowModal: PropTypes.func,
  type: PropTypes.string,
};

export default ApproveProjectRequestContent;
