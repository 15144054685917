import React, { useState } from "react";

// LIBRARIES
import useMediaQuery from "@mui/material/useMediaQuery";

// CONSTANTS & MOCKS
// REDUX
// COMPONENTS
import logo from "assets/header/bp-logo.png";

// STYLES
import "./SelectRolePage.scss";
import SelectRoleCard from "components/SelectRoleCard/SelectRoleCard";

const SelectRolePage = () => {
  // PROPS

  // CONSTANTS USING LIBRARYS

  // CONSTANTS USING HOOKS

  const [isBrainerHovered, setIsBrainerHovered] = useState(false);
  const [isPartnerHovered, setIsPartnerHovered] = useState(false);
  const [isBrainerClicked, setIsBrainerClicked] = useState(false);
  const [isPartnerClicked, setIsPartnerClicked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  // GENERAL CONSTANTS

  // USE EFFECT FUNCTION

  // REQUEST API FUNCTIONS

  // HANDLERS FUNCTIONS

  return (
    <div className={"page-register-container"}>
      {isMobile && <div className="pages-register-mobile-gradient"></div>}
      {isMobile && (
        <img className="page-register-mobile-logo" src={logo} alt="" />
      )}
      {((isMobile && !isPartnerClicked) || !isMobile) && (
        <div className="page-register-brainer-container">
          <SelectRoleCard
            className="page-register-brainer-wrapper"
            title={"page.select.role.brainer.title"}
            text={"page.register.brainer.description"}
            buttonText={"page.register.role.selector.item.sign.up"}
            question={"page.register.existingaccount"}
            isReversed={false}
            isHovered={isHovered}
            setIsHovered={setIsHovered}
            isBrainerHovered={isBrainerHovered}
            setIsBrainerHovered={setIsBrainerHovered}
            isPartnerHovered={isPartnerHovered}
            setIsPartnerHovered={setIsPartnerHovered}
            setIsBrainerClicked={setIsBrainerClicked}
            isBrainerClicked={isBrainerClicked}
            setIsPartnerClicked={setIsPartnerClicked}
            isPartnerClicked={isPartnerClicked}
            isClicked={isClicked}
            setIsClicked={setIsClicked}
            side={"brainer"}
          />
        </div>
      )}
      {isMobile && !isClicked && (
        <p
          className="page-register-or-text"
          style={{ marginTop: "3rem", marginBottom: "3rem" }}
        >
          or
        </p>
      )}
      {((isMobile && !isBrainerClicked) || !isMobile) && (
        <div className="page-register-partner-container">
          <SelectRoleCard
            className="page-register-partner-wrapper"
            title={"page.select.role.partner.title"}
            text={"page.register.partner.description"}
            buttonText={"page.register.role.selector.item.sign.up"}
            question={"page.register.existingaccount"}
            isReversed={true}
            isHovered={isHovered}
            setIsHovered={setIsHovered}
            isPartnerHovered={isPartnerHovered}
            setIsPartnerHovered={setIsPartnerHovered}
            isBrainerHovered={isBrainerHovered}
            setIsBrainerHovered={setIsBrainerHovered}
            setIsBrainerClicked={setIsBrainerClicked}
            isBrainerClicked={isBrainerClicked}
            setIsPartnerClicked={setIsPartnerClicked}
            isPartnerClicked={isPartnerClicked}
            isClicked={isClicked}
            setIsClicked={setIsClicked}
            side={"partner"}
          />
        </div>
      )}
    </div>
  );
};

export default SelectRolePage;
