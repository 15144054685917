import { configureStore } from "@reduxjs/toolkit";
// import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import userSlice from "slices/userSlice";
import sessionSlice from "slices/sessionSlice";
import createProjectSlice from "slices/projectSlice";
import adminSlice from "slices/adminSlice";
import exploreSlice from "slices/exploreSlice"
import localforage from 'localforage';

const reducers = combineReducers({
  user: userSlice,
  session: sessionSlice,
  project: createProjectSlice,
  admin: adminSlice,
  explore: exploreSlice
});

// const persistConfig = {
//   key: "root",
//   storage,
// };

const persistConfig = {
  key: 'root',
  storage: localforage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export default store;
